import { CalendarChangeParams } from 'primereact/calendar';
import { useId } from 'react-id-generator';
import BeeErrorLabel from './BeeErrorLabel';
import BeeInfoLabel from './BeeInfoLabel';
import BeeTimeInput from './BeeTimeInput';

import './BeeValidatedTimeInput.scss';

type BeeValidatedTimeInputProps = {
  label?: string;
  errorLabel?: string;
  neutralLabel?: string;
  validLabel?: string;
  value?: Date;
  disabled?: boolean;
  formstate?: string;
  tabIndex?: number;
  readOnly?: boolean;
  required?: boolean;
  onChange?: (e: CalendarChangeParams) => void;
  onFocusOut?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
};

export function BeeValidatedTimeInput({
  label,
  errorLabel,
  neutralLabel,
  validLabel,
  value,
  disabled,
  formstate,
  tabIndex,
  readOnly,
  required,
  onChange,
  onFocusOut
}: BeeValidatedTimeInputProps) {
  const currentId = useId(1, 'bee-val-date-input-')[0];

  // classNames
  const labelClassNames = 'bee-validated-date-input';

  const textSize = 'medium';
  const inputFormstate = formstate === 'none' ? 'neutral' : formstate;

  function change(e: CalendarChangeParams) {
    if (!disabled && onChange) {
      onChange(e);
    }
  }
  function focusOut(e: React.FocusEvent<HTMLInputElement, Element>) {
    if (!disabled && onFocusOut) {
      onFocusOut(e);
    }
  }

  const renderLabel = () => {
    return (
      <div className={labelClassNames}>
        {formstate === 'error' && errorLabel ? (
          <BeeErrorLabel size={textSize} label={errorLabel} visible={true} />
        ) : formstate === 'valid' && validLabel ? (
          <BeeInfoLabel
            size={textSize}
            label={validLabel}
            type={'valid'}
            visible={true}
          />
        ) : formstate === 'neutral' && neutralLabel ? (
          <BeeInfoLabel
            size={textSize}
            label={neutralLabel}
            type={'neutral'}
            visible={true}
          />
        ) : null}
      </div>
    );
  };

  return (
    <div id={currentId}>
      <BeeTimeInput
        label={label}
        value={value}
        disabled={disabled}
        formstate={inputFormstate}
        tabIndex={tabIndex}
        readOnly={readOnly}
        required={required}
        onChange={(e) => change(e)}
        onFocusOut={(e) => focusOut(e)}
      />
      {formstate !== 'none' && (errorLabel || neutralLabel || validLabel)
        ? renderLabel()
        : null}
    </div>
  );
}

export default BeeValidatedTimeInput;
