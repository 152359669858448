import _ from 'lodash';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { DURATION_NOTIFICATION_ERROR_LONG } from '../../../Helper/Statics/Constants';
import {
  checkStepActivation,
  checkUserParticipation,
  STEP_IS_ACTIVE
} from '../../../Helper/Util/TimelineStepHelper';
import { AppDispatch, RootState } from '../../../Redux/store';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import BeeTimeline, { TimelineStep } from '../../Atoms/BeeTimeline';
import './DLOverviewPage.scss';
import DLOverviewTabView from './DLOverviewTabView';
import { hasUserWriteRights } from '../../../Helper/Util/JwtHelper';

type DLOverviewPageProps = {
  dlPhasen: any;
};

export function DLOverviewPage(props: DLOverviewPageProps) {
  const [phasen, setPhasen] = useState<TimelineStep[]>([]);
  const [selectedPhase, setSelectedPhase] = useState<TimelineStep>();
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useRef<Toast>(null);

  useEffect(() => {
    if (props.dlPhasen) {
      let steps: TimelineStep[] = [...props.dlPhasen];
      if (!_.isEqual(phasen, steps)) {
        setPhasen(steps);
        let activePhase: any;
        let stepFound = false;
        for (let i = 0; i < steps.length; i++) {
          if (checkStepActivation(steps[i]) === STEP_IS_ACTIVE) {
            activePhase = steps[i];
            if (checkUserParticipation(activePhase)) {
              stepFound = true;
              if (searchParams.get('pId') !== activePhase.id) {
                searchParams.set('pId', activePhase.id);
                setSearchParams(searchParams);
              }
            } else {
              while (i > 0) {
                if (checkUserParticipation(steps[i])) {
                  stepFound = true;
                  if (searchParams.get('pId') !== steps[i].id) {
                    searchParams.set('pId', steps[i].id);
                    setSearchParams(searchParams);
                    break;
                  }
                }
                i = i - 1;
              }
            }
            break;
          }
        }
        if (!stepFound) {
          if (steps.length > 0) {
            if (searchParams.get('pId') !== steps[0]?.id) {
              searchParams.set('pId', steps[0]?.id);
              setSearchParams(searchParams);
            }
          } else {
            toast.current?.show({
              severity: 'error',
              summary: 'Keine Phasen vorhanden',
              detail:
                'Aktuell wurden von der Administration noch keine Projektphasen hinterlegt. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut. ',
              sticky: true,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dlPhasen]);

  useEffect(() => {
    if (phasen) {
      const phaseId = searchParams.get('pId');
      if (!(selectedPhase && selectedPhase.id === phaseId)) {
        const currPhase: any = _.find(phasen, {
          id: phaseId
        });
        if (currPhase) {
          setReadOnly(
            !(
              checkStepActivation(currPhase) === STEP_IS_ACTIVE &&
              checkUserParticipation(currPhase) &&
              hasUserWriteRights()
            )
          );
          setSelectedPhase(currPhase);
        } else {
          setSelectedPhase(phasen[0]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, phasen]);

  ///////////////////////
  /// VIEW COMPONENTS ///
  ///////////////////////

  return (
    <div className={'dl-overview-page'}>
      <div className={'overview-header'}>
        <BeeContentHeadline
          label={'Preisübersicht'}
          headline={'h1'}
          type={'primary'}
        />
        {!phasen ? (
          <BeeLoadingSpinner strokeWidth={'3'} type={'primary'} />
        ) : null}
      </div>
      {phasen && selectedPhase ? (
        <>
          <BeeTimeline steps={phasen} currentPhase={selectedPhase} />
          <DLOverviewTabView phase={selectedPhase} readOnly={readOnly} />
        </>
      ) : null}
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}

const mapState = (state: RootState) => {
  return {
    dlPhasen: state.app.dlPhasen
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {};
};

export default connect(mapState, mapDispatchToProps)(DLOverviewPage);
