import _ from 'lodash';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { LiegenschaftsData } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import './AlphaAwardingDialog.scss';
import BeeButton from '../../Atoms/BeeButton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AuthUserType } from '../../../Helper/ApiHelper/LoginNetworkHelper';
import {
  KeyValEntry,
  createNewKeyValueForOrgaAndPhaseForAlpha,
  updateKeyValueForOrgaAndPhaseForAlpha
} from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import { generateAwardingKey } from '../../../Helper/Util/IdGeneratorHelper';
import { extractUserId } from '../../../Helper/Util/JwtHelper';
import {
  DEFAULT_CALCULATION_MULTIPLIER,
  DURATION_NOTIFICATION_ERROR_LONG,
  LEISTUNGSART,
  MAX_NUMBER_OF_DECIMALS
} from '../../../Helper/Statics/Constants';
import BeeCurrencyInput from '../../Atoms/BeeCurrencyInput';
import { ReactComponent as MarktgebietIncompleteSVG } from '../../../Style/Icons/svgs/icons8-country-warning.svg';
import { ReactComponent as PriceInputIncompleteSVG } from '../../../Style/Icons/svgs/icons8-incompletePrice.svg';
import { Tooltip } from 'primereact/tooltip';
import LiegenschaftDialog from './LiegenschaftDialog';
import BeeIconButton from '../../Atoms/BeeIconButton';

type AlphaAwardingDialogProps = {
  phaseId: string;
  property: LiegenschaftsData | null;
  provider: AuthUserType[];
  selectedProvider: AuthUserType[];
  slice: LEISTUNGSART | undefined;
  visible: boolean;
  targetPrice: number | null;
  lookup: any;
  awarding: Map<string, KeyValEntry>;
  onHide: () => void;
  onSaved: (data: KeyValEntry, key: string) => void;
};

export default function AlphaAwardingDialog({
  phaseId,
  property,
  provider,
  selectedProvider,
  slice,
  visible,
  targetPrice,
  lookup,
  awarding,
  onHide,
  onSaved
}: AlphaAwardingDialogProps) {
  const [requestPending, setRequestPending] = useState<boolean>(false);
  const [sProvider, setSProvider] = useState<any>();
  const [data, setData] = useState<any>();
  const [v_LiegenschaftDialog, setV_LiegenschaftDialog] =
    useState<boolean>(false);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    if (property && slice && slice.id && awarding) {
      if (lookup && lookup[property.id!] && lookup[property.id!][slice.id]) {
        let values = [];
        let incompleteValues = [];
        let notInMarketValues = [];
        for (const providerId in lookup[property.id!][slice.id]) {
          let entry = _.cloneDeep(lookup[property.id!][slice.id][providerId]);
          entry.providerid = providerId;
          if (entry.inComplete) {
            incompleteValues.push(entry);
          } else if (entry.notInMarket) {
            notInMarketValues.push(entry);
          } else {
            values.push(entry);
          }
        }
        //sort entries
        values = _.sortBy(values, ['sum']);
        incompleteValues = _.sortBy(incompleteValues, ['sum']);
        notInMarketValues = _.sortBy(notInMarketValues, ['sum']);
        values.push(...incompleteValues);
        values.push(...notInMarketValues);
        setData(values);
        //search stored value
        const currKey = generateAwardingKey(phaseId, property.id!, slice.id);
        if (awarding.get(currKey)) {
          const selection = awarding.get(currKey)?.value;
          const sProv = _.find(values, function (val) {
            return val.providerid === selection;
          });
          setSProvider(sProv);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property, slice, lookup, awarding]);

  function storeSelectionOnServer() {
    setRequestPending(true);
    if (awarding && property && slice && slice.id && sProvider) {
      const currKey = generateAwardingKey(phaseId, property.id!, slice.id);
      let userId = extractUserId();
      userId = userId ? userId : '';
      if (awarding.get(currKey)) {
        const id = awarding.get(currKey)?.id;
        updateKeyValueForOrgaAndPhaseForAlpha(
          userId,
          phaseId,
          id!,
          currKey,
          sProvider.providerid
        )
          .then((data: any) => {
            setRequestPending(false);
            onSaved(data, currKey);
          })
          .catch(() => {
            setRequestPending(false);
            if (toast.current) {
              toast.current.show({
                severity: 'error',
                summary: 'Dienstleister konnte nicht gespeichert werden',
                detail:
                  'Beim Speichern ist etwas schiefgelaufen. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice. ',
                sticky: false,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            }
          });
      } else {
        createNewKeyValueForOrgaAndPhaseForAlpha(
          userId,
          phaseId,
          currKey,
          sProvider.providerid
        )
          .then((data: any) => {
            setRequestPending(false);
            onSaved(data, currKey);
          })
          .catch(() => {
            setRequestPending(false);
            if (toast.current) {
              toast.current.show({
                severity: 'error',
                summary: 'Dienstleister konnte nicht gespeichert werden',
                detail:
                  'Beim Speichern ist etwas schiefgelaufen. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice. ',
                sticky: false,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            }
          });
      }
    }
  }

  //////////////////////
  ////  UI METHODS  ////
  //////////////////////

  const injectPropertyInfo = () => {
    if (property) {
      return (
        <div className="mt-4 mb-2">
          {property && property.name && slice && slice.title ? (
            <>
              <span>
                Bitte wählen Sie in folgender Tabelle den Dienstleister aus, an
                den Sie die Leistungen der Leistungsgruppe
              </span>{' '}
              <b>{slice.title}</b>
              <span> für die Liegenschaft </span>
              <b>{property.name}</b> <span> vergeben möchten.</span>
            </>
          ) : (
            <span>
              Bitte wählen Sie in folgender Tabelle den Dienstleister aus, an
              den Sie die Leistungen vergeben möchten
            </span>
          )}
        </div>
      );
    }
  };

  const infoTemplate = (rowData: any) => {
    return (
      <div>
        {rowData.inComplete ? (
          <div className={'alpha-awarding-dialog-info'}>
            <PriceInputIncompleteSVG className="priceInputIncomplete" />
            <Tooltip target=".priceInputIncomplete">
              unvollständige Preiseingabe
            </Tooltip>
          </div>
        ) : null}
        {rowData.notInMarket ? (
          <div className={'alpha-awarding-dialog-info'}>
            <MarktgebietIncompleteSVG className="marktgebietIncomplete" />
            <Tooltip target=".marktgebietIncomplete">
              Nicht im Marktgebiet
            </Tooltip>
          </div>
        ) : null}
      </div>
    );
  };

  const priceTemplate = (rowData: any) => {
    return (
      <div>
        <BeeCurrencyInput
          value={
            rowData.sum
              ? _.divide(rowData.sum, DEFAULT_CALCULATION_MULTIPLIER)
              : rowData.sum === 0
              ? 0
              : null
          }
          disabled={false}
          minFractionDigits={MAX_NUMBER_OF_DECIMALS}
          maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
          formstate={'none'}
          readOnly={true}
        />
      </div>
    );
  };

  const ansprechpartnerTemplate = (rowData: any) => {
    const prId = rowData.providerid;
    const prov = _.find(provider, function (pr) {
      return pr.id === prId;
    });
    if (prov) {
      return <div>{prov.firstname + ' ' + prov.lastname}</div>;
    }
    return;
  };

  const organisationTemplate = (rowData: any) => {
    const prId = rowData.providerid;
    const prov = _.find(provider, function (pr) {
      return pr.id === prId;
    });
    if (prov) {
      return <div>{prov.organisation}</div>;
    }
    return;
  };

  const favTemplate = (rowData: any) => {
    const prId = rowData.providerid;
    const prov = _.find(selectedProvider, function (pr) {
      return pr.id === prId;
    });
    if (prov) {
      return (
        <div>
          <i className="icon-star-filled alpha-awarding-dialog-fav-icon" />
          <Tooltip target=".alpha-awarding-dialog-fav-icon">
            In Szenario enthalten
          </Tooltip>
        </div>
      );
    }
    return;
  };

  const injectPricingTable = () => {
    if (data) {
      console.log(data);
      return (
        <DataTable
          value={data}
          /* selectionMode="single" */
          className="alpha-award-dialog-table"
          selection={sProvider}
          onSelectionChange={(e) => setSProvider(e.value)}
        >
          <Column
            selectionMode="single"
            className="alpha-award-dialog-table-selection-col"
          />
          <Column
            body={favTemplate}
            header=""
            className="alpha-award-dialog-table-fav-col"
          />
          <Column
            body={organisationTemplate}
            header="Dienstleister"
            className="alpha-award-dialog-table-dl-col"
          />
          <Column
            body={ansprechpartnerTemplate}
            header="Ansprechpartner"
            className="alpha-award-dialog-table-name-col"
          />
          <Column
            body={priceTemplate}
            header="Preis"
            className="alpha-award-dialog-table-price-col"
          />
          <Column
            body={infoTemplate}
            header=""
            className="alpha-award-dialog-table-info-col"
          />
        </DataTable>
      );
    }
  };

  const injectTargetPricing = () => {
    console.log(sProvider);
    return (
      <div className="alpha-awarding-dialog-target-area mt-4">
        <div className="alpha-awarding-dialog-target-diff flex align-items-center">
          {' '}
          <b>Abweichung Zielsumme:</b>
          <div>
            {targetPrice && sProvider && sProvider.sum
              ? _.round((sProvider.sum / targetPrice) * 100) + ' %'
              : ' — %'}
          </div>
        </div>
        {targetPrice || targetPrice === 0 ? (
          <div className="alpha-awarding-dialog-target-price flex align-items-center">
            <b> Zielpreis:</b>
            <BeeCurrencyInput
              value={
                targetPrice
                  ? _.divide(targetPrice, DEFAULT_CALCULATION_MULTIPLIER)
                  : targetPrice === 0
                  ? 0
                  : null
              }
              disabled={false}
              minFractionDigits={MAX_NUMBER_OF_DECIMALS}
              maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
              formstate={'none'}
              readOnly={true}
            />
          </div>
        ) : (
          <div>kein Zielpreis hinterlegt</div>
        )}
      </div>
    );
  };

  function injectButtonRow() {
    return (
      <div className="flex justify-content-between mt-4 mb-3">
        <BeeButton
          label={'Abbrechen'}
          disabled={requestPending}
          type={'secondary'}
          isLoading={false}
          onClick={() => onHide()}
        />
        <BeeButton
          label={'Auswahl speichern'}
          disabled={requestPending}
          type={'primary'}
          isLoading={requestPending}
          onClick={() => storeSelectionOnServer()}
        />
      </div>
    );
  }

  function injectInfoButton() {
    return (
      <BeeIconButton
        iconClass={'icon-view'}
        rounded={true}
        disabled={false}
        type={'secondary'}
        onClick={(e) => setV_LiegenschaftDialog(true)}
      />
    );
  }
  
  function injectLiegenschaftsdialog() {
    return (
      <LiegenschaftDialog
        property={property!}
        visible={v_LiegenschaftDialog}
        onHide={() => setV_LiegenschaftDialog(false)}
      />
    );
  }

  return (
    <div>
      <Dialog
        header={
          <>
            {property && slice && slice.title ? (
              <>
                <span>
                  {'Dienstleister auswählen: ' +
                    property.name +
                    ' - ' +
                    slice.title}
                </span>
                <span className="ml-3">{injectInfoButton()}</span>
              </>
            ) : (
              'Dienstleister auswählen'
            )}
          </>
        }
        visible={visible}
        className={'alpha-arwarding-dialog'}
        modal={true}
        onHide={() => onHide()}
      >
        <div className="mt-2 mb-2">
          {injectPropertyInfo()}
          {injectPricingTable()}
          {injectTargetPricing()}
          {injectButtonRow()}
        </div>
      </Dialog>
      {v_LiegenschaftDialog ? injectLiegenschaftsdialog() : null}
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
