import _ from 'lodash';
import { Checkbox } from 'primereact/checkbox';
import { ListBox } from 'primereact/listbox';
import BeeIcon from './BeeIcon';
import './BeeListbox.scss';

// export type ListboxItem = { label: string; value: string; info?: string };

// export type ListboxOptionItem = {
//   label: string;
//   code: string;
//   items: ListboxItem[];
// };

type BeeListboxProps = {
  selectedValues?: any[];
  options: any;
  grouped: boolean;
  label?: string;
  filterLabel?: string;
  required?: boolean;
  disabled: boolean;
  readOnly: boolean;
  optionRenderTemplate?: (option: any) => void;
  onChangeSelectedOptions: (e: string[]) => void;
};

export default function BeeListbox({
  selectedValues,
  grouped,
  options,
  label,
  filterLabel,
  required,
  disabled,
  readOnly,
  optionRenderTemplate,
  onChangeSelectedOptions
}: BeeListboxProps) {
  let readOnlyClassnames = '';
  if (readOnly) {
    readOnlyClassnames = ' bee-listbox-readOnly';
  }
  const classNames = 'bee-listbox p-float-label' + readOnlyClassnames;
  const requiredClassName = 'bee-listbox-label-required';
  const requiredStarClassName = 'bee-listbox-label-required-star';

  //placeholder
  const checkPlaceholder = 'pi pi-check';
  const timesPlaceholder = 'pi pi-times';
  const placeholderType = 'primary';
  const placeholderSize = 'large';

  function changeSelectedGroupedOptions(value: any) {
    if (onChangeSelectedOptions && !disabled) {
      onChangeSelectedOptions(value);
    }
  }

  function changeSelectedOptions(value: any) {
    if (onChangeSelectedOptions && !disabled) {
      onChangeSelectedOptions(value);
    }
  }

  const renderCheckPlaceholder = () => {
    return (
      <BeeIcon
        iconClass={checkPlaceholder}
        type={placeholderType}
        size={placeholderSize}
      />
    );
  };

  const renderTimesPlaceholder = () => {
    return (
      <BeeIcon
        iconClass={timesPlaceholder}
        type={placeholderType}
        size={placeholderSize}
      />
    );
  };

  function itemTemplate(option: any) {
    //Fixme checkbox if value is selected
    const isChecked = _.filter(selectedValues, function (o: string) {
      return option === o;
    });
    return (
      <div className="flex align-items-center">
        {!readOnly ? (
          <Checkbox
            checked={isChecked && isChecked.length > 0 ? true : false}
          />
        ) : isChecked && isChecked.length > 0 ? (
          renderCheckPlaceholder()
        ) : (
          renderTimesPlaceholder()
        )}
        <>{optionTemplate(option)}</>
      </div>
    );
  }

  function optionTemplate(option: any) {
    if (optionRenderTemplate) {
      return optionRenderTemplate(option);
    } else {
      return (
        <div>
          <div className="ml-2">{option.label}</div>
          {option.info ? (
            <div className="listbox-info">
              <i className={'pi pi-info'}></i>
              <span>{option.info}</span>
            </div>
          ) : null}
        </div>
      );
    }
  }

  function optionGroupTemplate(option: any) {
    return (
      <div className="flex align-items-center font-bold">
        <div>{option.label}</div>
      </div>
    );
  }

  return (
    <div className={classNames}>
      {grouped === true ? (
        <ListBox
          value={selectedValues}
          disabled={disabled}
          options={options}
          onChange={(e) => changeSelectedGroupedOptions(e.value)}
          itemTemplate={itemTemplate}
          optionGroupLabel="label"
          optionGroupChildren="items"
          optionLabel={filterLabel ? filterLabel : 'label'}
          optionGroupTemplate={optionGroupTemplate}
          listStyle={{ height: '17em' }}
          listClassName={readOnly ? 'listbox-list-readOnly' : ''}
          multiple
          filter
        />
      ) : (
        <ListBox
          value={selectedValues}
          disabled={disabled}
          options={options}
          onChange={(e) => changeSelectedOptions(e.value)}
          itemTemplate={itemTemplate}
          optionLabel={filterLabel ? filterLabel : 'label'}
          listStyle={{ height: '17em' }}
          listClassName={readOnly ? 'listbox-list-readOnly' : ''}
          multiple
          filter
        />
      )}
      {label ? (
        <label>
          {label}
          {required && label ? (
            <span className={requiredClassName}>
              <span className={requiredStarClassName}> *</span>
            </span>
          ) : null}
        </label>
      ) : null}
    </div>
  );
}
