import { Card } from 'primereact';
import React, { useEffect, useState } from 'react';
import './ResetPwForm.scss';

import { ENTER } from '../../../Helper/Statics/KeyCodes';
import BeeButton from '../../Atoms/BeeButton';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeLinkButton from '../../Atoms/BeeLinkButton';
import BeeValidatedPasswordInput from '../../Atoms/BeeValidatedPasswordInput';

type ResetPwProps = {
  showProgress: boolean;
  resetPwError: string;
  onResetPw: (token: string, password: string) => void;
  onRedirectToLogin: () => void;
};

function ResetPwForm({
  showProgress,
  resetPwError,
  onResetPw,
  onRedirectToLogin
}: ResetPwProps) {
  //component state
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');

  //get token from url
  const searchParams = new URLSearchParams(window.location.search);
  const resetPwToken = searchParams.get('t');

  useEffect(() => {
    if (resetPwError) {
      setPasswordError(resetPwError);
    }
    if (!resetPwToken) {
      setPasswordError('Sie haben keine Berechtigung das Passwort zu ändern.');
    }
  }, [resetPwError]);

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.keyCode === ENTER && !e.altKey) {
      resetPw();
    }
  }

  function redirectToLogin(): void {
    onRedirectToLogin();
  }

  function resetPw(): void {
    setPasswordError('');
    if (resetPwToken && isInputValid()) {
      onResetPw(resetPwToken, password);
    }
  }
  function isInputValid(): boolean {
    if (password.length < 8 || repeatPassword.length < 8) {
      setPasswordError(
        'Das gewählte Passwort muss mindestens 8 Zeichen besitzen.'
      );
      return false;
    }
    if (password !== repeatPassword) {
      setPasswordError('Die angegebenen Passwörter stimmen nicht überein.');
      return false;
    }
    return true;
  }

  function injectHeadline(): JSX.Element {
    return (
      <div
        className={'logo'}
        onClick={() => {
          redirectToLogin();
        }}
      />
    );
  }

  function injectResetPwForm(): JSX.Element {
    return (
      <div>
        <div>
          <BeeContentHeadline
            label={'Neues Passwort'}
            headline={'h2'}
            type={'secondary'}
          />
        </div>
        <div className={'infotext'}>
          {
            'Erstellen Sie ein neues, sicheres Passwort und bestätigen Sie die Eingabe.'
          }
        </div>

        <div className="field">
          <BeeValidatedPasswordInput
            label={'Passwort'}
            errorLabel={null}
            value={password}
            disabled={false}
            showPwStrength={false}
            showHint={false}
            formstate={passwordError ? 'error' : 'none'}
            readOnly={false}
            required={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
          />
        </div>
        <div className="field" onKeyDown={(e) => handleKeyDown(e)}>
          <BeeValidatedPasswordInput
            label={'Passwort wiederholen'}
            errorLabel={passwordError}
            value={repeatPassword}
            disabled={false}
            showPwStrength={false}
            showHint={false}
            formstate={passwordError ? 'error' : 'none'}
            readOnly={false}
            required={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setRepeatPassword(e.target.value)
            }
          />
        </div>
      </div>
    );
  }

  function injectActions(): JSX.Element {
    return (
      <>
        <div className="reset-pw-btn">
          <BeeButton
            label={'Neues Passwort speichern'}
            disabled={!resetPwToken || !password || !repeatPassword}
            type={'primary'}
            onClick={(e) => resetPw()}
          />
        </div>
        <div className="back-to-login-btn">
          <BeeLinkButton
            label={'Zurück zum Login'}
            raised={false}
            disabled={false}
            type={'secondary'}
            onClick={() => redirectToLogin()}
          />
        </div>
      </>
    );
  }

  return (
    <div
      className={
        'o-reset-pw-form form grid justify-content-center align-center'
      }
    >
      <div className="col-12 sm:col-7 md:col-6 lg:col-5 xl:col-4">
        <Card>
          {injectHeadline()}
          {injectResetPwForm()}
          {injectActions()}
        </Card>
      </div>
    </div>
  );
}

export default ResetPwForm;
