import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';

import fileSizeSI from '../../Helper/Util/fileSizeConverter';
//theme
import BeeIconButton from '../Atoms/BeeIconButton';
import './BeeDocumentTable.scss';

import _ from 'lodash';
import BeeButton from '../Atoms/BeeButton';
import BeeCheckbox from '../Atoms/BeeCheckbox';
import { DocType } from '../Pages/DLPages/DLPriceInputPage';

type BeeDocumentTableProps = {
  data: DocType[];
  type: string;
  readOnly: boolean;
  disabled?: boolean;
  showDownload: boolean;
  showDelete: boolean;
  enableBulkDownload: boolean;
  isBulkDownloadLoading: boolean;
  onDownload?: (itemId: string) => void;
  onDelete?: (itemId: string) => void;
  onDownloadAll?: (itemId: string[]) => void;
};

export type documentTableRow = {
  id: string;
  title: string;
  filename: string;
  timestamp: any;
  size: string;
  type: string;
};

declare module 'react' {
  interface HTMLAttributes<T> {
    headername?: string;
  }
}

export function BeeDocumentTable({
  data,
  type,
  readOnly,
  disabled,
  showDownload,
  showDelete,
  enableBulkDownload,
  isBulkDownloadLoading,
  onDownload,
  onDelete,
  onDownloadAll
}: BeeDocumentTableProps) {
  const [width, setWidth] = useState(window.innerWidth);
  const [selectedDocs, setSelectedDocs] = useState<string[]>([]);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  //classNames
  const typeClassNames =
    type === 'dark'
      ? 'bee-doc-table-dark'
      : type === 'light'
      ? 'bee-doc-table-light'
      : 'bee-doc-table-light';
  const disablesClassName = disabled ? ' disabled' : '';
  const classNames = 'bee-doc-table ' + typeClassNames + disablesClassName;
  const actionColClassNames =
    'bee-doc-table-col-action' + calculateActionColWidthClassName();
  const titleColClassNames = 'bee-doc-table-col-title';

  const dateColClassNames = 'bee-doc-table-col-date';
  const sizeColClassNames = 'bee-doc-table-col-size';
  const typeColClassNames = 'bee-doc-table-col-type';
  const tableClassNames = 'p-datatable-sm';

  //labels

  const dateColLabel = 'Stand';
  const sizeColLabel = 'Größe';
  const titleColLabel = 'Bezeichnung';
  const typeColLabel = 'Dateityp';
  const actionColLabel = '';
  const emptyMessage = 'Keine Dokumente vorhanden.';

  //windowsizes
  const p_sm = 576;
  const p_md = 768;
  const p_lg = 992;

  //Icons
  const downloadIcon = 'icon-download';
  const deleteIcon = 'icon-trash-can';

  function download(itemId: string) {
    if (onDownload) {
      onDownload(itemId);
    }
  }

  function deleteEntry(itemId: string) {
    if (onDelete) {
      onDelete(itemId);
    }
  }

  function downloadAllSelected() {
    if (onDownloadAll && showDownload) {
      onDownloadAll(selectedDocs);
    }
  }

  function calculateActionColWidthClassName() {
    const buttonCount = readOnly
      ? 1
      : (showDownload ? 1 : 0) + (showDelete ? 1 : 0);

    const actionColWidthClassNames =
      buttonCount === 1
        ? ' bee-doc-table-col-action-oneBtn'
        : buttonCount === 2
        ? ' bee-doc-table-col-action-twoBtn'
        : '';
    return actionColWidthClassNames;
  }

  function titleTemplate(rowData: DocType) {
    if (rowData) {
      return <p headername={titleColLabel}>{rowData.filename}</p>;
    }
  }
  function selectionTemplate(rowData: DocType) {
    const selectedIndex = _.findIndex(selectedDocs, function (docId: string) {
      return docId === rowData.id;
    });

    if (rowData) {
      return (
        <BeeCheckbox
          label=""
          value={selectedIndex >= 0 ? true : false}
          disabled={disabled}
          formstate={'none'}
          stateTrue={''}
          stateFalse={''}
          required={false}
          readOnly={false}
          onChange={(e) => {
            if (e.checked) {
              let currSelectedDocs = _.cloneDeep(selectedDocs);
              currSelectedDocs.push(rowData.id);
              setSelectedDocs(currSelectedDocs);
            } else {
              let currSelectedDocs = _.cloneDeep(selectedDocs);
              _.remove(currSelectedDocs, function (docId: string) {
                return docId === rowData.id;
              });

              setSelectedDocs(currSelectedDocs);
            }
          }}
        />
      );
    }
  }

  function calculateSelection() {
    if (data.length === selectedDocs.length) {
      return true;
    }
    return false;
  }
  const selectionHeader = (
    <BeeCheckbox
      label=""
      value={
        selectedDocs && selectedDocs.length > 0 ? calculateSelection() : false
      }
      disabled={disabled}
      formstate={'none'}
      stateTrue={''}
      stateFalse={''}
      required={false}
      readOnly={false}
      onChange={(e) => {
        if (e.checked && data) {
          let selectedDocsArray = [];
          for (let i = 0; i < data.length; i++) {
            selectedDocsArray.push(data[i].id);
          }
          setSelectedDocs(selectedDocsArray);
        } else {
          setSelectedDocs([]);
        }
      }}
    />
  );

  function creationDateTemplate(rowData: DocType) {
    if (rowData) {
      const date = rowData.timestamp;
      return date ? (
        <p headername={dateColLabel}>{date.toLocaleDateString('de-DE')}</p>
      ) : null;
    }
  }

  function sizeTemplate(rowData: DocType) {
    const size = rowData.size ? fileSizeSI(rowData.size) : '';
    if (rowData) {
      return <p headername={sizeColLabel}>{size}</p>;
    }
  }

  function typeTemplate(rowData: DocType) {
    if (rowData) {
      return <p headername={typeColLabel}>{rowData.type}</p>;
    }
  }

  function actionTemplate(rowData: DocType) {
    const rowDataId = rowData.id;
    if (rowData) {
      return (
        <div headername={actionColLabel}>
          {showDownload ? (
            <BeeIconButton
              iconClass={downloadIcon}
              rounded={false}
              disabled={false}
              type={'secondary'}
              onClick={() => download(rowDataId)}
            />
          ) : null}
          {!readOnly && showDelete ? (
            <BeeIconButton
              iconClass={deleteIcon}
              rounded={false}
              disabled={readOnly}
              type={'danger'}
              onClick={() => deleteEntry(rowDataId)}
            />
          ) : null}
        </div>
      );
    }
  }

  return (
    <div className={classNames}>
      <DataTable
        value={data}
        className={tableClassNames}
        emptyMessage={emptyMessage}
      >
        {enableBulkDownload && showDownload ? (
          <Column
            className={'bee-doc-table-selectAll-col'}
            body={selectionTemplate}
            header={selectionHeader}
          />
        ) : null}
        <Column
          className={titleColClassNames}
          body={titleTemplate}
          header={titleColLabel}
        />

        <Column
          className={dateColClassNames}
          body={creationDateTemplate}
          header={dateColLabel}
        />

        {width > p_md ? (
          <Column
            className={sizeColClassNames}
            body={sizeTemplate}
            header={sizeColLabel}
          />
        ) : null}
        {width > p_sm ? (
          <Column
            className={typeColClassNames}
            body={typeTemplate}
            header={typeColLabel}
          />
        ) : null}
        {showDelete || showDownload ? (
          <Column
            className={actionColClassNames}
            body={actionTemplate}
            header={actionColLabel}
          />
        ) : null}
      </DataTable>
      {enableBulkDownload && showDownload ? (
        <div className={'bee-doc-table-downloadAll'}>
          <BeeButton
            label={
              <>
                <i className={downloadIcon}></i>
                <span>Auswahl herunterladen</span>
              </>
            }
            disabled={!(selectedDocs && selectedDocs.length > 0)}
            type={'secondary'}
            isLoading={isBulkDownloadLoading ? true : false}
            onClick={() => downloadAllSelected()}
          />
        </div>
      ) : null}
    </div>
  );
}

export default BeeDocumentTable;
