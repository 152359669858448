import _ from 'lodash';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import {
  alphaReadAllServiceProvider,
  User
} from '../../../Helper/ApiHelper/AuthServiceHelper';
import {
  KeyValEntry,
  readAllKeyValuesForPhaseAndOrgaByAlpha,
  readAllPhases
} from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import {
  LiegenschaftsData,
  readAllLiegenschaften
} from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import {
  generateEpTreeFromPositions,
  readEpCatalogForPhase,
  removeAllHiddenCategoriesFromEpTree
} from '../../../Helper/ApiHelper/LvEpNetworkHelper';
import {
  DURATION_NOTIFICATION_ERROR_LONG,
  NAMING_USER_MEDIA
} from '../../../Helper/Statics/Constants';
import { handlePromisesAndIgnoreErrors } from '../../../Helper/Util/PromiseHelper';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeDropDown from '../../Atoms/BeeDropDown';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import { TimelineStep } from '../../Atoms/BeeTimeline';
import AlphaDLVerlaufEPTable from '../../Organisms/Alpha/AlphaDLVerlaufEPTable';
import AlphaDLVerlaufFaktorTable from '../../Organisms/Alpha/AlphaDLVerlaufFaktorTable';
import './AlphaDlZeitverlaufPage.scss';
import AlphaDLVerlaufDocTable from '../../Organisms/Alpha/AlphaDLVerlaufDocTable';
import { TabPanel, TabView } from 'primereact/tabview';

export default function AlphaDlZeitverlaufPage() {
  const [phasen, setPhasen] = useState<TimelineStep[]>([]);
  const [selectedProvider, setSelectedProvider] = useState<User>();
  const [setupComplete, setSetupComplete] = useState<boolean>(false);
  const [initNewValues, setInitNewValues] = useState<boolean>(false);
  const [allServiceProvider, setAllServiceProvider] = useState<User[]>([]);
  const [allProperties, setAllProperties] = useState<LiegenschaftsData[]>([]);
  const [allEpCatalogs, setAllEpCatalogs] = useState<Map<string, any>>(
    new Map()
  );
  const [valueLookup, setValueLookup] = useState<Map<string, any>>(new Map());
  const toast = useRef<Toast>(null);

  useEffect(() => {
    readAllPhases()
      .then((json: any) => {
        let steps: TimelineStep[] = [...json];
        let phaseIds: string[] = [];
        steps.forEach((p) => phaseIds.push(p.id));
        alphaReadAllServiceProvider()
          .then((serviceProvider: any) => {
            let aSp: User[] = serviceProvider;
            aSp = _.sortBy(aSp, ['organisation', 'username']);
            let epPromises: Promise<any>[] = [];
            phaseIds.forEach((pId) => {
              epPromises.push(readEpCatalogForPhase(pId));
            });
            handlePromisesAndIgnoreErrors(epPromises)
              .then((payload: any) => {
                let epCLookup = new Map<string, any>();
                if (payload && payload.fulfilled) {
                  payload.fulfilled.forEach((c: any) => {
                    if (c.value && c.value.data && c.value.info) {
                      let currCatalog: any = generateEpTreeFromPositions(
                        c.value.data
                      );
                      currCatalog = currCatalog ? currCatalog : [];
                      currCatalog = removeAllHiddenCategoriesFromEpTree(
                        currCatalog,
                        false
                      );
                      epCLookup.set(c.value.info.phaseId, currCatalog);
                    }
                  });
                }
                readAllLiegenschaften()
                  .then((properties: any) => {
                    const pr: LiegenschaftsData[] = properties;
                    setSelectedProvider(aSp.length > 0 ? aSp[0] : undefined);
                    setAllProperties(pr);
                    setAllServiceProvider(aSp);
                    setAllEpCatalogs(epCLookup);
                    setPhasen(steps);
                    setSetupComplete(true);
                  })
                  .catch(() => {
                    toast.current?.show({
                      severity: 'error',
                      summary: 'Liegenschaften konnten nicht geladen werden',
                      detail:
                        'Leider konnten die Liegenschaften nicht geladen werden. Bitte versuchen Sie es später erneut. ',
                      sticky: false,
                      closable: true,
                      life: DURATION_NOTIFICATION_ERROR_LONG
                    });
                  });
              })
              .catch(() => {
                toast.current?.show({
                  severity: 'error',
                  summary: 'EP-Kataloge konnten nicht geladen werden',
                  detail:
                    'Leider konnten die EP-Kataloge nicht geladen werden. Bitte versuchen Sie es später erneut. ',
                  sticky: false,
                  closable: true,
                  life: DURATION_NOTIFICATION_ERROR_LONG
                });
              });
          })
          .catch(() => {
            toast.current?.show({
              severity: 'error',
              summary: 'Dienstleister konnten nicht geladen werden',
              detail:
                'Leider konnten die Dienstleister nicht geladen werden. Bitte versuchen Sie es später erneut. ',
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          });
      })
      .catch((error) => {
        toast.current?.show({
          severity: 'error',
          summary: 'Fehler beim Laden benötigter Daten',
          detail:
            'Leider konnten die Phasen nicht geladen werden. Bitte versuchen Sie es später erneut. ' +
            error,
          sticky: false,
          closable: true,
          life: DURATION_NOTIFICATION_ERROR_LONG
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (phasen && selectedProvider) {
      setInitNewValues(false);
      let phaseIds: string[] = [];
      phasen.forEach((p) => phaseIds.push(p.id));
      let valPromises: Promise<any>[] = [];
      phaseIds.forEach((pId) => {
        valPromises.push(
          readAllKeyValuesForPhaseAndOrgaByAlpha(pId, selectedProvider!.id!)
        );
      });
      handlePromisesAndIgnoreErrors(valPromises)
        .then((result: any) => {
          let valueLookup: any = new Map();
          if (result && result.fulfilled) {
            result.fulfilled.forEach((curr: any) => {
              if (curr.value && curr.value.length > 0) {
                curr.value.forEach((kv: KeyValEntry) => {
                  valueLookup.set(kv.key, kv.value);
                });
              }
            });
            setValueLookup(valueLookup);
            setInitNewValues(true);
          }
        })
        .catch(() => {
          toast.current?.show({
            severity: 'error',
            summary: 'Nutzereingaben konnten nicht geladen werden',
            detail:
              'Leider konnten die vom Dienstleister eingegebenen Daten nicht geladen werden. Bitte versuchen Sie es später erneut. ',
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProvider]);

  function getKeyName(phase: TimelineStep) {
    return phase.id + NAMING_USER_MEDIA;
  }

  ////////////////
  // UI METHODS //
  ////////////////

  const dynamicTabs = phasen.map((col: TimelineStep, i: any) => {
    const userMediaEntry = valueLookup.get(getKeyName(col));
    const userMedia: string[] = userMediaEntry
      ? JSON.parse(userMediaEntry)
      : [];
    return (
      <TabPanel
        key={'dl-zeitverlauf-docs-tab_' + col.id}
        header={'Dokumente aus ' + col.title}
      >
        <AlphaDLVerlaufDocTable userMediaEntry={userMedia} />
      </TabPanel>
    );
  });

  function injectDocTableTabs() {
    return (
      <div className="alpha-dl-zeitverlauf-docs pt-6">
        <BeeContentHeadline
          label={'Dokumente'}
          headline={'h2'}
          type={'secondary'}
        />
        <div className="mb-3">
          Folgende Übersicht zeigt die Entwicklung der hinterlegten Dokumente
          des ausgewählten Dienstleisters über die Angebotsphasen hinweg.
          Wechseln Sie bitte den Tab um die Dateien einer anderen Phase zu
          analysieren.
        </div>
        <TabView>{dynamicTabs}</TabView>
      </div>
    );
  }

  const injectPayload = () => {
    if (!setupComplete) {
      return (
        <div className="large-spinner">
          <BeeLoadingSpinner strokeWidth={'3'} type={'primary'} />
        </div>
      );
    } else {
      return (
        <div>
          <AlphaDLVerlaufFaktorTable
            dataLoading={initNewValues}
            phases={phasen}
            properties={allProperties}
            valueLookup={valueLookup}
            providerId={selectedProvider?.id!}
          />
          <AlphaDLVerlaufEPTable
            dataLoading={initNewValues}
            phases={phasen}
            epCatalogs={allEpCatalogs}
            valueLookup={valueLookup}
            providerId={selectedProvider?.id!}
          />

          {injectDocTableTabs()}
        </div>
      );
    }
  };

  return (
    <div className={'alpha-dl-over-time-page m-4 pt-2 justify-content-start'}>
      <div className="alpha-dl-over-time-headline ">
        <div className="mr-3">
          <BeeContentHeadline
            label={'Dienstleister im Zeitverlauf'}
            headline={'h1'}
            type={'primary'}
          />
        </div>
        <div>
          <BeeDropDown
            label={'Dienstleister'}
            value={selectedProvider}
            options={allServiceProvider}
            optionLabel={'organisation'}
            disabled={false}
            formstate={'neutral'}
            readOnly={false}
            required={false}
            onChange={(e: any) => {
              if (!selectedProvider || e.id !== selectedProvider.id) {
                setSelectedProvider(e);
              }
            }}
          />
        </div>
      </div>
      {injectPayload()}
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
