import _ from 'lodash';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  createNewKeyValueForOrgaAndPhase,
  KeyValEntry,
  readAllStoredKeyValuesForOrgaAndPhase,
  updateKeyValueForOrgaAndPhase
} from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import { LiegenschaftsData } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import {
  EnlargedLvPosition,
  EpPosition,
  LV,
  searchTreePosition
} from '../../../Helper/ApiHelper/LvEpNetworkHelper';
import {
  COUNTRIES,
  DEFAULT_FACTOR,
  DURATION_NOTIFICATION_ERROR_LONG,
  LEISTUNGSART,
  LeistungsSlices,
  MAX_VALUE_FACTOR_LIEGENSCHAFTEN,
  MIN_VALUE_FACTOR_LIEGENSCHAFTEN,
  REGION,
  SERVER_RESPONSE_ERROR,
  SERVER_RESPONSE_PENDING,
  SERVER_RESPONSE_SUCCESS
} from '../../../Helper/Statics/Constants';
import {
  generateBlFactorId,
  generateEpEntryId,
  generateLsFactorId,
  generateLsInMarktgebietId
} from '../../../Helper/Util/IdGeneratorHelper';
import { extractUserId } from '../../../Helper/Util/JwtHelper';
import {
  calculateGP,
  calculateSlices,
  filterArrayByHavingLvPosLeafs,
  generateEnlargedLV
} from '../../../Helper/Util/LvCalculator';
import BeeCascadeSelectLiegenschaften from '../../Atoms/BeeCascadeSelectLiegenschaften';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import { TimelineStep } from '../../Atoms/BeeTimeline';
import { FaktorKeyValueStore } from '../../Molecules/DL/DlBundeslandfaktoren';
import DLPropertyDetail from '../../Molecules/DL/DLPropertyDetail';
import DLPropertyFactor from '../../Molecules/DL/DLPropertyFactor';
import DLPropertyLV from '../../Molecules/DL/DLPropertyLV';
import Error404 from '../../Molecules/Error404';
import { ImageType } from '../../Pages/AlphaPages/AlphaLiegenschaftVerwaltung';
import DLOverviewAnalytics from '../../Pages/DLPages/DLOverviewAnalytics';
import './DLTabProperty.scss';
import { AuthUserType } from '../../../Helper/ApiHelper/LoginNetworkHelper';

type DLTabPropertyProps = {
  phase?: TimelineStep;
  properties?: LiegenschaftsData[];
  imageLookup?: Map<string, ImageType>;
  readOnly: boolean;
  epLookup?: Map<string, EpPosition>;
  lvs: LV[];
  shortenLvTree: any;
};

export default function DLTabProperty({
  phase,
  properties,
  imageLookup,
  readOnly,
  epLookup,
  lvs,
  shortenLvTree
}: DLTabPropertyProps) {
  const [property, setProperty] = useState<LiegenschaftsData>();
  const [marketAreaId, setMarketAreaId] = useState<string>('error');
  const [noProperty, setNoProperty] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [keyValueStore, setKeyValueStore] = useState<{
    [key: string]: FaktorKeyValueStore;
  }>({});
  const [propRegionId, setPropRegionId] = useState<string>('');
  const [lvTree, setLvTree] = useState<any>();
  const [enlargedLv, setEnlargedLv] = useState<EnlargedLvPosition[]>();
  const [sliceValues, setSliceValues] = useState<Map<string, number>>(
    new Map()
  );
  const toast = useRef<Toast>(null);

  //extract userId
  let userId: string = 'no_user';
  const extracted = extractUserId();
  userId = extracted ? extracted : userId;

  useEffect(() => {
    const propertyId = searchParams.get('lId');
    if (phase && lvs && !(property && property.id === propertyId)) {
      const property = _.find(properties, function (pr: LiegenschaftsData) {
        return pr.id === propertyId;
      });
      if (property) {
        readAllStoredKeyValuesForOrgaAndPhase(userId, phase.id)
          .then((data: any) => {
            const keyValues = [...data];
            const currLV: any = _.find(lvs, function (lv: LV) {
              return lv.info.propertyId === propertyId;
            });
            initKeyValueStore(
              keyValues,
              property,
              currLV,
              phase,
              shortenLvTree
            );
          })
          .catch(() => {
            setProperty(undefined);
            setKeyValueStore({});
            toast.current?.show({
              severity: 'error',
              summary: 'Daten können nicht geladen werden.',
              detail:
                'Das Laden der von Ihnen eingegebenen Preise und Faktoren ist schiefgelaufen. Bitte versuchen Sie es später erneut oder wenden sich an den Kundensupport. ',
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          });
      } else {
        setProperty(undefined);
        setKeyValueStore({});
        setNoProperty(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phase, searchParams]);

  function initKeyValueStore(
    keyValues: KeyValEntry[],
    property: LiegenschaftsData,
    lv: LV,
    phase: TimelineStep,
    shortenLvTree: any
  ) {
    const values: any = {};
    if (phase && property.adresse) {
      //search bundesland
      let region = null;
      for (let i = 0; i < COUNTRIES.length; i++) {
        const c = COUNTRIES[i];
        const val = _.find(c.regions, function (reg: REGION) {
          return reg.region === property.adresse!.region;
        });
        region = val ? val : region;
      }
      const blID = region ? region.id : '';
      setPropRegionId(blID);
      LeistungsSlices.forEach((slice: LEISTUNGSART) => {
        //for each slice BL-FACTOR
        const blKey: string = generateBlFactorId(phase, blID, slice);
        const valObj = _.find(keyValues, function (o: any) {
          return o.key === blKey;
        });
        if (valObj) {
          //data for keyval entry are available
          values[blKey] = {};
          values[blKey].id = valObj.id;
          values[blKey].value = valObj.value ? parseInt(valObj.value) : null;
          values[blKey].process = null;
        } else {
          //no entry on server -> initialize with null
          values[blKey] = {};
          values[blKey].id = null;
          values[blKey].value = null;
          values[blKey].process = null;
        }
        //for each slice LS-Factor
        const lKey: string = generateLsFactorId(phase, property, false, slice);
        const lValObj = _.find(keyValues, function (o: any) {
          return o.key === lKey;
        });
        if (lValObj) {
          //data for keyval entry are available
          values[lKey] = {};
          values[lKey].id = lValObj.id;
          values[lKey].value = lValObj.value ? parseInt(lValObj.value) : null;
          values[lKey].process = null;
        } else {
          //no entry on server -> initialize with null
          values[lKey] = {};
          values[lKey].id = null;
          values[lKey].value = null;
          values[lKey].process = null;
        }
        //for each slice SUSTAIN-Factor
        const lsKey: string = generateLsFactorId(phase, property, true, slice);
        const lsValObj = _.find(keyValues, function (o: any) {
          return o.key === lsKey;
        });
        if (lsValObj) {
          //data for keyval entry are available
          values[lsKey] = {};
          values[lsKey].id = lsValObj.id;
          values[lsKey].value = lsValObj.value
            ? parseInt(lsValObj.value)
            : null;
          values[lsKey].process = null;
        } else {
          //no entry on server -> initialize with null
          values[lsKey] = {};
          values[lsKey].id = null;
          values[lsKey].value = null;
          values[lsKey].process = null;
        }
      });
      //test if property is marketarea
      const marketKey: string = generateLsInMarktgebietId(phase, property);
      const marketValObj = _.find(keyValues, function (o: any) {
        return o.key === marketKey;
      });
      if (marketValObj) {
        //data for keyval entry are available
        values[marketKey] = {};
        values[marketKey].id = marketValObj.id;
        values[marketKey].value =
          marketValObj.value === 'false' ? 'false' : 'true';
        values[marketKey].process = null;
      } else {
        //no entry on server -> initialize with null
        values[marketKey] = {};
        values[marketKey].id = null;
        values[marketKey].value = 'true';
        values[marketKey].process = null;
      }
      //find keyValues fpr pricing
      if (epLookup) {
        epLookup.forEach((ep) => {
          //only if entry has epCode => add to value lookup
          if (ep.epCode) {
            const key: string = generateEpEntryId(phase, ep.epCode);
            const valObj = _.find(keyValues, function (o: any) {
              return o.key === key;
            });
            if (valObj) {
              //data for keyval ep are available
              values[key] = {};
              values[key].id = valObj.id;
              values[key].value = valObj.value ? parseInt(valObj.value) : null;
              values[key].process = null;
            } else {
              //no ep on server -> initialize with null
              values[key] = {};
              values[key].id = null;
              values[key].value = null;
              values[key].process = null;
            }
          }
        });
      }
      setMarketAreaId(marketKey);
      if (lv) {
        const enlargedLvPos = generateEnlargedLV(
          lv.data,
          property,
          blID,
          phase,
          epLookup
        );
        setEnlargedLv(enlargedLvPos);
        setSliceValues(calculateSlices(enlargedLvPos, values));
        setLvTree(
          filterArrayByHavingLvPosLeafs(
            calculateLV(shortenLvTree, enlargedLvPos, values)
          )
        );
      } else {
        setEnlargedLv(undefined);
        setSliceValues(new Map());
        setLvTree(undefined);
      }
    }
    setKeyValueStore(values);
    setProperty(property);
    setNoProperty(false);
  }

  ///////////////////////
  /////// HELPER ////////
  ///////////////////////

  function updateKeyValStore(
    id: string,
    valueId: string,
    val: number | null,
    progress: string
  ) {
    setKeyValueStore((previousInputs: any) => ({
      ...previousInputs,
      [valueId]: { id: id, value: val, process: progress }
    }));
  }

  useEffect(() => {
    if (enlargedLv && enlargedLv.length > 0 && keyValueStore) {
      setSliceValues(calculateSlices(enlargedLv, keyValueStore));
      setLvTree(
        filterArrayByHavingLvPosLeafs(
          calculateLV(shortenLvTree, enlargedLv, keyValueStore)
        )
      );
    } else {
      setLvTree([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyValueStore]);

  function changeCellInput(val: any | null, valueId: string) {
    let currValue = val ? val : DEFAULT_FACTOR;
    const dbId = keyValueStore[valueId].id;
    if (
      keyValueStore[valueId] &&
      (keyValueStore[valueId].value !== currValue ||
        keyValueStore[valueId].process === SERVER_RESPONSE_ERROR)
    ) {
      setKeyValueStore(
        (previousInputs: { [key: string]: FaktorKeyValueStore }) => ({
          ...previousInputs,
          [valueId]: {
            id: dbId,
            value: currValue,
            process: SERVER_RESPONSE_PENDING
          }
        })
      );
      if (dbId) {
        updateKeyValueForOrgaAndPhase(
          userId,
          phase!.id,
          dbId,
          valueId,
          '' + currValue
        )
          .then((responseValue) => {
            updateKeyValStore(
              dbId,
              valueId,
              currValue,
              SERVER_RESPONSE_SUCCESS
            );
          })
          .catch(() =>
            updateKeyValStore(dbId, valueId, currValue, SERVER_RESPONSE_ERROR)
          );
      } else {
        createNewKeyValueForOrgaAndPhase(userId, phase!.id, valueId, '' + val)
          .then((responseValue: any) => {
            const responseObject: KeyValEntry = responseValue;
            updateKeyValStore(
              responseObject.id,
              valueId,
              currValue,
              SERVER_RESPONSE_SUCCESS
            );
          })
          .catch(() =>
            //dbId is initial value // null
            updateKeyValStore(dbId, valueId, currValue, SERVER_RESPONSE_ERROR)
          );
      }
    }
  }

  function calculateLV(
    shortenLvTree: any,
    enlargedLvPositions: EnlargedLvPosition[],
    values: any
  ) {
    if (shortenLvTree && enlargedLvPositions) {
      let lvTableTree = _.cloneDeep(shortenLvTree);
      //lvPos are sorted
      for (let x = 0; x < enlargedLvPositions.length; x++) {
        const lvPos = enlargedLvPositions[x];
        let parent = null;
        //search ep node
        for (let i = 0; i < lvTableTree.length; i++) {
          parent = searchTreePosition(
            lvTableTree[i],
            lvPos.leftInt,
            lvPos.rightInt
          );
          if (parent) {
            break;
          }
        }
        parent.children = parent.children ? parent.children : [];
        const amount: number = lvPos.amount ? lvPos.amount : 0;
        const price = values[lvPos.epPriceId];
        const blFactor = values[lvPos.blFactorId];
        const lsFactor = values[lvPos.lsFactorId];
        const nFactor = values[lvPos.nFactorId];
        // const mFactor = values[pos.mFactorId]; //hier nicht gebraucht
        //calculate
        const priceValue: number = price && price.value ? price.value : 0;
        const blValue: number =
          blFactor && blFactor.value ? blFactor.value : DEFAULT_FACTOR;
        const lsValue: number =
          lsFactor && lsFactor.value ? lsFactor.value : DEFAULT_FACTOR;
        const nValue: number =
          nFactor && nFactor.value ? nFactor.value : DEFAULT_FACTOR;
        const sum: number = calculateGP(
          amount,
          priceValue,
          blValue,
          lsValue,
          nValue
        );
        parent.children.push({
          key: lvPos.id,
          className: 'lv-pos',
          data: {
            tag: lvPos.sliceTag,
            ep: priceValue,
            blFactor: blValue,
            lsFactor: lsValue,
            nFactor: nValue,
            gp: sum,
            amount: amount,
            unit: lvPos.unit,
            epCode: lvPos.epCode,
            title: lvPos.description,
            posNumber: lvPos.serialNumber,
            optionalPosition: lvPos.optionalPosition,
            optionalPositionActive: lvPos.optionalPositionActive,
            aks: lvPos.identificationNumber,
            noPriceGiven: price.value ? false : true
          }
        });
      }
      return lvTableTree;
    }
  }

  ///////////////////////
  /// VIEW COMPONENTS ///
  ///////////////////////

  function injectSelect() {
    return (
      <BeeCascadeSelectLiegenschaften
        label={'Liegenschaften'}
        value={property}
        options={properties}
        disabled={false}
        formstate={'neutral'}
        readOnly={false}
      />
    );
  }

  const injectPayload = () => {
    const propArray: LiegenschaftsData[] = [];
    if (property) {
      propArray.push(property);
    }
    let image = null;
    if (property && property.hauptBildId) {
      image = imageLookup?.get(property.hauptBildId);
    }
    return (
      <div className={'dl-tab-property m-2 grid'}>
        <div className={'col-12'}> {injectSelect()} </div>
        <div className={'col-12'}>
          <DLPropertyDetail
            property={property}
            image={image ? image : undefined}
            marktgebietCheck={keyValueStore[marketAreaId]}
            readOnly={readOnly}
            onChangeMarktgebietCheck={(value) => {
              changeCellInput(value === true ? 'true' : 'false', marketAreaId);
            }}
          />
        </div>
        <div>
          <BeeContentHeadline
            label={'Liegenschaftsfaktoren'}
            headline={'h2'}
            type={'primary'}
          />
        </div>
        <div>
          {readOnly ? (
            <div className={'readOnly mb-5'}>
              <span>
                {
                  'Da diese Phase derzeit nicht aktiv ist, können Sie keine Änderungen an den Faktoren vornehmen. Bitte wählen Sie eine aktive Phase, um die Liegenschaftsfaktoren anzupassen.'
                }
              </span>
            </div>
          ) : (
            <div className={'mb-5'}>
              <span>
                Über entsprechende Faktoren können Zu- und Abschläge auf die EPs
                abgebildet werden. Hierdurch können zum einen Synergien aber
                auch besondere Herausforderungen in einer konkreten Liegenschaft
                abgebildet werden ohne die EP-Kalkulation des Rahmenvertrages zu
                ändern. Sie können Faktoren zwischen
              </span>{' '}
              <b>{MIN_VALUE_FACTOR_LIEGENSCHAFTEN}</b> <span> und </span>{' '}
              <b>{MAX_VALUE_FACTOR_LIEGENSCHAFTEN}</b> <span>angeben.</span>
            </div>
          )}
        </div>
        <div className={'grid'}>
          <div className={'col-12 sm:col-12 md:col-6 lg:col-fixed '}>
            <DLPropertyFactor
              phase={phase}
              property={property}
              readOnly={readOnly}
              disabled={
                keyValueStore[marketAreaId] &&
                keyValueStore[marketAreaId].value === 'true'
                  ? false
                  : true
              }
              keyValueStore={keyValueStore}
              propRegionId={propRegionId}
              changeCellInput={(val, valueId) => changeCellInput(val, valueId)}
            />
          </div>
          <div
            className={
              'col-12 sm:col-12 md:col-6 lg:col-fixed dl-tab-property-analytics'
            }
          >
            <DLOverviewAnalytics
              showTable={false}
              chartType="bar"
              readOnly={readOnly}
              lvData={sliceValues}
              disabled={
                keyValueStore[marketAreaId] &&
                keyValueStore[marketAreaId].value === 'true'
                  ? false
                  : true
              }
            />
          </div>
        </div>
        <div className={'col-12 dl-tab-property-lv'}>
          <div>
            <BeeContentHeadline
              label={'Leistungsverzeichnis'}
              headline={'h2'}
              type={'primary'}
            />
            <div className={'mb-5'}>
              <span>
                Nachfolgendes Leistungsverzeichnis bildet das Angebot ab,
                welches sich aus den EPs, den Faktoren und dem zum
                Ausschreibungszeitpunkt bekannten Mengengerüst der Liegenschaft
                errechnet.
              </span>
              <span className={'bold'}> Hinweis: </span>
              <span>
                Die Ansicht basiert auf einer Berechnung - Änderungen sind nur
                auf Ebene der EPs und der Faktoren möglich!
              </span>
            </div>
            {phase && property ? (
              <DLPropertyLV
                phaseId={phase.id}
                lvTree={lvTree}
                property={property}
                disabled={
                  keyValueStore[marketAreaId] &&
                  keyValueStore[marketAreaId].value === 'true'
                    ? false
                    : true
                }
                readOnly={readOnly}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {noProperty ? <Error404 /> : injectPayload()}
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
