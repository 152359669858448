import FileSaver from 'file-saver';
import _ from 'lodash';
import { TimelineStep } from '../../UI/Atoms/BeeTimeline';
import {
  DEFAULT_FACTOR,
  LeistungsSlices,
  OUTPUT_FILE_HEADLINE,
  prefixActive,
  prefixInactive
} from '../Statics/Constants';
import { EnlargedLvPosition } from '../ApiHelper/LvEpNetworkHelper';
import { AuthUserType } from '../ApiHelper/LoginNetworkHelper';
import { calculateGP } from '../Util/LvCalculator';
import {
  calculateTreeDepth,
  numberToExcelLetter,
  shadeColor
} from './ExcelExportTooling';

const ExcelJS = require('exceljs');

const excelAlphaSheetName = 'Preisspiegel';
const excelAlphaEpNameHeader = 'Ep-Position';
const excelAlphaTagHeader = 'Leistungstyp';
const excelAlphaOptionalPositionHeader = 'Positionstyp';
const excelAlphaOptionalPositionActiveHeader = 'Aktiv';
const excelAlphaEpCodeHeader = 'Ep-Code';
const excelAlphaUnitHeader = 'Einheit';
const excelAlphaPostTextHeader = 'Nachtext';
const excelAlphaAmountHeader = 'Menge';

export function exportAlphaClusterExcel(
  tree: any,
  selectedProvider: AuthUserType[],
  epCodeLookup: Map<string, EnlargedLvPosition[]>,
  valueLookup: Map<string, any>,
  phase: TimelineStep
) {
  return new Promise(async (resolve, reject) => {
    const workbook = new ExcelJS.Workbook();
    //metadata
    workbook.creator = '';
    workbook.lastModifiedBy = '';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();
    workbook.views = [
      {
        x: 0,
        y: 0,
        width: 10000,
        height: 20000,
        firstSheet: 0,
        activeTab: 0,
        visibility: 'visible'
      }
    ];
    // Force workbook calculation on load
    workbook.calcProperties.fullCalcOnLoad = true;

    //create Sheets
    let sheetDlEp = workbook.addWorksheet(excelAlphaSheetName, {
      properties: { defaultColWidth: 14 }
    });

    // make worksheets visible
    sheetDlEp.state = 'visible';
    sheetDlEp.views = [{ state: 'frozen', xSplit: 0, ySplit: 5 }];

    const treeDepth = calculateTreeDepth(tree) - 1;
    const colIndexInitDl = getColIndexForInitDl(treeDepth);

    //col widhts
    sheetDlEp = setUpCols(tree, selectedProvider, sheetDlEp);

    //col header
    sheetDlEp = createSheetAlphaClusterHeader(
      tree,
      selectedProvider,
      colIndexInitDl,
      sheetDlEp,
      phase
    );

    //content
    if (tree) {
      let enlargedTree = createAlphaClusterContent(
        tree,
        selectedProvider,
        epCodeLookup,
        valueLookup,
        phase,
        treeDepth,
        [],
        colIndexInitDl,
        sheetDlEp
      );
      console.log(enlargedTree);
      enlargedTree = [
        {
          children: enlargedTree,
          rowNumber: enlargedTree[0].rowNumber - 1
        }
      ];
      generateFormulars(
        enlargedTree,
        selectedProvider,
        colIndexInitDl,
        sheetDlEp
      );

      applyConditionalFormattingDiff(
        selectedProvider,
        colIndexInitDl,
        sheetDlEp
      );
    }

    // write to a file
    workbook.xlsx
      .writeBuffer()
      .then((buffer: any) =>
        FileSaver.saveAs(
          new Blob([buffer]),
          `${Date.now()}_Cluster-Preisspiegel.xlsx`
        )
      )
      .catch((err: any) => console.log('Error writing excel export', err));
  });
}

function setUpCols(
  tree: any,
  selectedProvider: AuthUserType[],
  worksheet: any
) {
  let treedepth = calculateTreeDepth(tree) - 1;

  /*   columnwidths/styles */
  for (let i = 1; i < treedepth + 2; i++) {
    worksheet.getColumn(i).key = 'depth-' + i;
    worksheet.getColumn(i).width = 3;
  }
  //price

  const priceIndex = getColIndexForInitDl(treedepth);
  for (let i = 0; i < selectedProvider.length; i++) {
    const currIndex = priceIndex + i * 6;
    //sum
    worksheet.getColumn(currIndex).width = 14;
    worksheet.getColumn(currIndex).numFmt = '#,###0.000 [$€-1]';
    //gessum
    worksheet.getColumn(currIndex + 1).width = 14;
    worksheet.getColumn(currIndex + 1).numFmt = '#,###0.000 [$€-1]';
    //Diff
    worksheet.getColumn(currIndex + 2).width = 10;
    worksheet.getColumn(currIndex + 2).numFmt = '0%';
    //helpercols
    worksheet.getColumn(currIndex + 4).width = 5;
    worksheet.getColumn(currIndex + 4).hidden = true;
    worksheet.getColumn(currIndex + 5).width = 5;
    worksheet.getColumn(currIndex + 5).hidden = true;
  }

  //tag
  worksheet.getColumn(getColIndexForTag(treedepth)).key = 'typ';
  //epcode
  worksheet.getColumn(getColIndexForEp(treedepth)).key = 'ep';
  //optional position
  worksheet.getColumn(getColIndexForOptionalPos(treedepth)).key =
    'optional_pos';
  //optional position active
  worksheet.getColumn(getColIndexForOptionalPosActive(treedepth)).key =
    'optional_pos_active';
  //unit
  worksheet.getColumn(getColIndexForUnit(treedepth)).key = 'ep';
  worksheet.getColumn(getColIndexForUnit(treedepth)).width = 10;
  //postText
  worksheet.getColumn(getColIndexForPostText(treedepth)).key = 'ep';
  worksheet.getColumn(getColIndexForPostText(treedepth)).width = 25;
  //amount
  worksheet.getColumn(getColIndexForAmount(treedepth)).key = 'amount';
  worksheet.getColumn(getColIndexForAmount(treedepth)).width = 10;
  worksheet.getColumn(getColIndexForAmount(treedepth)).alignment = {
    horizontal: 'left'
  };
  return worksheet;
}

function createSheetAlphaClusterHeader(
  tree: any,
  selectedProvider: AuthUserType[],
  colIndexInitDl: number,
  worksheet: any,
  phase: TimelineStep
) {
  let treedepth = calculateTreeDepth(tree) - 1;

  /*  metainfo */
  const metaInfoRow = worksheet.addRow();
  metaInfoRow.getCell(1).value = OUTPUT_FILE_HEADLINE;
  metaInfoRow.getCell(getColIndexForEp(treedepth) - 2).value =
    'Phase: ' + phase.title;
  metaInfoRow.getCell(getColIndexForInitDl(treedepth)).value =
    'Datum: ' + new Date().toLocaleDateString('de-DE');

  //empty row
  const emptyRow = worksheet.addRow();
  emptyRow.getCell(1).value = '';

  //header for table dl names
  const headerDlRow = worksheet.addRow();
  //header for table
  const headerRow = worksheet.addRow();
  const sumDlRow = worksheet.addRow();

  //dls + safe positions of dl gesprices
  let pricesExcelCells: string = '';
  const rowIndex = worksheet.rowCount;
  for (let index = 0; index < selectedProvider.length; index++) {
    headerDlRow.getCell(getColIndexForInitDl(treedepth) + index * 6).value =
      selectedProvider[index].organisation;
    const colLetter = numberToExcelLetter(colIndexInitDl + index * 6 + 1);
    pricesExcelCells += colLetter + rowIndex + ',';
  }
  pricesExcelCells = pricesExcelCells.slice(0, -1);

  //ep title
  headerRow.getCell(1).value = excelAlphaEpNameHeader;
  //tag
  headerRow.getCell(getColIndexForTag(treedepth)).value = excelAlphaTagHeader;
  //epcode
  headerRow.getCell(getColIndexForEp(treedepth)).value = excelAlphaEpCodeHeader;
  //optionalPosition
  headerRow.getCell(getColIndexForOptionalPos(treedepth)).value =
    excelAlphaOptionalPositionHeader;
  //optionalPosActive
  headerRow.getCell(getColIndexForOptionalPosActive(treedepth)).value =
    excelAlphaOptionalPositionActiveHeader;
  //unit
  headerRow.getCell(getColIndexForUnit(treedepth)).value = excelAlphaUnitHeader;
  //postText
  headerRow.getCell(getColIndexForPostText(treedepth)).value =
    excelAlphaPostTextHeader;
  //postText
  headerRow.getCell(getColIndexForAmount(treedepth)).value =
    excelAlphaAmountHeader;

  //dls
  for (let index = 0; index < selectedProvider.length; index++) {
    const currIndex = index * 6;
    headerRow.getCell(colIndexInitDl + currIndex).value = 'GP';
    headerRow.getCell(colIndexInitDl + (currIndex + 1)).value = 'Gesamtsumme';
    headerRow.getCell(colIndexInitDl + (currIndex + 2)).value = 'Diff';
    headerRow.getCell(colIndexInitDl + (currIndex + 3)).value = 'Anmerkung';
    headerRow.getCell(colIndexInitDl + (currIndex + 4)).value =
      'nicht alle Werte bepreist';
    headerRow.getCell(colIndexInitDl + (currIndex + 5)).value =
      'nicht alle Liegenschaften in Marktgebiet';

    //sumrow diff
    const diffCellName =
      numberToExcelLetter(colIndexInitDl + (currIndex + 1)) + rowIndex;

    const formula = `IFERROR(IF(${diffCellName}=MIN(${pricesExcelCells}), 1, ${diffCellName}/MIN(${pricesExcelCells})),"")`;
    sumDlRow.getCell(colIndexInitDl + currIndex + 2).value = {
      formula: formula
    };
  }

  //style header
  const backgroundColorHeadline = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--primary-color');

  const colorHeadline = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--primary-color-text');

  metaInfoRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'FFFFFF'
    }
  };
  metaInfoRow.font = {
    bold: true,
    color: { argb: '000000' }
  };

  emptyRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'FFFFFF'
    },
    bgColor: {
      argb: 'FFFFFF'
    }
  };

  headerDlRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: backgroundColorHeadline.replace('#', '').replace(' ', '')
    }
  };
  headerDlRow.font = {
    bold: true,
    color: { argb: 'FFFFFF' }
  };
  headerDlRow.height = 25;
  headerDlRow.border = {
    bottom: { style: 'thin', color: { argb: 'FFFFFFFF' } }
  };

  headerRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: backgroundColorHeadline.replace('#', '').replace(' ', '')
    }
  };
  headerRow.font = {
    bold: true,
    color: { argb: 'FFFFFF' }
  };
  headerRow.height = 50;
  headerRow.border = {
    bottom: { style: 'thick', color: { argb: 'FFFFFFFF' } }
  };
  sumDlRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: backgroundColorHeadline.replace('#', '').replace(' ', '')
    }
  };
  sumDlRow.font = {
    bold: true,
    color: { argb: 'FFFFFF' }
  };

  return worksheet;
}

function generateFormulars(
  enlargedData: any,
  selectedProvider: AuthUserType[],
  colIndexInitDl: number,
  sheetDlEp: any
) {
  let pricesExcelCols: string[] = [];
  let commentExcelCols: number[] = [];
  for (let k = 0; k < selectedProvider.length; k++) {
    const priceColLetter = numberToExcelLetter(colIndexInitDl + k * 6 + 1);
    pricesExcelCols.push(priceColLetter);
    const commentColLetter = numberToExcelLetter(colIndexInitDl + k * 6 + 3);
    const commentColNumber = colIndexInitDl + k * 6 + 3;
    commentExcelCols.push(commentColNumber);
  }

  if (enlargedData && enlargedData.length > 0) {
    for (let i = 0; i < enlargedData.length; i++) {
      if (enlargedData[i] && enlargedData[i].children) {
        const rowObjectToAccumulate = accumulateChildRows(enlargedData[i], []);
        const rowsToAccumulate = rowObjectToAccumulate.rowArray;

        const isPosArray = rowObjectToAccumulate?.isPosArray;

        //priceCols
        for (let j = 0; j < pricesExcelCols.length; j++) {
          const currCol = pricesExcelCols[j];
          const currCell = sheetDlEp.getCell(
            currCol + enlargedData[i].rowNumber
          );

          currCell.value = {
            formula: `SUM(${
              isPosArray
                ? currCol +
                  rowsToAccumulate[0] +
                  ':' +
                  currCol +
                  rowsToAccumulate[rowsToAccumulate.length - 1]
                : rowsToAccumulate.map((num: number) => currCol + num).join(',')
            })`
          };
        }
        //commentCols
        for (let j = 0; j < commentExcelCols.length; j++) {
          const currCol = commentExcelCols[j];
          const commentCell = sheetDlEp.getCell(
            numberToExcelLetter(currCol) + enlargedData[i].rowNumber
          );
          const commentPriceHelperCell = sheetDlEp.getCell(
            numberToExcelLetter(currCol + 1) + enlargedData[i].rowNumber
          );
          const commentMarketareaHelperCell = sheetDlEp.getCell(
            numberToExcelLetter(currCol + 2) + enlargedData[i].rowNumber
          );
          const commentFormula = `IF(NOT(${
            numberToExcelLetter(currCol + 1) + enlargedData[i].rowNumber
          }=0),"nicht alle Werte bepreist, ","")&IF(NOT(${
            numberToExcelLetter(currCol + 2) + enlargedData[i].rowNumber
          }=0),"nicht alle Liegenschaften in Marktgebiet","")`;
          const commentPriceFormula = `SUM(${
            isPosArray
              ? numberToExcelLetter(currCol + 1) +
                rowsToAccumulate[0] +
                ':' +
                numberToExcelLetter(currCol + 1) +
                rowsToAccumulate[rowsToAccumulate.length - 1]
              : rowsToAccumulate
                  .map((num: number) => numberToExcelLetter(currCol + 1) + num)
                  .join(',')
          })`;
          const commentMarketareaFormula = `SUM(${
            isPosArray
              ? numberToExcelLetter(currCol + 2) +
                rowsToAccumulate[0] +
                ':' +
                numberToExcelLetter(currCol + 2) +
                rowsToAccumulate[rowsToAccumulate.length - 1]
              : rowsToAccumulate
                  .map((num: number) => numberToExcelLetter(currCol + 2) + num)
                  .join(',')
          })`;
          commentPriceHelperCell.value = {
            formula: commentPriceFormula
          };
          commentMarketareaHelperCell.value = {
            formula: commentMarketareaFormula
          };
          commentCell.value = {
            formula: commentFormula
          };
        }

        generateFormulars(
          enlargedData[i].children,
          selectedProvider,
          colIndexInitDl,
          sheetDlEp
        );
      }
    }
  }
}

function accumulateChildRows(
  node: any,
  rowArray: number[]
): { isPosArray: boolean; rowArray: number[] } {
  let isPositionsArray = false;
  if (node && node.children) {
    node.children.forEach((child: any) => {
      rowArray.push(child.rowNumber);
      if (child.className === 'p') {
        isPositionsArray = true;
      }
    });
  }

  return { isPosArray: isPositionsArray, rowArray: rowArray };
}

function createAlphaClusterContent(
  data: any,
  selectedProvider: AuthUserType[],
  epCodeLookup: Map<string, EnlargedLvPosition[]>,
  valueLookup: Map<string, any>,
  phase: TimelineStep,
  treeDepth: number,
  parentTitles: { title: string; depth: number }[],
  colIndexInitDl: number,
  worksheet: any
) {
  let newParentTitles: { title: string; depth: number }[] = [];
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      if (data[i] && data[i].data && data[i].data.title) {
        const newRow = worksheet.addRow();
        data[i].rowNumber = newRow.number;

        //title
        const currentTitle = data[i].data.number
          ? '[' + data[i].data.number + '] ' + data[i].data.title
          : data[i].data.title;
        const newParentTitle = { title: currentTitle, depth: data[i].depth };
        newParentTitles = [...parentTitles, newParentTitle];

        for (let i = 0; i < newParentTitles.length; i++) {
          newRow.getCell(newParentTitles[i].depth).value =
            newParentTitles[i].title;
        }
        newRow.getCell(data[i].depth).value = currentTitle;

        let pricesExcelCells: string = '';
        for (let k = 0; k < selectedProvider.length; k++) {
          const rowIndex = worksheet.rowCount;
          const colLetter = numberToExcelLetter(colIndexInitDl + k * 6 + 1);
          pricesExcelCells += colLetter + rowIndex + ',';
        }
        pricesExcelCells = pricesExcelCells.slice(0, -1);

        //price per dl
        for (let x = 0; x < selectedProvider.length; x++) {
          const currIndex = colIndexInitDl + x * 6;
          const currGp = getDlGp(
            selectedProvider[x].id,
            data[i].data.epCode,
            epCodeLookup,
            valueLookup
          );

          let comment = currGp?.notAllPrices ? currGp.notAllPrices + ', ' : '';
          comment += currGp?.notAllPropsInArea ? currGp.notAllPropsInArea : '';
          //sumgp
          newRow.getCell(currIndex).value =
            currGp?.gpSum || currGp?.gpSum === 0 ? currGp.gpSum : null;
          //sum
          const currRowIndex = worksheet.rowCount;
          const optionalPosActiveCell =
            numberToExcelLetter(getColIndexForOptionalPosActive(treeDepth)) +
            currRowIndex;
          const gpCell = numberToExcelLetter(currIndex) + currRowIndex;

          const sumFormula = `IFERROR(IF(${optionalPosActiveCell}="Nein",0,${gpCell}),"")`;
          newRow.getCell(currIndex + 1).value = { formula: sumFormula };
          //mean diff
          const currColLetter = numberToExcelLetter(colIndexInitDl + x * 6 + 1);

          const currCell = currColLetter + currRowIndex;
          const formula = `IFERROR(IF(${currCell}=MIN(${pricesExcelCells}), 1, ${currCell}/MIN(${pricesExcelCells})),"")`;
          newRow.getCell(currIndex + 2).value = {
            formula: formula
          };
          //comments
          newRow.getCell(currIndex + 3).value = comment ? comment : null;
          //helperCell
          newRow.getCell(currIndex + 4).value = currGp?.notAllPrices ? 1 : null;
          newRow.getCell(currIndex + 5).value = currGp?.notAllPropsInArea
            ? 1
            : null;
        }

        //find tag and translate it into slice title
        const slice = _.find(LeistungsSlices, function (sl) {
          return _.intersection(sl.tag, data[i].data.tags).length > 0;
        });
        //tag
        newRow.getCell(getColIndexForTag(treeDepth)).value = slice
          ? slice.title
          : null;
        newRow.getCell(getColIndexForTag(treeDepth)).font = {
          color: { argb: '808080' }
        };
        //ep
        newRow.getCell(getColIndexForEp(treeDepth)).value =
          data[i].data.epCode && _.startsWith(data[i].data.epCode, prefixActive)
            ? _.trimStart(data[i].data.epCode, prefixActive)
            : data[i].data.epCode &&
              _.startsWith(data[i].data.epCode, prefixInactive)
            ? _.trimStart(data[i].data.epCode, prefixInactive)
            : data[i].data.epCode
            ? data[i].data.epCode
            : null;
        newRow.getCell(getColIndexForEp(treeDepth)).font = {
          color: { argb: '808080' }
        };
        //optionalPos
        newRow.getCell(getColIndexForOptionalPos(treeDepth)).value =
          data[i].data.epCode &&
          (_.startsWith(data[i].data.epCode, prefixActive) ||
            _.startsWith(data[i].data.epCode, prefixInactive))
            ? 'Bedarfsposition'
            : null;

        //optionalPosActive
        newRow.getCell(getColIndexForOptionalPosActive(treeDepth)).value =
          data[i].data.epCode && _.startsWith(data[i].data.epCode, prefixActive)
            ? 'Ja'
            : data[i].data.epCode &&
              _.startsWith(data[i].data.epCode, prefixInactive)
            ? 'Nein'
            : null;

        //unit
        newRow.getCell(getColIndexForUnit(treeDepth)).value = data[i].data.unit
          ? data[i].data.unit
          : null;
        //posttext
        newRow.getCell(getColIndexForPostText(treeDepth)).value = data[i].data
          .postText
          ? data[i].data.postText
          : null;
        //amount
        //fixme
        newRow.getCell(getColIndexForAmount(treeDepth)).value = getEPAmount(
          data[i].data.epCode,
          epCodeLookup
        );

        let calculatedColor = '';
        // style row background depending on tree depth
        if (data[i].data && data[i].data.type !== 'position') {
          const opacity = (data[i].depth / treeDepth) * 100;
          if (data[i].depth === 1) {
            const primaryColor = getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary-light-color');
            calculatedColor = primaryColor.replace('#', '').replace(' ', '');
            newRow.font = { color: { argb: 'FFFFFF' }, bold: true };
          } else if (data[i].depth === 2) {
            const secondaryColor = getComputedStyle(
              document.documentElement
            ).getPropertyValue('--secondary-color');
            calculatedColor =
              'FF' + secondaryColor.replace('#', '').replace(' ', '');
          } else {
            const greyColor = getComputedStyle(
              document.documentElement
            ).getPropertyValue('--surface-700');

            calculatedColor = shadeColor(
              greyColor.replace('#', '').replace(' ', ''),
              opacity
            );
          }
        } else {
          calculatedColor = 'ffffff';
          // positions style

          newRow.border = {
            bottom: { style: 'thin', color: { argb: 'eeeeee' } }
          };
        }
        newRow.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: calculatedColor
          },
          bgColor: {
            argb: 'ffffff'
          }
        };

        //style fontcolor for filtercategories
        if (data[i].depth) {
          for (let k = 1; k < data[i].depth; k++) {
            const cell = newRow.getCell(k);
            cell.font = {
              color: { argb: calculatedColor }
            };
          }
        }
      }
      if (data[i] && data[i].children) {
        createAlphaClusterContent(
          data[i].children,
          selectedProvider,
          epCodeLookup,
          valueLookup,
          phase,
          treeDepth,
          newParentTitles,
          colIndexInitDl,
          worksheet
        );
      }
    }
  }
  return data;
}

/////////////////
///// Helper ////
/////////////////

function getDlGp(
  providerId: string,
  ep: string,
  epCodeLookup: Map<string, EnlargedLvPosition[]>,
  valueLookup: Map<string, any>
) {
  if (ep) {
    let gpSum: number = 0;
    let notAllPrices: boolean = false;
    let notAllPropsInArea: boolean = false;
    //load all data for this provider
    const epPositions = epCodeLookup.get(ep);
    if (epPositions && valueLookup) {
      for (let j = 0; j < epPositions.length; j++) {
        const pos = epPositions[j];
        const amount = pos.amount ? pos.amount : 0;
        const priceRaw =
          valueLookup.get(pos.epPriceId) &&
          valueLookup.get(pos.epPriceId)[providerId]
            ? valueLookup.get(pos.epPriceId)[providerId]
            : null;
        let priceVal = 0;
        if (!priceRaw || !_.isNumber(parseInt(priceRaw))) {
          notAllPrices = true;
        } else {
          priceVal = parseInt(priceRaw);
        }
        const blFac = parseInt(
          valueLookup.get(pos.blFactorId) &&
            valueLookup.get(pos.blFactorId)[providerId]
            ? valueLookup.get(pos.blFactorId)[providerId]
            : DEFAULT_FACTOR
        );
        const lsFac = parseInt(
          valueLookup.get(pos.lsFactorId) &&
            valueLookup.get(pos.lsFactorId)[providerId]
            ? valueLookup.get(pos.lsFactorId)[providerId]
            : DEFAULT_FACTOR
        );
        const nFac = parseInt(
          valueLookup.get(pos.nFactorId) &&
            valueLookup.get(pos.nFactorId)[providerId]
            ? valueLookup.get(pos.nFactorId)[providerId]
            : DEFAULT_FACTOR
        );
        const mVal: boolean =
          valueLookup.get(pos.mFactorId) &&
          valueLookup.get(pos.mFactorId)[providerId] === 'false'
            ? false
            : true;
        if (!mVal) {
          notAllPropsInArea = true;
        }
        const blVal = blFac ? blFac : DEFAULT_FACTOR;
        const lsVal = lsFac ? lsFac : DEFAULT_FACTOR;
        const nVal = nFac ? nFac : DEFAULT_FACTOR;
        gpSum = gpSum + calculateGP(amount, priceVal, blVal, lsVal, nVal);
      }
    }

    if (notAllPropsInArea && notAllPrices) {
      return {
        notAllPropsInArea: 'nicht alle Liegenschaften in Marktgebiet',
        notAllPrices: 'nicht alle Werte bepreist',
        gpSum: gpSum / 1000
      };
    } else if (notAllPropsInArea) {
      return {
        notAllPropsInArea: 'nicht alle Liegenschaften in Marktgebiet',
        gpSum: gpSum / 1000
      };
    } else if (notAllPrices) {
      return { notAllPrices: 'nicht alle Werte bepreist', gpSum: gpSum / 1000 };
    } else {
      return { gpSum: gpSum / 1000 };
    }
  }
  return null;
}
function getDlGesamtsumme(
  providerId: string,
  ep: string,
  epCodeLookup: Map<string, EnlargedLvPosition[]>,
  valueLookup: Map<string, any>
) {
  //fixme return price
  if (ep) {
    let gpSum: number = 0;

    //load all data for this provider
    const epPositions = epCodeLookup.get(ep);
    if (epPositions && valueLookup) {
      for (let j = 0; j < epPositions.length; j++) {
        const pos = epPositions[j];
        const amount = pos.amount ? pos.amount : 0;
        const priceRaw =
          valueLookup.get(pos.epPriceId) &&
          valueLookup.get(pos.epPriceId)[providerId]
            ? valueLookup.get(pos.epPriceId)[providerId]
            : null;
        let priceVal = 0;
        if ((priceRaw || priceRaw === 0) && _.isNumber(parseInt(priceRaw))) {
          priceVal = parseInt(priceRaw);
        }
        const blFac = parseInt(
          valueLookup.get(pos.blFactorId) &&
            valueLookup.get(pos.blFactorId)[providerId]
            ? valueLookup.get(pos.blFactorId)[providerId]
            : DEFAULT_FACTOR
        );
        const lsFac = parseInt(
          valueLookup.get(pos.lsFactorId) &&
            valueLookup.get(pos.lsFactorId)[providerId]
            ? valueLookup.get(pos.lsFactorId)[providerId]
            : DEFAULT_FACTOR
        );
        const nFac = parseInt(
          valueLookup.get(pos.nFactorId) &&
            valueLookup.get(pos.nFactorId)[providerId]
            ? valueLookup.get(pos.nFactorId)[providerId]
            : DEFAULT_FACTOR
        );

        const blVal = blFac ? blFac : DEFAULT_FACTOR;
        const lsVal = lsFac ? lsFac : DEFAULT_FACTOR;
        const nVal = nFac ? nFac : DEFAULT_FACTOR;

        if (
          !pos.optionalPosition ||
          (pos.optionalPosition && pos.optionalPositionActive)
        ) {
          gpSum = gpSum + calculateGP(amount, priceVal, blVal, lsVal, nVal);
        }
      }
    }

    return gpSum / 1000;
  }
  return '';
}

function getEPAmount(
  epCode: string,
  epCodeLookup: Map<string, EnlargedLvPosition[]>
) {
  const entries: any = !epCode ? null : epCodeLookup.get(epCode);
  if (entries) {
    let amount: number = 0;
    entries.forEach((e: EnlargedLvPosition) => {
      if (e.amount) {
        amount += e.amount;
      }
    });
    return '' + amount;
  }
  return '';
}

function getColIndexForAmount(treedepth: number) {
  return treedepth + 12;
}
function getColIndexForInitDl(treedepth: number) {
  return treedepth + 13;
}
function getColIndexForTag(treedepth: number) {
  return treedepth + 5;
}
function getColIndexForEp(treedepth: number) {
  return treedepth + 6;
}

function getColIndexForOptionalPos(treedepth: number) {
  return treedepth + 8;
}
function getColIndexForOptionalPosActive(treedepth: number) {
  return treedepth + 9;
}
function getColIndexForUnit(treedepth: number) {
  return treedepth + 10;
}
function getColIndexForPostText(treedepth: number) {
  return treedepth + 11;
}

function applyConditionalFormattingDiff(
  selectedProvider: AuthUserType[],
  colIndexForInitDl: number,
  worksheet: any
) {
  let colNamesToFormat = [];
  const priceIndex = colIndexForInitDl;
  const maxRow = worksheet.rowCount;
  for (let h = 0; h < selectedProvider.length; h++) {
    //diff
    const currColLetter = numberToExcelLetter(priceIndex + (h * 6 + 2));
    colNamesToFormat.push(`${currColLetter}5:${currColLetter + maxRow}`);
  }

  // conditional formatting
  if (colNamesToFormat) {
    //color cells depending on input

    // Define the conditional formatting rules
    const rule1 = {
      type: 'cellIs',
      formulae: ['1'],
      operator: 'equal',

      style: {
        font: {
          color: { argb: '2AB06F' } // Green text color (ARGB format)
        }
      }
    };

    const rule2 = {
      type: 'cellIs',
      formulae: ['1'],
      operator: 'notEqual',
      style: {
        font: {
          color: { argb: 'B71C1C' } // Red text color (ARGB format)
        }
      }
    };
    // Apply the conditional formatting rules to a range
    for (let j = 0; j < colNamesToFormat.length; j++) {
      worksheet.addConditionalFormatting({
        ref: colNamesToFormat[j],
        rules: [rule1, rule2]
      });
    }
  }

  return worksheet;
}
