import _ from 'lodash';
import { Toast } from 'primereact/toast';
import { useRef, useState } from 'react';
import './AlphaVergabeTable.scss';
import { LiegenschaftsData } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import { AuthUserType } from '../../../Helper/ApiHelper/LoginNetworkHelper';
import {
  ALPHA_DL_NOT_IN_MARKETAREA,
  ALPHA_DL_NO_PRICE,
  DEFAULT_CALCULATION_MULTIPLIER,
  DEFAULT_FACTOR,
  DURATION_NOTIFICATION_ERROR_LONG,
  LEISTUNGSART,
  LeistungsSlices,
  MAX_NUMBER_OF_DECIMALS,
  MIN_NUMBER_OF_DECIMALS
} from '../../../Helper/Statics/Constants';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import BeeButton from '../../Atoms/BeeButton';
import {
  generateAwardingKey,
  generateTargetPriceId
} from '../../../Helper/Util/IdGeneratorHelper';
import {
  KeyValEntry,
  MiniKeyValEntry
} from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import AlphaAwardingDialog from '../Dialogs/AlphaAwardingDialog';
import BeeCurrencyInput from '../../Atoms/BeeCurrencyInput';
import {
  EnlargedLvPosition,
  generateAwardedLV,
  searchTreePosition
} from '../../../Helper/ApiHelper/LvEpNetworkHelper';
import {
  calculateFakEp,
  filterArrayByHavingLvPosLeafs
} from '../../../Helper/Util/LvCalculator';
import { generateAwardRequest } from '../../../Helper/Util/AwardedLvHelper';
import { handlePromisesAndIgnoreErrors } from '../../../Helper/Util/PromiseHelper';
import { zipDocs } from '../../../Helper/StorageHelper/ZipHelper';
import {
  generateExportKeyVals,
  generateExportPropContract
} from '../../../Helper/StorageHelper/ExportHelper';

type AlphaVergabeTableProps = {
  phaseId: string;
  selectedProperties: LiegenschaftsData[];
  selectedProvider: AuthUserType[];
  selectedSlices: LEISTUNGSART[];
  allProperties: LiegenschaftsData[];
  allProvider: AuthUserType[];
  allValues: any;
  lookup: any;
  lvLookup: Map<string, EnlargedLvPosition>;
  lvTree: any;
  kvRaw: MiniKeyValEntry[];
  awarding: Map<string, KeyValEntry>;
  targetPriceLookup: Map<string, any>;
  onUpdateAwarding: (data: KeyValEntry, key: string) => void;
};

export default function AlphaVergabeTable({
  phaseId,
  selectedProperties,
  selectedProvider,
  selectedSlices,
  allProperties,
  allProvider,
  allValues,
  lookup,
  lvLookup,
  lvTree,
  kvRaw,
  awarding,
  targetPriceLookup,
  onUpdateAwarding
}: AlphaVergabeTableProps) {
  const [vAwardingDialog, setVAwardingDialog] = useState<boolean>(false);
  const [currProperty, setCurrProperty] = useState<LiegenschaftsData | null>(
    null
  );
  const [currSlice, setCurrSlice] = useState<LEISTUNGSART>();
  const [currTargetPrice, setCurrTargetPrice] = useState<number | null>(null);
  const [generatingLvs, setGeneratingLvs] = useState<boolean>(false);
  const toast = useRef<Toast>(null);

  ////////////////////
  // BUSINESS LOGIC //
  ////////////////////

  function generateAuftragsLvs() {
    if (allProperties) {
      setGeneratingLvs(true);
      let promiseArray: any = [];
      const awardees: String[] = [];
      allProperties.forEach((prop: LiegenschaftsData) => {
        const propId: string = prop.id!;
        const allPositions: EnlargedLvPosition | undefined =
          lvLookup.get(propId);
        if (allPositions) {
          const lup: Map<string, LEISTUNGSART[]> = new Map();
          LeistungsSlices.forEach((slice: LEISTUNGSART) => {
            const aKey = generateAwardingKey(phaseId, propId, slice.id);
            if (awarding.get(aKey)) {
              const y: any = awarding.get(aKey)!.value;
              const x: any = lup.get(y) ? lup.get(y) : [];
              x.push(slice);
              lup.set(y, x);
            }
          });
          //filter all positions by selected slices
          lup.forEach((slices, provId) => {
            const filterTags: string[] = [];
            slices.forEach((s: LEISTUNGSART) => filterTags.push(...s.tag));
            let filPayload: any = _.filter(allPositions, function (p: any) {
              if (p.sliceTag) {
                return filterTags.includes(p.sliceTag);
              }
              return false;
            });
            let filteredTree = calculateLV(lvTree, filPayload);
            if (filteredTree) {
              filteredTree = filterArrayByHavingLvPosLeafs(filteredTree);
            }
            let pArray: any = [];
            let provider: any = _.find(allProvider, function (p) {
              return p.id === provId;
            });
            awardees.push(provider!.id);
            //add for export json
            promiseArray.push(
              generateExportPropContract(prop, filPayload, provider)
            );
            pArray.push(provider);
            const req = generateAwardRequest(prop, pArray, filteredTree);
            if (req) {
              promiseArray.push(
                generateAwardedLV(req, phaseId, propId, 'excel').then(
                  (result: any) => {
                    let filename =
                      'AuftragsLV_' +
                      _.camelCase(provider.organisation) +
                      '_[' +
                      _.camelCase(prop.nummer) +
                      ']_' +
                      _.kebabCase(prop.name) +
                      '_' +
                      new Date().toLocaleDateString('de-DE') +
                      '.xlsx';
                    if (filename) {
                      return { name: filename, blob: result };
                    }
                  }
                )
              );
            }
          });
        }
      });
      promiseArray.push(generateExportKeyVals(kvRaw, awardees, phaseId));
      //fire all promises
      handlePromisesAndIgnoreErrors(promiseArray)
        .then((result: any) => {
          if (result.fulfilled && result.fulfilled.length > 0) {
            let fileArray: any = [];
            for (let j = 0; j < result.fulfilled.length; j++) {
              fileArray.push(result.fulfilled[j].value);
            }
            const date = new Date().toLocaleDateString('de-DE');
            zipDocs(fileArray, 'AuftragsLVs' + date + '.zip')
              .then(() => {
                if (toast && toast.current) {
                  if (!result.rejected || result.rejected.length <= 0) {
                    toast.current?.show({
                      severity: 'success',
                      summary: 'Download erfolgreich abgeschlossen',
                      sticky: false,
                      closable: true,
                      life: DURATION_NOTIFICATION_ERROR_LONG
                    });
                  } else {
                    toast.current?.show({
                      severity: 'warn',
                      summary: 'Erstellung ist fehlgeschlagen',
                      detail:
                        'Die Erstellung einzelner AuftragsLVs ist fehlgeschlagen, bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
                      sticky: false,
                      closable: true,
                      life: DURATION_NOTIFICATION_ERROR_LONG
                    });
                  }
                }
              })
              .catch((error) => {
                if (toast && toast.current) {
                  toast.current?.show({
                    severity: 'error',
                    summary: 'Der Download ist fehlgeschlagen',
                    detail:
                      'Die AuftragsLVs konnten nicht gezipped und heruntergeladen werden. Bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
                    sticky: true,
                    closable: true,
                    life: DURATION_NOTIFICATION_ERROR_LONG
                  });
                }
              });
          } else if (result.rejected && result.rejected.length > 0) {
            if (toast && toast.current) {
              toast.current?.show({
                severity: 'error',
                summary: 'Erstellung ist fehlgeschlagen',
                detail:
                  'Der Server konnte nicht erreicht werden. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
                sticky: true,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            }
          }
        })
        .catch(() => {
          if (toast && toast.current) {
            toast.current?.show({
              severity: 'error',
              summary: 'Erstellung ist fehlgeschlagen',
              detail:
                'Der Server konnte nicht erreicht werden. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        })
        .finally(() => setGeneratingLvs(false));
    }
  }

  function calculateLV(
    shortenLvTree: any,
    enlargedLvPositions: EnlargedLvPosition[]
  ) {
    if (shortenLvTree && enlargedLvPositions) {
      let lvTableTree = _.cloneDeep(shortenLvTree);
      //lvPos are sorted
      for (let x = 0; x < enlargedLvPositions.length; x++) {
        const lvPos = enlargedLvPositions[x];
        let parent = null;
        //search ep node
        for (let i = 0; i < lvTableTree.length; i++) {
          parent = searchTreePosition(
            lvTableTree[i],
            lvPos.leftInt,
            lvPos.rightInt
          );
          if (parent) {
            break;
          }
        }
        parent.children = parent.children ? parent.children : [];
        const amount: number = lvPos.amount ? lvPos.amount : 0;
        parent.children.push({
          key: lvPos.id,
          className: 'lv-pos',
          data: {
            tag: lvPos.sliceTag,
            values: calculateEpForProviders(
              lvPos.blFactorId,
              lvPos.lsFactorId,
              lvPos.nFactorId,
              lvPos.mFactorId,
              lvPos.epPriceId,
              amount
            ),
            amount: amount,
            epCode: lvPos.epCode,
            title: lvPos.description,
            posNumber: lvPos.serialNumber,
            aks: lvPos.identificationNumber
          }
        });
      }
      return lvTableTree;
    }
    return [];
  }

  function search(lookupKey: string) {
    return allValues.get(lookupKey);
  }

  function calculateEpForProviders(
    blFactor: string,
    lsFactor: string,
    nFactor: string,
    mFactor: string,
    epPriceId: string,
    amount: number
  ) {
    let answer: any = {};
    const blFacValue = search(blFactor);
    const lsFacValue = search(lsFactor);
    const nFacValue = search(nFactor);
    const mFacValue = search(mFactor);
    const priceValue = search(epPriceId);
    allProvider.forEach((p: any) => {
      if (mFacValue && mFacValue[p.id] && mFacValue[p.id] === 'false') {
        answer[p.id] = ALPHA_DL_NOT_IN_MARKETAREA;
      } else if (!priceValue[p.id] || priceValue[p.id] === 'null') {
        answer[p.id] = ALPHA_DL_NO_PRICE;
      } else {
        const price = parseInt(priceValue[p.id]);
        const blF = parseInt(
          blFacValue && blFacValue[p.id] ? blFacValue[p.id] : DEFAULT_FACTOR
        );
        const lsF = parseInt(
          lsFacValue && lsFacValue[p.id] ? lsFacValue[p.id] : DEFAULT_FACTOR
        );
        const nF = parseInt(
          nFacValue && nFacValue[p.id] ? nFacValue[p.id] : DEFAULT_FACTOR
        );
        const blFac = blF ? blF : DEFAULT_FACTOR;
        const lsFac = lsF ? lsF : DEFAULT_FACTOR;
        const nFac = nF ? nF : DEFAULT_FACTOR;
        answer[p.id] = {};
        answer[p.id].ep = calculateFakEp(price, blFac, lsFac, nFac);
        answer[p.id].gp = amount * answer[p.id].ep;
      }
    });
    return answer;
  }

  function activateVergabeDialog(
    property: LiegenschaftsData,
    slice: LEISTUNGSART,
    targetPrice?: number
  ) {
    setCurrProperty(property);
    setCurrSlice(slice);
    setCurrTargetPrice(targetPrice ? targetPrice : null);
    setVAwardingDialog(true);
  }

  ////////////////
  // UI METHODS //
  ////////////////

  const renderBodyTemplate = (
    rowData: LiegenschaftsData,
    slice: LEISTUNGSART
  ) => {
    //target prices
    const tpId = generateTargetPriceId(phaseId, rowData.id!, slice.id);
    const valString = targetPriceLookup
      ? targetPriceLookup.get(tpId)
      : undefined;
    const targetPrice = valString ? parseInt(valString) : undefined;
    //selected provider & awarding
    const propId: any = rowData.id;
    const property: any = _.find(
      selectedProperties,
      function (pr: LiegenschaftsData) {
        return pr.id === propId;
      }
    );
    const awardingKey = generateAwardingKey(phaseId, propId, slice.id);
    const aEntry = awarding.get(awardingKey);
    //comparison values
    let values = [];
    let incompleteValues = [];
    let notInMarketValues = [];
    if (lookup && lookup[property.id] && lookup[property.id!][slice.id]) {
      for (const providerId in lookup[property.id][slice.id]) {
        let entry = _.cloneDeep(lookup[property.id][slice.id][providerId]);
        entry.providerid = providerId;
        if (entry.inComplete) {
          incompleteValues.push(entry);
        } else if (entry.notInMarket) {
          notInMarketValues.push(entry);
        } else {
          values.push(entry);
        }
      }
    }
    //sort entries
    values = _.sortBy(values, ['sum']);
    incompleteValues = _.sortBy(incompleteValues, ['sum']);
    notInMarketValues = _.sortBy(notInMarketValues, ['sum']);
    values.push(...incompleteValues);
    values.push(...notInMarketValues);
    ///////
    //UIs//
    ///////
    if (aEntry) {
      //provider is selected
      const awardedProvId = aEntry.value;
      const p = _.find(allProvider, function (o) {
        return o.id === awardedProvId;
      });
      let rank;
      let award;
      for (let i = 0; i < values.length; i++) {
        if (values[i].providerid === awardedProvId) {
          rank = i + 1;
          award = values[i];
          break;
        }
      }
      return (
        <div
          onClick={() => activateVergabeDialog(property, slice, targetPrice)}
          className={
            award && (award.notInMarket === true || award.inComplete === true)
              ? 'grid alpha-vergabe-dt-vergeben error-background'
              : 'grid alpha-vergabe-dt-vergeben valid-background'
          }
        >
          {p ? (
            <div className="col col-7 alpha-vergabe-organisation">
              <b>{rank ? rank + '. ' + p.organisation : p.organisation}</b>
            </div>
          ) : null}
          {award ? (
            <div className="col col-5">
              <b>Diff ZS:</b>{' '}
              <div
                className={
                  targetPrice && award.sum
                    ? _.round(
                        (award.sum / targetPrice) *
                          DEFAULT_CALCULATION_MULTIPLIER
                      ) /
                        10 >
                      100
                      ? 'error-color'
                      : 'valid-color'
                    : ''
                }
              >
                {targetPrice
                  ? _.round(
                      (award.sum / targetPrice) * DEFAULT_CALCULATION_MULTIPLIER
                    ) /
                      10 +
                    ' %'
                  : '—%'}
              </div>
            </div>
          ) : null}
          {award && award.sum ? (
            <div className="col col-7">
              <b>Summe:</b>{' '}
              <div className={'alpha-vergabe-kiloPrice'}>
                <span className={'alpha-vergabe-price-kilo-tag'}>K</span>
                <BeeCurrencyInput
                  value={
                    award.sum
                      ? _.divide(
                          award.sum,
                          DEFAULT_CALCULATION_MULTIPLIER * 1000
                        )
                      : award.sum === 0
                      ? 0
                      : null
                  }
                  disabled={false}
                  minFractionDigits={MIN_NUMBER_OF_DECIMALS}
                  maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
                  formstate={'none'}
                  readOnly={true}
                />
              </div>
            </div>
          ) : null}
          {targetPrice ? (
            <div className="col col-5">
              <b>ZS:</b>{' '}
              <div className={'alpha-vergabe-kiloPrice'}>
                <span className={'alpha-vergabe-price-kilo-tag'}>K</span>
                <BeeCurrencyInput
                  value={
                    targetPrice
                      ? _.divide(
                          targetPrice,
                          DEFAULT_CALCULATION_MULTIPLIER * 1000
                        )
                      : targetPrice === 0
                      ? 0
                      : null
                  }
                  disabled={false}
                  minFractionDigits={MIN_NUMBER_OF_DECIMALS}
                  maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
                  formstate={'none'}
                  readOnly={true}
                />
              </div>
            </div>
          ) : null}
        </div>
      );
    } else {
      let bestSelected: any;
      for (let i = 0; i < values.length; i++) {
        if (_.some(selectedProvider, { id: values[i].providerid })) {
          bestSelected = values[i];
          break;
        }
      }
      const p: any = _.find(allProvider, function (o) {
        return o.id === (bestSelected ? bestSelected.providerid : '');
      });
      return (
        <div
          onClick={() => activateVergabeDialog(property, slice, targetPrice)}
          className="grid alpha-vergabe-dt-unvergeben"
        >
          {p ? (
            <div className="col col-7 alpha-vergabe-suggestion">
              <b>Vorschlag:</b>
              <div>{p.organisation}</div>
            </div>
          ) : null}
          {bestSelected ? (
            <div className="col col-5">
              <b>Diff ZS: </b>
              <div
                className={
                  targetPrice && bestSelected.sum
                    ? _.round((bestSelected.sum / targetPrice) * 1000) / 10 >
                      100
                      ? 'error-color'
                      : 'valid-color'
                    : ''
                }
              >
                {targetPrice
                  ? _.round((bestSelected.sum / targetPrice) * 1000) / 10 + ' %'
                  : ' — %'}
              </div>
            </div>
          ) : null}
          {bestSelected && bestSelected.sum ? (
            <div className="col col-7">
              <b>Summe:</b>
              <div className={'alpha-vergabe-kiloPrice'}>
                <span className={'alpha-vergabe-price-kilo-tag'}>K</span>
                <BeeCurrencyInput
                  value={
                    bestSelected.sum
                      ? _.divide(bestSelected.sum, 1000000)
                      : bestSelected.sum === 0
                      ? 0
                      : null
                  }
                  disabled={false}
                  minFractionDigits={MIN_NUMBER_OF_DECIMALS}
                  maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
                  formstate={'none'}
                  readOnly={true}
                />
              </div>
            </div>
          ) : (
            <div className="col col-7">
              {' '}
              <b>Summe: </b>
              <div> — </div>
            </div>
          )}
          {targetPrice ? (
            <div className="col col-5">
              <b>ZS:</b>
              <div className={'alpha-vergabe-kiloPrice'}>
                <span className={'alpha-vergabe-price-kilo-tag'}>K</span>
                <BeeCurrencyInput
                  value={
                    targetPrice
                      ? _.divide(targetPrice, 1000000)
                      : targetPrice === 0
                      ? 0
                      : null
                  }
                  disabled={false}
                  minFractionDigits={MIN_NUMBER_OF_DECIMALS}
                  maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
                  formstate={'none'}
                  readOnly={true}
                />
              </div>
            </div>
          ) : (
            <div className="col col-5">
              {' '}
              <b>ZS: </b>
              <div> — </div>
            </div>
          )}
        </div>
      );
    }
  };

  const dynamicColumns = selectedSlices.map((slice: LEISTUNGSART) => {
    return (
      <Column
        body={(rowData: LiegenschaftsData) =>
          renderBodyTemplate(rowData, slice)
        }
        header={slice.title.replace('management', 'mgmt')}
        className="alpha-vergabe-dt-slice-col"
        style={{ width: '14em' }}
      />
    );
  });

  const propertyTemplate = (data: any, options: ColumnBodyOptions) => {
    if (options) {
      if (options.field === 'field_0') {
        return (
          <div className={'lv-table-liegenschaft'}>
            <div className={'lv-number'}>{data.nummer}</div>
            <div>{data.name}</div>
          </div>
        );
      } else if (options.field === 'field_1') {
        return (
          <div>
            <div>
              <span>{data.adresse ? data.adresse.strasse : null}</span>{' '}
              <span>{data.adresse ? data.adresse.hausnummer : null}</span>
              <div></div>
              <span>
                {data.adresse ? data.adresse.postleitzahl : null}
              </span>{' '}
              <span>{data.adresse ? data.adresse.stadt : null}</span>
            </div>
          </div>
        );
      }
    }
  };

  const injectTable = () => {
    return (
      <DataTable
        value={selectedProperties}
        emptyMessage={'Keine Liegenschaften vorhanden'}
        className={'alpha-vergabe-datatable'}
        scrollable
        scrollDirection="horizontal"
        style={{ width: '100%' }}
      >
        <Column
          className="alpha-vergabe-dt-prop-col frozen-col"
          body={propertyTemplate}
          header={'Liegenschaft'}
          style={{ width: '11em' }}
        />
        <Column
          className="alpha-vergabe-dt-adress-col frozen-col"
          body={propertyTemplate}
          header={'Adresse'}
          style={{ width: '12em' }}
        />
        {selectedSlices ? dynamicColumns : null}
      </DataTable>
    );
  };

  const injectAwardingDialog = () => {
    return (
      <AlphaAwardingDialog
        phaseId={phaseId}
        property={currProperty}
        provider={allProvider}
        selectedProvider={selectedProvider}
        slice={currSlice}
        visible={vAwardingDialog}
        targetPrice={currTargetPrice}
        lookup={lookup}
        awarding={awarding}
        onHide={() => setVAwardingDialog(false)}
        onSaved={(data, key) => {
          onUpdateAwarding(data, key);
          setVAwardingDialog(false);
        }}
      />
    );
  };

  return (
    <div className="alpha-vergabe-table">
      <BeeContentHeadline
        label={'Vergabe'}
        headline={'h2'}
        type={'secondary'}
      />
      <div className="mb-3">
        Die Möglichkeit der Eingabe von Zielpreisen dient dazu eine
        Vergleichsbasis für die Leistungsgruppen/Liegenschaften anzugeben. Diese
        können beispielsweise Daten aus dem Vorjahr sein o.Ä. Für die Vergabe
        klicken Sie bitte auf die Tabellenzellen um für ein Slice einen
        Dientleister anzugeben.
      </div>
      {injectTable()}
      <div className="flex justify-content-end mt-3">
        <BeeButton
          label={'Auftrags-LVs generieren'}
          disabled={generatingLvs}
          type={'secondary'}
          isLoading={generatingLvs}
          onClick={() => {
            if (!generatingLvs) {
              generateAuftragsLvs();
            }
          }}
        />
      </div>
      {vAwardingDialog ? injectAwardingDialog() : null}
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
