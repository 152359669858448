import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  PATH_ALPHA_ANALYSIS,
  PATH_DL_PRICEINPUT,
  PATH_ROOT
} from '../../Helper/Statics/Routes';
import { isUserDL, isUserFromAlpha } from '../../Helper/Util/JwtHelper';
import { logoutUser } from '../../Redux/Authentication/authActions';
import { AppDispatch, RootState } from '../../Redux/store';
import BeeIcon from '../Atoms/BeeIcon';
import './Header.scss';

type HeaderProps = {
  logoutUser: any;
};

function Header(props: HeaderProps) {
  let navigate = useNavigate();

  function logoutUser() {
    props.logoutUser();
  }
  function clickLogo() {
    if (isUserFromAlpha()) {
      navigate(PATH_ALPHA_ANALYSIS);
    } else if (isUserDL()) {
      navigate(PATH_DL_PRICEINPUT);
    } else {
      navigate(PATH_ROOT);
    }
  }
  return (
    <div className={'header'}>
      <div
        className={'logo'}
        onClick={() => {
          clickLogo();
        }}
      />
      {/* only show logout if signed in */}
      {sessionStorage.getItem('aT') ? (
        <BeeIcon
          iconClass={'icon-logout'}
          type={'light'}
          size={'large'}
          onClick={() => logoutUser()}
        />
      ) : null}
    </div>
  );
}

const mapState = (state: RootState) => {
  return {
    refreshToken: state.app.refreshToken
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser())
  };
};

export default connect(mapState, mapDispatchToProps)(Header);
