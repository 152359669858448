import FileSaver from 'file-saver';
import _ from 'lodash';
import { TimelineStep } from '../../UI/Atoms/BeeTimeline';
import { BLKeyValueStore } from '../../UI/Molecules/DL/DLPriceInput';
import {
  DEFAULT_CALCULATION_MULTIPLIER,
  DEFAULT_FACTOR,
  LeistungsSlices,
  OUTPUT_FILE_HEADLINE,
  SERVER_RESPONSE_ERROR,
  SERVER_RESPONSE_PENDING
} from '../Statics/Constants';
import {
  generateEpEntryId,
  simpleGenerateBlFactorId,
  simpleGenerateLsFactorId,
  simpleGenerateLsInMarktgebietId
} from '../Util/IdGeneratorHelper';
import { AuthUserType } from '../ApiHelper/LoginNetworkHelper';
import {
  calculateTreeDepth,
  numberToExcelLetter,
  shadeColor
} from './ExcelExportTooling';

const ExcelJS = require('exceljs');

const excelAlphaPricingSheetName = 'EP-Katalog';
const excelAlphaBlFacSheetName = 'Bundeslandsfaktoren';
const excelAlphaLsFacSheetName = 'Liegenschaftsfaktoren';

const excelAlphaEpNameHeader = 'Ep-Position';
const excelAlphaPriceHeader = 'EP-Katalog';
const excelAlphaTagHeader = 'Leistungstyp';
const excelAlphaEpCodeHeader = 'Ep-Code';
const excelAlphaUnitHeader = 'Einheit';
const excelAlphaPostTextHeader = 'Nachtext';

const excelAlphaBlFacHeader = 'Bundesländer';
const excelAlphaLsFacHeader = 'Liegenschaften';

export function exportAlphaPricingExcel(
  epTree: any,
  selectedProvider: AuthUserType[],
  keyValueStore: Map<string, any>,
  blStructure: any,
  lsStructure: any,
  phase: TimelineStep
) {
  return new Promise<void>(async (resolve, reject) => {
    const workbook = new ExcelJS.Workbook();

    //metadata
    workbook.creator = '';
    workbook.lastModifiedBy = '';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();
    workbook.views = [
      {
        x: 0,
        y: 0,
        width: 10000,
        height: 20000,
        firstSheet: 0,
        activeTab: 2,
        visibility: 'visible'
      }
    ];

    // Force workbook calculation on load
    workbook.calcProperties.fullCalcOnLoad = true;

    //create Sheets
    let sheetAlphaBlFac = workbook.addWorksheet(excelAlphaBlFacSheetName, {
      properties: { defaultColWidth: 14 }
    });
    let sheetAlphaLsFac = workbook.addWorksheet(excelAlphaLsFacSheetName, {
      properties: { defaultColWidth: 14 }
    });
    let sheetAlphaEp = workbook.addWorksheet(excelAlphaPricingSheetName, {
      properties: { defaultColWidth: 14 }
    });

    //col widhts
    sheetAlphaEp = setUpColsAlphaEp(epTree, selectedProvider, sheetAlphaEp);
    sheetAlphaBlFac = setUpColsAlphaBlFac(
      selectedProvider,
      blStructure,
      sheetAlphaBlFac
    );
    sheetAlphaLsFac = setUpColsAlphaLsFac(
      selectedProvider,
      lsStructure,
      sheetAlphaLsFac
    );

    //col header
    sheetAlphaEp = createSheetAlphaEpHeader(
      epTree,
      selectedProvider,
      sheetAlphaEp,
      phase
    );
    //col header
    sheetAlphaBlFac = createSheetAlphaBlFacHeader(
      blStructure,
      selectedProvider,
      sheetAlphaBlFac,
      phase
    );
    //col header
    sheetAlphaLsFac = createSheetAlphaLsFacHeader(
      lsStructure,
      selectedProvider,
      sheetAlphaLsFac,
      phase
    );

    //content
    if (epTree) {
      const epTreeDepth = calculateTreeDepth(epTree) - 1;
      const colIndexPrice = getColIndexForInitDl(epTreeDepth);
      sheetAlphaEp = createAlphaEpContent(
        epTree,
        selectedProvider,
        keyValueStore,
        phase,
        epTreeDepth,
        [],
        colIndexPrice,
        sheetAlphaEp
      );
      applyConditionalFormatting(selectedProvider, colIndexPrice, sheetAlphaEp);
    }
    if (blStructure) {
      const blFacStructureDepth = calculateTreeDepth(blStructure) - 1;
      const colIndexBlFac = getColIndexBlFacForInitDl(blFacStructureDepth);

      sheetAlphaBlFac = createAlphaBlFacContent(
        blStructure,
        selectedProvider,
        keyValueStore,
        phase,
        blFacStructureDepth,
        [],
        colIndexBlFac,
        sheetAlphaBlFac
      );
      applyConditionalFormatting(
        selectedProvider,
        colIndexBlFac,
        sheetAlphaBlFac
      );
    }
    if (lsStructure) {
      const lsFacStructureDepth = calculateTreeDepth(lsStructure) - 1;
      const colIndexLsFac = getColIndexBlFacForInitDl(lsFacStructureDepth);

      sheetAlphaLsFac = createAlphaLsFacContent(
        lsStructure,
        selectedProvider,
        keyValueStore,
        phase,
        lsFacStructureDepth,
        [],
        colIndexLsFac,
        sheetAlphaLsFac
      );
      applyConditionalFormatting(
        selectedProvider,
        colIndexLsFac,
        sheetAlphaLsFac
      );
    }

    // write to a file
    workbook.xlsx
      .writeBuffer()
      .then((buffer: any) => {
        FileSaver.saveAs(
          new Blob([buffer]),
          `${Date.now()}_Rahmenpreise_Preisspiegel.xlsx`
        );
        resolve();
      })
      .catch((err: any) => {
        console.log('Error writing excel export', err);
        reject(err);
      });
  });
}

function setUpColsAlphaEp(
  tree: any,
  selectedProvider: AuthUserType[],
  worksheet: any
) {
  let treedepth = calculateTreeDepth(tree) - 1;

  /*   columnwidths/styles */
  for (let i = 1; i < treedepth + 2; i++) {
    worksheet.getColumn(i).key = 'depth-' + i;
    worksheet.getColumn(i).width = 3;
  }
  //price
  const priceIndex = getColIndexForInitDl(treedepth);
  for (let h = 0; h < selectedProvider.length; h++) {
    worksheet.getColumn(priceIndex + h * 2).width = 14;
    worksheet.getColumn(priceIndex + h * 2).numFmt = '#,###0.000 [$€-1]';
    //diff
    worksheet.getColumn(priceIndex + (h * 2 + 1)).width = 10;
    worksheet.getColumn(priceIndex + (h * 2 + 1)).numFmt = '0%';
  }

  //unit
  worksheet.getColumn(getColIndexForUnit(treedepth)).width = 10;
  //postText
  worksheet.getColumn(getColIndexForPostText(treedepth)).width = 25;

  return worksheet;
}
function setUpColsAlphaBlFac(
  selectedProvider: AuthUserType[],
  blStructure: any,
  worksheet: any
) {
  let treedepth = calculateTreeDepth(blStructure) - 1;

  /*   columnwidths/styles */
  for (let i = 1; i < treedepth + 1; i++) {
    worksheet.getColumn(i).key = 'depth-' + i;
    worksheet.getColumn(i).width = 3;
  }
  //title
  worksheet.getColumn(treedepth + 1).width = 20;
  //price
  const priceIndex = getColIndexBlFacForInitDl(treedepth);
  for (let h = 0; h < selectedProvider.length; h++) {
    worksheet.getColumn(priceIndex + h * 2).width = 14;

    //diff
    worksheet.getColumn(priceIndex + (h * 2 + 1)).width = 10;
    worksheet.getColumn(priceIndex + (h * 2 + 1)).numFmt = '0%';
  }

  return worksheet;
}
function setUpColsAlphaLsFac(
  selectedProvider: AuthUserType[],
  lsStructure: any,
  worksheet: any
) {
  let treedepth = calculateTreeDepth(lsStructure) - 1;

  /*   columnwidths/styles */
  for (let i = 1; i < treedepth + 1; i++) {
    worksheet.getColumn(i).key = 'depth-' + i;
    worksheet.getColumn(i).width = 3;
  }
  //title
  worksheet.getColumn(treedepth + 1).width = 25;
  //price
  const priceIndex = getColIndexLsFacForInitDl(treedepth);
  for (let h = 0; h < selectedProvider.length; h++) {
    worksheet.getColumn(priceIndex + h * 2).width = 14;

    //diff
    worksheet.getColumn(priceIndex + (h * 2 + 1)).width = 10;
    worksheet.getColumn(priceIndex + (h * 2 + 1)).numFmt = '0%';
  }

  return worksheet;
}

function createSheetAlphaEpHeader(
  tree: any,
  selectedProvider: AuthUserType[],
  worksheet: any,
  phase: TimelineStep
) {
  let treedepth = calculateTreeDepth(tree) - 1;

  /*  metainfo */
  const metaInfoRow = worksheet.addRow();
  metaInfoRow.getCell(1).value = OUTPUT_FILE_HEADLINE;
  metaInfoRow.getCell(getColIndexForEp(treedepth) - 2).value =
    'Phase: ' + phase.title;
  metaInfoRow.getCell(getColIndexForPostText(treedepth)).value =
    'Datum: ' + new Date().toLocaleDateString('de-DE');

  //empty row
  const emptyRow = worksheet.addRow();
  emptyRow.getCell(1).value = '';

  //header for table
  const headerRow = worksheet.addRow();

  //ep title
  headerRow.getCell(1).value = excelAlphaEpNameHeader;
  //tag
  headerRow.getCell(getColIndexForTag(treedepth)).value = excelAlphaTagHeader;
  //epcode
  headerRow.getCell(getColIndexForEp(treedepth)).value = excelAlphaEpCodeHeader;
  //unit
  headerRow.getCell(getColIndexForUnit(treedepth)).value = excelAlphaUnitHeader;
  //postText
  headerRow.getCell(getColIndexForPostText(treedepth)).value =
    excelAlphaPostTextHeader;
  //dls
  for (let index = 0; index < selectedProvider.length; index++) {
    headerRow.getCell(getColIndexForInitDl(treedepth) + index * 2).value =
      selectedProvider[index].organisation;
  }
  //style header
  const backgroundColorHeadline = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--primary-color');

  const colorHeadline = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--primary-color-text');

  styleHeader(metaInfoRow, emptyRow, headerRow, backgroundColorHeadline);

  return worksheet;
}
function createSheetAlphaBlFacHeader(
  blStructure: any,
  selectedProvider: AuthUserType[],
  worksheet: any,
  phase: TimelineStep
) {
  let treedepth = calculateTreeDepth(blStructure) - 1;

  /*  metainfo */
  const metaInfoRow = worksheet.addRow();
  metaInfoRow.getCell(1).value = OUTPUT_FILE_HEADLINE;
  metaInfoRow.getCell(getColIndexForEp(treedepth) - 2).value =
    'Phase: ' + phase.title;
  metaInfoRow.getCell(getColIndexBlFacForInitDl(treedepth)).value =
    'Datum: ' + new Date().toLocaleDateString('de-DE');

  //empty row
  const emptyRow = worksheet.addRow();
  emptyRow.getCell(1).value = '';

  //header for table
  const headerRow = worksheet.addRow();

  //bl title
  headerRow.getCell(1).value = excelAlphaBlFacHeader;

  //dls
  for (let index = 0; index < selectedProvider.length; index++) {
    headerRow.getCell(getColIndexBlFacForInitDl(treedepth) + index * 2).value =
      selectedProvider[index].organisation;
  }
  //style header
  const backgroundColorHeadline = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--primary-color');

  const colorHeadline = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--primary-color-text');

  styleHeader(metaInfoRow, emptyRow, headerRow, backgroundColorHeadline);

  return worksheet;
}

function createSheetAlphaLsFacHeader(
  lsStructure: any,
  selectedProvider: AuthUserType[],
  worksheet: any,
  phase: TimelineStep
) {
  let treedepth = calculateTreeDepth(lsStructure) - 1;

  /*  metainfo */
  const metaInfoRow = worksheet.addRow();
  metaInfoRow.getCell(1).value = OUTPUT_FILE_HEADLINE;
  metaInfoRow.getCell(getColIndexForEp(treedepth) - 2).value =
    'Phase: ' + phase.title;
  metaInfoRow.getCell(getColIndexForInitDl(treedepth)).value =
    'Datum: ' + new Date().toLocaleDateString('de-DE');

  //empty row
  const emptyRow = worksheet.addRow();
  emptyRow.getCell(1).value = '';

  //header for table
  const headerRow = worksheet.addRow();

  //ls title
  headerRow.getCell(1).value = excelAlphaLsFacHeader;
  //dls
  for (let index = 0; index < selectedProvider.length; index++) {
    headerRow.getCell(getColIndexLsFacForInitDl(treedepth) + index * 2).value =
      selectedProvider[index].organisation;
  }
  //style header
  const backgroundColorHeadline = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--primary-color');

  const colorHeadline = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--primary-color-text');
  styleHeader(metaInfoRow, emptyRow, headerRow, backgroundColorHeadline);

  return worksheet;
}

function createAlphaEpContent(
  data: any,
  selectedProvider: AuthUserType[],
  keyValueStore: Map<string, any>,
  phase: TimelineStep,
  treeDepth: number,
  parentTitles: { title: string; depth: number }[],
  colIndexInitDl: number,
  worksheet: any
) {
  if (data && data.length > 0) {
    let newParentTitles: { title: string; depth: number }[] = [];

    for (let i = 0; i < data.length; i++) {
      const valueId = generateEpEntryId(phase, data[i].data.epCode);
      const valueObject =
        keyValueStore && keyValueStore.get(valueId)
          ? keyValueStore.get(valueId)
          : null;

      if (data[i] && data[i].data && data[i].data.title) {
        const newRow = worksheet.addRow();
        //title

        const currentTitle = data[i].data.number
          ? '[' + data[i].data.number + '] ' + data[i].data.title
          : data[i].data.title;
        const newParentTitle = { title: currentTitle, depth: data[i].depth };
        newParentTitles = [...parentTitles, newParentTitle];
        if (newParentTitles.length > 0) {
          for (let q = 0; q < newParentTitles.length; q++) {
            newRow.getCell(newParentTitles[q].depth).value =
              newParentTitles[q].title;
          }
        }

        newRow.getCell(data[i].depth).value = currentTitle;

        //price per dl
        if (valueObject) {
          //price and diff per dl
          let pricesExcelCells: string = '';

          for (let k = 0; k < selectedProvider.length; k++) {
            const rowIndex = worksheet.rowCount;
            const colLetter = numberToExcelLetter(colIndexInitDl + k * 2);
            pricesExcelCells += colLetter + rowIndex + ',';
          }
          pricesExcelCells = pricesExcelCells.slice(0, -1);
          for (let x = 0; x < selectedProvider.length; x++) {
            const price = getDlPrice(selectedProvider[x].id, valueObject);
            newRow.getCell(colIndexInitDl + x * 2).value = price;
            const currRowIndex = worksheet.rowCount;
            const currColLetter = numberToExcelLetter(colIndexInitDl + x * 2);
            const currCell = currColLetter + currRowIndex;

            if (price !== 'nicht bepreist') {
              const formula = `IFERROR(IF(${currCell}=MIN(${pricesExcelCells}), 1, ${currCell}/MIN(${pricesExcelCells})),"")`;
              const currIndex = colIndexInitDl + (x * 2 + 1);

              newRow.getCell(currIndex).value = {
                formula: formula
              };
            }
          }

          //find tag and translate it into slice title
          const slice = _.find(LeistungsSlices, function (sl) {
            return _.intersection(sl.tag, data[i].data.tags).length > 0;
          });
          //tag
          newRow.getCell(getColIndexForTag(treeDepth)).value = slice
            ? slice.title
            : null;
          newRow.getCell(getColIndexForTag(treeDepth)).font = {
            color: { argb: '808080' }
          };
          //ep
          newRow.getCell(getColIndexForEp(treeDepth)).value = data[i].data
            .epCode
            ? data[i].data.epCode
            : null;
          newRow.getCell(getColIndexForEp(treeDepth)).font = {
            color: { argb: '808080' }
          };
          //unit
          newRow.getCell(getColIndexForUnit(treeDepth)).value = data[i].data
            .unit
            ? data[i].data.unit
            : null;
          //posttext
          newRow.getCell(getColIndexForPostText(treeDepth)).value = data[i].data
            .postText
            ? data[i].data.postText
            : null;
        }
        // style row depending on tree depth
        let calculatedColor = '';
        if (data[i].data && data[i].data.type !== 'position') {
          const opacity = (data[i].depth / treeDepth) * 100;
          if (data[i].depth === 1) {
            const primaryColor = getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary-light-color');
            calculatedColor = primaryColor.replace('#', '').replace(' ', '');
            newRow.font = { color: { argb: 'FFFFFF' }, bold: true };
          } else if (data[i].depth === 2) {
            const secondaryColor = getComputedStyle(
              document.documentElement
            ).getPropertyValue('--secondary-color');
            calculatedColor =
              'FF' + secondaryColor.replace('#', '').replace(' ', '');
          } else {
            const greyColor = getComputedStyle(
              document.documentElement
            ).getPropertyValue('--surface-700');
            calculatedColor = shadeColor(
              greyColor.replace('#', '').replace(' ', ''),
              opacity
            );
          }
        } else {
          calculatedColor = 'ffffff';

          newRow.border = {
            bottom: { style: 'thin', color: { argb: 'eeeeee' } }
          };
        }
        newRow.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: calculatedColor
          },
          bgColor: {
            argb: 'ffffff'
          }
        };

        //style fontcolor for filtercategories
        if (data[i].depth) {
          for (let k = 1; k < data[i].depth; k++) {
            const cell = newRow.getCell(k);
            cell.font = {
              color: { argb: calculatedColor }
            };
          }
        }
      }
      if (data[i] && data[i].children) {
        createAlphaEpContent(
          data[i].children,
          selectedProvider,
          keyValueStore,
          phase,
          treeDepth,
          newParentTitles,
          colIndexInitDl,
          worksheet
        );
      }
    }
  }
  return worksheet;
}
function createAlphaBlFacContent(
  data: any,
  selectedProvider: AuthUserType[],
  keyValueStore: Map<string, any>,
  phase: TimelineStep,
  treeDepth: number,
  parentTitles: { title: string; depth: number }[],
  colIndexInitDl: number,
  worksheet: any
) {
  if (data && data.length > 0) {
    let newParentTitles: { title: string; depth: number }[] = [];

    for (let i = 0; i < data.length; i++) {
      const valueId = simpleGenerateBlFactorId(
        phase.id,
        data[i].data.regionId,
        data[i].data.id
      );
      const valueObject =
        keyValueStore && keyValueStore.get(valueId)
          ? keyValueStore.get(valueId)
          : null;
      if (data[i] && data[i].data && data[i].data.title) {
        const newRow = worksheet.addRow();
        //title
        const currentTitle = data[i].data.title;

        const newParentTitle = { title: currentTitle, depth: data[i].depth };
        newParentTitles = [...parentTitles, newParentTitle];

        for (let q = 0; q < newParentTitles.length; q++) {
          newRow.getCell(q + 1).value = newParentTitles[q].title;
        }
        //FIXME data[].data?
        if (data[i].data.type === 'cat_1') {
          newRow.getCell(1).value = currentTitle;
        } else if (data[i].data.type === 'cat_2') {
          newRow.getCell(2).value = currentTitle;
        } else if (data[i].data.type === 'pos') {
          newRow.getCell(3).value = currentTitle;
        }

        //price per dl
        if (data[i].data.type === 'pos') {
          //price and diff per dl
          let pricesExcelCells: string = '';
          for (let k = 0; k < selectedProvider.length; k++) {
            const rowIndex = worksheet.rowCount;
            const colLetter = numberToExcelLetter(colIndexInitDl + k * 2);
            pricesExcelCells += colLetter + rowIndex + ',';
          }
          pricesExcelCells = pricesExcelCells.slice(0, -1);
          for (let x = 0; x < selectedProvider.length; x++) {
            const price = getDlFactor(selectedProvider[x].id, valueObject);
            newRow.getCell(colIndexInitDl + x * 2).value = price;
            const currRowIndex = worksheet.rowCount;
            const currColLetter = numberToExcelLetter(colIndexInitDl + x * 2);
            const currCell = currColLetter + currRowIndex;

            const formula = `IFERROR(IF(${currCell}=MIN(${pricesExcelCells}), 1, ${currCell}/MIN(${pricesExcelCells})),"")`;
            const currIndex = colIndexInitDl + (x * 2 + 1);

            newRow.getCell(currIndex).value = {
              formula: formula
            };
          }
        } else if (data[i].data.type === 'cat_2') {
          // calculate sums
          let pricesExcelCells: string = '';
          for (let k = 0; k < selectedProvider.length; k++) {
            const rowIndex = worksheet.rowCount;
            const colLetter = numberToExcelLetter(colIndexInitDl + k * 2);
            pricesExcelCells += colLetter + rowIndex + ',';
          }
          pricesExcelCells = pricesExcelCells.slice(0, -1);

          //get length of Leistungsslices
          let numberOfSlices = 0;
          LeistungsSlices.forEach((sl) => {
            numberOfSlices += 1;
          });

          for (let x = 0; x < selectedProvider.length; x++) {
            //mean price through formular
            const currColLetter = numberToExcelLetter(colIndexInitDl + x * 2);
            const currRowIndex = worksheet.rowCount;
            const sumStartRow = currRowIndex + 1;
            const sumEndRow = sumStartRow + numberOfSlices - 1;
            const sumFormula = `IFERROR(ROUND(AVERAGE(${
              currColLetter + sumStartRow
            }:${currColLetter + sumEndRow}),3),"")`;
            newRow.getCell(colIndexInitDl + x * 2).value = {
              formula: sumFormula
            };

            //mean diff
            const currCell = currColLetter + currRowIndex;
            const formula = `IFERROR(IF(${currCell}=MIN(${pricesExcelCells}), 1, ${currCell}/MIN(${pricesExcelCells})),"")`;
            const currIndex = colIndexInitDl + (x * 2 + 1);

            newRow.getCell(currIndex).value = {
              formula: formula
            };
          }
        }
        // style row depending on tree depth
        let calculatedColor = '';
        if (data[i].data && data[i].data.type !== 'pos') {
          if (data[i].data.type === 'cat_1') {
            const primaryColor = getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary-light-color');
            calculatedColor = primaryColor.replace('#', '').replace(' ', '');
            newRow.font = { color: { argb: 'FFFFFF' }, bold: true };
          } else if (data[i].data.type === 'cat_2') {
            const secondaryColor = getComputedStyle(
              document.documentElement
            ).getPropertyValue('--secondary-color');
            calculatedColor =
              'FF' + secondaryColor.replace('#', '').replace(' ', '');
          } else {
            const greyColor = getComputedStyle(
              document.documentElement
            ).getPropertyValue('--surface-700');
            calculatedColor = shadeColor(
              greyColor.replace('#', '').replace(' ', ''),
              66
            );
          }
        } else {
          calculatedColor = 'ffffff';

          newRow.border = {
            bottom: { style: 'thin', color: { argb: 'eeeeee' } }
          };
        }
        newRow.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: calculatedColor
          },
          bgColor: {
            argb: 'ffffff'
          }
        };

        //style fontcolor for filtercategories
        const loopDuration =
          data[i].data.type === 'cat_1'
            ? 1
            : data[i].data.type === 'cat_2'
            ? 2
            : data[i].data.type === 'pos'
            ? 3
            : 0;
        if (loopDuration) {
          for (let k = 1; k < loopDuration; k++) {
            const cell = newRow.getCell(k);
            cell.font = {
              color: { argb: calculatedColor }
            };
          }
        }
      }
      if (data[i] && data[i].children) {
        createAlphaBlFacContent(
          data[i].children,
          selectedProvider,
          keyValueStore,
          phase,
          treeDepth,
          newParentTitles,
          colIndexInitDl,
          worksheet
        );
      }
    }
  }
  return worksheet;
}
function createAlphaLsFacContent(
  data: any,
  selectedProvider: AuthUserType[],
  keyValueStore: Map<string, any>,
  phase: TimelineStep,
  treeDepth: number,
  parentTitles: { title: string; depth: number }[],
  colIndexInitDl: number,
  worksheet: any
) {
  if (data && data.length > 0) {
    let newParentTitles: { title: string; depth: number }[] = [];

    for (let i = 0; i < data.length; i++) {
      const valueId = createPrFactorKey(
        data[i].key,
        phase.id,
        data[i].data.propertyId,
        data[i].data.id
      );
      const valueObject =
        keyValueStore && keyValueStore.get(valueId)
          ? keyValueStore.get(valueId)
          : null;
      if (data[i] && data[i].data && data[i].data.title) {
        const newRow = worksheet.addRow();
        //title
        const currentTitle = data[i].data.title;

        const newParentTitle = { title: currentTitle, depth: data[i].depth };
        newParentTitles = [...parentTitles, newParentTitle];

        for (let q = 0; q < newParentTitles.length; q++) {
          newRow.getCell(q + 1).value = newParentTitles[q].title;
        }
        if (data[i].data.type === 'region') {
          newRow.getCell(1).value = currentTitle;
        } else if (data[i].data.type === 'property') {
          newRow.getCell(2).value = currentTitle;
        } else if (data[i].type === 'pos') {
          newRow.getCell(3).value = currentTitle;
        }

        //price per dl
        if (data[i].data.type === 'pos') {
          //price and diff per dl
          let pricesExcelCells: string = '';
          for (let k = 0; k < selectedProvider.length; k++) {
            const rowIndex = worksheet.rowCount;
            const colLetter = numberToExcelLetter(colIndexInitDl + k * 2);
            pricesExcelCells += colLetter + rowIndex + ',';
          }
          pricesExcelCells = pricesExcelCells.slice(0, -1);
          for (let x = 0; x < selectedProvider.length; x++) {
            const price = getDlPropFactor(
              selectedProvider[x].id,
              valueObject,
              keyValueStore,
              phase.id,
              data[i].data.propertyId
            );
            if (price !== 'Nicht in Marktgebiet') {
              newRow.getCell(colIndexInitDl + x * 2).value = price;
              const currRowIndex = worksheet.rowCount;
              const currColLetter = numberToExcelLetter(colIndexInitDl + x * 2);
              const currCell = currColLetter + currRowIndex;
              const formula = `IFERROR(IF(${currCell}=MIN(${pricesExcelCells}), 1, ${currCell}/MIN(${pricesExcelCells})),"")`;
              const currIndex = colIndexInitDl + (x * 2 + 1);
              newRow.getCell(currIndex).value = {
                formula: formula
              };
            } else {
              newRow.getCell(colIndexInitDl + x * 2).value = price;
            }
          }
        } else if (data[i].data.type === 'property') {
          // calculate sums
          let pricesExcelCells: string = '';
          for (let k = 0; k < selectedProvider.length; k++) {
            const rowIndex = worksheet.rowCount;
            const colLetter = numberToExcelLetter(colIndexInitDl + k * 2);
            pricesExcelCells += colLetter + rowIndex + ',';
          }
          pricesExcelCells = pricesExcelCells.slice(0, -1);

          //get length of Leistungsslices
          let numberOfSlices = 0;
          LeistungsSlices.forEach((sl) => {
            numberOfSlices += 1;
            if (sl.sustainability === true) {
              numberOfSlices += 1;
            }
          });

          for (let x = 0; x < selectedProvider.length; x++) {
            //mean price through formular
            const currColLetter = numberToExcelLetter(colIndexInitDl + x * 2);
            const currRowIndex = worksheet.rowCount;
            const sumStartRow = currRowIndex + 1;
            const sumEndRow = sumStartRow + numberOfSlices - 1;
            const sumFormula = `IFERROR(ROUND(AVERAGE(${
              currColLetter + sumStartRow
            }:${currColLetter + sumEndRow}),3),"Nicht in Marktgebiet")`;
            newRow.getCell(colIndexInitDl + x * 2).value = {
              formula: sumFormula
            };

            //mean diff
            const currCell = currColLetter + currRowIndex;
            const formula = `IFERROR(IF(${currCell}=MIN(${pricesExcelCells}), 1, ${currCell}/MIN(${pricesExcelCells})),"")`;
            const currIndex = colIndexInitDl + (x * 2 + 1);

            newRow.getCell(currIndex).value = {
              formula: formula
            };
          }
        }
        // style row depending on tree depth
        let calculatedColor = '';
        if (data[i].data && data[i].data.type !== 'pos') {
          if (data[i].data.type === 'region') {
            const primaryColor = getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary-light-color');
            calculatedColor = primaryColor.replace('#', '').replace(' ', '');
            newRow.font = { color: { argb: 'FFFFFF' }, bold: true };
          } else if (data[i].data.type === 'property') {
            const secondaryColor = getComputedStyle(
              document.documentElement
            ).getPropertyValue('--secondary-color');
            calculatedColor =
              'FF' + secondaryColor.replace('#', '').replace(' ', '');
          } else {
            const greyColor = getComputedStyle(
              document.documentElement
            ).getPropertyValue('--surface-700');
            calculatedColor = shadeColor(
              greyColor.replace('#', '').replace(' ', ''),
              66
            );
          }
        } else {
          calculatedColor = 'ffffff';

          newRow.border = {
            bottom: { style: 'thin', color: { argb: 'eeeeee' } }
          };
        }
        newRow.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: calculatedColor
          },
          bgColor: {
            argb: 'ffffff'
          }
        };

        //style fontcolor for filtercategories
        const loopDuration =
          data[i].data.type === 'region'
            ? 1
            : data[i].data.type === 'property'
            ? 2
            : data[i].data.type === 'pos'
            ? 3
            : 0;
        if (loopDuration) {
          for (let k = 1; k < loopDuration; k++) {
            const cell = newRow.getCell(k);
            cell.font = {
              color: { argb: calculatedColor }
            };
          }
        }
      }
      if (data[i] && data[i].children) {
        createAlphaLsFacContent(
          data[i].children,
          selectedProvider,
          keyValueStore,
          phase,
          treeDepth,
          newParentTitles,
          colIndexInitDl,
          worksheet
        );
      }
    }
  }
  return worksheet;
}

function applyConditionalFormatting(
  selectedProvider: AuthUserType[],
  colIndexForInitDl: number,
  worksheet: any
) {
  let colNamesToFormat = [];
  const priceIndex = colIndexForInitDl;
  const maxRow = worksheet.rowCount;
  for (let h = 0; h < selectedProvider.length; h++) {
    //diff
    const currColLetter = numberToExcelLetter(priceIndex + (h * 2 + 1));
    colNamesToFormat.push(`${currColLetter}4:${currColLetter + maxRow}`);
  }

  // conditional formatting
  if (colNamesToFormat) {
    //color cells depending on input

    // Define the conditional formatting rules
    const rule1 = {
      type: 'cellIs',
      formulae: ['1'],
      operator: 'equal',

      style: {
        font: {
          color: { argb: '2AB06F' } // Green text color (ARGB format)
        }
      }
    };

    const rule2 = {
      type: 'cellIs',
      formulae: ['1'],
      operator: 'notEqual',
      style: {
        font: {
          color: { argb: 'B71C1C' } // Red text color (ARGB format)
        }
      }
    };
    // Apply the conditional formatting rules to a range
    for (let j = 0; j < colNamesToFormat.length; j++) {
      worksheet.addConditionalFormatting({
        ref: colNamesToFormat[j],
        rules: [rule1, rule2]
      });
    }
  }

  return worksheet;
}

/////////////////
///// Helper ////
/////////////////

function getDlPrice(providerId: string, valueObject: any) {
  //fixme return price
  if (valueObject) {
    let price: number = 0;
    let notAllPrices: boolean = false;

    let priceString =
      valueObject[providerId] !== null ? valueObject[providerId] : null;

    let priceNumber = priceString ? parseInt(priceString) : null;

    if (!_.isNumber(priceNumber)) {
      notAllPrices = true;
    } else {
      price = priceNumber;
    }

    if (notAllPrices) {
      return 'nicht bepreist';
    } else {
      return _.divide(price, DEFAULT_CALCULATION_MULTIPLIER);
    }
  }
  return '';
}

function getDlPropFactor(
  providerId: string,
  valueObject: any,
  keyValueStore: Map<string, any>,
  phaseId: string,
  propId: string
) {
  //fixme test if in marketarea
  const val = keyValueStore.get(
    simpleGenerateLsInMarktgebietId(phaseId, propId)
  );
  if (val && val[providerId] === 'false') {
    return 'Nicht in Marktgebiet';
  } else {
    return getDlFactor(providerId, valueObject);
  }
}
function getDlFactor(providerId: string, valueObject: any) {
  //fixme return price
  if (valueObject) {
    let fac: number = 0;
    let notAllFacs: boolean = false;

    let priceString =
      valueObject[providerId] || valueObject[providerId] === 0
        ? valueObject[providerId]
        : DEFAULT_FACTOR;

    let priceNumber = priceString ? parseInt(priceString) : null;

    if (!priceNumber || !_.isNumber(priceNumber)) {
      notAllFacs = true;
    } else {
      fac = priceNumber;
    }

    if (notAllFacs) {
      return _.divide(DEFAULT_FACTOR, DEFAULT_CALCULATION_MULTIPLIER);
    } else {
      return _.divide(fac, DEFAULT_CALCULATION_MULTIPLIER);
    }
  }

  return _.divide(DEFAULT_FACTOR, DEFAULT_CALCULATION_MULTIPLIER);
}

function getColIndexForInitDl(treedepth: number) {
  return treedepth + 13;
}
function getColIndexForTag(treedepth: number) {
  return treedepth + 5;
}
function getColIndexForEp(treedepth: number) {
  return treedepth + 6;
}
function getColIndexForUnit(treedepth: number) {
  return treedepth + 10;
}
function getColIndexForPostText(treedepth: number) {
  return treedepth + 11;
}

function getColIndexBlFacForInitDl(treedepth: number) {
  return treedepth + 2;
}
function getColIndexLsFacForInitDl(treedepth: number) {
  return treedepth + 2;
}

function createPrFactorKey(
  key: string,
  phaseId: string,
  propertyId: string,
  sliceId: string
) {
  console.log(key);
  if (_.endsWith(key, '_sus')) {
    return simpleGenerateLsFactorId(phaseId, propertyId, true, sliceId);
  } else {
    return simpleGenerateLsFactorId(phaseId, propertyId, false, sliceId);
  }
}

function styleHeader(
  metaInfoRow: any,
  emptyRow: any,
  headerRow: any,
  backgroundColorHeadline: string
) {
  metaInfoRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'FFFFFF'
    }
  };
  metaInfoRow.font = {
    bold: true,
    color: { argb: '000000' }
  };

  emptyRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'FFFFFF'
    },
    bgColor: {
      argb: 'FFFFFF'
    }
  };

  headerRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: backgroundColorHeadline.replace('#', '').replace(' ', '')
    }
  };
  headerRow.font = {
    bold: true,
    color: { argb: 'FFFFFF' }
  };
  headerRow.height = 50;
  headerRow.border = {
    bottom: { style: 'thick', color: { argb: 'FFFFFFFF' } }
  };
}
