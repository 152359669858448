import { useEffect, useRef, useState } from 'react';
import { LiegenschaftsData } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import { AuthUserType } from '../../../Helper/ApiHelper/LoginNetworkHelper';
import BeeListbox from '../../Atoms/BeeListbox';
import BeeOutlinedButton from '../../Atoms/BeeOutlinedButton';
import AlphaFilterKonfigurationTable from './AlphaFilterKonfigurationTable';
import './BeeMultiListbox.scss';
import {
  DURATION_NOTIFICATION_ERROR_LONG,
  LEISTUNGSART,
  LeistungsSlices
} from '../../../Helper/Statics/Constants';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import AlphaSaveFilterDialog from '../Dialogs/AlphaSaveFilterDialog';
import {
  generateAlphaFilterId,
  generateUUID
} from '../../../Helper/Util/IdGeneratorHelper';
import {
  KeyValEntry,
  readAllStoredKeyValuesForOrgaAndPhase,
  updateKeyValueForOrgaAndPhaseForAlpha
} from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import { extractUserId } from '../../../Helper/Util/JwtHelper';
import BeeTreeLiegenschaften from '../../Atoms/BeeTreeLiegenschaften';

type BeeMultiListboxProps = {
  phaseId: string;
  properties: LiegenschaftsData[];
  serviceProvider: AuthUserType[];
  onAdaptSelection: (e: BeeClusterFilter) => void;
};

export type BeeClusterFilter = {
  id: string;
  title: string;
  propertyIds: string[];
  serviceProviderIds: string[];
  sliceTags: string[];
};

export default function BeeMultiListbox({
  phaseId,
  properties,
  serviceProvider,
  onAdaptSelection
}: BeeMultiListboxProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [allServiceProvider, setServiceProvider] = useState<AuthUserType[]>();
  const [allSlices, setSlices] = useState<any>();
  const [storedFilter, setStoredFilter] = useState<BeeClusterFilter[]>([]);
  const [storedFilterId, setStoredFilterId] = useState<string>();
  //selected
  const [selectedProperties, setSelectedProperties] = useState<any>();
  const [selectedServiceProvider, setSelectedServiceProvider] = useState<any>();
  const [selectedSlices, setSelectedSlices] = useState<any>();
  const [lastReportedFilterId, setLastReportedFilterId] = useState<string>();
  //dialogs
  const [saveVisible, setSaveVisible] = useState<boolean>();
  //toast
  const toast = useRef<Toast>(null);

  useEffect(() => {
    setServiceProvider(serviceProvider);
    setSlices(LeistungsSlices);
    initStoredFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties, serviceProvider]);

  useEffect(() => {
    if (searchParams.get('cId')) {
      if (
        searchParams.get('cId') !== 'new' &&
        lastReportedFilterId !== searchParams.get('cId')
      ) {
        const filter = _.find(storedFilter, function (f) {
          return f.id === searchParams.get('cId');
        });
        if (filter) {
          setLastReportedFilterId(filter.id);
          //recreate service provider
          let filteredServiceProvider: any = [];
          if (serviceProvider) {
            filteredServiceProvider = serviceProvider.filter(
              (o: AuthUserType) => filter.serviceProviderIds.includes(o.id)
            );
          }
          //recreate properties
          let filteredProperties: any = [];
          if (properties) {
            filteredProperties = properties.filter((o: LiegenschaftsData) =>
              filter.propertyIds.includes(o.id!)
            );
          }
          //recreate slice tags
          let filteredSlices: any = [];
          if (LeistungsSlices) {
            filteredSlices = LeistungsSlices.filter(
              (l: LEISTUNGSART) =>
                _.intersection(l.tag, filter.sliceTags).length > 0
            );
          }
          setSelectedServiceProvider(filteredServiceProvider);
          setSelectedProperties(filteredProperties);
          setSelectedSlices(filteredSlices);
          onAdaptSelection(filter);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchParams,
    storedFilter,
    serviceProvider,
    LeistungsSlices,
    properties
  ]);

  function initStoredFilter(data?: any) {
    if (data) {
      setStoredFilter(JSON.parse(data.value));
      setStoredFilterId(data.id);
    } else {
      readAllStoredKeyValuesForOrgaAndPhase(extractUserId()!, phaseId)
        .then((data: any) => {
          const filterKey = generateAlphaFilterId(phaseId);
          const vals: KeyValEntry[] = data;
          const stored = _.find(vals, function (e) {
            return e.key === filterKey;
          });
          if (stored) {
            setStoredFilter(JSON.parse(stored.value));
            setStoredFilterId(stored.id);
          }
        })
        .catch(() => {
          if (toast && toast.current) {
            toast.current.show({
              severity: 'error',
              summary: 'Filter konnten nicht geladen werden',
              detail:
                'Die gespeicherten Filter konnten nicht geladen werden. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.',
              sticky: true,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        });
    }
  }

  ////////////
  // HELPER //
  ////////////

  function generateCurrFilter(slices: any, provider: any, properties: any) {
    const sliceTags: string[] = [];
    const serviceProviderIds: string[] = [];
    const propertyIds: string[] = [];
    if (slices) {
      slices.forEach((s: LEISTUNGSART) => {
        sliceTags.push(...s.tag);
      });
    }
    if (provider) {
      provider.forEach((p: AuthUserType) => {
        serviceProviderIds.push(p.id);
      });
    }
    if (properties) {
      properties.forEach((p: LiegenschaftsData) => {
        propertyIds.push(p.id!);
      });
    }
    const filterConfig: BeeClusterFilter = {
      id: generateUUID(),
      title: 'new',
      propertyIds: propertyIds,
      serviceProviderIds: serviceProviderIds,
      sliceTags: sliceTags
    };
    return filterConfig;
  }

  function changeSelectedOptions(slices: any, provider: any, properties: any) {
    setSelectedSlices(slices);
    setSelectedServiceProvider(provider);
    setSelectedProperties(properties);
    searchParams.set('cId', 'new');
    setSearchParams(searchParams);
    setLastReportedFilterId('new');
    onAdaptSelection(generateCurrFilter(slices, provider, properties));
  }

  ////////////////
  // UI METHODS //
  ////////////////

  const injectSliceSelect = () => {
    return (
      <BeeListbox
        selectedValues={selectedSlices}
        grouped={false}
        options={allSlices}
        label={'Leistungsgruppen'}
        filterLabel={'title'}
        disabled={false}
        readOnly={false}
        optionRenderTemplate={(option: any) => {
          return <div className="ml-2">{option.title}</div>;
        }}
        onChangeSelectedOptions={(data) =>
          changeSelectedOptions(
            data,
            selectedServiceProvider,
            selectedProperties
          )
        }
      />
    );
  };

  const injectServiceProviderSelect = () => {
    return (
      <BeeListbox
        selectedValues={selectedServiceProvider}
        grouped={false}
        options={allServiceProvider}
        label={'Dienstleister'}
        filterLabel={'organisation'}
        disabled={false}
        readOnly={false}
        optionRenderTemplate={(option: any) => {
          return <div className="ml-2">{option.organisation}</div>;
        }}
        onChangeSelectedOptions={(data) =>
          changeSelectedOptions(selectedSlices, data, selectedProperties)
        }
      />
    );
  };

  const injectPropertySelect = () => {
    return (
      <BeeTreeLiegenschaften
        label={'Liegenschaften'}
        value={selectedProperties}
        options={properties}
        disabled={false}
        formstate={'neutral'}
        readOnly={false}
        onChange={(data) => {
          changeSelectedOptions(selectedSlices, selectedServiceProvider, data);
        }}
      />
    );
  };

  return (
    <div>
      <div className={'grid'}>
        <div className={'col-6 sm:col-6 md:col-6 lg:col-4'}>
          {injectPropertySelect()}
        </div>
        <div className={'col-6 sm:col-6 md:col-6 lg:col-4'}>
          {injectServiceProviderSelect()}
        </div>
        <div className={'col-6 sm:col-6 md:col-6 lg:col-4'}>
          {injectSliceSelect()}
        </div>
      </div>
      <div className={'flex justify-content-end'}>
        <BeeOutlinedButton
          label={'Filterkonfiguration speichern'}
          disabled={false}
          type={'secondary'}
          onClick={() => setSaveVisible(true)}
        />
      </div>
      <div>
        <AlphaFilterKonfigurationTable
          data={storedFilter}
          readOnly={false}
          disabled={false}
          onDelete={(id) => {
            const newFilter = _.filter(_.cloneDeep(storedFilter), function (f) {
              return f.id !== id;
            });
            updateKeyValueForOrgaAndPhaseForAlpha(
              extractUserId()!,
              phaseId,
              storedFilterId!,
              generateAlphaFilterId(phaseId),
              JSON.stringify(newFilter)
            )
              .then((data: any) => initStoredFilter(data))
              .catch(() => {
                if (toast && toast.current) {
                  toast.current.show({
                    severity: 'error',
                    summary: 'Filter konnten nicht gespeichert werden',
                    detail:
                      'Der gewünschte Filter konnte leider nicht gespeichert werden. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.',
                    sticky: true,
                    closable: true,
                    life: DURATION_NOTIFICATION_ERROR_LONG
                  });
                }
              });
          }}
          onLoadConfig={(id) => {
            searchParams.set('cId', id);
            setSearchParams(searchParams);
          }}
        />
      </div>
      {saveVisible ? (
        <AlphaSaveFilterDialog
          visible={saveVisible}
          phaseId={phaseId}
          currentFilter={generateCurrFilter(
            selectedSlices,
            selectedServiceProvider,
            selectedProperties
          )}
          knownFilter={storedFilter}
          storageId={storedFilterId}
          onAccept={(data) => {
            initStoredFilter(data);
            setSaveVisible(false);
          }}
          onHide={() => setSaveVisible(false)}
        />
      ) : null}
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
