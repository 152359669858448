import { InputTextarea } from 'primereact/inputtextarea';
import React from 'react';
import { useId } from 'react-id-generator';
import BeeTextInput from './BeeTextInput';

import { EMPTY_FIELD_PLACEHOLDER } from '../../Helper/Statics/Constants';
import './BeeTextArea.scss';

type BeeTextAreaProps = {
  id?: string;
  label?: string;
  value?: string;
  rows?: number;
  autoResize?: boolean;
  disabled?: boolean;
  formstate?: string;
  readOnly?: boolean;
  required?: boolean;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocusOut?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

export function BeeTextArea({
  id,
  label,
  value,
  rows,
  disabled,
  autoResize,
  formstate,
  readOnly,
  required,
  onChange,
  onFocusOut
}: BeeTextAreaProps) {
  const currentId = useId(1, 'bee-txt-area-')[0];

  //classNames
  const formStateClassNames =
    formstate === 'valid'
      ? 'bee-ta-input-valid'
      : formstate === 'error'
      ? 'bee-ta-input-error'
      : formstate === 'neutral'
      ? 'bee-ta-input-neutral'
      : 'bee-ta-input-neutral';

  let readOnlyClassNames = '';
  if (readOnly) {
    readOnlyClassNames = ' bee-ta-input-readOnly';
    disabled = true;
  }

  const classNames = formStateClassNames + readOnlyClassNames;
  const containerClassNames = 'p-float-label bee-ta-container';

  //label
  const labelClassNames = 'bee-ta-label';

  function change(e: React.ChangeEvent<HTMLTextAreaElement>) {
    if (!disabled && onChange) {
      onChange(e);
    }
  }
  function focusOut(e: React.ChangeEvent<HTMLTextAreaElement>) {
    if (!disabled && onFocusOut) {
      onFocusOut(e);
    }
  }

  const renderPlaceholder = () => {
    return (
      <BeeTextInput
        label={label}
        value={EMPTY_FIELD_PLACEHOLDER}
        disabled={disabled}
        readOnly={true}
        required={required}
      />
    );
  };

  return (
    <>
      {readOnly && !value ? (
        renderPlaceholder()
      ) : (
        <span id={currentId} className={containerClassNames}>
          <InputTextarea
            id={currentId + 'input'}
            value={value ? value : ''}
            rows={rows}
            disabled={disabled}
            autoResize={autoResize}
            className={classNames}
            onChange={(e) => change(e)}
            onBlur={(e) => focusOut(e)}
          />
          {label ? (
            <label htmlFor={currentId + 'input'} className={labelClassNames}>
              {label}
              {required && label ? (
                <span className="bee-ta-label-required">
                  <span className="bee-ta-label-required-star"> *</span>
                </span>
              ) : null}
            </label>
          ) : null}
        </span>
      )}
    </>
  );
}

export default BeeTextArea;
