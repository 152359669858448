import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import { KJUR } from 'jsrsasign';
import { mediaTokenSecret } from '../Statics/Constants';

type JwtType = {
  iss?: string;
  sub?: string;
  aud?: string[] | string;
  authority?: string[] | string;
  generatedBy?: string;
  exp?: number;
  nbf?: number;
  iat?: number;
  jti?: string;
};

function extractPermissionsOfUser(token: string): string[] | string | null {
  if (token) {
    const decoded: JwtType = jwtDecode(token);
    if (decoded.authority) {
      return decoded.authority;
    }
  }
  return null;
}

export function extractUserId(): string | null {
  const token = sessionStorage.getItem('aT');
  if (token) {
    const decoded: JwtType = jwtDecode(token);
    if (decoded.iss) {
      return decoded.iss;
    }
  }
  return null;
}

export function isUserFromAlpha(): boolean {
  const token = sessionStorage.getItem('aT');
  if (token) {
    let permissions = extractPermissionsOfUser(token);
    return (
      _.includes(permissions, 'PERMISSION_ALPHAREAD') ||
      _.includes(permissions, 'PERMISSION_ALPHAWRITE')
    );
  }
  return false;
}

export function isUserDL(): boolean {
  const token = sessionStorage.getItem('aT');
  if (token) {
    let permissions = extractPermissionsOfUser(token);
    return (
      _.includes(permissions, 'PERMISSION_DLREAD') ||
      _.includes(permissions, 'PERMISSION_DLWRITEKEYVAL') ||
      _.includes(permissions, 'PERMISSION_DLWRITEMEDIA')
    );
  }
  return false;
}

export function hasUserWriteRights(): boolean {
  const token = sessionStorage.getItem('aT');
  if (token) {
    let permissions = extractPermissionsOfUser(token);
    return (
      _.includes(permissions, 'PERMISSION_DLWRITEKEYVAL') &&
      _.includes(permissions, 'PERMISSION_DLWRITEMEDIA')
    );
  }
  return false;
}

/////////////////
// MEDIA TOKEN //
/////////////////

export function generatePublicMediaAccessJWT() {
  const date = new Date();
  date.setHours(date.getHours() + 12); // expires in 12 hour
  const expTimestamp = Math.floor(date.getTime() / 1000);
  const payload = {
    sub: 'HoneyComb',
    aud: 'BeeServices',
    iss: extractUserId(),
    authority: ['PERMISSION_PUBLIC_IMG'],
    exp: expTimestamp
  };
  const alg = 'HS256'; // HMAC-SHA256
  const header = { alg: alg, typ: 'JWT' };
  const jwsToken = KJUR.jws.JWS.sign(alg, header, payload, mediaTokenSecret);
  return jwsToken;
}

export function testMediaTokenExpired(token: string) {
  const decoded: JwtType = jwtDecode(token);
  if (decoded.exp) {
    return Date.now() >= decoded.exp * 1000;
  }
  return true;
}
