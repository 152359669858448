import { TabPanel, TabView } from 'primereact/tabview';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import { AlphaSetupTabLvVerwaltung } from '../../Organisms/Alpha/AlphaSetupTabLvVerwaltung';
import AlphaSetupTabPhaseToLiegenschaft from '../../Organisms/Alpha/AlphaSetupTabPhaseToLiegenschaft';
import { AlphaSetupTabPhaseToMedia } from '../../Organisms/Alpha/AlphaSetupTabPhaseToMedia';
import AlphaSetupTabPhaseToUser from '../../Organisms/Alpha/AlphaSetupTabPhaseToUser';
import './AlphaOptionsPage.scss';

export default function AlphaOptionsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const property = searchParams.get('property');
    const user = searchParams.get('user');
    const media = searchParams.get('media');
    if (media) {
      if (activeIndex !== 3) {
        setActiveIndex(3);
      }
    } else if (user) {
      if (activeIndex !== 2) {
        setActiveIndex(2);
      }
    } else if (property) {
      if (activeIndex !== 1) {
        setActiveIndex(1);
      }
    } else {
      if (activeIndex !== 0) {
        setActiveIndex(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  ///////////////////////
  /// VIEW COMPONENTS ///
  ///////////////////////

  function injectTabView() {
    return (
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => {
          if (e.index === 0) {
            //reset lId if selected tab is 0
            searchParams.delete('property');
            searchParams.delete('user');
            searchParams.delete('media');
            setSearchParams(searchParams);
          } else if (e.index === 1) {
            searchParams.set('property', 'true');
            searchParams.delete('user');
            searchParams.delete('media');
            setSearchParams(searchParams);
          } else if (e.index === 2) {
            searchParams.delete('property');
            searchParams.set('user', 'true');
            searchParams.delete('media');
            setSearchParams(searchParams);
          } else if (e.index === 3) {
            searchParams.delete('property');
            searchParams.delete('user');
            searchParams.set('media', 'true');
            setSearchParams(searchParams);
          }
        }}
      >
        <TabPanel header="Leistungsverzeichnisse" leftIcon="icon-reminders">
          <AlphaSetupTabLvVerwaltung />
        </TabPanel>
        <TabPanel header="Liegenschaften" leftIcon="icon-company">
          <AlphaSetupTabPhaseToLiegenschaft />
        </TabPanel>
        <TabPanel header="Nutzer" leftIcon="icon-user">
          <AlphaSetupTabPhaseToUser />
        </TabPanel>
        <TabPanel
          header="Ausschreibungsdokumente"
          leftIcon="icon-stack-of-paper"
        >
          <AlphaSetupTabPhaseToMedia />
        </TabPanel>
      </TabView>
    );
  }
  
  function injectHeadline() {
    return (
      <div className={'overview-header'}>
        <BeeContentHeadline
          label={'Phasen-Setup'}
          headline={'h1'}
          type={'primary'}
        />
      </div>
    );
  }

  return (
    <div className={'alpha-options-page m-4 pt-2 justify-content-start'}>
      {injectHeadline()}
      <div className="mb-3">
        Pro Phase können unterschiedliche EP-Kataloge, Leistungsverzeichnisse,
        Liegenschaften, Nutzer und Medien als Datengrundlage dienen. Bitte
        ordnen Sie in folgenden Übersichten die phasenzugehörigen Daten zu.
      </div>
      <div className="mt-6">{injectTabView()}</div>
    </div>
  );
}
