import _ from 'lodash';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { useEffect, useRef, useState } from 'react';
import {
  Phase,
  readAllPhases,
  updatePhase
} from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import { readAllLiegenschaften } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import {
  DURATION_NOTIFICATION_ERROR_LONG,
  SERVER_RESPONSE_ERROR,
  SERVER_RESPONSE_PENDING,
  SERVER_RESPONSE_SUCCESS
} from '../../../Helper/Statics/Constants';
import { isBefore } from '../../../Helper/Util/TimelineStepHelper';
import BeeIcon from '../../Atoms/BeeIcon';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import BeeValidatedCheckbox from '../../Atoms/BeeValidatedCheckbox';
import { LiegenschaftType } from '../../Molecules/Alpha/AlphaLiegenschaftEingabeCard';
import { FaktorKeyValueStore } from '../../Molecules/DL/DlBundeslandfaktoren';
import './AlphaSetupTabPhaseToLiegenschaft.scss';

export default function AlphaSetupTabPhaseToLiegenschaft() {
  const [forceReload, setForceReload] = useState<boolean>(true);
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);
  const [phasen, setPhasen] = useState<Phase[]>([]);
  const [liegenschaften, setLiegenschaften] = useState<LiegenschaftType[]>([]);
  const toast = useRef<Toast>(null);
  const [keyValueStore, setKeyValueStore] = useState<{
    [key: string]: FaktorKeyValueStore;
  }>({});

  useEffect(() => {
    setDataIsLoading(true);
    readAllPhases()
      .then((phases: any) => {
        readAllLiegenschaften()
          .then((ls: any) => {
            setupKeyValueStore(phases, ls);
          })
          .catch(() => {
            if (toast && toast.current) {
              toast.current.show({
                severity: 'error',
                summary: 'Liegenschaften konnten nicht geladen werden',
                detail:
                  'Leider konnten die Liegenschaften nicht geladen werden. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.',
                sticky: true,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            }
            setDataIsLoading(false);
          });
      })
      .catch((error) => {
        if (toast && toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Phasen konnten nicht geladen werden',
            detail:
              'Leider konnten die Phasen nicht geladen werden. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.',
            sticky: true,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
        setDataIsLoading(false);
      });
  }, [forceReload]);

  function setupKeyValueStore(phases: Phase[], ls: LiegenschaftType[]) {
    const values: any = {};
    let liegenschaften: LiegenschaftType[] = [...ls];
    //interner key = phaseId_lsId!!s
    let allLsIds: String[] = [];
    ls.forEach((e) => {
      allLsIds.push(e.id);
    });
    for (let i = 0; i < phases.length; i++) {
      const p: any = phases[i];
      if (p.liegenschaftIds) {
        const cleanedLs = _.intersection(p.liegenschaftIds, allLsIds);
        if (cleanedLs.length !== p.liegenschaftIds.length) {
          p.liegenschaftIds = cleanedLs;
          //show needs update FIXME
          updatePhase(p.id, p)
            .then(() => {
              setForceReload(!forceReload);
            })
            .catch(() => {
              if (toast && toast.current) {
                toast.current.show({
                  severity: 'error',
                  summary: 'Phasen konnte nicht geupdated werden',
                  detail:
                    'Leider enthält mindestens eine Phase eine gelöschte Liegenschaft. Das Automatische Löschen ist fehlgeschlagen. Bitte laden Sie die Seite erneut. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.',
                  sticky: true,
                  closable: true,
                  life: DURATION_NOTIFICATION_ERROR_LONG
                });
              }
            });
        }
      }
    }
    phases.forEach((p) => {
      let allLsIdClone: String[] = _.cloneDeep(allLsIds);
      let intersection: String[] = _.intersection(
        allLsIdClone,
        p.liegenschaftIds
      );
      let nonChecked: String[] = allLsIdClone.filter(
        (item: String) => !intersection.includes(item)
      );
      //generate lookup
      intersection.forEach((checked) => {
        values[p.id + '_' + checked] = {};
        values[p.id + '_' + checked].value = true;
        values[p.id + '_' + checked].process = SERVER_RESPONSE_SUCCESS;
      });
      nonChecked.forEach((nC) => {
        values[p.id + '_' + nC] = {};
        values[p.id + '_' + nC].value = false;
        values[p.id + '_' + nC].process = SERVER_RESPONSE_SUCCESS;
      });
    });
    //set values
    setKeyValueStore(values);
    setPhasen(_.sortBy(phases, ['start', 'end', 'title']));
    setLiegenschaften(liegenschaften);
    setDataIsLoading(false);
  }

  function generatePhases() {
    const pay = phasen;
    let data = [];
    data.push({ type: 'nonPhase', header: 'Liegenschaft' });
    data.push({ type: 'nonPhase', header: 'Adresse' });
    pay.forEach((entry) => data.push(entry));
    return data;
  }

  /* property to phase */
  function updatePropertyToPhase(
    phase: Phase,
    propId: string,
    checked: boolean,
    previousState: boolean
  ) {
    const valueKey: string = phase.id + '_' + propId;
    if (
      keyValueStore[valueKey] &&
      (keyValueStore[valueKey].value !== checked ||
        keyValueStore[valueKey].process === SERVER_RESPONSE_ERROR)
    ) {
      setKeyValueStore((previousInputs: { [key: string]: any }) => ({
        ...previousInputs,
        [valueKey]: {
          value: checked,
          process: SERVER_RESPONSE_PENDING
        }
      }));
      //update phase
      let allConnectedPropIds: string[] = [];
      if (checked) {
        allConnectedPropIds.push(propId);
      }
      liegenschaften.forEach((pr) => {
        let curr = keyValueStore[phase.id + '_' + pr.id];
        if (curr && curr.value === true && pr.id !== propId) {
          allConnectedPropIds.push(pr.id);
        }
      });
      let updatedPhase: Phase = _.cloneDeep(phase);
      updatedPhase.liegenschaftIds = allConnectedPropIds;
      // keyValueStore
      updatePhase(updatedPhase.id, updatedPhase)
        .then((responseValue) => {
          updateKeyValStore(valueKey, checked, SERVER_RESPONSE_SUCCESS);
        })
        .catch(() => {
          updateKeyValStore(valueKey, previousState, SERVER_RESPONSE_ERROR);
        });
    }
  }

  function updateKeyValStore(
    valueKey: string,
    val: boolean | null,
    progress: string
  ) {
    setKeyValueStore((previousInputs: any) => ({
      ...previousInputs,
      [valueKey]: { value: val, process: progress }
    }));
  }

  const bodyTemplate = (
    data: LiegenschaftType,
    options: ColumnBodyOptions,
    phase: Phase
  ) => {
    if (options) {
      if (options.field === 'field_0') {
        return (
          <div className={'lv-table-liegenschaft'}>
            <div className={'lv-number'}>{data.nummer}</div>
            <div>{data.name}</div>
          </div>
        );
      } else if (options.field === 'field_1') {
        return (
          <div>
            <div>
              <span>{data.adresse ? data.adresse.strasse : null}</span>{' '}
              <span>{data.adresse ? data.adresse.hausnummer : null}</span>
              <div></div>
              <span>
                {data.adresse ? data.adresse.postleitzahl : null}
              </span>{' '}
              <span>{data.adresse ? data.adresse.stadt : null}</span>
            </div>
          </div>
        );
      } else {
        //phase ist abgelaufen
        if (isBefore(new Date(), new Date(phase.end!))) {
          return (
            <BeeValidatedCheckbox
              label={''}
              value={keyValueStore[phase.id + '_' + data.id].value}
              disabled={true}
              formstate={'neutral'}
              stateFalse={''}
              stateTrue={''}
              required={false}
              readOnly={true}
              onChange={() => {}}
            />
          );
        } else {
          const status = keyValueStore[phase.id + '_' + data.id];

          return (
            <div className={'liegenschaft-to-phase-checkbox'}>
              <BeeValidatedCheckbox
                label={''}
                value={status.value}
                disabled={false}
                formstate={'neutral'}
                stateFalse={''}
                stateTrue={''}
                required={false}
                readOnly={status.process === SERVER_RESPONSE_PENDING}
                onChange={(e) => {
                  console.log(e);
                  updatePropertyToPhase(
                    phase,
                    data.id,
                    e.checked,
                    status.value
                  );
                }}
              />

              {status.process === SERVER_RESPONSE_PENDING ? (
                <span className={'status-liegenschaftChange pending'}>
                  <BeeLoadingSpinner strokeWidth={'3'} type={'primary'} />
                </span>
              ) : null}
              {status.process === SERVER_RESPONSE_ERROR ? (
                <>
                  <span
                    className={
                      'status-liegenschaftChange error  tooltip-factor-bl'
                    }
                  >
                    <BeeIcon
                      iconClass={'pi pi-exclamation-triangle'}
                      type={'primary'}
                    />
                    <span>{'Fehler'}</span>
                  </span>
                  <Tooltip
                    target=".tooltip-factor-bl"
                    mouseTrack
                    mouseTrackLeft={10}
                  >
                    {
                      'Die Änderung konnte nicht gespeichert werden. Bitte prüfen Sie Ihre Internetverbindung.'
                    }
                  </Tooltip>
                </>
              ) : null}
            </div>
          );
        }
      }
    }
  };

  const headerTemplate = (data: any, number: any) => {
    if (data.type === 'nonPhase') {
      return data.header;
    } else {
      const start = data && data.start ? new Date(data.start) : null;
      const end = data && data.end ? new Date(data.end) : null;
      return (
        <>
          <div>{data.title}</div>
          <div className={'ep-lv-phase-date flex'}>
            <span>
              {start ? start.toLocaleDateString('de-DE') + ' - ' : null}
            </span>
            <span>{end ? ' ' + end.toLocaleDateString('de-DE') : null}</span>
          </div>
        </>
      );
    }
  };

  const dynamicColumns = generatePhases().map((col: any, i) => {
    const phaseId = col && col.id ? col.id : null;
    return (
      <Column
        body={(data: any, options: ColumnBodyOptions) =>
          bodyTemplate(data, options, col)
        }
        className={
          col.type === 'nonPhase'
            ? col.header.replace(' ', '_') + '-col prop-nonPhase'
            : col.title.replace(' ', '_') + '-col prop-phase'
        }
        style={{ flex: '0 0 4rem' }}
        frozen={phaseId ? false : true}
        header={headerTemplate(col, i)}
      />
    );
  });

  function injectDescription() {
    return (
      <div className={'mb-3'}>
        Bitte geben Sie in folgender Tabelle an, welche Liegenschaften in
        welcher Phase ausgeschrieben werden. Ist eine Phase bereits abgelaufen
        kann eine Liegenschaft nicht mehr aus einer Phase entfernt oder
        hinzugefügt werden.
      </div>
    );
  }

  return (
    <div className={'setup-phase-to-liegenschaften m-2 mt-4 ml-1 p-2'}>
      {injectDescription()}

      {!dataIsLoading ? (
        <DataTable
          value={liegenschaften}
          size="small"
          className={'alpha-phase-to-liegenschaft-table'}
          emptyMessage={'Keine Liegenschaften vorhanden'}
        >
          {dynamicColumns}
        </DataTable>
      ) : (
        <BeeLoadingSpinner strokeWidth={'3'} type={'secondary'} />
      )}
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
