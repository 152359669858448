import _ from 'lodash';
import { Column } from 'primereact/column';
import { TreeTable } from 'primereact/treetable';
import { useEffect, useState } from 'react';
import { LiegenschaftsData } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import {
  COUNTRIES,
  DEFAULT_CALCULATION_MULTIPLIER,
  DEFAULT_FACTOR,
  LeistungsSlices,
  MAX_F
} from '../../../Helper/Statics/Constants';
import {
  simpleGenerateBlFactorId,
  simpleGenerateLsFactorId,
  simpleGenerateLsInMarktgebietId
} from '../../../Helper/Util/IdGeneratorHelper';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import { TimelineStep } from '../../Atoms/BeeTimeline';
import './AlphaAnalysisFactorTable.scss';
import { AuthUserType } from '../../../Helper/ApiHelper/LoginNetworkHelper';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import BeeNumberInput from '../../Atoms/BeeNumberInput';
import { NodeFlags } from 'typescript';

type AlphaAnalysisFactorTableProps = {
  phase: TimelineStep;
  blStructure: any;
  lsStructure: any;
  valueLookup: Map<string, any>;
  selectedProvider: AuthUserType[];
};

export default function AlphaAnalysisFactorTable({
  phase,
  blStructure,
  lsStructure,
  valueLookup,
  selectedProvider
}: AlphaAnalysisFactorTableProps) {
  const [columns, setColumns] = useState<any>([]);

  useEffect(() => {
    let cols: any = [];
    if (selectedProvider) {
      selectedProvider.forEach((p: AuthUserType) => {
        cols.push({ id: p.id, header: p.organisation });
      });
    }
    setColumns(cols);
  }, [selectedProvider]);

  ///////////////////////
  //////// LOGIC ////////
  ///////////////////////

  function createBlFactorKey(node: any, phaseId: string) {
    if (node.data && node.data.type === 'pos') {
      const regionId: String = node.data.regionId;
      const sliceId: String = node.data.id;
      return simpleGenerateBlFactorId(phaseId, regionId, sliceId);
    }
    return '';
  }

  function createPrFactorKey(node: any, phaseId: String) {
    if (node.data && node.data.type === 'pos') {
      const propertyId: String = node.data.propertyId;
      const sliceId: String = node.data.id;
      if (_.endsWith(node.key, '_sus')) {
        return simpleGenerateLsFactorId(phaseId, propertyId, true, sliceId);
      } else {
        return simpleGenerateLsFactorId(phaseId, propertyId, false, sliceId);
      }
    }
    return '';
  }

  function search(lookupKey: string) {
    return valueLookup.get(lookupKey);
  }

  ///////////////////////
  /// VIEW COMPONENTS ///
  ///////////////////////

  const blFactorValueTemplate = (node: any, col: any) => {
    const providerId: string = col.props.header.key;
    if (node.data) {
      if (node.data.type === 'pos' || node.data.type === 'cat_2') {
        if (node.data.type === 'pos') {
          const value = search(createBlFactorKey(node, phase.id));
          const valueAsNum =
            value && value[providerId] && parseInt(value[providerId])
              ? parseInt(value[providerId])
              : DEFAULT_FACTOR;
          return (
            <div className={'alpha-pricing-blFac-value'}>
              <BeeNumberInput
                value={
                  valueAsNum
                    ? _.divide(valueAsNum, DEFAULT_CALCULATION_MULTIPLIER)
                    : valueAsNum === 0
                    ? 0
                    : undefined
                }
                grouping={false}
                readOnly={true}
              />
            </div>
          );
        } else if (node.data.type === 'cat_2') {
          let intVal: number = 0;
          let valid = false;
          if (node.children) {
            node.children.forEach((child: any) => {
              valid = true;
              let val = search(createBlFactorKey(child, phase.id));
              intVal +=
                val && val[providerId] && parseInt(val[providerId])
                  ? parseInt(val[providerId])
                  : DEFAULT_FACTOR;
            });
          }
          return (
            <div className={'alpha-pricing-blFac-value'}>
              {valid ? (
                <BeeNumberInput
                  value={
                    intVal === 0
                      ? 0
                      : _.divide(
                          _.round(intVal / node.children.length),
                          DEFAULT_CALCULATION_MULTIPLIER
                        )
                  }
                  grouping={false}
                  readOnly={true}
                />
              ) : null}
            </div>
          );
        }
      }
    }
    return '';
  };

  const blFactorDiffTemplate = (node: any, col: any) => {
    const providerId: string = col.props.header.key;
    if (node.data) {
      if (node.data.type === 'pos' || node.data.type === 'cat_2') {
        if (node.data.type === 'pos') {
          const value = search(createBlFactorKey(node, phase.id));
          let lowestFactor: number = MAX_F + 1;
          selectedProvider.forEach((sp) => {
            const vString =
              value && value[sp.id] ? value[sp.id] : DEFAULT_FACTOR;
            const vNum = parseInt(vString);
            lowestFactor = lowestFactor < vNum ? lowestFactor : vNum;
          });
          const proValue =
            value && value[providerId] && parseInt(value[providerId])
              ? parseInt(value[providerId])
              : DEFAULT_FACTOR;
          const calcValue =
            lowestFactor === MAX_F + 1
              ? 100
              : _.round((proValue / lowestFactor) * 100);
          const className =
            calcValue === 100 ? 'valid-background' : 'error-background';
          return <div className={className}>{calcValue + ' %'}</div>;
        } else if (node.data.type === 'cat_2') {
          if (node.children) {
            const look: any = new Map<string, number>();
            node.children.forEach((child: any) => {
              let val = search(createBlFactorKey(child, phase.id));
              selectedProvider.forEach((sp) => {
                const vString = val && val[sp.id] ? val[sp.id] : DEFAULT_FACTOR;
                const vNum = parseInt(vString)
                  ? parseInt(vString)
                  : DEFAULT_FACTOR;
                let num = look.get(sp.id);
                num = num ? num : 0;
                look.set(sp.id, num + vNum);
              });
            });
            //search lowest and current value
            let lowestFactor: number = MAX_F * node.children.length + 1;
            selectedProvider.forEach((sp) => {
              const vNum = parseInt(look.get(sp.id));
              lowestFactor = lowestFactor < vNum ? lowestFactor : vNum;
            });
            const calcValue = _.round(
              (look.get(providerId) / lowestFactor) * 100
            );
            const className =
              calcValue === 100 ? 'valid-background' : 'error-background';
            return <div className={className}>{calcValue + ' %'}</div>;
          }
        }
      }
    }
    return '';
  };

  const propertyFactorValueTemplate = (node: any, col: any) => {
    const providerId: string = col.props.header.key;
    if (node.data) {
      if (node.data.type === 'pos' || node.data.type === 'property') {
        const propId =
          node.data.type === 'pos' ? node.data.propertyId : node.key;
        //test property phase participation
        const val = valueLookup.get(
          simpleGenerateLsInMarktgebietId(phase.id, propId)
        );
        if (val && val[providerId] === 'false') {
          return <div>Nicht in Marktgebiet</div>;
        }
        //difference for pos and property
        if (node.data.type === 'pos') {
          const value = search(createPrFactorKey(node, phase.id));
          const vAsNum =
            value && value[providerId] && parseInt(value[providerId])
              ? parseInt(value[providerId])
              : DEFAULT_FACTOR;
          return (
            <div className={'alpha-pricing-blProp-value '}>
              <BeeNumberInput
                value={
                  vAsNum
                    ? _.divide(vAsNum, DEFAULT_CALCULATION_MULTIPLIER)
                    : vAsNum === 0
                    ? 0
                    : undefined
                }
                grouping={false}
                readOnly={true}
              />
            </div>
          );
        } else if (node.data.type === 'property') {
          let intVal: number = 0;
          let valid = false;
          if (node.children) {
            node.children.forEach((child: any) => {
              valid = true;
              let val = search(createPrFactorKey(child, phase.id));
              intVal +=
                val && val[providerId] && parseInt(val[providerId])
                  ? parseInt(val[providerId])
                  : DEFAULT_FACTOR;
            });
          }
          return (
            <div className={'alpha-pricing-blFac-value'}>
              {valid ? (
                <BeeNumberInput
                  value={
                    intVal === 0
                      ? 0
                      : _.divide(
                          _.round(intVal / node.children.length),
                          DEFAULT_CALCULATION_MULTIPLIER
                        )
                  }
                  grouping={false}
                  readOnly={true}
                />
              ) : null}
            </div>
          );
        }
      }
    }
    return null;
  };

  const propertyFactorDiffTemplate = (node: any, col: any) => {
    const providerId: string = col.props.header.key;
    if (node.data) {
      if (node.data.type === 'pos' || node.data.type === 'property') {
        const propId =
          node.data.type === 'pos' ? node.data.propertyId : node.key;
        //test property phase participation
        const val = valueLookup.get(
          simpleGenerateLsInMarktgebietId(phase.id, propId)
        );
        if (val && val[providerId] === 'false') {
          return <div> — </div>;
        }
        //difference for pos and property
        if (node.data.type === 'pos') {
          const value = search(createPrFactorKey(node, phase.id));
          let lowestFactor: number = MAX_F + 1;
          selectedProvider.forEach((sp) => {
            const vString =
              value && value[sp.id] ? value[sp.id] : DEFAULT_FACTOR;
            const vNum = parseInt(vString);
            if (vNum) {
              lowestFactor = lowestFactor < vNum ? lowestFactor : vNum;
            }
          });
          const proValue =
            value && value[providerId] && parseInt(value[providerId])
              ? parseInt(value[providerId])
              : DEFAULT_FACTOR;
          const calcValue =
            lowestFactor === MAX_F + 1
              ? 100
              : _.round((proValue / lowestFactor) * 100);
          const className =
            calcValue === 100 ? 'valid-background' : 'error-background';
          return <div className={className}>{calcValue + ' %'}</div>;
        } else if (node.data.type === 'property') {
          if (node.children) {
            console.log(node.children);
            const look: any = new Map<string, number>();
            node.children.forEach((child: any) => {
              let val = search(createPrFactorKey(child, phase.id));
              selectedProvider.forEach((sp) => {
                const vString = val && val[sp.id] ? val[sp.id] : DEFAULT_FACTOR;
                const vNum = parseInt(vString)
                  ? parseInt(vString)
                  : DEFAULT_FACTOR;
                let num = look.get(sp.id);
                num = num ? num : 0;
                console.log(sp.id);
                look.set(sp.id, num + vNum);
              });
            });
            //search lowest and current value
            let lowestFactor: number = MAX_F * node.children.length + 1;
            selectedProvider.forEach((sp) => {
              const vNum = parseInt(look.get(sp.id));
              lowestFactor = lowestFactor < vNum ? lowestFactor : vNum;
            });
            const calcValue = _.round(
              (look.get(providerId) / lowestFactor) * 100
            );
            console.log(providerId);
            console.log(look.get(providerId));
            console.log(lowestFactor);
            console.log(calcValue);
            const className =
              calcValue === 100 ? 'valid-background' : 'error-background';
            return <div className={className}>{calcValue + ' %'}</div>;
          }
        }
      }
    }
    return '';
  };

  const dynamicRegionColumns = columns.map((col: any, i: any) => {
    const factorUI = {
      className: 'bl-factor-col',
      key: col.id + '_' + col.header + '_factor',
      body: blFactorValueTemplate
    };
    const diffUI = {
      className: 'bl-diff-col',
      key: col.id + '_' + col.header + '_diff',
      body: blFactorDiffTemplate
    };
    const values = [factorUI, diffUI];
    return values.map((e) => {
      return (
        <Column
          key={e.key}
          columnKey={'alphaAnalysisFacTable_dynCol_' + e.key}
          className={e.className}
          body={e.body}
          header={<div key={col.id}>{col.header}</div>}
          style={{ width: '7em' }}
        />
      );
    });
  });

  const dynamicPropertyColumns = columns.map((col: any, i: any) => {
    const factorUI = {
      className: 'prop-factor-col',
      key: col.id + '_' + col.header + '_factor',
      body: propertyFactorValueTemplate
    };
    const diffUI = {
      className: 'prop-diff-col',
      key: col.id + '_' + col.header + '_diff',
      body: propertyFactorDiffTemplate
    };
    const values = [factorUI, diffUI];
    return values.map((e) => {
      return (
        <Column
          key={e.key}
          className={e.className}
          columnKey={'alphaAnalysisFacTable_dynCol_prop_' + e.key}
          body={e.body}
          header={<div key={col.id}>{col.header}</div>}
          style={{ width: '7em' }}
        />
      );
    });
  });

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          header="Positionen"
          rowSpan={2}
          colSpan={1}
          style={{ width: '15em' }}
          className={'alpha-analysis-factorEp-col frozen-col'}
        />
        {columns.map((col: any, index: number) => {
          return (
            <Column
              header={col.header}
              colSpan={2}
              style={{ width: '14em' }}
              columnKey={'alphaAnalysisFacTable_header_' + index}
            />
          );
        })}
      </Row>
      <Row>
        {columns.map(() => {
          const values = ['Faktor', ''];
          return values.map((e, index) => {
            return (
              <Column
                header={e}
                style={{ width: '7em' }}
                columnKey={'alphaAnalysisFacTable_dynCol_Factor' + e + index}
              />
            );
          });
        })}
      </Row>
    </ColumnGroup>
  );

  return (
    <div className={'alpha-analysis-pricing'}>
      <div>
        <BeeContentHeadline
          label={'Bundesländer'}
          headline={'h2'}
          type={'secondary'}
        />
        <div className="mb-3">
          Folgende Übersicht zeigt die Bundeslandfaktoren der in obigem Filter
          ausgewählten Dienstleister im Vergleich. Von den Dienstleistern nicht
          angegebene Faktoren werden mit 1,0 ausgewertet.
        </div>
        <TreeTable
          value={blStructure}
          headerColumnGroup={headerGroup}
          className={'alpha-analysis-factorEp-bl-table'}
          scrollable
          style={{ width: '100%' }}
          emptyMessage={'Keine Bundeslandfaktoren gefunden'}
        >
          <Column
            field="title"
            header="Bundesland"
            style={{ width: '15em' }}
            columnKey="alpha-analysis-factorEp-bl-col"
            className={'alpha-analysis-factorEp-bl-col frozen-col'}
            expander
          />
          {dynamicRegionColumns}
        </TreeTable>
      </div>
      <div className="mt-6">
        <BeeContentHeadline
          label={'Liegenschaften'}
          headline={'h2'}
          type={'secondary'}
        />
        <div className="mb-3">
          Folgende Übersicht zeigt die Liegenschaftsfaktoren der in obigem
          Filter ausgewählten Dienstleister im Vergleich. Von den Dienstleistern
          nicht angegebene Faktoren werden mit 1,0 ausgewertet.
        </div>
        <TreeTable
          value={lsStructure}
          headerColumnGroup={headerGroup}
          className={'alpha-analysis-factorEp-prop-table'}
          scrollable
          style={{ width: '100%' }}
          emptyMessage={'Keine Bundeslandfaktoren gefunden'}
        >
          <Column
            field="title"
            header="Liegenschaft"
            expander
            style={{ width: '15em' }}
            columnKey="alpha-analysis-factorEp-prop-col"
            className={'alpha-analysis-factorEp-prop-col frozen-col'}
          />
          {dynamicPropertyColumns}
        </TreeTable>
      </div>
    </div>
  );
}
