import React, { useState, useEffect } from 'react';
import {
  EMPTY_FIELD_PLACEHOLDER,
  MIN_NUMBER_OF_DECIMALS
} from '../../../Helper/Statics/Constants';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeNutzungsartMix from '../../Atoms/BeeNutzungsartMix';
import BeeValidatedCheckboxTriState from '../../Atoms/BeeValidatedCheckboxTriState';
import BeeValidatedCurrencyInput from '../../Atoms/BeeValidatedCurrencyInput';
import BeeValidatedDropDown from '../../Atoms/BeeValidatedDropDown';
import BeeValidatedNumberInput from '../../Atoms/BeeValidatedNumberInput';
import BeeValidatedNumberSelect from '../../Atoms/BeeValidatedNumberSelect';
import BeeValidatedTextArea from '../../Atoms/BeeValidatedTextArea';
import './AlphaGebaeudeGebDatenForm.scss';
import _ from 'lodash';
import BeeValidatedTimeInput from '../../Atoms/BeeValidatedTimeInput';
import { Dropdown } from '../../Organisms/Alpha/AlphaGebaeudeForm';

export type GebaeudeGebData = {
  baujahr: number | undefined;
  anzahlEtagen: number | undefined;
  anzahlEtagenOberirdisch: number | undefined;
  anzahlEtagenUnterirdisch: number | undefined;
  anzahlTreppenhaeuser: number | undefined;
  anzahlAufzuege: number | undefined;
  anzahlStellplaetze: number | undefined;
  anzahlNutzerPersonen: number | undefined;
  anzahlBauteile: number | undefined;
  kostenNettoFmProJahr: number | undefined;
  jahresMieteinnahmen: number | undefined;
  nutzungsanteilOffice: number | undefined;
  nutzungsanteilRetail: number | undefined;
  nutzungsanteilWohnen: number | undefined;
  nutzungsanteilLogistik: number | undefined;
  nutzungsanteilSonstige: number | undefined;
  bruttoGrundflaeche: number | undefined;
  nettoRaumflaeche: number | undefined;
  nutzungsflaeche: number | undefined;
  technikflaeche: number | undefined;
  verkehrsflaeche: number | undefined;
  grundstuecksflaeche: number | undefined;
  aussenanlagenflaeche: number | undefined;
  flaecheLeerstand: number | undefined;
  zertifiziert: boolean | undefined | null;
  zertifiziertInfo: string | undefined;
  tiefgarage: boolean | undefined | null;
  fassadenbefahranlage: boolean | undefined | null;
  denkmalschutz: boolean | undefined | null;
  hauptbetriebszeitVon: string | undefined;
  hauptbetriebszeitBis: string | undefined;
  hauptbetriebsTage: string | undefined;
  baualtersstufe: string | undefined;
  technisierungsgrad: string | undefined;
  klimatisierung: string | undefined;
  gebaeudeTyp: string | undefined;
};

type AlphaGebaeudeGebDatenFormProps = {
  data: GebaeudeGebData | null | undefined;
  baualtersstufenOptions: Dropdown;
  betriebstageOptions: Dropdown;
  technisierungsgradeOptions: Dropdown;
  klimatisierungenOptions: Dropdown;
  gebaeudetypenOptions: Dropdown;
  disabled: boolean;
  readOnly: boolean;
  onChangeGebData: (gebdata: GebaeudeGebData) => void;
};

export default function AlphaGebaeudeGebDatenForm({
  data,
  baualtersstufenOptions,
  betriebstageOptions,
  technisierungsgradeOptions,
  klimatisierungenOptions,
  gebaeudetypenOptions,
  disabled,
  readOnly,
  onChangeGebData
}: AlphaGebaeudeGebDatenFormProps) {
  const [baujahr, setBaujahr] = useState(
    data && data.baujahr ? data.baujahr : undefined
  );
  const [anzahlEtagen, setAnzahlEtagen] = useState(
    data && data.anzahlEtagen ? data.anzahlEtagen : undefined
  );
  const [anzahlEtagenOberirdisch, setAnzahlEtagenOberirdisch] = useState(
    data && data.anzahlEtagenOberirdisch
      ? data.anzahlEtagenOberirdisch
      : undefined
  );
  const [anzahlEtagenUnterirdisch, setAnzahlEtagenUnterirdisch] = useState(
    data && data.anzahlEtagenUnterirdisch
      ? data.anzahlEtagenUnterirdisch
      : undefined
  );
  const [anzahlTreppenhaeuser, setAnzahlTreppenhaeuser] = useState(
    data && data.anzahlTreppenhaeuser ? data.anzahlTreppenhaeuser : undefined
  );
  const [anzahlAufzuege, setAnzahlAufzuege] = useState(
    data && data.anzahlAufzuege ? data.anzahlAufzuege : undefined
  );
  const [anzahlStellplaetze, setAnzahlStellplaetze] = useState(
    data && data.anzahlStellplaetze ? data.anzahlStellplaetze : undefined
  );
  const [anzahlNutzerPersonen, setAnzahlNutzerPersonen] = useState(
    data && data.anzahlNutzerPersonen ? data.anzahlNutzerPersonen : undefined
  );
  const [anzahlBauteile, setAnzahlBauteile] = useState(
    data && data.anzahlBauteile ? data.anzahlBauteile : undefined
  );
  const [kostenNettoFmProJahr, setKostenNettoFmProJahr] = useState(
    data && data.kostenNettoFmProJahr ? data.kostenNettoFmProJahr : undefined
  );
  const [jahresMieteinnahmen, setJahresMieteinnahmen] = useState(
    data && data.jahresMieteinnahmen ? data.jahresMieteinnahmen : undefined
  );
  const [nutzungsanteilOffice, setNutzungsanteilOffice] = useState(
    data && data.nutzungsanteilOffice ? data.nutzungsanteilOffice : undefined
  );
  const [nutzungsanteilRetail, setNutzungsanteilRetail] = useState(
    data && data.nutzungsanteilRetail ? data.nutzungsanteilRetail : undefined
  );
  const [nutzungsanteilWohnen, setNutzungsanteilWohnen] = useState(
    data && data.nutzungsanteilWohnen ? data.nutzungsanteilWohnen : undefined
  );
  const [nutzungsanteilLogistik, setNutzungsanteilLogistik] = useState(
    data && data.nutzungsanteilLogistik
      ? data.nutzungsanteilLogistik
      : undefined
  );
  const [nutzungsanteilSonstige, setNutzungsanteilSonstige] = useState(
    data && data.nutzungsanteilSonstige
      ? data.nutzungsanteilSonstige
      : undefined
  );
  const [bruttoGrundflaeche, setBruttoGrundflaeche] = useState(
    data && data.bruttoGrundflaeche ? data.bruttoGrundflaeche : undefined
  );
  const [nettoRaumflaeche, setNettoRaumflaeche] = useState(
    data && data.nettoRaumflaeche ? data.nettoRaumflaeche : undefined
  );
  const [nutzungsflaeche, setNutzungsflaeche] = useState(
    data && data.nutzungsflaeche ? data.nutzungsflaeche : undefined
  );
  const [technikflaeche, setTechnikflaeche] = useState(
    data && data.technikflaeche ? data.technikflaeche : undefined
  );
  const [verkehrsflaeche, setVerkehrsflaeche] = useState(
    data && data.verkehrsflaeche ? data.verkehrsflaeche : undefined
  );
  const [grundstuecksflaeche, setGrundstuecksflaeche] = useState(
    data && data.grundstuecksflaeche ? data.grundstuecksflaeche : undefined
  );
  const [aussenanlagenflaeche, setAussenanlagenflaeche] = useState(
    data && data.aussenanlagenflaeche ? data.aussenanlagenflaeche : undefined
  );
  const [flaecheLeerstand, setFlaecheLeerstand] = useState(
    data && data.flaecheLeerstand ? data.flaecheLeerstand : undefined
  );
  const [zertifiziert, setZertifiziert] = useState(
    data ? data.zertifiziert : null
  );
  const [zertifiziertInfo, setZertifiziertInfo] = useState(
    data && data.zertifiziertInfo ? data.zertifiziertInfo : undefined
  );
  const [tiefgarage, setTiefgarage] = useState(data ? data.tiefgarage : null);
  const [fassadenbefahranlage, setFassadenbefahranlage] = useState(
    data ? data.fassadenbefahranlage : null
  );
  const [denkmalschutz, setDenkmalschutz] = useState(
    data ? data.denkmalschutz : null
  );
  const [hauptbetriebszeitVon, setHauptbetriebszeitVon] = useState(
    data && data.hauptbetriebszeitVon ? data.hauptbetriebszeitVon : undefined
  );
  const [hauptbetriebszeitBis, setHauptbetriebszeitBis] = useState(
    data && data.hauptbetriebszeitBis ? data.hauptbetriebszeitBis : undefined
  );
  const [hauptbetriebsTage, setHauptbetriebsTage] = useState(
    data && data.hauptbetriebsTage ? data.hauptbetriebsTage : undefined
  ); //Dropdown
  const [baualtersstufe, setBaualtersstufe] = useState(
    data && data.baualtersstufe ? data.baualtersstufe : undefined
  ); //Dropdown
  const [technisierungsgrad, setTechnisierungsgrad] = useState(
    data && data.technisierungsgrad ? data.technisierungsgrad : undefined
  ); //Dropdown
  const [klimatisierung, setKlimatisierung] = useState(
    data && data.klimatisierung ? data.klimatisierung : undefined
  ); //Dropdown
  const [gebaeudeTyp, setGebaeudeTyp] = useState(
    data && data.gebaeudeTyp ? data.gebaeudeTyp : undefined
  ); //Dropdown

  const [gebTypenOptions, setGebTypenOptions] = useState(gebaeudetypenOptions);
  const [gebBetriebstageOptions, setGebBetriebstageOptions] =
    useState(betriebstageOptions);

  useEffect(() => {
    if (data) {
      setBaujahr(data.baujahr);
      setAnzahlEtagen(data.anzahlEtagen);
      setAnzahlEtagenOberirdisch(data.anzahlEtagenOberirdisch);
      setAnzahlEtagenUnterirdisch(data.anzahlEtagenUnterirdisch);
      setAnzahlTreppenhaeuser(data.anzahlTreppenhaeuser);
      setAnzahlAufzuege(data.anzahlAufzuege);
      setAnzahlStellplaetze(data.anzahlStellplaetze);
      setAnzahlNutzerPersonen(data.anzahlNutzerPersonen);
      setAnzahlBauteile(data.anzahlBauteile);
      setKostenNettoFmProJahr(data.kostenNettoFmProJahr);
      setJahresMieteinnahmen(data.jahresMieteinnahmen);
      setNutzungsanteilOffice(data.nutzungsanteilOffice);
      setNutzungsanteilRetail(data.nutzungsanteilRetail);
      setNutzungsanteilWohnen(data.nutzungsanteilWohnen);
      setNutzungsanteilLogistik(data.nutzungsanteilLogistik);
      setNutzungsanteilSonstige(data.nutzungsanteilSonstige);
      setBruttoGrundflaeche(data.bruttoGrundflaeche);
      setNettoRaumflaeche(data.nettoRaumflaeche);
      setNutzungsflaeche(data.nutzungsflaeche);
      setTechnikflaeche(data.technikflaeche);
      setVerkehrsflaeche(data.verkehrsflaeche);
      setGrundstuecksflaeche(data.grundstuecksflaeche);
      setAussenanlagenflaeche(data.aussenanlagenflaeche);
      setFlaecheLeerstand(data.flaecheLeerstand);
      setZertifiziert(data.zertifiziert);
      setZertifiziertInfo(data.zertifiziertInfo);
      setTiefgarage(data.tiefgarage);
      setFassadenbefahranlage(data.fassadenbefahranlage);
      setDenkmalschutz(data.denkmalschutz);
      setHauptbetriebszeitVon(data.hauptbetriebszeitVon);
      setHauptbetriebszeitBis(data.hauptbetriebszeitBis);
      setHauptbetriebsTage(data.hauptbetriebsTage);
      setBaualtersstufe(data.baualtersstufe);
      setTechnisierungsgrad(data.technisierungsgrad);
      setKlimatisierung(data.klimatisierung);
      setGebaeudeTyp(data.gebaeudeTyp);
    }

    if (gebaeudetypenOptions) {
      let currentGebTypenOptions = _.cloneDeep(gebaeudetypenOptions);
      for (let i = 0; i < currentGebTypenOptions.length; i++) {
        switch (currentGebTypenOptions[i].value) {
          case 'landwirtschaftliche_gebaeude':
            currentGebTypenOptions[i].newLabel = 'Landwirtschaftliche Gebäude';
            break;
          case 'produktionsgebaeude':
            currentGebTypenOptions[i].newLabel = 'Produktionsgebäude';
            break;
          case 'gebaeude_fuer_versorgungstechnik':
            currentGebTypenOptions[i].newLabel =
              'Gebäude für Versorgungstechnik';
            break;
          case 'buerogebaeude':
            currentGebTypenOptions[i].newLabel = 'Bürogebäude';
            break;
          case 'gewerbebau':
            currentGebTypenOptions[i].newLabel = 'Gewerbebau';
            break;
          case 'gastronomie_freizeitobjekt':
            currentGebTypenOptions[i].newLabel = 'Gastronomie Freizeitobjekt';
            break;
          case 'wohngebaeude':
            currentGebTypenOptions[i].newLabel = 'Wohngebäude';
            break;
          case 'bildungseinrichtungen':
            currentGebTypenOptions[i].newLabel = 'Bildungseinrichtungen';
            break;
          case 'gebaeude_fuer_gesundheitsvorsorge':
            currentGebTypenOptions[i].newLabel =
              'Gebäude für Gesundheitsvorsorge';
            break;
          case 'oeffentliche_gebaeude':
            currentGebTypenOptions[i].newLabel = 'Öffentliche Gebäude';
            break;
          case 'park_und_lagergebaeude':
            currentGebTypenOptions[i].newLabel = 'Park und Lagergebäude';
            break;
          case 'sakralbau':
            currentGebTypenOptions[i].newLabel = 'Sakralbau';
            break;
          case 'verkehrsbauten':
            currentGebTypenOptions[i].newLabel = 'Verkehrsbauten';
            break;
          case 'sonstige_gebaeude':
            currentGebTypenOptions[i].newLabel = 'Sonstige Gebäude';
            break;
        }
      }
      setGebTypenOptions(currentGebTypenOptions);
    }

    if (betriebstageOptions) {
      let currentbetriebstageOptions = _.cloneDeep(betriebstageOptions);
      for (let i = 0; i < betriebstageOptions.length; i++) {
        switch (currentbetriebstageOptions[i].value) {
          case 'MO_FR':
            currentbetriebstageOptions[i].newLabel = 'Montag bis Freitag';
            break;

          case 'MO_SA':
            currentbetriebstageOptions[i].newLabel = 'Montag bis Samstag';
            break;
          case 'MO_SO':
            currentbetriebstageOptions[i].newLabel = 'Montag bis Sonntag ';
            break;

          default:
          // code block
        }
      }
      setGebBetriebstageOptions(currentbetriebstageOptions);
    }
  }, [data, gebaeudetypenOptions, betriebstageOptions]);

  function changeGebData(data: GebaeudeGebData) {
    if (onChangeGebData) {
      onChangeGebData(data);
    }
  }

  function changeBaujahr(currBaujahr: number | undefined) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.baujahr = currBaujahr;

    changeGebData(currGebGD);
  }
  function changeAnzahlEtagen(currAnzahlEtagen: number | undefined) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.anzahlEtagen = currAnzahlEtagen;

    changeGebData(currGebGD);
  }

  function changeAnzahlEtagenOberirdisch(
    currAnzahlEtagenOberirdisch: number | undefined
  ) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.anzahlEtagenOberirdisch = currAnzahlEtagenOberirdisch;

    changeGebData(currGebGD);
  }

  function changeAnzahlEtagenUnterirdisch(
    currAnzahlEtagenUnterirdisch: number | undefined
  ) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.anzahlEtagenUnterirdisch = currAnzahlEtagenUnterirdisch;

    changeGebData(currGebGD);
  }

  function changeAnzahlTreppenhaeuser(
    currAnzahlTreppenhaeuser: number | undefined
  ) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.anzahlTreppenhaeuser = currAnzahlTreppenhaeuser;

    changeGebData(currGebGD);
  }

  function changeAnzahlAufzuege(currAnzahlAufzuege: number | undefined) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.anzahlAufzuege = currAnzahlAufzuege;

    changeGebData(currGebGD);
  }
  function changeAnzahlStellplaetze(
    currAnzahlStellplaetze: number | undefined
  ) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.anzahlStellplaetze = currAnzahlStellplaetze;

    changeGebData(currGebGD);
  }
  function changeAnzahlNutzerPersonen(
    currAnzahlNutzerPersonen: number | undefined
  ) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.anzahlNutzerPersonen = currAnzahlNutzerPersonen;

    changeGebData(currGebGD);
  }
  function changeAnzahlBauteile(currAnzahlBauteile: number | undefined) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.anzahlBauteile = currAnzahlBauteile;

    changeGebData(currGebGD);
  }
  function changeKostenNettoFmProJahr(
    currKostenNettoFmProJahr: number | undefined
  ) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.kostenNettoFmProJahr = currKostenNettoFmProJahr;

    changeGebData(currGebGD);
  }
  function changeJahresMieteinnahmen(
    currJahresMieteinnahmen: number | undefined
  ) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.jahresMieteinnahmen = currJahresMieteinnahmen;

    changeGebData(currGebGD);
  }

  function changeNutzungsanteile(
    currNutzungsanteilOffice: number | undefined,
    currNutzungsanteilRetail: number | undefined,
    currNutzungsanteilWohnen: number | undefined,
    currNutzungsanteilLogistik: number | undefined,
    currNutzungsanteilSonstige: number | undefined
  ) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.nutzungsanteilOffice = currNutzungsanteilOffice;
    currGebGD.nutzungsanteilRetail = currNutzungsanteilRetail;
    currGebGD.nutzungsanteilWohnen = currNutzungsanteilWohnen;
    currGebGD.nutzungsanteilLogistik = currNutzungsanteilLogistik;
    currGebGD.nutzungsanteilSonstige = currNutzungsanteilSonstige;

    changeGebData(currGebGD);
  }

  function changeBruttoGrundflaeche(
    currBruttoGrundflaeche: number | undefined
  ) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.bruttoGrundflaeche = currBruttoGrundflaeche;

    changeGebData(currGebGD);
  }
  function changeNettoRaumflaeche(currNettoRaumflaeche: number | undefined) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.nettoRaumflaeche = currNettoRaumflaeche;

    changeGebData(currGebGD);
  }
  function changeNutzungsflaeche(currNutzungsflaeche: number | undefined) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.nutzungsflaeche = currNutzungsflaeche;

    changeGebData(currGebGD);
  }
  function changeTechnikflaeche(currTechnikflaeche: number | undefined) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.technikflaeche = currTechnikflaeche;

    changeGebData(currGebGD);
  }
  function changeVerkehrsflaeche(currVerkehrsflaeche: number | undefined) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.verkehrsflaeche = currVerkehrsflaeche;

    changeGebData(currGebGD);
  }
  function changeGrundstuecksflaeche(
    currGrundstuecksflaeche: number | undefined
  ) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.grundstuecksflaeche = currGrundstuecksflaeche;

    changeGebData(currGebGD);
  }
  function changeAussenanlagenflaeche(
    currAussenanlagenflaeche: number | undefined
  ) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.aussenanlagenflaeche = currAussenanlagenflaeche;

    changeGebData(currGebGD);
  }
  function changeFlaecheLeerstand(currFlaecheLeerstand: number | undefined) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.flaecheLeerstand = currFlaecheLeerstand;

    changeGebData(currGebGD);
  }
  function changeZertifiziert(currZertifiziert: boolean | undefined | null) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.zertifiziert = currZertifiziert;

    changeGebData(currGebGD);
  }
  function changeZertifiziertInfo(currZertifiziertInfo: string | undefined) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.zertifiziertInfo = currZertifiziertInfo;

    changeGebData(currGebGD);
  }
  function changeTiefgarage(currTiefgarage: boolean | undefined | null) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.tiefgarage = currTiefgarage;

    changeGebData(currGebGD);
  }

  function changeFassadenbefahranlage(
    currFassadenbefahranlage: boolean | undefined | null
  ) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.fassadenbefahranlage = currFassadenbefahranlage;

    changeGebData(currGebGD);
  }

  function changeDenkmalschutz(currDenkmalschut: boolean | undefined | null) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.denkmalschutz = currDenkmalschut;

    changeGebData(currGebGD);
  }
  function changeHauptbetriebszeitVon(
    currHauptbetriebszeitVon: string | undefined
  ) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.hauptbetriebszeitVon = currHauptbetriebszeitVon;

    changeGebData(currGebGD);
  }
  function changeHauptbetriebszeitBis(
    currHauptbetriebszeitBis: string | undefined
  ) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.hauptbetriebszeitBis = currHauptbetriebszeitBis;

    changeGebData(currGebGD);
  }

  function changeHauptbetriebsTage(currHauptbetriebsTage: string | undefined) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.hauptbetriebsTage = currHauptbetriebsTage;

    changeGebData(currGebGD);
  }
  function changeBaualtersstufe(currBaualtersstufe: string | undefined) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.baualtersstufe = currBaualtersstufe;

    changeGebData(currGebGD);
  }
  function changeTechnisierungsgrad(
    currTechnisierungsgrad: string | undefined
  ) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.technisierungsgrad = currTechnisierungsgrad;

    changeGebData(currGebGD);
  }

  function changeKlimatisierung(currKlimatisierung: string | undefined) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.klimatisierung = currKlimatisierung;

    changeGebData(currGebGD);
  }
  function changeGebaeudeTyp(currGebaeudeTyp: string | undefined) {
    let currGebGD: GebaeudeGebData = createGebStateTemplate();
    currGebGD.gebaeudeTyp = currGebaeudeTyp;

    changeGebData(currGebGD);
  }

  function createGebStateTemplate() {
    const currGebGD: GebaeudeGebData = {
      baujahr: baujahr,
      anzahlEtagen: anzahlEtagen,
      anzahlEtagenOberirdisch: anzahlEtagenOberirdisch,
      anzahlEtagenUnterirdisch: anzahlEtagenUnterirdisch,
      anzahlTreppenhaeuser: anzahlTreppenhaeuser,
      anzahlAufzuege: anzahlAufzuege,
      anzahlStellplaetze: anzahlStellplaetze,
      anzahlBauteile: anzahlBauteile,
      anzahlNutzerPersonen: anzahlNutzerPersonen,
      kostenNettoFmProJahr: kostenNettoFmProJahr,
      jahresMieteinnahmen: jahresMieteinnahmen,
      nutzungsanteilOffice: nutzungsanteilOffice,
      nutzungsanteilRetail: nutzungsanteilRetail,
      nutzungsanteilWohnen: nutzungsanteilWohnen,
      nutzungsanteilLogistik: nutzungsanteilLogistik,
      nutzungsanteilSonstige: nutzungsanteilSonstige,
      bruttoGrundflaeche: bruttoGrundflaeche,
      nettoRaumflaeche: nettoRaumflaeche,
      nutzungsflaeche: nutzungsflaeche,
      technikflaeche: technikflaeche,
      verkehrsflaeche: verkehrsflaeche,
      grundstuecksflaeche: grundstuecksflaeche,
      aussenanlagenflaeche: aussenanlagenflaeche,
      flaecheLeerstand: flaecheLeerstand,
      zertifiziert: zertifiziert,
      zertifiziertInfo: zertifiziertInfo,
      tiefgarage: tiefgarage,
      fassadenbefahranlage: fassadenbefahranlage,
      denkmalschutz: denkmalschutz,
      hauptbetriebszeitVon: hauptbetriebszeitVon,
      hauptbetriebszeitBis: hauptbetriebszeitBis,
      hauptbetriebsTage: hauptbetriebsTage,
      baualtersstufe: baualtersstufe,
      technisierungsgrad: technisierungsgrad,
      klimatisierung: klimatisierung,
      gebaeudeTyp: gebaeudeTyp
    };
    return currGebGD;
  }

  function renderHeadline() {
    return (
      <BeeContentHeadline
        label={'Gebäudedaten'}
        headline={'h2'}
        type={'secondary'}
      />
    );
  }
  const renderBaujahr = () => {
    return (
      <BeeValidatedNumberInput
        label={'Baujahr'}
        minFractionDigits={MIN_NUMBER_OF_DECIMALS}
        maxFractionDigits={MIN_NUMBER_OF_DECIMALS}
        grouping={false}
        value={baujahr}
        required={false}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(e) => {
          setBaujahr(e.target.value ? e.target.value : undefined);
        }}
        onFocusOut={(e) => {
          changeBaujahr(e.target.value ? parseInt(e.target.value) : undefined);
        }}
      />
    );
  };

  const renderAnzahlEtagen = () => {
    return (
      <BeeValidatedNumberSelect
        disabled={disabled}
        readOnly={readOnly}
        grouping={false}
        value={anzahlEtagen}
        label={'Anzahl der Geschosse'}
        minFractionDigits={MIN_NUMBER_OF_DECIMALS}
        maxFractionDigits={MIN_NUMBER_OF_DECIMALS}
        stepSize={1}
        required={false}
        onChange={(e) =>
          setAnzahlEtagen(e.target.value ? e.target.value : undefined)
        }
        onFocusOut={(e) => {
          changeAnzahlEtagen(e);
        }}
      />
    );
  };

  const renderAnzahlEtagenOberirdisch = () => {
    return (
      <BeeValidatedNumberSelect
        disabled={disabled}
        readOnly={readOnly}
        grouping={false}
        value={anzahlEtagenOberirdisch}
        label={'Anzahl Geschosse über Grund'}
        minFractionDigits={MIN_NUMBER_OF_DECIMALS}
        maxFractionDigits={MIN_NUMBER_OF_DECIMALS}
        stepSize={1}
        required={false}
        onChange={(e) =>
          setAnzahlEtagenOberirdisch(
            e.target.value ? e.target.value : undefined
          )
        }
        onFocusOut={(e) => {
          changeAnzahlEtagenOberirdisch(e);
        }}
      />
    );
  };

  const renderAnzahlEtagenUnterirdisch = () => {
    return (
      <BeeValidatedNumberSelect
        disabled={disabled}
        readOnly={readOnly}
        grouping={false}
        value={anzahlEtagenUnterirdisch}
        label={'Anzahl Geschosse unter Grund'}
        minFractionDigits={MIN_NUMBER_OF_DECIMALS}
        maxFractionDigits={MIN_NUMBER_OF_DECIMALS}
        stepSize={1}
        required={false}
        onChange={(e) =>
          setAnzahlEtagenUnterirdisch(
            e.target.value ? e.target.value : undefined
          )
        }
        onFocusOut={(e) => {
          changeAnzahlEtagenUnterirdisch(e);
        }}
      />
    );
  };

  const renderAnzahlTreppenhaeuser = () => {
    return (
      <BeeValidatedNumberSelect
        disabled={disabled}
        readOnly={readOnly}
        grouping={false}
        value={anzahlTreppenhaeuser}
        label={'Anzahl Treppenhäuser'}
        minFractionDigits={MIN_NUMBER_OF_DECIMALS}
        maxFractionDigits={MIN_NUMBER_OF_DECIMALS}
        stepSize={1}
        required={false}
        onChange={(e) =>
          setAnzahlTreppenhaeuser(e.target.value ? e.target.value : undefined)
        }
        onFocusOut={(e) => {
          changeAnzahlTreppenhaeuser(e);
        }}
      />
    );
  };

  const renderAnzahlAufzuege = () => {
    return (
      <BeeValidatedNumberSelect
        disabled={disabled}
        readOnly={readOnly}
        grouping={false}
        value={anzahlAufzuege}
        label={'Anzahl Aufzüge'}
        minFractionDigits={MIN_NUMBER_OF_DECIMALS}
        maxFractionDigits={MIN_NUMBER_OF_DECIMALS}
        stepSize={1}
        required={false}
        onChange={(e) =>
          setAnzahlAufzuege(e.target.value ? e.target.value : undefined)
        }
        onFocusOut={(e) => {
          changeAnzahlAufzuege(e);
        }}
      />
    );
  };

  const renderStellplaetze = () => {
    return (
      <BeeValidatedNumberSelect
        disabled={disabled}
        readOnly={readOnly}
        grouping={false}
        value={anzahlStellplaetze}
        label={'Anzahl Stellplätze'}
        minFractionDigits={MIN_NUMBER_OF_DECIMALS}
        maxFractionDigits={MIN_NUMBER_OF_DECIMALS}
        stepSize={1}
        required={false}
        onChange={(e) =>
          setAnzahlStellplaetze(e.target.value ? e.target.value : undefined)
        }
        onFocusOut={(e) => {
          changeAnzahlStellplaetze(e);
        }}
      />
    );
  };

  const renderNutzerpersonen = () => {
    return (
      <BeeValidatedNumberSelect
        disabled={disabled}
        readOnly={readOnly}
        minFractionDigits={MIN_NUMBER_OF_DECIMALS}
        maxFractionDigits={MIN_NUMBER_OF_DECIMALS}
        value={anzahlNutzerPersonen}
        label={'Anzahl Nutzer'}
        required={false}
        grouping={false}
        stepSize={1}
        onChange={(e) =>
          setAnzahlNutzerPersonen(e.target.value ? e.target.value : undefined)
        }
        onFocusOut={(e) => {
          changeAnzahlNutzerPersonen(e);
        }}
      />
    );
  };

  const renderBauteile = () => {
    return (
      <BeeValidatedNumberSelect
        disabled={disabled}
        readOnly={readOnly}
        minFractionDigits={MIN_NUMBER_OF_DECIMALS}
        maxFractionDigits={MIN_NUMBER_OF_DECIMALS}
        value={anzahlBauteile}
        label={'Anzahl Bauteile'}
        required={false}
        grouping={false}
        stepSize={1}
        onChange={(e) =>
          setAnzahlBauteile(e.target.value ? e.target.value : undefined)
        }
        onFocusOut={(e) => {
          changeAnzahlBauteile(e);
        }}
      />
    );
  };

  const renderMieteinnahmen = () => {
    /// /1000 *1000
    return (
      <BeeValidatedCurrencyInput
        label={'Mieteinnahmen (jährlich)'}
        minFractionDigits={MIN_NUMBER_OF_DECIMALS}
        maxFractionDigits={MIN_NUMBER_OF_DECIMALS}
        value={jahresMieteinnahmen}
        required={false}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(e) => {
          setJahresMieteinnahmen(e.target.value ? e.target.value : undefined);
        }}
        onFocusOut={(e) => {
          changeJahresMieteinnahmen(e);
        }}
      />
    );
  };

  const renderFmKosten = () => {
    return (
      <BeeValidatedCurrencyInput
        label={'FM-Kosten (jährlich)'}
        minFractionDigits={MIN_NUMBER_OF_DECIMALS}
        maxFractionDigits={MIN_NUMBER_OF_DECIMALS}
        value={kostenNettoFmProJahr}
        required={false}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(e) =>
          setKostenNettoFmProJahr(e.target.value ? e.target.value : undefined)
        }
        onFocusOut={(e) => {
          changeKostenNettoFmProJahr(e);
        }}
      />
    );
  };

  const renderNutzungsartmix = () => {
    if (
      !nutzungsanteilOffice &&
      !nutzungsanteilRetail &&
      !nutzungsanteilWohnen &&
      !nutzungsanteilLogistik &&
      !nutzungsanteilSonstige
    ) {
      setNutzungsanteilOffice(20);
      setNutzungsanteilRetail(20);
      setNutzungsanteilWohnen(20);
      setNutzungsanteilLogistik(20);
      setNutzungsanteilSonstige(20);
      changeNutzungsanteile(20, 20, 20, 20, 20);
    }
    return (
      <BeeNutzungsartMix
        label={'Nutzungsart'}
        disabled={false}
        forceRerenderToggle={false} //fixme
        aOffice={nutzungsanteilOffice ? nutzungsanteilOffice : 0}
        aRetail={nutzungsanteilRetail ? nutzungsanteilRetail : 0}
        aWohnen={nutzungsanteilWohnen ? nutzungsanteilWohnen : 0}
        aLogistik={nutzungsanteilLogistik ? nutzungsanteilLogistik : 0}
        aSonstige={nutzungsanteilSonstige ? nutzungsanteilSonstige : 0}
        type={'primary'}
        formstate={'none'}
        errorLabel={null}
        neutralLabel={null}
        validLabel={null}
        readOnly={readOnly}
        required={false}
        onChange={(e) => {
          setNutzungsanteilOffice(e.anteilOffice);
          setNutzungsanteilRetail(e.anteilRetail);
          setNutzungsanteilWohnen(e.anteilWohnen);
          setNutzungsanteilLogistik(e.anteilLogistik);
          setNutzungsanteilSonstige(e.anteilSonstige);
          changeNutzungsanteile(
            e.anteilOffice,
            e.anteilRetail,
            e.anteilWohnen,
            e.anteilLogistik,
            e.anteilSonstige
          );
        }}
      />
    );
  };

  const renderBruttoGrundflaeche = () => {
    return (
      <BeeValidatedNumberInput
        label={'Bruttogrundfläche [m²]'}
        minFractionDigits={MIN_NUMBER_OF_DECIMALS}
        maxFractionDigits={MIN_NUMBER_OF_DECIMALS}
        grouping={false}
        value={bruttoGrundflaeche}
        required={false}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(e) =>
          setBruttoGrundflaeche(e.target.value ? e.target.value : undefined)
        }
        onFocusOut={(e) => {
          changeBruttoGrundflaeche(
            e.target.value ? parseInt(e.target.value) : undefined
          );
        }}
      />
    );
  };
  const renderNettoRaumflaeche = () => {
    return (
      <BeeValidatedNumberInput
        label={'Nettoraumfläche [m²]'}
        minFractionDigits={MIN_NUMBER_OF_DECIMALS}
        maxFractionDigits={MIN_NUMBER_OF_DECIMALS}
        grouping={false}
        value={nettoRaumflaeche}
        required={false}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(e) =>
          setNettoRaumflaeche(e.target.value ? e.target.value : undefined)
        }
        onFocusOut={(e) => {
          changeNettoRaumflaeche(
            e.target.value ? parseInt(e.target.value) : undefined
          );
        }}
      />
    );
  };
  const renderNutzungsflaeche = () => {
    return (
      <BeeValidatedNumberInput
        label={'Nutzungsfläche [m²]'}
        minFractionDigits={MIN_NUMBER_OF_DECIMALS}
        maxFractionDigits={MIN_NUMBER_OF_DECIMALS}
        grouping={false}
        value={nutzungsflaeche}
        required={false}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(e) =>
          setNutzungsflaeche(e.target.value ? e.target.value : undefined)
        }
        onFocusOut={(e) => {
          changeNutzungsflaeche(
            e.target.value ? parseInt(e.target.value) : undefined
          );
        }}
      />
    );
  };

  const renderTechnikflaeche = () => {
    return (
      <BeeValidatedNumberInput
        label={'Technikfläche [m²]'}
        minFractionDigits={MIN_NUMBER_OF_DECIMALS}
        maxFractionDigits={MIN_NUMBER_OF_DECIMALS}
        grouping={false}
        value={technikflaeche}
        required={false}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(e) =>
          setTechnikflaeche(e.target.value ? e.target.value : undefined)
        }
        onFocusOut={(e) => {
          changeTechnikflaeche(
            e.target.value ? parseInt(e.target.value) : undefined
          );
        }}
      />
    );
  };

  const renderVerkehrsflaeche = () => {
    return (
      <BeeValidatedNumberInput
        label={'Verkehrsfläche [m²]'}
        minFractionDigits={MIN_NUMBER_OF_DECIMALS}
        maxFractionDigits={MIN_NUMBER_OF_DECIMALS}
        grouping={false}
        value={verkehrsflaeche}
        required={false}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(e) =>
          setVerkehrsflaeche(e.target.value ? e.target.value : undefined)
        }
        onFocusOut={(e) => {
          changeVerkehrsflaeche(
            e.target.value ? parseInt(e.target.value) : undefined
          );
        }}
      />
    );
  };

  const renderGrundstuecksflaeche = () => {
    return (
      <BeeValidatedNumberInput
        label={'Grundstücksfläche [m²]'}
        minFractionDigits={MIN_NUMBER_OF_DECIMALS}
        maxFractionDigits={MIN_NUMBER_OF_DECIMALS}
        grouping={false}
        value={grundstuecksflaeche}
        required={false}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(e) =>
          setGrundstuecksflaeche(e.target.value ? e.target.value : undefined)
        }
        onFocusOut={(e) => {
          changeGrundstuecksflaeche(
            e.target.value ? parseInt(e.target.value) : undefined
          );
        }}
      />
    );
  };

  const renderAussenanlagenflaeche = () => {
    return (
      <BeeValidatedNumberInput
        label={'Außenanlangenfläche [m²]'}
        minFractionDigits={MIN_NUMBER_OF_DECIMALS}
        maxFractionDigits={MIN_NUMBER_OF_DECIMALS}
        grouping={false}
        value={aussenanlagenflaeche}
        required={false}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(e) =>
          setAussenanlagenflaeche(e.target.value ? e.target.value : undefined)
        }
        onFocusOut={(e) => {
          changeAussenanlagenflaeche(
            e.target.value ? parseInt(e.target.value) : undefined
          );
        }}
      />
    );
  };
  const renderFlaecheLeerstand = () => {
    return (
      <BeeValidatedNumberInput
        label={'Leerstandsfläche [m²]'}
        minFractionDigits={MIN_NUMBER_OF_DECIMALS}
        maxFractionDigits={MIN_NUMBER_OF_DECIMALS}
        grouping={false}
        value={flaecheLeerstand}
        required={false}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(e) =>
          setFlaecheLeerstand(e.target.value ? e.target.value : undefined)
        }
        onFocusOut={(e) => {
          changeFlaecheLeerstand(
            e.target.value ? parseInt(e.target.value) : undefined
          );
        }}
      />
    );
  };

  const renderZertifiziert = () => {
    return (
      <BeeValidatedCheckboxTriState
        disabled={disabled}
        readOnly={readOnly}
        stateNull={EMPTY_FIELD_PLACEHOLDER}
        stateFalse={'Nein'}
        stateTrue={'Ja'}
        value={zertifiziert}
        label={'Zertifiziert'}
        required={false}
        onChange={(e: boolean | null | undefined) => {
          setZertifiziert(e);
          changeZertifiziert(e);
        }}
      />
    );
  };

  function renderZertifiziertInfo() {
    return (
      <>
        <BeeValidatedTextArea
          label={'Zertifizierungen'}
          formstate={'none'}
          value={zertifiziertInfo}
          disabled={disabled}
          rows={readOnly ? 1 : 3}
          autoResize={true}
          readOnly={readOnly}
          required={false}
          onChange={(e) => setZertifiziertInfo(e.target.value)}
          onFocusOut={(e) => changeZertifiziertInfo(e.target.value)}
        />
      </>
    );
  }
  const renderTiefgarage = () => {
    return (
      <BeeValidatedCheckboxTriState
        disabled={disabled}
        readOnly={readOnly}
        stateNull={EMPTY_FIELD_PLACEHOLDER}
        stateFalse={'Nein'}
        stateTrue={'Ja'}
        value={tiefgarage}
        label={'Tiefgarage'}
        required={false}
        onChange={(e: boolean | null | undefined) => {
          setTiefgarage(e);
          changeTiefgarage(e);
        }}
      />
    );
  };

  const renderDenkmalschutz = () => {
    return (
      <BeeValidatedCheckboxTriState
        disabled={disabled}
        readOnly={readOnly}
        stateNull={EMPTY_FIELD_PLACEHOLDER}
        stateFalse={'Nein'}
        stateTrue={'Ja'}
        value={denkmalschutz}
        label={'Denkmalschutz'}
        required={false}
        onChange={(e: boolean | null | undefined) => {
          setDenkmalschutz(e);
          changeDenkmalschutz(e);
        }}
      />
    );
  };

  const renderFassadenbefahranlage = () => {
    return (
      <BeeValidatedCheckboxTriState
        disabled={disabled}
        readOnly={readOnly}
        stateNull={EMPTY_FIELD_PLACEHOLDER}
        stateFalse={'Nein'}
        stateTrue={'Ja'}
        value={fassadenbefahranlage}
        label={'Fassadenbefahranlage'}
        required={false}
        onChange={(e: boolean | null | undefined) => {
          setFassadenbefahranlage(e);
          changeFassadenbefahranlage(e);
        }}
      />
    );
  };
  const renderHauptbetriebstage = () => {
    return (
      <BeeValidatedDropDown
        disabled={disabled}
        value={hauptbetriebsTage}
        options={gebBetriebstageOptions}
        optionLabel={'newLabel'}
        readOnly={readOnly}
        label={'Hauptbetriebstage'}
        required={false}
        onChange={(e) => {
          setHauptbetriebsTage(e);
          changeHauptbetriebsTage(e);
        }}
      />
    );
  };

  function calculateDateFromTimeString(date: Date, time: string) {
    if (time) {
      const index = time.indexOf(':');
      const index2 = time.lastIndexOf(':');

      if (index && index2 && index !== index2) {
        const hours = time.substring(0, index);
        const minutes = time.substring(index + 1, index2);
        const seconds = time.substring(index2 + 1);
        date.setHours(parseInt(hours));
        date.setMinutes(parseInt(minutes));
        date.setSeconds(parseInt(seconds));
      } else if (index && index2 && index === index2) {
        const hours = time.substring(0, index);
        const minutes = time.substring(index + 1);

        date.setHours(parseInt(hours));
        date.setMinutes(parseInt(minutes));
        date.setSeconds(0);
      } else {
        const hours = time;

        date.setHours(parseInt(hours));
        date.setMinutes(0);
        date.setSeconds(0);
      }

      return date;
    }
  }

  const renderHauptbetriebszeitVon = () => {
    return (
      <BeeValidatedTimeInput
        disabled={disabled}
        value={
          hauptbetriebszeitVon
            ? calculateDateFromTimeString(new Date(), hauptbetriebszeitVon)
            : undefined
        }
        readOnly={readOnly}
        label={'Hauptbetriebszeit von'}
        required={false}
        onChange={(e: any) => {
          setHauptbetriebszeitVon(
            e && e.value ? e.value.toLocaleTimeString() : undefined
          );
        }}
        onFocusOut={(e) => {
          changeHauptbetriebszeitVon(
            e && e.target && e.target.value ? e.target.value : undefined
          );
        }}
      />
    );
  };

  const renderHauptbetriebszeitBis = () => {
    return (
      <BeeValidatedTimeInput
        disabled={disabled}
        value={
          hauptbetriebszeitBis
            ? calculateDateFromTimeString(new Date(), hauptbetriebszeitBis)
            : undefined
        }
        readOnly={readOnly}
        label={'Hauptbetriebszeit bis'}
        required={false}
        onChange={(e: any) =>
          setHauptbetriebszeitBis(
            e && e.value ? e.value.toLocaleTimeString() : undefined
          )
        }
        onFocusOut={(e) => {
          changeHauptbetriebszeitBis(
            e && e.target && e.target.value ? e.target.value : undefined
          );
        }}
      />
    );
  };

  const renderBaualtersstufe = () => {
    return (
      <BeeValidatedDropDown
        disabled={disabled}
        value={baualtersstufe}
        options={baualtersstufenOptions}
        readOnly={readOnly}
        label={'Baualtersstufe'}
        required={false}
        onChange={(e) => {
          setBaualtersstufe(e);
          changeBaualtersstufe(e);
        }}
      />
    );
  };
  const renderTechnisierungsgrad = () => {
    return (
      <BeeValidatedDropDown
        disabled={disabled}
        value={technisierungsgrad}
        options={technisierungsgradeOptions}
        readOnly={readOnly}
        label={'Technisierungsgrad'}
        required={false}
        onChange={(e) => {
          setTechnisierungsgrad(e);
          changeTechnisierungsgrad(e);
        }}
      />
    );
  };
  const renderKlimatisierung = () => {
    return (
      <BeeValidatedDropDown
        disabled={disabled}
        value={klimatisierung}
        options={klimatisierungenOptions}
        readOnly={readOnly}
        label={'Klimatisierung'}
        required={false}
        onChange={(e) => {
          setKlimatisierung(e);
          changeKlimatisierung(e);
        }}
      />
    );
  };
  const renderGebaeudeTyp = () => {
    return (
      <BeeValidatedDropDown
        disabled={disabled}
        value={gebaeudeTyp}
        options={gebTypenOptions}
        optionLabel={'newLabel'}
        readOnly={readOnly}
        label={'Gebäudetyp'}
        required={false}
        onChange={(e) => {
          setGebaeudeTyp(e);
          changeGebaeudeTyp(e);
        }}
      />
    );
  };

  return (
    <div className={'alpha-gebaeude-gebdaten-form'}>
      {renderHeadline()}

      <div className={'grid'}>
        <div className={'col col-12 sm:col-12 md:col-12 lg:col-12'}>
          {renderGebaeudeTyp()}
        </div>
        {/* 2er row */}
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderBaujahr()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderBaualtersstufe()}
        </div>

        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderTechnisierungsgrad()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderKlimatisierung()}
        </div>

        {/* 2er row */}
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderBruttoGrundflaeche()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderNettoRaumflaeche()}
        </div>
        {/* 2er row */}
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderNutzungsflaeche()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderTechnikflaeche()}
        </div>
        {/* 2er row */}
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderVerkehrsflaeche()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderGrundstuecksflaeche()}
        </div>
        {/* 2er row */}
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderAussenanlagenflaeche()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderFlaecheLeerstand()}
        </div>

        {/* 2er row */}
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderAnzahlEtagen()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderAnzahlEtagenOberirdisch()}
        </div>
        {/* 2er row */}
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderAnzahlEtagenUnterirdisch()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderAnzahlTreppenhaeuser()}
        </div>
        {/* 2er row */}
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderAnzahlAufzuege()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderStellplaetze()}
        </div>
        {/* 2er row */}
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderNutzerpersonen()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderBauteile()}
        </div>

        {/* 4er row */}
        <div className={'col col-12 sm:col-6 md:col-3 lg:col-3'}>
          {renderZertifiziert()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-3 lg:col-3'}>
          {renderTiefgarage()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-3 lg:col-3'}>
          {renderFassadenbefahranlage()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-3 lg:col-3'}>
          {renderDenkmalschutz()}
        </div>
        {/* 1er row */}
        <div className={'col col-12 sm:col-12 md:col-12 lg:col-12'}>
          {renderZertifiziertInfo()}
        </div>

        {/* 1er row */}
        <div className={'col col-12 sm:col-12 md:col-12 lg:col-12'}>
          {renderNutzungsartmix()}
        </div>

        {/* 2er row */}
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderMieteinnahmen()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderFmKosten()}
        </div>

        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderHauptbetriebstage()}
        </div>
        <div className={'col col-12 sm:col-3 md:col-3 lg:col-3'}>
          {renderHauptbetriebszeitVon()}
        </div>
        <div className={'col col-12 sm:col-3 md:col-3 lg:col-3'}>
          {renderHauptbetriebszeitBis()}
        </div>
      </div>
    </div>
  );
}
