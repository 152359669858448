import axios, { InternalAxiosRequestConfig } from 'axios';
import { logoutUser } from '../../Redux/Authentication/authActions';
import { store } from '../../Redux/store';
import {
  URL_TO_AUTH_API,
  URL_TO_REFRESH_TOKEN_AT_AUTH_API
} from '../Statics/Constants';

export type UserRight = { id?: string; title: string; description?: string };

export type User = {
  id?: string;
  password?: string;
  username: string;
  firstname: string;
  lastname: string;
  phone?: string;
  organisation?: string;
  organisationLogo?: string;
  type?: string;
  valid?: boolean;
  expire?: Date;
  registration?: Date;
  grantedRights?: UserRight[];
};

export const authAxios = axios.create({
  /*  baseURL: URL_TO_AUTH_API, */
});

// Add a request interceptor
authAxios.interceptors.request.use(
  (config: InternalAxiosRequestConfig<any>) => {
    //load accessToken from session
    const accessToken = sessionStorage.getItem('aT');
    if (accessToken) {
      config.headers['Authorization'] = 'Bearer ' + accessToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//Add a response interceptor
authAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    if (error && error.response && error.response.status === 401) {
      //breaking the loop if refreshtoken is invalid
      if (originalRequest.url === URL_TO_REFRESH_TOKEN_AT_AUTH_API) {
        //USENAVIGATE NOT WORKING
        store.dispatch(logoutUser());
        return Promise.reject(error);
      } else if (originalRequest && !originalRequest._retry) {
        originalRequest._retry = true;

        const refreshToken = store.getState().app.refreshToken;
        console.log(store.getState());
        console.log(store.getState().app);
        console.log(store.getState().app.refreshToken);
        //load new tokens
        const axiosConfig = {
          method: 'put',
          cache: 'no-cache',
          url: URL_TO_REFRESH_TOKEN_AT_AUTH_API,
          redirect: 'follow',
          referrer: 'no-referrer',
          data: {},
          headers: {
            Authorization: `Bearer ` + refreshToken
          }
        };
        return axios(axiosConfig)
          .then((response: any) => {
            const token = response.data.token;
            //store token
            sessionStorage.setItem('aT', token);
            //resend request using new token
            console.log('RETRY!!!');
            return authAxios(originalRequest);
          })
          .catch((error) => {
            //kein valider refresh token mehr
            console.log(error);
            if (error && error.response && error.response.status === 401) {
              store.dispatch(logoutUser());
            } else {
              return Promise.reject(error);
            }
          });
      }
    }
    return Promise.reject(error.response);
  }
);

//GET ALL SERVICEPROVIDER
export const alphaReadAllServiceProvider = () => {
  return new Promise((resolve, reject) => {
    const uploadUrl = URL_TO_AUTH_API + '/v1/alpha/serviceprovider';
    authAxios
      .get(uploadUrl)
      .then((result: any) => {
        console.log(result);
        const user: User[] =
          result && result.data && result.data.data ? result.data.data : [];
        resolve(user);
      })
      .catch((error) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//CREATE SERVICEPROVIDER
export const alphaCreateServiceProvider = (data: User) => {
  return new Promise((resolve, reject) => {
    const uploadUrl = URL_TO_AUTH_API + '/v1/alpha/serviceprovider';

    authAxios
      .put(uploadUrl, data)
      .then((result) => {
        console.log(result);
        const user: User = result && result.data ? result.data : [];
        resolve(user);
      })
      .catch((error) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//CREATE SERVICEPROVIDER
export const setDlPermissionsReadOnly = (userId: string) => {
  return new Promise((resolve, reject) => {
    const uploadUrl =
      URL_TO_AUTH_API + '/v1/alpha/serviceprovider/' + userId + '/readOnly';

    authAxios
      .patch(uploadUrl)
      .then((result) => {
        console.log(result);
        const user: User = result && result.data ? result.data : [];
        resolve(user);
      })
      .catch((error) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//CREATE SERVICEPROVIDER
export const setDlPermissionsFullAccess = (userId: string) => {
  return new Promise((resolve, reject) => {
    const uploadUrl =
      URL_TO_AUTH_API + '/v1/alpha/serviceprovider/' + userId + '/fullAccess';

    authAxios
      .patch(uploadUrl)
      .then((result) => {
        console.log(result);
        const user: User = result && result.data ? result.data : [];
        resolve(user);
      })
      .catch((error) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};
