import _ from 'lodash';
import TreeNode from 'primereact/treenode';
import { TreeSelect, TreeSelectSelectionKeys } from 'primereact/treeselect';
import { useEffect, useState } from 'react';
import { useId } from 'react-id-generator';
import { v4 as uuidv4 } from 'uuid';
import { LiegenschaftsData } from '../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import { EMPTY_FIELD_PLACEHOLDER } from '../../Helper/Statics/Constants';
import BeeTextInput from './BeeTextInput';
import './BeeTreeSelectLiegenschaften.scss';

type BeeTreeSelectLiegenschaftenProps = {
  id?: string;
  label?: any;
  value: any;
  options?: LiegenschaftsData[];
  disabled?: boolean;
  formstate?: string;
  readOnly?: boolean;
  onChange: (e: TreeSelectSelectionKeys) => void;
};

export function BeeTreeSelectLiegenschaften({
  id,
  label,
  value,
  options,
  disabled,
  readOnly,
  onChange
}: BeeTreeSelectLiegenschaftenProps) {
  const [data, setData] = useState<TreeNode[]>([]);
  const genId = useId(1, 'bee-drop-down-')[0];
  const currentId = id ? id : genId;
  const inputId = currentId + 'input';

  useEffect(() => {
    setData(reworkPayload(options));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  function change(value: TreeSelectSelectionKeys) {
    if (!disabled && onChange) {
      onChange(value);
    }
  }

  function reworkPayload(options?: LiegenschaftsData[]) {
    if (options) {
      let ids: any = {};
      const pay: TreeNode[] = [];
      //sort array by land, region, city
      const sorted: LiegenschaftsData[] = _.sortBy(options, [
        'adresse.land',
        'adresse.region',
        'adresse.stadt',
        'nummer'
      ]);
      //loop and build tree
      for (let i = 0; i < sorted.length; i++) {
        const property: LiegenschaftsData = sorted[i];
        const countryName = property.adresse?.land;
        const regionName = property.adresse?.region;
        const cityName = property.adresse?.stadt;
        const propId = property.id ? property.id : '';
        if (pay.length === 0) {
          const countryId = uuidv4();
          const regionId = uuidv4();
          const cityId = uuidv4();
          ids[countryId] = { checked: true };
          ids[regionId] = { checked: true };
          ids[cityId] = { checked: true };
          ids[propId] = { checked: true };
          //create this country
          //create this region
          //create this city
          pay.push({
            id: countryId,
            key: countryId,
            label: countryName,
            data: countryName,
            children: [
              {
                id: regionId,
                key: regionId,
                label: regionName,
                data: regionName,
                children: [
                  {
                    id: cityId,
                    key: cityId,
                    label: cityName,
                    data: cityName,
                    children: [
                      {
                        id: property.id,
                        key: property.id,
                        label: property.nummer + ' ' + property.name,
                        data: property,
                        selectable: true
                      }
                    ]
                  }
                ]
              }
            ]
          });
        } else {
          const entry = _.find(pay, function (e: TreeNode) {
            return e.data === countryName;
          });
          if (entry) {
            //we found the country => test region
            const region = _.find(entry.children, function (e: TreeNode) {
              return e.data === regionName;
            });
            if (region) {
              //we found region => test city
              const city = _.find(region.children, function (e: TreeNode) {
                return e.data === cityName;
              });
              if (city) {
                //if property is not contained > create it
                if (
                  !_.find(city.children, function (e: TreeNode) {
                    return e.id === property.id;
                  })
                ) {
                  ids[propId] = { checked: true };
                  city.children?.push({
                    id: property.id,
                    key: property.id,
                    label: property.nummer + ' ' + property.name,
                    data: property,
                    selectable: true
                  });
                }
              } else {
                const cityId = uuidv4();
                ids[cityId] = { checked: true };
                ids[propId] = { checked: true };
                // add city to this region
                region.children?.push({
                  id: cityId,
                  key: cityId,
                  label: cityName,
                  data: cityName,
                  children: [
                    {
                      id: property.id,
                      key: property.id,
                      label: property.nummer + ' ' + property.name,
                      data: property,
                      selectable: true
                    }
                  ]
                });
              }
            } else {
              const regionId = uuidv4();
              const cityId = uuidv4();
              ids[regionId] = { checked: true };
              ids[cityId] = { checked: true };
              ids[propId] = { checked: true };
              // add region to this entry
              // add city to this entry
              entry.children?.push({
                id: regionId,
                key: regionId,
                label: regionName,
                data: regionName,
                children: [
                  {
                    id: cityId,
                    key: cityId,
                    label: cityName,
                    data: cityName,
                    children: [
                      {
                        id: property.id,
                        key: property.id,
                        label: property.nummer + ' ' + property.name,
                        data: property,
                        selectable: true
                      }
                    ]
                  }
                ]
              });
            }
          } else {
            const countryId = uuidv4();
            const regionId = uuidv4();
            const cityId = uuidv4();
            ids[countryId] = { checked: true };
            ids[regionId] = { checked: true };
            ids[cityId] = { checked: true };
            ids[propId] = { checked: true };
            //create this country
            //create this region
            //create this city
            pay.push({
              id: countryId,
              key: countryId,
              label: countryName,
              data: countryName,
              children: [
                {
                  id: regionId,
                  key: regionId,
                  label: regionName,
                  data: regionName,
                  children: [
                    {
                      id: cityId,
                      key: cityId,
                      label: cityName,
                      data: cityName,
                      children: [
                        {
                          id: property.id,
                          key: property.id,
                          label: property.nummer + ' ' + property.name,
                          data: property,
                          selectable: true
                        }
                      ]
                    }
                  ]
                }
              ]
            });
          }
        }
      }
      change(ids);
      return pay;
    } else {
      return [];
    }
  }

  const renderPlaceholder = () => {
    return (
      <BeeTextInput
        label={label}
        value={EMPTY_FIELD_PLACEHOLDER}
        disabled={disabled}
        readOnly={true}
        required={false}
        onChange={() => {}}
      />
    );
  };

  return (
    <>
      {readOnly && !value ? (
        renderPlaceholder()
      ) : (
        <div
          id={currentId}
          className={'p-float-label bee-treeselect-liegenschaften'}
        >
          <TreeSelect
            value={value}
            options={data}
            selectionMode="checkbox"
            onChange={(e) => {
              console.log(e.value);
              change(e.value);
            }}
          />

          {label ? <label>{label}</label> : null}
        </div>
      )}
    </>
  );
}

export default BeeTreeSelectLiegenschaften;
