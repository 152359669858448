import _ from 'lodash';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { LiegenschaftsData } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import {
  DEFAULT_CALCULATION_MULTIPLIER,
  LEISTUNGSART,
  LeistungsSlices,
  MAX_NUMBER_OF_DECIMALS,
  MAX_VALUE_FACTOR_LIEGENSCHAFTEN,
  MIN_NUMBER_OF_DECIMALS,
  MIN_VALUE_FACTOR_LIEGENSCHAFTEN,
  SERVER_RESPONSE_ERROR,
  SERVER_RESPONSE_PENDING
} from '../../../Helper/Statics/Constants';
import {
  generateBlFactorId,
  generateLsFactorId
} from '../../../Helper/Util/IdGeneratorHelper';
import BeeIcon from '../../Atoms/BeeIcon';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import BeeNumberInput from '../../Atoms/BeeNumberInput';
import { TimelineStep } from '../../Atoms/BeeTimeline';
import { FaktorKeyValueStore } from './DlBundeslandfaktoren';
import './DLPropertyFactor.scss';

type DLPropertyFactorProps = {
  phase?: TimelineStep;
  property?: LiegenschaftsData;
  readOnly: boolean;
  disabled: boolean;
  keyValueStore: {
    [key: string]: FaktorKeyValueStore;
  };
  propRegionId: string;
  changeCellInput: (val: number | null, valueId: string) => void;
};

export default function DLPropertyFactor({
  phase,
  property,
  readOnly,
  disabled,
  keyValueStore,
  propRegionId,
  changeCellInput
}: DLPropertyFactorProps) {
  //////////////
  /// HELPER ///
  //////////////

  function renderNumberInput(valueId: string) {
    return keyValueStore && keyValueStore[valueId] ? (
      <BeeNumberInput
        value={
          keyValueStore[valueId] && keyValueStore[valueId].value
            ? _.divide(
                keyValueStore[valueId].value,
                DEFAULT_CALCULATION_MULTIPLIER
              )
            : undefined
        }
        autoFocus={false}
        min={MIN_VALUE_FACTOR_LIEGENSCHAFTEN}
        max={MAX_VALUE_FACTOR_LIEGENSCHAFTEN}
        minFractionDigits={MIN_NUMBER_OF_DECIMALS}
        maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
        disabled={disabled}
        grouping={false}
        formstate={'neutral'}
        readOnly={readOnly || disabled}
        required={false}
        onChange={(e) => {
          console.log(e.target.value);
          e.target.value
            ? changeCellInput(
                _.round(
                  _.multiply(e.target.value, DEFAULT_CALCULATION_MULTIPLIER)
                ),
                valueId
              )
            : changeCellInput(e.target.value, valueId);
        }}
      />
    ) : (
      injectLoadingSpinner()
    );
  }

  const renderBodyTemplate = (
    slice: LEISTUNGSART,
    regionBlock: boolean,
    sustainable: boolean
  ) => {
    let valueId: string = '';
    //search id
    if (phase && propRegionId && property) {
      if (regionBlock) {
        valueId = generateBlFactorId(phase, propRegionId, slice);
      } else {
        valueId = generateLsFactorId(phase, property, sustainable, slice);
      }
    }
    if (
      keyValueStore &&
      keyValueStore[valueId] &&
      (keyValueStore[valueId].value || keyValueStore[valueId].value === 0)
    ) {
      const classNames =
        keyValueStore[valueId].process &&
        keyValueStore[valueId].process === SERVER_RESPONSE_ERROR
          ? 'bl-col tooltip-factor-bl'
          : 'bl-col-body';
      return (
        <>
          <span className={readOnly ? classNames + ' disabled' : classNames}>
            <BeeNumberInput
              value={_.divide(
                keyValueStore[valueId].value,
                DEFAULT_CALCULATION_MULTIPLIER
              )}
              grouping={false}
              readOnly={true}
            />
          </span>
          {/* bundesland blocking icon */}
          {regionBlock ? (
            <span className={'icon-locked-input error '}>
              <i className={'icon-lock '}></i>
            </span>
          ) : null}
          {/* process status */}
          {keyValueStore[valueId].process === SERVER_RESPONSE_PENDING ? (
            <span className={'status-factorChange pending'}>
              <BeeLoadingSpinner strokeWidth={'3'} type={'primary'} />
            </span>
          ) : keyValueStore[valueId].process === SERVER_RESPONSE_ERROR ? (
            <>
              <span className={'status-factorChange error  tooltip-factor-bl'}>
                <BeeIcon
                  iconClass={'pi pi-exclamation-triangle'}
                  type={'primary'}
                />
                <span>{'Fehler'}</span>
              </span>
              <Tooltip
                target=".tooltip-factor-bl"
                mouseTrack
                mouseTrackLeft={10}
              >
                {
                  'Die Änderung konnte nicht gespeichert werden. Bitte prüfen Sie Ihre Internetverbindung.'
                }
              </Tooltip>
            </>
          ) : null}
        </>
      );
    } else {
      return (
        <>
          <span> - </span>
          {regionBlock ? (
            <span className={'status-factorChange error tooltip-factor-bl'}>
              <BeeIcon iconClass={'pi pi-lock'} type={'primary'} />
            </span>
          ) : null}
        </>
      );
    }
  };

  function injectLoadingSpinner() {
    return (
      <span className={'load-factor pending'}>
        <BeeLoadingSpinner strokeWidth={'3'} type={'primary'} />
      </span>
    );
  }

  ///////////////////////
  /// VIEW COMPONENTS ///
  ///////////////////////

  const bodyTemplate = (rowData: any) => {
    if (rowData) {
      return (
        <div className={'dl-property-factor-header-leistungsgr'}>
          <i className={rowData.icon} /> <span>{rowData.title}</span>
        </div>
      );
    }
    return <span />;
  };

  const blockedTemplate = () => {
    return (
      <span className={'readOnly'}>
        <BeeIcon iconClass={'pi pi-times'} type={'primary'} />
      </span>
    );
  };

  return (
    <div
      className={
        disabled ? 'dl-property-factor disabled' : 'dl-property-factor '
      }
    >
      <DataTable
        disabled={disabled}
        readOnly={readOnly || disabled ? true : false}
        value={LeistungsSlices}
        size="small"
        responsiveLayout="stack"
        breakpoint="560px"
      >
        <Column
          header="Faktor"
          body={bodyTemplate}
          className="dl-property-factor-facCol"
        />
        <Column
          header={
            <span>
              <i className={'icon-country'} /> <span>{'Bundesland'}</span>
            </span>
          }
          body={(rowData: any) => renderBodyTemplate(rowData, true, false)}
        />
        <Column
          header={
            <span>
              <i className={'icon-company'} /> <span>{'Liegenschaft'}</span>
            </span>
          }
          body={(rowData: any) => renderBodyTemplate(rowData, false, false)}
          editor={(col: ColumnEditorOptions) => {
            if (property && phase && keyValueStore) {
              let valId = generateLsFactorId(
                phase!,
                property!,
                false,
                col.rowData
              );
              return !readOnly &&
                !(
                  keyValueStore[valId] &&
                  keyValueStore[valId].process === SERVER_RESPONSE_PENDING
                )
                ? renderNumberInput(valId)
                : renderBodyTemplate(col.rowData, false, false);
            }
          }}
        />
        <Column
          header={
            <span>
              <i className={'icon-sustainability'} />{' '}
              <span>{'Nachhaltigkeit'}</span>
            </span>
          }
          body={(rowData: any) =>
            rowData && rowData.sustainability
              ? renderBodyTemplate(rowData, false, true)
              : blockedTemplate()
          }
          editor={(col: ColumnEditorOptions) => {
            const sustainabilityFactorAllowed =
              col && col.rowData && col.rowData.sustainability;
            if (property && phase && keyValueStore) {
              let valId = generateLsFactorId(
                phase!,
                property!,
                true,
                col.rowData
              );
              return !readOnly &&
                !disabled &&
                sustainabilityFactorAllowed &&
                !(
                  keyValueStore[valId] &&
                  keyValueStore[valId].process === SERVER_RESPONSE_PENDING
                )
                ? renderNumberInput(valId)
                : sustainabilityFactorAllowed
                ? renderBodyTemplate(col.rowData, false, true)
                : blockedTemplate();
            }
          }}
        />
      </DataTable>
    </div>
  );
}
