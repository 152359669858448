import _ from 'lodash';
import { Column } from 'primereact/column';
import TreeNode from 'primereact/treenode';
import { TreeTable, TreeTableExpandedKeysType } from 'primereact/treetable';
import { useEffect, useRef, useState } from 'react';
import { EpPosition } from '../../../Helper/ApiHelper/LvEpNetworkHelper';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import { TimelineStep } from '../../Atoms/BeeTimeline';
import './AlphaAnalysisEPTable.scss';
import { AuthUserType } from '../../../Helper/ApiHelper/LoginNetworkHelper';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { simpleGenerateEpEntryId } from '../../../Helper/Util/IdGeneratorHelper';
import BeeCurrencyInput from '../../Atoms/BeeCurrencyInput';
import {
  DEFAULT_CALCULATION_MULTIPLIER,
  DURATION_NOTIFICATION_ERROR_LONG,
  MAX_NUMBER_OF_DECIMALS
} from '../../../Helper/Statics/Constants';
import BeeOutlinedButton from '../../Atoms/BeeOutlinedButton';
import { exportAlphaPricingExcel } from '../../../Helper/StorageHelper/ExcelExportAlphaPricing';
import { Toast } from 'primereact/toast';

type AlphaAnalysisEPTableProps = {
  phase: TimelineStep;
  epTree: any;
  valueLookup: Map<string, any>;
  selectedProvider: AuthUserType[];
};

export default function AlphaAnalysisEPTable({
  phase,
  epTree,
  valueLookup,
  selectedProvider
}: AlphaAnalysisEPTableProps) {
  const [columns, setColumns] = useState<any>([]);
  const [expanded, setExpanded] = useState<TreeTableExpandedKeysType>();
  const toast = useRef<Toast>(null);

  useEffect(() => {
    let cols: any = [];
    if (selectedProvider) {
      selectedProvider.forEach((p: AuthUserType) => {
        cols.push({ id: p.id, header: p.organisation });
      });
    }
    setColumns(cols);
  }, [selectedProvider]);

  ///////////////////////
  //////// LOGIC ////////
  ///////////////////////

  function search(lookupKey: string) {
    return valueLookup.get(lookupKey);
  }

  ///////////////////////
  /// VIEW COMPONENTS ///
  ///////////////////////

  function calculateRowClassName(rowData: TreeNode) {
    return {
      'alpha-analysis-row-category-tabIndex ': rowData.data.type === 'category',
      'alpha-analysis-row-position-noTabIndex': rowData.data.type === 'position'
    };
  }

  const titleTemplate = (node: any) => {
    const epEntry: EpPosition = node.data;
    return (
      <div className={'alpha-analysis-epTable-title'}>
        <div className={'alpha-analysis-ep-code'}>{epEntry.epCode}</div>
        <div>{`[${epEntry.number}] ${epEntry.title}`}</div>
      </div>
    );
  };

  const epValueTemplate = (node: any, col: any) => {
    const providerId: string = col.props.header.key;
    if (node.data) {
      if (node.data && node.data.type === 'position') {
        const value = search(
          simpleGenerateEpEntryId(phase.id, node.data.epCode)
        );

        return (
          <div className={'alpha-analysis-epTable-ep-value'}>
            <BeeCurrencyInput
              value={
                !value || !value[providerId]
                  ? null
                  : value[providerId] === 0
                  ? 0
                  : _.divide(value[providerId], DEFAULT_CALCULATION_MULTIPLIER)
              }
              disabled={false}
              minFractionDigits={MAX_NUMBER_OF_DECIMALS}
              maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
              formstate={'none'}
              readOnly={true}
            />
          </div>
        );
      }
    }
    return '';
  };

  const epDiffTemplate = (node: any, col: any) => {
    const providerId: string = col.props.header.key;
    if (node.data) {
      if (node.data && node.data.type === 'position') {
        const value = search(
          simpleGenerateEpEntryId(phase.id, node.data.epCode)
        );
        if (!(value && value[providerId])) {
          return <div> {'—'} </div>;
        }
        let lowestPrice: number = 1000000000000; //one billion euro max
        selectedProvider.forEach((sp) => {
          const vString = value[sp.id];
          if (vString) {
            const vNum = parseInt(vString);
            if (_.isNumber(vNum)) {
              lowestPrice = lowestPrice < vNum ? lowestPrice : vNum;
            }
          }
        });
        const proValue = value[providerId];
        const calcValue = proValue
          ? _.round((proValue / lowestPrice) * 100)
          : null;
        const className =
          calcValue && calcValue === 100
            ? 'valid-background'
            : 'error-background';
        return (
          <div className={className}>{calcValue ? calcValue + ' %' : ''}</div>
        );
      }
    }
    return '';
  };

  const dynamicPhaseColumns = columns.map((col: any, i: any) => {
    const factorUI = {
      className: 'alpha-epTable-epPrice-col',
      key: col.id + col.header + '_factor',
      body: epValueTemplate,
      width: '10em'
    };
    const diffUI = {
      className: 'alpha-epTable-diff-col',
      key: col.id + col.header + '_diff',
      body: epDiffTemplate,
      width: '7em'
    };
    const values = [factorUI, diffUI];
    return values.map((e, index) => {
      return (
        <Column
          key={
            e.key
              ? index + 'aAnalaysis-epTable-col_' + e.key
              : index + 'aAnalaysis-epTable-col_'
          }
          columnKey={
            e.key
              ? index + 'aAnalaysis-epTable-colkey_' + e.key
              : index + 'aAnalaysis-epTable-colkey_'
          }
          className={e.className}
          body={e.body}
          header={<div key={col.id}>{col.header}</div>}
          style={{ width: e.width }}
        />
      );
    });
  });

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          header="Position"
          columnKey={'analysis-ep-table-dl_pos'}
          rowSpan={2}
          colSpan={1}
          className={'ep-position-col frozen-col '}
          style={{ width: '32em' }}
        />
        {columns.map((col: any, index: number) => {
          return [1, 2].map((e) => {
            return e === 1 ? (
              <Column
                header={<div style={{ width: '14em' }}>{col.header}</div>}
                columnKey={'analysis-ep-table-dl' + e + index}
                className="analysis-ep-table-dl-header"
                colSpan={1}
                style={{ width: '10em' }}
              />
            ) : (
              <Column
                colSpan={1}
                style={{ width: '7em' }}
                columnKey={'analysis-ep-table-dl' + e + index}
              />
            );
          });
        })}
      </Row>
      <Row>
        {columns.map((col: any, i: number) => {
          const values = [
            { title: 'EP', width: '10em' },
            { title: '', width: '7em' }
          ];
          return values.map((e, index) => {
            return (
              <Column
                header={e.title}
                style={{ width: e.width }}
                columnKey={'analysis-ep-table-dl-content' + index + i}
              />
            );
          });
        })}
      </Row>
    </ColumnGroup>
  );

  return (
    <div className={'alpha-analysis-epTable'}>
      <BeeContentHeadline
        label={'EP-Katalog'}
        headline={'h2'}
        type={'secondary'}
      />
      <div className="mb-2">
        Folgende Übersicht zeigt die Rahmenpreise der in obigem Filter
        ausgewählten Dienstleister im Vergleich.
      </div>
      <TreeTable
        value={epTree}
        expandedKeys={expanded}
        rowClassName={(rowData: TreeNode) => calculateRowClassName(rowData)}
        headerColumnGroup={headerGroup}
        emptyMessage={'Keine Position gefunden'}
        onToggle={(e) => setExpanded(e.value)}
        className={'alpha-analysis-epTable-table'}
        scrollable
        style={{ width: '100%' }}
      >
        <Column
          field={'title'}
          columnKey={'alpha-analysis-epTable-title-col'}
          body={titleTemplate}
          header={
            <div className={'ep-table-title'}>
              <div>{'Position'}</div>
            </div>
          }
          className={'ep-position-col frozen-col '}
          expander
          style={{ width: '32em' }}
        />
        {dynamicPhaseColumns}
      </TreeTable>
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
