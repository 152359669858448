import { Chart } from 'primereact/chart';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import {
  LeistungsSlices,
  MAX_NUMBER_OF_DECIMALS,
  tags
} from '../../../Helper/Statics/Constants';
import BeeValidatedCurrencyInput from '../../Atoms/BeeValidatedCurrencyInput';
import './DLOverviewAnalytics.scss';
import _ from 'lodash';

type DLOverviewAnalyticsProps = {
  lvData?: Map<string, number>;
  showTable: boolean;
  chartType: 'pie' | 'bar';
  disabled: boolean;
  readOnly: boolean;
};

type LeistungsartTableEntry = {
  leistungsart: string;
  sum: number;
  child?: boolean;
};

type chartDataSetBar = {
  title: string[];
  values: number[];
  colors: string[];
  backgroundColors: string[];
};

export default function DLOverviewAnalytics({
  lvData,
  showTable,
  chartType,
  disabled,
  readOnly
}: DLOverviewAnalyticsProps) {
  const [initData, setInitData] = useState<Map<string, number>>();
  const [chartDataSetPie, setChartDataSetPie] = useState<number[]>([]);
  const [chartDataSetBar, setChartDataSetBar] = useState<chartDataSetBar[]>([]);
  const [leistungsartenLabelPie, setLeistungsartenLabelPie] = useState<
    string[]
  >([]);
  const [leistungsartenLabelBar, setLeistungsartenLabelBar] = useState<
    string[]
  >([]);
  const [chartColorsPie, setChartColorsPie] = useState<string[]>([]);
  const [chartBackgroundColorsPie, setChartBackgroundColorsPie] = useState<
    string[]
  >([]);
  const [tableData, setTableData] = useState<LeistungsartTableEntry[]>([]);

  useEffect(() => {
    if (!_.isEqual(lvData, initData)) {
      setInitData(lvData);
      const l = LeistungsSlices;
      const currLeistungsartenLabelPie: string[] = [];
      const currLeistungsartenLabelBar: string[] = [];
      const currDataSetPie: number[] = [];
      let currDataSetBar: chartDataSetBar[] = [];
      const currColorsPie: string[] = [];
      const currBackgroundColorsPie: string[] = [];
      const currTableData: LeistungsartTableEntry[] = [];
      let sum = 0;
      if (lvData) {
        let gesamtsumme = 0;
        for (let i = 0; i < l.length; i++) {
          //get color from stylesheet
          const colorVar = getComputedStyle(
            document.documentElement
          ).getPropertyValue('--diagram-color-' + i);
          //subgroups
          if (l[i].tag && l[i].tag.length && l[i].tag.length > 1) {
            //datatable
            let groupEntry = {
              leistungsart: l[i].title,
              sum: 0,
              child: false
            };
            currTableData.push(groupEntry);
            let subSum = 0;
            currLeistungsartenLabelBar.push(l[i].title);
            //Dataset Bar
            if (!currDataSetBar[0]) {
              currDataSetBar[0] = {
                title: [],
                values: [],
                colors: [],
                backgroundColors: []
              };
            }
            currDataSetBar[0].title.push(
              l[i].title.replace('management', 'mgmt')
            );
            currDataSetBar[0].colors.push(colorVar);
            currDataSetBar[0].backgroundColors.push(colorVar + '88');
            //every subgroup
            for (let j = 0, k = 14; j < l[i].tag.length; j++, k--) {
              const currTag = l[i].tag[j];
              const share = lvData.get(currTag);
              //fixme
              const allTags: any = tags;
              const label = allTags[currTag];
              //title
              const combinedLabel =
                l[i].title.replace('management', 'mgmt') +
                ' - ' +
                label.replace('management', 'mgmt');
              currLeistungsartenLabelPie.push(combinedLabel);
              //chart data
              currDataSetPie.push(share ? share : 0);
              //colors
              currColorsPie.push(
                colorVar.slice(0, 5) + k.toString(16) + k.toString(16)
              );
              currBackgroundColorsPie.push(colorVar + '88');
              //table
              const currTableEntryBase: LeistungsartTableEntry = {
                leistungsart: label,
                sum: share ? share : 0,
                child: true
              };
              currTableData.push(currTableEntryBase);
              subSum = share ? subSum + share : subSum;
            }
            groupEntry.sum = subSum;
            gesamtsumme = subSum ? gesamtsumme + subSum : gesamtsumme;
            currDataSetBar[0].values.push(gesamtsumme ? gesamtsumme : 0);
          } else {
            if (!currDataSetBar[0]) {
              currDataSetBar[0] = {
                title: [],
                values: [],
                colors: [],
                backgroundColors: []
              };
            }
            const share = lvData.get(l[i].tag[0]) ? lvData.get(l[i].tag[0]) : 0;
            //title
            currLeistungsartenLabelPie.push(
              l[i].title.replace('management', 'mgmt')
            );
            currLeistungsartenLabelBar.push(
              l[i].title.replace('management', 'mgmt')
            );
            currDataSetBar[0].title.push(
              l[i].title.replace('management', 'mgmt')
            );
            //chart data
            currDataSetPie.push(share ? share : 0);
            currDataSetBar[0].values.push(share ? share : 0);

            //colors
            currColorsPie.push(colorVar);
            currBackgroundColorsPie.push(colorVar + '88');
            currDataSetBar[0].colors.push(colorVar);
            currDataSetBar[0].backgroundColors.push(colorVar + '88');
            //table
            const currTableEntry: LeistungsartTableEntry = {
              leistungsart: l[i].title,
              sum: share ? share : 0
            };
            currTableData.push(currTableEntry);
            //sum
            sum = share ? sum + share : sum;
            gesamtsumme = share ? gesamtsumme + share : gesamtsumme;
          }
        }
        const sumTableEntry: LeistungsartTableEntry = {
          leistungsart: 'Gesamtsumme',
          sum: gesamtsumme
        };
        currTableData.push(sumTableEntry);
        setLeistungsartenLabelPie(currLeistungsartenLabelPie);
        setLeistungsartenLabelBar(currLeistungsartenLabelBar);
        setChartDataSetPie(currDataSetPie);
        setChartDataSetBar(currDataSetBar);
        setChartColorsPie(currColorsPie);
        setChartBackgroundColorsPie(currBackgroundColorsPie);
        setTableData(currTableData);
      } else {
        //no data for lv -> default diagram
        setLeistungsartenLabelPie(['']);
        setLeistungsartenLabelBar(['']);
        setChartDataSetPie([100]);
        setChartDataSetBar([
          {
            title: [''],
            values: [100, 400],
            colors: ['lightgrey', 'lightgrey'],
            backgroundColors: ['lightgrey', 'lightgrey']
          }
        ]);
        setChartColorsPie(['lightgrey']);
        setChartBackgroundColorsPie(['lightgrey']);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lvData]);

  const pieData = {
    labels: leistungsartenLabelPie,
    datasets: [
      {
        data: chartDataSetPie,
        backgroundColor: chartColorsPie,
        hoverBackgroundColor: chartBackgroundColorsPie
      }
    ]
  };

  const barData = {
    labels: leistungsartenLabelBar,
    datasets: chartDataSetBar.map((dataSet, i) => {
      return {
        type: 'bar',
        label: dataSet.title && dataSet.title.length > 1 ? '' : dataSet.title,
        data: dataSet.values,
        backgroundColor: dataSet.colors,
        hoverBackgroundColor: dataSet.backgroundColors
      };
    })
  };

  const barOptions = {
    responsive: true,
    readOnly: readOnly || disabled,
    disabled: disabled,
    maintainAspectRatio: false,
    aspectRatio: 0.7,
    scales: {
      yAxis: {
        position: 'right'
      }
    },
    plugins: {
      legend: {
        display: false
      }
    },
    layout: {
      padding: {
        top: 0
      }
    }
  };

  const pieOptions = {
    responsive: true,
    readOnly: readOnly || disabled,
    disabled: disabled,
    maintainAspectRatio: true,
    layout: {
      padding: {
        top: 0
      }
    },
    plugins: {
      legend: {
        position: 'right'
      }
    }
  };

  function renderDiagram() {
    const isAllZero = pieData.datasets[0].data.every((item) => item === 0);
    if (isAllZero) {
      const emptyPieData = {
        labels: [],
        datasets: [
          {
            data: [100],
            backgroundColor: ['lightgrey']
          }
        ]
      };
      return (
        <Chart
          type={
            chartType === 'pie' ? 'pie' : chartType === 'bar' ? 'bar' : 'pie'
          }
          data={
            chartType === 'pie'
              ? emptyPieData
              : chartType === 'bar'
              ? barData
              : emptyPieData
          }
          options={
            chartType === 'pie'
              ? pieOptions
              : chartType === 'bar'
              ? barOptions
              : pieOptions
          }
        />
      );
    }
    return (
      <Chart
        type={chartType === 'pie' ? 'pie' : chartType === 'bar' ? 'bar' : 'pie'}
        data={
          chartType === 'pie'
            ? pieData
            : chartType === 'bar'
            ? barData
            : pieData
        }
        options={
          chartType === 'pie'
            ? pieOptions
            : chartType === 'bar'
            ? barOptions
            : pieOptions
        }
      />
    );
  }

  const sumPriceTemplate = (rowData: LeistungsartTableEntry) => {
    return (
      <div className={rowData.child ? 'table-child' : ''}>
        <BeeValidatedCurrencyInput
          label={''}
          value={rowData.sum}
          minFractionDigits={MAX_NUMBER_OF_DECIMALS}
          maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
          disabled={true}
          readOnly={true}
          required={false}
        />
      </div>
    );
  };

  const leistungsartTemplate = (rowData: LeistungsartTableEntry) => {
    return (
      <div className={rowData.child ? 'table-child' : ''}>
        {rowData.leistungsart}
      </div>
    );
  };

  function renderTable() {
    return (
      <div className={'leistungsart-sum-table'}>
        <DataTable
          value={tableData}
          responsiveLayout={'scroll'}
          emptyMessage={'Keine Preise vorhanden'}
        >
          <Column
            field="leistungsart"
            header="Leistungsart"
            body={leistungsartTemplate}
            className={'table-leistungsart-col'}
          />
          <Column
            field="sum"
            header="Summe"
            body={sumPriceTemplate}
            className="table-sum-col"
          />
        </DataTable>
      </div>
    );
  }

  return (
    <div
      className={
        disabled
          ? 'dl-overview-analytics grid disabled'
          : 'dl-overview-analytics grid'
      }
    >
      <div
        className={
          showTable
            ? 'col-12 sm:col-12 md:col-12 lg:col-8 dl-overview-analytics-chart'
            : 'col-12 dl-overview-analytics-chart'
        }
      >
        {renderDiagram()}
      </div>
      {showTable ? (
        <div
          className={
            'col-12 sm:col-12 md:col-12 lg:col-4 dl-overview-analytics-table'
          }
        >
          {renderTable()}
        </div>
      ) : null}
    </div>
  );
}
