import { useState } from 'react';

import { useId } from 'react-id-generator';
import BeeErrorLabel from './BeeErrorLabel';
import BeeInfoLabel from './BeeInfoLabel';

import './BeeNutzungsartMixHeizenergie.scss';
import BeeSlider from './BeeSlider';

type BeeNutzungsartMixHeizenergieProps = {
  label?: string;
  errorLabel?: string | null;
  neutralLabel?: string | null;
  validLabel?: string | null;
  aFernwaerme: number;
  aGas: number;
  aOel: number;
  aWaermepumpe: number;
  aHolz: number;
  aSonstiges: number;
  type: string;
  forceRerenderToggle?: boolean;
  disabled?: boolean;
  formstate?: string;
  readOnly?: boolean;
  required?: boolean;
  onChange: (e: {
    aFernwaerme: number;
    aGas: number;
    aOel: number;
    aWaermepumpe: number;
    aHolz: number;
    aSonstiges: number;
  }) => void;
};

export function BeeNutzungsartMixHeizenergie({
  label,
  disabled,
  forceRerenderToggle,
  aFernwaerme,
  aGas,
  aOel,
  aWaermepumpe,
  aHolz,
  aSonstiges,
  type,
  formstate,
  errorLabel,
  neutralLabel,
  validLabel,
  readOnly,
  required,
  onChange
}: BeeNutzungsartMixHeizenergieProps) {
  const currentId = useId(1, 'bee-nutzungsart-mix-')[0];

  const [anteilFernwaerme, setAnteilFernwaerme] = useState(
    aFernwaerme ? aFernwaerme : 0
  );
  const [anteilGas, setAnteilGas] = useState(aGas ? aGas : 0);
  const [anteilOel, setAnteilOel] = useState(aOel ? aOel : 0);
  const [anteilWaermepumpe, setAnteilWaermepumpe] = useState(
    aWaermepumpe ? aWaermepumpe : 0
  );
  const [anteilHolz, setAnteilHolz] = useState(aHolz ? aHolz : 0);
  const [anteilSonstiges, setAnteilSonstiges] = useState(
    aSonstiges ? aSonstiges : 0
  );
  const [forceRerender, setForceRerender] = useState(readOnly);

  //classNames
  const classNames = 'bee-nutzungsart-mix';
  const requiredClassNames = 'bee-nutzungsart-mix-label-required';
  const requiredStarClassNames = 'bee-nutzungsart-mix-label-required-star';
  const labelClassNames = 'bee-nutzungsarten-mix-label';
  const titleColClassNames = 'bee-nutzungsarten-table-title';
  const percentageColClassNames = 'bee-nutzungsarten-table-percentage';
  let readOnlyClassNames = '';

  if (readOnly) {
    readOnlyClassNames = ' bee-nutzungsart-mix-readOnly';
  }
  const disabledClassNames = disabled ? ' bee-nutzungsart-mix-disabled' : '';

  const formStateClassNames =
    formstate === 'valid'
      ? ' bee-nutzungsart-mix-valid'
      : formstate === 'error'
      ? ' bee-nutzungsart-mix-error'
      : formstate === 'neutral'
      ? ' bee-nutzungsart-mix-neutral'
      : ' bee-nutzungsart-mix-neutral';

  const inputClassNames =
    'bee-nutzungsart-mix-input ' +
    readOnlyClassNames +
    disabledClassNames +
    formStateClassNames;

  const validatedLabelClassNames = 'bee-validated-nutzungsart-mix-label';
  const textSize = 'medium';

  function reportNewValues() {
    if (!disabled && onChange) {
      onChange({
        aFernwaerme: anteilFernwaerme,
        aGas: anteilGas,
        aOel: anteilOel,
        aWaermepumpe: anteilWaermepumpe,
        aHolz: anteilHolz,
        aSonstiges: anteilSonstiges
      });
    }
  }

  function changeAnteilFernwaerme(value: number) {
    setAnteilFernwaerme(value);
    let sum =
      anteilFernwaerme +
      anteilGas +
      anteilOel +
      anteilWaermepumpe +
      anteilHolz +
      anteilSonstiges;

    let overflow = 100 - sum;

    adaptAnteilGas(overflow);
  }

  function changeAnteilGas(value: number) {
    setAnteilGas(value);
    let sum =
      anteilFernwaerme +
      anteilGas +
      anteilOel +
      anteilWaermepumpe +
      anteilHolz +
      anteilSonstiges;

    let overflow = 100 - sum;
    adaptAnteilOel(overflow);
  }

  function changeAnteilOel(value: number) {
    setAnteilOel(value);
    let sum =
      anteilFernwaerme +
      anteilGas +
      anteilOel +
      anteilWaermepumpe +
      anteilHolz +
      anteilSonstiges;

    let overflow = 100 - sum;
    adaptAnteilWaermepumpe(overflow);
  }

  function changeAnteilWaermepumpe(value: number) {
    setAnteilWaermepumpe(value);
    let sum =
      anteilFernwaerme +
      anteilGas +
      anteilOel +
      anteilWaermepumpe +
      anteilHolz +
      anteilSonstiges;

    let overflow = 100 - sum;
    adaptAnteilHolz(overflow);
  }

  function changeAnteilHolz(value: number) {
    setAnteilHolz(value);
    let sum =
      anteilFernwaerme +
      anteilGas +
      anteilOel +
      anteilWaermepumpe +
      anteilHolz +
      anteilSonstiges;

    let overflow = 100 - sum;
    adaptAnteilSonstiges(overflow);
  }

  function changeAnteilSonstiges(value: number) {
    setAnteilSonstiges(value);
    let sum =
      anteilFernwaerme +
      anteilGas +
      anteilOel +
      anteilWaermepumpe +
      anteilHolz +
      anteilSonstiges;

    let overflow = 100 - sum;
    adaptAnteilFernwaerme(overflow);
  }

  function adaptAnteilFernwaerme(progress: number) {
    let change = anteilFernwaerme + progress;
    setAnteilFernwaerme(change < 0 ? 0 : change);
    if (change < 0) {
      adaptAnteilGas(change);
    }
  }

  function adaptAnteilGas(progress: number) {
    let change = anteilGas + progress;
    setAnteilGas(change < 0 ? 0 : change);
    if (change < 0) {
      adaptAnteilOel(change);
    }
  }

  function adaptAnteilOel(progress: number) {
    let change = anteilOel + progress;
    setAnteilOel(change < 0 ? 0 : change);
    if (change < 0) {
      adaptAnteilWaermepumpe(change);
    }
  }

  function adaptAnteilWaermepumpe(progress: number) {
    let change = anteilWaermepumpe + progress;
    setAnteilWaermepumpe(change < 0 ? 0 : change);
    if (change < 0) {
      adaptAnteilHolz(change);
    }
  }

  function adaptAnteilHolz(progress: number) {
    let change = anteilHolz + progress;
    setAnteilHolz(change < 0 ? 0 : change);
    if (change < 0) {
      adaptAnteilSonstiges(change);
    }
  }

  function adaptAnteilSonstiges(progress: number) {
    let change = anteilSonstiges + progress;
    setAnteilSonstiges(change < 0 ? 0 : change);
    if (change < 0) {
      adaptAnteilFernwaerme(change);
    }
  }

  const injectFernwaermeSlider = () => {
    return (
      <BeeSlider
        type={type}
        value={anteilFernwaerme}
        min={0}
        max={100}
        step={1}
        onChange={(e: any) => changeAnteilFernwaerme(e.value)}
        onSlideEnd={(e: any) => {
          changeAnteilFernwaerme(e.value);
          reportNewValues();
        }}
      />
    );
  };

  const injectGasSlider = () => {
    return (
      <BeeSlider
        type={type}
        value={anteilGas}
        min={0}
        max={100}
        step={1}
        onChange={(e: any) => changeAnteilGas(e.value)}
        onSlideEnd={(e: any) => {
          changeAnteilGas(e.value);
          reportNewValues();
        }}
      />
    );
  };

  const injectOelSlider = () => {
    return (
      <BeeSlider
        type={type}
        value={anteilOel}
        min={0}
        max={100}
        step={1}
        onChange={(e: any) => changeAnteilOel(e.value)}
        onSlideEnd={(e: any) => {
          changeAnteilOel(e.value);
          reportNewValues();
        }}
      />
    );
  };

  const injectWaermepumpeSlider = () => {
    return (
      <BeeSlider
        type={type}
        value={anteilWaermepumpe}
        min={0}
        max={100}
        step={1}
        onChange={(e: any) => changeAnteilWaermepumpe(e.value)}
        onSlideEnd={(e: any) => {
          changeAnteilWaermepumpe(e.value);
          reportNewValues();
        }}
      />
    );
  };

  const injectHolzSlider = () => {
    return (
      <BeeSlider
        type={type}
        value={anteilHolz}
        min={0}
        max={100}
        step={1}
        onChange={(e: any) => changeAnteilHolz(e.value)}
        onSlideEnd={(e: any) => {
          changeAnteilHolz(e.value);
          reportNewValues();
        }}
      />
    );
  };
  const injectSonstigeSlider = () => {
    return (
      <BeeSlider
        type={type}
        value={anteilSonstiges}
        min={0}
        max={100}
        step={1}
        onChange={(e: any) => changeAnteilSonstiges(e.value)}
        onSlideEnd={(e: any) => {
          changeAnteilSonstiges(e.value);
          reportNewValues();
        }}
      />
    );
  };

  const renderLabel = () => {
    return (
      <div className={validatedLabelClassNames}>
        {formstate === 'error' && errorLabel ? (
          <BeeErrorLabel size={textSize} label={errorLabel} />
        ) : formstate === 'valid' && validLabel ? (
          <BeeInfoLabel size={textSize} label={validLabel} type={'valid'} />
        ) : formstate === 'neutral' && neutralLabel ? (
          <BeeInfoLabel size={textSize} label={neutralLabel} type={'neutral'} />
        ) : null}
      </div>
    );
  };

  function forceRerenderValues() {
    if (forceRerenderToggle !== forceRerender) {
      setForceRerender(forceRerenderToggle);
      setAnteilFernwaerme(aFernwaerme);
      setAnteilGas(aGas);
      setAnteilOel(aOel);
      setAnteilWaermepumpe(aWaermepumpe);
      setAnteilHolz(aHolz);
      setAnteilSonstiges(aSonstiges);
    }
  }

  return (
    <div className={classNames}>
      <>{forceRerenderValues()}</>
      <label className={labelClassNames}>
        {label}
        {required && label ? (
          <span className={requiredClassNames}>
            <span className={requiredStarClassNames}> *</span>
          </span>
        ) : null}
      </label>
      <div id={currentId} className={inputClassNames}>
        <table>
          <tbody>
            <tr>
              <td className={titleColClassNames}>
                <label>{'Fernwärme'}</label>
              </td>
              <td>{injectFernwaermeSlider()}</td>
              <td className={percentageColClassNames}>
                <label>{anteilFernwaerme + '%'}</label>
              </td>
            </tr>
            <tr>
              <td className={titleColClassNames}>
                <label>{'Gas'}</label>
              </td>
              <td>{injectGasSlider()}</td>
              <td className={percentageColClassNames}>
                <label>{anteilGas + '%'}</label>
              </td>
            </tr>
            <tr>
              <td className={titleColClassNames}>
                <label>{'Öl'}</label>
              </td>
              <td>{injectOelSlider()}</td>
              <td className={percentageColClassNames}>
                <label>{anteilOel + '%'}</label>
              </td>
            </tr>
            <tr>
              <td className={titleColClassNames}>
                <label>{'Wärmepumpe'}</label>
              </td>
              <td>{injectWaermepumpeSlider()}</td>
              <td className={percentageColClassNames}>
                <label>{anteilWaermepumpe + '%'}</label>
              </td>
            </tr>
            <tr>
              <td className={titleColClassNames}>
                <label>{'Holz'}</label>
              </td>
              <td>{injectHolzSlider()}</td>
              <td className={percentageColClassNames}>
                <label>{anteilHolz + '%'}</label>
              </td>
            </tr>
            <tr>
              <td className={titleColClassNames}>
                <label>{'Sonstige'}</label>
              </td>
              <td>{injectSonstigeSlider()}</td>
              <td className={percentageColClassNames}>
                <label>{anteilSonstiges + '%'}</label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {formstate != 'none' && (errorLabel || neutralLabel || validLabel)
        ? renderLabel()
        : null}
    </div>
  );
}

export default BeeNutzungsartMixHeizenergie;
