import { useEffect, useState } from 'react';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeValidatedTextArea from '../../Atoms/BeeValidatedTextArea';
import BeeValidatedTextInput from '../../Atoms/BeeValidatedTextInput';

type AlphaLiegenschaftGDFormProps = {
  data: LiegenschaftsGrunddatenType;
  errorName?: boolean;
  errorNummer?: boolean;
  disabled: boolean;
  readOnly: boolean;
  onChange?: (e: any) => void;
};

export type LiegenschaftsGrunddatenType = {
  nummer: string;
  name: string;
  beschreibung: string;
  anmerkung: string;
  eigentuemer: string;
  verantwortlicher: string;
};

export default function AlphaLiegenschaftGDForm({
  data,
  errorName,
  errorNummer,
  disabled,
  readOnly,
  onChange
}: AlphaLiegenschaftGDFormProps) {
  const [nummer, setNummer] = useState<string>(
    data && data.nummer ? data.nummer : ''
  );
  const [name, setName] = useState<string>(data && data.name ? data.name : '');
  const [beschreibung, setBeschreibung] = useState<string>(
    data && data.beschreibung ? data.beschreibung : ''
  );
  const [bemerkung, setBemerkung] = useState<string>(
    data && data.anmerkung ? data.anmerkung : ''
  );
  const [eigentuemer, setEigentuemer] = useState<string>(
    data && data.eigentuemer ? data.eigentuemer : ''
  );
  const [verantwortlicher, setVerantwortlicher] = useState<string>(
    data && data.verantwortlicher ? data.verantwortlicher : ''
  );
  const [isAdaptedNummer, setIsAdaptedNummer] = useState<boolean>(false);
  const [isAdaptedName, setIsAdaptedName] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      setNummer(data.nummer);
      setName(data.name);
      setBeschreibung(data.beschreibung);
      setBemerkung(data.anmerkung);
      setEigentuemer(data.eigentuemer);
      setVerantwortlicher(data.verantwortlicher);
    }
  }, [data]);

  function change(currLiegenschaftGD: LiegenschaftsGrunddatenType) {
    if (onChange) {
      onChange(currLiegenschaftGD);
    }
  }

  function changeNumber(currNummer: string) {
    const currLiegenschaftGD: LiegenschaftsGrunddatenType = {
      nummer: currNummer,
      name: name,
      beschreibung: beschreibung,
      anmerkung: bemerkung,
      eigentuemer: eigentuemer,
      verantwortlicher: verantwortlicher
    };

    change(currLiegenschaftGD);
  }

  function changeName(currName: string) {
    const currLiegenschaftGD: LiegenschaftsGrunddatenType = {
      nummer: nummer,
      name: currName,
      beschreibung: beschreibung,
      anmerkung: bemerkung,
      eigentuemer: eigentuemer,
      verantwortlicher: verantwortlicher
    };

    change(currLiegenschaftGD);
  }

  function changeBeschreibung(currBeschreibung: string) {
    const currLiegenschaftGD: LiegenschaftsGrunddatenType = {
      nummer: nummer,
      name: name,
      beschreibung: currBeschreibung,
      anmerkung: bemerkung,
      eigentuemer: eigentuemer,
      verantwortlicher: verantwortlicher
    };

    change(currLiegenschaftGD);
  }

  function changeBemerkung(currBemerkung: string) {
    const currLiegenschaftGD: LiegenschaftsGrunddatenType = {
      nummer: nummer,
      name: name,
      beschreibung: beschreibung,
      anmerkung: currBemerkung,
      eigentuemer: eigentuemer,
      verantwortlicher: verantwortlicher
    };

    change(currLiegenschaftGD);
  }

  function changeEigentuemer(currEigentuemer: string) {
    const currLiegenschaftGD: LiegenschaftsGrunddatenType = {
      nummer: nummer,
      name: name,
      beschreibung: beschreibung,
      anmerkung: bemerkung,
      eigentuemer: currEigentuemer,
      verantwortlicher: verantwortlicher
    };
    change(currLiegenschaftGD);
  }

  function changeVerantwortlicher(currVerantwortlicher: string) {
    const currLiegenschaftGD: LiegenschaftsGrunddatenType = {
      nummer: nummer,
      name: name,
      beschreibung: beschreibung,
      anmerkung: bemerkung,
      eigentuemer: eigentuemer,
      verantwortlicher: currVerantwortlicher
    };

    change(currLiegenschaftGD);
  }

  function renderHeadline() {
    return (
      <BeeContentHeadline
        label={'Grunddaten'}
        headline={'h2'}
        type={'secondary'}
      />
    );
  }

  function renderNummer() {
    const errorLabel = 'Bitte geben Sie eine Liegenschaftsnummer an.';

    return (
      <>
        <BeeValidatedTextInput
          label={'Liegenschaftsnummer'}
          errorLabel={errorNummer && !isAdaptedNummer ? errorLabel : null}
          neutralLabel={errorNummer && isAdaptedNummer ? errorLabel : null}
          formstate={
            errorNummer && isAdaptedNummer
              ? 'neutral'
              : errorNummer
              ? 'error'
              : 'none'
          }
          value={nummer}
          disabled={disabled}
          readOnly={readOnly}
          required={true}
          onChange={(e) => {
            setNummer(e.target.value);
            if (errorNummer) {
              setIsAdaptedNummer(true);
            }
          }}
          onFocusOut={(e) => changeNumber(e.target.value)}
        />
      </>
    );
  }
  function renderName() {
    const errorLabel = 'Bitte geben Sie einen Liegenschaftsnamen an.';
    return (
      <>
        <BeeValidatedTextInput
          label={'Liegenschaftsname'}
          errorLabel={errorName && !isAdaptedName ? errorLabel : null}
          neutralLabel={errorName && isAdaptedName ? errorLabel : null}
          formstate={
            errorName && isAdaptedName
              ? 'neutral'
              : errorName
              ? 'error'
              : 'none'
          }
          value={name}
          disabled={disabled}
          readOnly={readOnly}
          required={true}
          onChange={(e) => {
            setName(e.target.value);
            if (errorName) {
              setIsAdaptedName(true);
            }
          }}
          onFocusOut={(e) => changeName(e.target.value)}
        />
      </>
    );
  }
  function renderBeschreibung() {
    return (
      <>
        <BeeValidatedTextArea
          label={'Beschreibung'}
          formstate={'none'}
          value={beschreibung}
          disabled={disabled}
          rows={readOnly ? 1 : 3}
          autoResize={true}
          readOnly={readOnly}
          required={false}
          onChange={(e) => setBeschreibung(e.target.value)}
          onFocusOut={(e) => changeBeschreibung(e.target.value)}
        />
      </>
    );
  }

  function renderAnmerkung() {
    return (
      <>
        <BeeValidatedTextArea
          label={'Bemerkung'}
          formstate={'none'}
          value={bemerkung}
          disabled={disabled}
          rows={readOnly ? 1 : 3}
          autoResize={true}
          readOnly={readOnly}
          required={false}
          onChange={(e) => setBemerkung(e.target.value)}
          onFocusOut={(e) => changeBemerkung(e.target.value)}
        />
      </>
    );
  }

  function renderEingentümer() {
    return (
      <>
        <BeeValidatedTextInput
          label={'Eigentümer'}
          formstate={'none'}
          value={eigentuemer}
          disabled={disabled}
          readOnly={readOnly}
          required={false}
          onChange={(e) => setEigentuemer(e.target.value)}
          onFocusOut={(e) => changeEigentuemer(e.target.value)}
        />
      </>
    );
  }

  function renderVerantwortlicher() {
    return (
      <>
        <BeeValidatedTextInput
          label={'Verantwortlicher'}
          formstate={'none'}
          value={verantwortlicher}
          disabled={disabled}
          readOnly={readOnly}
          required={false}
          onChange={(e) => setVerantwortlicher(e.target.value)}
          onFocusOut={(e) => changeVerantwortlicher(e.target.value)}
        />
      </>
    );
  }

  return (
    <div className={'alpha-liegenschaft-gd-form'}>
      {renderHeadline()}
      <div className={'grid'}>
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderName()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderNummer()}
        </div>
        <div className={'col col-12 sm:col-12 md:col-12 lg:col-12'}>
          {renderBeschreibung()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderEingentümer()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderVerantwortlicher()}
        </div>
        <div className={'col col-12 sm:col-12 md:col-12 lg:col-12'}>
          {renderAnmerkung()}
        </div>
      </div>
    </div>
  );
}
