import 'primeicons/primeicons.css';
import { useId } from 'react-id-generator';
import _ from 'lodash';
import { Tooltip } from 'primereact/tooltip';
import './BeeArrowDiff.scss';

type BeeArrowDiffProps = {
  id?: string;
  value: number | null;
  tooltip?: string;
  disabled: boolean;
  isRoot: boolean;
};

export default function BeeArrowDiff({
  id,
  value,
  tooltip,
  disabled,
  isRoot
}: BeeArrowDiffProps) {
  const currentId = useId(1, 'bee-arrow-diff-')[0];

  let arrowPosClass = '';
  const disabledClass = disabled ? ' disabled' : '';

  switch (true) {
    case value === null:
      break;
    case value! <= -0.5:
      arrowPosClass = 'bee-arrow bee-arrow-dive';
      break;
    case value! > -0.5 && value! < 0:
      arrowPosClass = 'bee-arrow-dip';
      break;
    case value! === 0:
      arrowPosClass = 'bee-arrow-steady';
      break;
    case value! > 0 && value! < 0.5:
      arrowPosClass = 'bee-arrow-bump';
      break;
    case value! >= 0.5:
      arrowPosClass = 'bee-arrow-jump';
      break;
    default:
      arrowPosClass = 'bee-arrow-steady';
  }

  return (
    <>
      {!isRoot && (value || value === 0) ? (
        <>
          <div
            id={id ? id : currentId}
            className={'bee-arrow-diff ' + arrowPosClass + disabledClass}
          >
            <div className={'bee-arrow-diff-icon'}>
              <i className={'icon-right-arrow bee-arrow'}></i>
            </div>
            <div className={'bee-arrow-diff-text'}>
              {_.round(value * 100)} %
            </div>
          </div>
          {tooltip && !disabled ? (
            <Tooltip target=".bee-arrow-diff">{tooltip}</Tooltip>
          ) : null}
        </>
      ) : null}
    </>
  );
}
