import Lottie from 'lottie-react';
import AnimationEmptyBox from '../../../Style/Lottie-Animations/629-empty-box.json';
import './NoProperties.scss';

export default function NoProperties() {
  return (
    <div className={'dl-noProerties'}>
      <div className={'noProperties-content'}>
        <h2>Diese Phase enthält keine Liegenschaften</h2>
        <div>
          Es wurde noch keine Liegenschaft mit dieser Phase verknüpft. Bitte
          versuchen Sie es zu einem späteren Zeitpunkt erneut oder wenden Sie
          sich an den Kundenservice.
        </div>
      </div>
      <div className={'animation animation-box'}>
        <Lottie animationData={AnimationEmptyBox} loop={true} autoplay={true} />
      </div>
    </div>
  );
}
