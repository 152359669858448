import { useId } from 'react-id-generator';
import './BeeAddEntryCard.scss';
import BeeIconButton from './BeeIconButton';

type BeeAddEntryCardProps = {
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export function BeeAddEntryCard({ disabled, onClick }: BeeAddEntryCardProps) {
  const currentId = useId(1, 'bee-add-entry-card-')[0];
  const icon = 'pi pi-plus';
  const type = 'primary';

  //classNames
  const classNames = 'bee-add-entry-card';

  function clicked(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (!disabled && onClick) {
      onClick(e);
    }
  }

  return (
    <div id={currentId} className={classNames}>
      <BeeIconButton
        iconClass={icon}
        rounded={true}
        disabled={disabled}
        type={type}
        onClick={(e) => clicked(e)}
      />
    </div>
  );
}

export default BeeAddEntryCard;
