export async function handlePromisesAndIgnoreErrors(promises: Promise<any>[]) {
  try {
    const results = await Promise.allSettled(promises);
    const fulfilled: any[] = [];
    const rejected: any[] = [];
    results.forEach((result) => {
      if (result.status === 'fulfilled') {
        fulfilled.push(result);
      } else {
        rejected.push(result);
      }
    });
    return { fulfilled, rejected };
  } catch (error) {
    throw error;
  }
}
