import _ from 'lodash';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { useEffect, useRef, useState } from 'react';
import {
  Phase,
  readAllPhases,
  updatePhase
} from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import {
  AuthUserType,
  readAllServiceProvider
} from '../../../Helper/ApiHelper/LoginNetworkHelper';
import {
  DURATION_NOTIFICATION_ERROR_LONG,
  SERVER_RESPONSE_ERROR,
  SERVER_RESPONSE_PENDING,
  SERVER_RESPONSE_SUCCESS
} from '../../../Helper/Statics/Constants';
import { isBefore } from '../../../Helper/Util/TimelineStepHelper';
import BeeIcon from '../../Atoms/BeeIcon';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import BeeValidatedCheckbox from '../../Atoms/BeeValidatedCheckbox';
import { FaktorKeyValueStore } from '../../Molecules/DL/DlBundeslandfaktoren';
import './AlphaSetupTabPhaseToUser.scss';

export default function AlphaSetupTabPhaseToUser() {
  const [forceReload, setForceReload] = useState<boolean>(true);
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);
  const [phasen, setPhasen] = useState<Phase[]>([]);
  const [serviceProvider, setServiceProvider] = useState<AuthUserType[]>([]);
  const toast = useRef<Toast>(null);
  const [keyValueStore, setKeyValueStore] = useState<{
    [key: string]: FaktorKeyValueStore;
  }>({});

  useEffect(() => {
    setDataIsLoading(true);
    readAllPhases()
      .then((phases: any) => {
        readAllServiceProvider()
          .then((provider: any) => {
            setupKeyValueStore(phases, provider);
          })
          .catch(() => {
            if (toast && toast.current) {
              toast.current.show({
                severity: 'error',
                summary: 'Dienstleister konnten nicht geladen werden',
                detail:
                  'Leider konnten die Dienstleister nicht geladen werden. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.',
                sticky: true,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            }
            setDataIsLoading(false);
          });
      })
      .catch((error) => {
        if (toast && toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Phasen konnten nicht geladen werden',
            detail:
              'Leider konnten die Phasen nicht geladen werden. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.',
            sticky: true,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
        setDataIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceReload]);

  function setupKeyValueStore(phases: Phase[], provider: AuthUserType[]) {
    const values: any = {};
    let prov: AuthUserType[] = [...provider];
    //interner key = phaseId_lsId!!s
    let allProviderIds: String[] = [];
    prov.forEach((e) => {
      allProviderIds.push(e.id);
    });
    for (let i = 0; i < phases.length; i++) {
      const p: any = phases[i];
      if (p.userIds) {
        const cleanedProvider = _.intersection(p.userIds, allProviderIds);
        if (cleanedProvider.length !== p.userIds.length) {
          p.userIds = cleanedProvider;
          updatePhase(p.id, p)
            .then(() => {
              setForceReload(!forceReload);
            })
            .catch(() => {
              if (toast && toast.current) {
                toast.current.show({
                  severity: 'error',
                  summary: 'Phasen konnte nicht geupdated werden',
                  detail:
                    'Leider enthält mindestens eine Phase einen gelöschten User. Das Automatische Löschen ist fehlgeschlagen. Bitte laden Sie die Seite erneut. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.',
                  sticky: true,
                  closable: true,
                  life: DURATION_NOTIFICATION_ERROR_LONG
                });
              }
            });
        }
      }
    }
    phases.forEach((p) => {
      let allProviderIdClone: String[] = _.cloneDeep(allProviderIds);
      let intersection: String[] = _.intersection(
        allProviderIdClone,
        p.userIds
      );
      let nonChecked: String[] = allProviderIdClone.filter(
        (item: String) => !intersection.includes(item)
      );
      //generate lookup
      intersection.forEach((checked) => {
        values[p.id + '_' + checked] = {};
        values[p.id + '_' + checked].value = true;
        values[p.id + '_' + checked].process = SERVER_RESPONSE_SUCCESS;
      });
      nonChecked.forEach((nC) => {
        values[p.id + '_' + nC] = {};
        values[p.id + '_' + nC].value = false;
        values[p.id + '_' + nC].process = SERVER_RESPONSE_SUCCESS;
      });
    });
    //set values
    setKeyValueStore(values);
    setPhasen(_.sortBy(phases, ['start', 'end', 'title']));
    setServiceProvider(prov);
    setDataIsLoading(false);
  }

  function generatePhases() {
    const pay = phasen;
    let data = [];
    data.push({ type: 'nonPhase', header: 'Nutzer' });
    data.push({ type: 'nonPhase', header: 'Unternehmen' });
    pay.forEach((entry) => data.push(entry));
    return data;
  }

  /* user to phase */
  function updateUserToPhase(
    phase: Phase,
    userId: string,
    checked: boolean,
    previousState: boolean
  ) {
    const valueKey: string = phase.id + '_' + userId;
    if (
      keyValueStore[valueKey] &&
      (keyValueStore[valueKey].value !== checked ||
        keyValueStore[valueKey].process === SERVER_RESPONSE_ERROR)
    ) {
      setKeyValueStore((previousInputs: { [key: string]: any }) => ({
        ...previousInputs,
        [valueKey]: {
          value: checked,
          process: SERVER_RESPONSE_PENDING
        }
      }));
      //update phase
      const allConnectedUserIds: string[] = [];
      if (checked) {
        allConnectedUserIds.push(userId);
      }
      serviceProvider.forEach((sp) => {
        let curr = keyValueStore[phase.id + '_' + sp.id];
        if (curr && curr.value === true && sp.id !== userId) {
          allConnectedUserIds.push(sp.id);
        }
      });
      let updatedPhase: Phase = _.cloneDeep(phase);
      updatedPhase.userIds = allConnectedUserIds;
      // keyValueStore
      updatePhase(updatedPhase.id, updatedPhase)
        .then((responseValue) => {
          updateKeyValStore(valueKey, checked, SERVER_RESPONSE_SUCCESS);
        })
        .catch(() => {
          updateKeyValStore(valueKey, previousState, SERVER_RESPONSE_ERROR);
        });
    }
  }

  function updateKeyValStore(
    valueKey: string,
    val: boolean | null,
    progress: string
  ) {
    setKeyValueStore((previousInputs: any) => ({
      ...previousInputs,
      [valueKey]: { value: val, process: progress }
    }));
  }

  const bodyTemplate = (
    data: AuthUserType,
    options: ColumnBodyOptions,
    phase: Phase
  ) => {
    if (options) {
      if (options.field === 'field_0') {
        return (
          <div className={'lv-table-liegenschaft'}>
            <div>{data.username}</div>
          </div>
        );
      } else if (options.field === 'field_1') {
        return (
          <div>
            <div>{data.organisation}</div>
          </div>
        );
      } else {
        //phase ist abgelaufen
        if (isBefore(new Date(), new Date(phase.end!))) {
          return (
            <BeeValidatedCheckbox
              label={''}
              value={keyValueStore[phase.id + '_' + data.id].value}
              disabled={true}
              formstate={'neutral'}
              stateFalse={''}
              stateTrue={''}
              required={false}
              readOnly={true}
              onChange={() => {}}
            />
          );
        } else {
          const status = keyValueStore[phase.id + '_' + data.id];
          return (
            <div className={'user-to-phase-checkbox'}>
              <BeeValidatedCheckbox
                label={''}
                value={status.value}
                disabled={false}
                formstate={'neutral'}
                stateFalse={''}
                stateTrue={''}
                required={false}
                readOnly={status.process === SERVER_RESPONSE_PENDING}
                onChange={(e) =>
                  updateUserToPhase(phase, data.id, e.checked, status.value)
                }
              />
              {status.process === SERVER_RESPONSE_PENDING ? (
                <span className={'status-userChange pending'}>
                  <BeeLoadingSpinner strokeWidth={'3'} type={'primary'} />
                </span>
              ) : null}
              {status.process === SERVER_RESPONSE_ERROR ? (
                <>
                  <span
                    className={'status-userChange error  tooltip-factor-bl'}
                  >
                    <BeeIcon
                      iconClass={'pi pi-exclamation-triangle'}
                      type={'primary'}
                    />
                    <span>{'Fehler'}</span>
                  </span>
                  <Tooltip
                    target=".tooltip-factor-bl"
                    mouseTrack
                    mouseTrackLeft={10}
                  >
                    {
                      'Die Änderung konnte nicht gespeichert werden. Bitte prüfen Sie Ihre Internetverbindung.'
                    }
                  </Tooltip>
                </>
              ) : null}
            </div>
          );
        }
      }
    }
  };

  const headerTemplate = (data: any, number: any) => {
    if (data.type === 'nonPhase') {
      return data.header;
    } else {
      const start = data && data.start ? new Date(data.start) : null;
      const end = data && data.end ? new Date(data.end) : null;
      return (
        <>
          <div>{data.title}</div>
          <div className={'ep-lv-phase-date flex'}>
            <span>
              {start ? start.toLocaleDateString('de-DE') + ' - ' : null}
            </span>
            <span>{end ? ' ' + end.toLocaleDateString('de-DE') : null}</span>
          </div>
        </>
      );
    }
  };

  const dynamicColumns = generatePhases().map((col: any, i) => {
    const phaseId = col && col.id ? col.id : null;
    return (
      <Column
        body={(data: any, options: ColumnBodyOptions) =>
          bodyTemplate(data, options, col)
        }
        className={
          col.type === 'nonPhase'
            ? col.header.replace(' ', '_') + '-col user-nonPhase'
            : col.title.replace(' ', '_') + '-col user-phase'
        }
        style={{ flex: '0 0 4rem' }}
        frozen={phaseId ? false : true}
        header={headerTemplate(col, i)}
      />
    );
  });

  function injectDescription() {
    return (
      <div className={'mb-3'}>
        Bitte geben Sie in folgender Tabelle an, welche Nutzer an welcher
        Ausschreibungsphase teilnehmen. Wird ein Nutzer aus einer Phase
        ausgeschlossen kann er für diese Phase kein Angebot abgeben.
      </div>
    );
  }

  return (
    <div className={'alpha-setup-phase-to-user m-2 mt-4 ml-1 p-2'}>
      {injectDescription()}
      {!dataIsLoading ? (
        <div>
          <DataTable
            value={serviceProvider}
            size="small"
            className={'alpha-phase-to-user-table'}
            emptyMessage={'Keine Dienstleister vorhanden'}
          >
            {dynamicColumns}
          </DataTable>
        </div>
      ) : (
        <BeeLoadingSpinner strokeWidth={'3'} type={'secondary'} />
      )}
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
