export const DEFAULT_ERROR = 'Die Anfrage konnte nicht verarbeitet werden';
export const ERROR_500 =
  'Ein unerwarteter Fehler ist aufgetreten. Der Server konnte Ihre Anfrage nicht verarbeiten. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.';
export const ERROR_429 =
  'Zu viele Anfragen von Ihrer IP-Adresse sind fehlgeschlagen. Bitte warten Sie ein paar Minuten, bevor Sie es erneut versuchen';

export const ERROR_400 = 'Die Anfrage konnte nicht verarbeitet werden';

export function errorToMsg(error: any) {
  if (error && error.data && error.data.status) {
    return `(Fehler: ${error.data.status})`;
  } else {
    return '';
  }
}
export function errorMsgToString(error: any) {
  console.log(error);
  let string = 'Fehler:';
  if (error && error.data && error.data.status) {
    string = string + ` ${error.data.status}`;
  } else if (error && error.status) {
    string = string + ` ${error.status}`;
  }
  if (error && error.data && error.data.error) {
    string = string + ` ${error.data.error};`;
  }
  if (error && error.data && error.data.message) {
    string = string + ` ${error.data.message}`;
  }
  if (string === 'Fehler:') {
    string =
      'Fehler: Bitte laden Sie die Seite neu und versuchen Sie es erneut';
  }
  return string;
}

export function arrayToString(array: any[]) {
  let string = '';
  if (array && array.length > 0) {
    if (array[0]) {
      string = array[0];
    }
    if (array.length > 1) {
      for (let i = 1; i < array.length - 1; i++) {
        string = string + ', ' + array[i];
      }
      string = string + ' und ' + array[array.length - 1];
    }
  }
  return string;
}
