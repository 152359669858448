import _ from 'lodash';
import { Column } from 'primereact/column';
import { TabPanel, TabView } from 'primereact/tabview';
import TreeNode from 'primereact/treenode';
import { TreeTable, TreeTableExpandedKeysType } from 'primereact/treetable';
import { useEffect, useState } from 'react';
import { EpPosition } from '../../../Helper/ApiHelper/LvEpNetworkHelper';
import {
  DEFAULT_CALCULATION_MULTIPLIER,
  MAX_NUMBER_OF_DECIMALS
} from '../../../Helper/Statics/Constants';
import {
  generateParticipationKey,
  simpleGenerateEpEntryId
} from '../../../Helper/Util/IdGeneratorHelper';
import BeeArrowDiff from '../../Atoms/BeeArrowDiff';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeCurrencyInput from '../../Atoms/BeeCurrencyInput';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import { TimelineStep } from '../../Atoms/BeeTimeline';
import './AlphaDLVerlaufEPTable.scss';

type AlphaDLVerlaufEPTableProps = {
  dataLoading: boolean;
  phases: TimelineStep[];
  epCatalogs: Map<string, any>;
  valueLookup: Map<string, any>;
  providerId: string;
};

export function calculateDiff(
  rootValue: number | null,
  value: number | null
): number | null {
  if (!rootValue) {
    return 1;
  } else if (value === null) {
    return null;
  }
  return _.subtract(_.divide(value, rootValue), 1);
}

export default function AlphaDLVerlaufEPTable({
  dataLoading,
  phases,
  epCatalogs,
  valueLookup,
  providerId
}: AlphaDLVerlaufEPTableProps) {
  const [setupComplete, setSetupComplete] = useState<boolean>(false);
  const [columns, setColumns] = useState<any>([]);
  const [expanded, setExpanded] = useState<TreeTableExpandedKeysType>();

  useEffect(() => {
    setSetupComplete(dataLoading);
  }, [dataLoading]);

  useEffect(() => {
    let cols: any = [];
    if (phases) {
      phases.forEach((p: TimelineStep) => {
        cols.push({
          id: p.id,
          header: p.title,
          period:
            new Date(p.start).toLocaleDateString('de-DE') +
            ' - ' +
            new Date(p.end).toLocaleDateString('de-DE')
        });
      });
    }
    setColumns(cols);
  }, [phases]);

  ///////////////////////
  //////// LOGIC ////////
  ///////////////////////

  function search(lookupKey: string) {
    return valueLookup.get(lookupKey);
  }

  ///////////////////////
  /// VIEW COMPONENTS ///
  ///////////////////////
  function calculateRowClassName(rowData: TreeNode) {
    return {
      'priceInput-row-category-tabIndex ': rowData.data.type === 'category',
      'priceInput-row-position-noTabIndex': rowData.data.type === 'position'
    };
  }

  const titleTemplate = (node: any) => {
    const epEntry: EpPosition = node.data;
    return (
      <div className={'priceInput-table-title'}>
        <div className={'priceInput-ep-code'}>{epEntry.epCode}</div>
        <div>{`[${epEntry.number}] ${epEntry.title}`}</div>
      </div>
    );
  };

  const epValueTemplate = (node: any, column: any) => {
    if (!setupComplete) {
      return <BeeLoadingSpinner strokeWidth={'3'} type={'primary'} />;
    }
    const phaseId: string = column.props.header.key;
    const phase: TimelineStep | undefined = _.find(phases, function (p) {
      return p.id === phaseId;
    });
    //test user participation
    if (!phase || !_.includes(phase.userIds, providerId)) {
      return <div>Keine Teilnahme</div>;
    }
    //test offer rejected
    if (!search(generateParticipationKey(phaseId))) {
      return <div>Keine Abgabe</div>;
    } else if ('false' === search(generateParticipationKey(phaseId))) {
      return <div>Angebot zurückgezogen</div>;
    }
    //if position
    if (node.data && node.data.type === 'position') {
      const value = search(simpleGenerateEpEntryId(phaseId, node.data.epCode));
      let rootValue = -1;
      let rootPId = null;
      let rootPTitle = null;
      for (let i = 0; i < columns.length; i++) {
        //search first valid participation
        const p: TimelineStep | undefined = _.find(phases, function (p) {
          return p.id === columns[i].id;
        });
        if (p && _.includes(p.userIds, providerId)) {
          if (search(generateParticipationKey(columns[i].id))) {
            if (
              !('false' === search(generateParticipationKey(columns[i].id)))
            ) {
              //test value itself - non inserted cannot be basis
              if (
                search(simpleGenerateEpEntryId(columns[i].id, node.data.epCode))
              ) {
                rootValue = search(
                  simpleGenerateEpEntryId(columns[i].id, node.data.epCode)
                );
                rootPId = columns[i].id;
                rootPTitle = columns[i].header;
                break;
              }
            }
          }
        }
      }
      return (
        <div className={'alpha-dl-epOverTime-ep-value'}>
          <BeeCurrencyInput
            value={
              value
                ? _.divide(value, DEFAULT_CALCULATION_MULTIPLIER)
                : value === 0
                ? 0
                : null
            }
            disabled={false}
            minFractionDigits={MAX_NUMBER_OF_DECIMALS}
            maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
            formstate={'none'}
            readOnly={true}
          />
          {rootPId && value ? (
            <BeeArrowDiff
              value={calculateDiff(rootValue, value)}
              tooltip={'In Bezug auf Phase: ' + rootPTitle}
              disabled={false}
              isRoot={phaseId === rootPId}
            />
          ) : null}
        </div>
      );
    }
    //seems to be empty category
    return <div />;
  };

  const dynamicPhaseColumns = columns
    ? columns.map((col: any, i: any) => {
        return (
          <Column
            key={col.id + '_' + col.header}
            className={'phase-epPrice-col'}
            body={epValueTemplate}
            header={
              <div key={col.id}>
                <div>{col.header}</div>
                <div className={'phase-period'}>{col.period}</div>
              </div>
            }
            style={{ width: '14em' }}
          />
        );
      })
    : null;

  function injectEpTable(currCatalog: any) {
    return (
      <TreeTable
        value={currCatalog}
        expandedKeys={expanded}
        rowClassName={(rowData: TreeNode) => calculateRowClassName(rowData)}
        scrollable
        style={{ width: '100%' }}
        className={'alpha-dl-epOverTime-table'}
        emptyMessage={'Keine Position gefunden'}
        onToggle={(e) => setExpanded(e.value)}
      >
        <Column
          field={'title'}
          columnKey={'priceinput-title-col'}
          body={titleTemplate}
          header={
            <div className={'ep-table-title'}>
              <div>{'Position'}</div>
            </div>
          }
          style={{ width: '30em' }}
          className={'ep-position-col frozen-col '}
          expander
        />
        {dynamicPhaseColumns}
      </TreeTable>
    );
  }

  const injectTabContent = (phaseId: string) => {
    if (!epCatalogs) {
      return 'ERROR NO CATALOGS';
    } else {
      const currCatalog: any = epCatalogs.get(phaseId);
      if (!currCatalog) {
        return 'Kein Katalog in dieser Phase hinterlegt';
      }
      return <div>{injectEpTable(currCatalog)}</div>;
    }
  };

  const dynamicTabs = phases.map((col: any, i: any) => {
    return (
      <TabPanel
        header={'EP-Katalog aus ' + col.title}
        key={'a_dlVerlauf_epTable_' + col.id + i}
      >
        {injectTabContent(col.id)}
      </TabPanel>
    );
  });

  return (
    <div className={'alpha-dl-epOverTime'}>
      <BeeContentHeadline
        label={'EP-Kataloge'}
        headline={'h2'}
        type={'secondary'}
      />
      <div className="mb-2">
        Folgende Übersicht zeigt die Entwicklung der Rahmenpreise des
        ausgewählten Dienstleisters über die Angebotsphasen hinweg. Die Basis
        stellt der EP-Katalog der ausgewählten Phase dar. Wechseln Sie bitte den
        Tab um die Daten auf Basis eines EP-Katalogs einer anderen Phase zu
        analysieren.
      </div>
      <TabView>{dynamicTabs}</TabView>
    </div>
  );
}
