import fileDownload from 'js-file-download';
import _ from 'lodash';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import {
  convertDocResponse,
  downloadDocumentFromServer,
  translateMediaIds
} from '../../../Helper/ApiHelper/MediaNetworkHelper';
import { DURATION_NOTIFICATION_ERROR_LONG } from '../../../Helper/Statics/Constants';
import { zipDocs } from '../../../Helper/StorageHelper/ZipHelper';
import { extractUserId } from '../../../Helper/Util/JwtHelper';
import { handlePromisesAndIgnoreErrors } from '../../../Helper/Util/PromiseHelper';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import { DocType } from '../../Pages/DLPages/DLPriceInputPage';
import BeeDocumentTable from '../BeeDocumentTable';
import './DLAusschrUnterlagen.scss';

type DLAusschrUnterlagenProps = {
  staticMediaIds: string[];
  readOnly: boolean;
};

function DLAusschrUnterlagen({
  staticMediaIds,
  readOnly
}: DLAusschrUnterlagenProps) {
  const [translatedDocs, setTranslatedDocs] = useState<DocType[]>([]);
  const [isLoadingDownloadDocs, setIsLoadingDownloadDocs] =
    useState<boolean>(false);

  const [error, setError] = useState<boolean>(false);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    if (staticMediaIds && staticMediaIds.length > 0) {
      let userId = extractUserId();
      translateMediaIds(staticMediaIds, userId)
        .then((data: any) => {
          let docs: DocType[] = convertDocResponse(data);
          setError(false);
          setTranslatedDocs(docs);
        })
        .catch(() => {
          setError(true);
        });
    }
  }, [staticMediaIds]);

  function downloadDocumentById(entryId: string) {
    const entry = _.find(translatedDocs, function (doc: DocType) {
      return doc.id === entryId;
    });
    if (entry) {
      downloadDocumentFromServer(entry.fileSrc, entry.fileKey)
        .then((response: any) => {
          let filename = entry.filename;
          if (filename) {
            fileDownload(response, filename);
          }
        })
        .catch((error) => {
          if (toast && toast.current) {
            toast.current?.show({
              severity: 'error',
              summary: 'Der Download ist fehlgeschlagen',
              detail:
                'Der Download des Dokuments ist fehlgeschlagen, bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        });
    }
  }

  function downloadDocumentsByIds(entryIds: string[]) {
    setIsLoadingDownloadDocs(true);
    let downloadDocsPromises = [];
    for (let i = 0; i < entryIds.length; i++) {
      const entry = _.find(translatedDocs, function (doc: DocType) {
        return doc.id === entryIds[i];
      });
      if (entry) {
        downloadDocsPromises.push(
          downloadDocumentFromServer(entry.fileSrc, entry.fileKey).then(
            (result) => {
              let filename = entry.filename;
              if (filename) {
                return { name: filename, blob: result };
              }
              //no filename
            }
          )
        );
      }
    }
    handlePromisesAndIgnoreErrors(downloadDocsPromises)
      .then((result: any) => {
        if (result.fulfilled && result.fulfilled.length > 0) {
          let docFiles = [];
          for (let j = 0; j < result.fulfilled.length; j++) {
            docFiles.push(result.fulfilled[j].value);
          }
          const date = new Date().toLocaleDateString('de-DE');
          //zip data
          zipDocs(docFiles, 'Ausschreibungsunterlagen_' + date + '.zip')
            .then(() => {
              //sucess
              console.log('success');
              if (toast && toast.current) {
                if (!result.rejected || result.rejected.length <= 0) {
                  toast.current?.show({
                    severity: 'success',
                    summary: 'Download erfolgreich abgeschlossen',
                    sticky: false,
                    closable: true,
                    life: DURATION_NOTIFICATION_ERROR_LONG
                  });
                } else {
                  toast.current?.show({
                    severity: 'warn',
                    summary:
                      'Der Download ist für einige Dokumente fehlgeschlagen',
                    detail:
                      'Der Download der Dokumente ist vereinzelt fehlgeschlagen, bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
                    //fixme filenames
                    sticky: false,
                    closable: true,
                    life: DURATION_NOTIFICATION_ERROR_LONG
                  });
                }
              }
              setIsLoadingDownloadDocs(false);
            })
            .catch((error) => {
              //Fixme zip didnt work
              console.log(error);
              if (toast && toast.current) {
                toast.current?.show({
                  severity: 'error',
                  summary: 'Der Download ist fehlgeschlagen',
                  detail:
                    'Die Dokumente konnten nicht gezipped und heruntergeladen werden. Bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
                  sticky: true,
                  closable: true,
                  life: DURATION_NOTIFICATION_ERROR_LONG
                });
              }
              setIsLoadingDownloadDocs(false);
            });
        } else if (result.rejected && result.rejected.length > 0) {
          //download failed for all documents
          if (toast && toast.current) {
            toast.current?.show({
              severity: 'error',
              summary: 'Der Download ist für alle Dokumente fehlgeschlagen',
              detail:
                'Der Server für den Download der Dokumente konnte nicht erreicht werden. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
              sticky: true,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
          setIsLoadingDownloadDocs(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (toast && toast.current) {
          toast.current?.show({
            severity: 'error',
            summary: 'Der Download ist fehlgeschlagen',
            detail:
              'Der Download der Dokumente ist leider fehlgeschlagen, bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
        setIsLoadingDownloadDocs(false);
      });
  }

  return (
    <div className={'dl-ausschrUnterlagen'}>
      <BeeContentHeadline
        label={'Ausschreibungsunterlagen'}
        headline={'h2'}
        type={'primary'}
      />
      {error ? (
        <div className={'error'}>
          <i className={'icon-error'}></i>
          <span>
            {
              ' Beim Laden der Ausschreibungsunterlagen ist etwas schiefgelaufen. Bitte versuchen Sie es später erneut oder wenden sich an den Kundensupport.'
            }
          </span>
        </div>
      ) : null}
      <div className={'ausschrUnterlagen-descr'}>
        <div>
          <span>
            {' '}
            Alle für die Ausschreibung relevanten Dokumente stehen nachfolgend
            zum Download zur Verfügung.{' '}
          </span>{' '}
          <span className={'bold'}> Hinweis:</span>
          <span>
            {' '}
            Änderungen im laufenden Verfahren sind möglich. Hierzu erfolgt
            jeweils eine gesonderte Information per Mail. Bitte beachten Sie in
            diesen Fällen dann den Bearbeitungsstand des Dokuments
          </span>
        </div>
      </div>
      <BeeDocumentTable
        type={'dark'}
        data={translatedDocs ? translatedDocs : []}
        readOnly={readOnly || error}
        disabled={error}
        showDownload={true}
        showDelete={false}
        enableBulkDownload={true}
        isBulkDownloadLoading={isLoadingDownloadDocs}
        onDownload={(id) => downloadDocumentById(id)}
        onDownloadAll={(ids) => downloadDocumentsByIds(ids)}
      />
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}

export default DLAusschrUnterlagen;
