import _ from 'lodash';
import { TabPanel, TabView } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { errorToMsg } from '../../../Helper/ApiHelper/ErrorMessages';
import {
  LiegenschaftsData,
  readAllLiegenschaften
} from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import {
  AuthUserType,
  readAllServiceProvider
} from '../../../Helper/ApiHelper/LoginNetworkHelper';
import {
  EP,
  EnlargedLvPosition,
  EpPosition,
  LV,
  generateEpTreeFromPositions,
  generateLVTreeFromEPPositions,
  readAllLvCatalogsForPhase,
  readEpCatalogForPhase,
  removeAllHiddenCategoriesFromEpTree
} from '../../../Helper/ApiHelper/LvEpNetworkHelper';
import {
  translateAlphaMediaIds,
  translateImageToData
} from '../../../Helper/ApiHelper/MediaNetworkHelper';
import {
  COUNTRIES,
  DURATION_NOTIFICATION_ERROR_LONG,
  REGION
} from '../../../Helper/Statics/Constants';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import { TimelineStep } from '../../Atoms/BeeTimeline';
import { ImageType } from '../../Pages/AlphaPages/AlphaLiegenschaftVerwaltung';
import './AlphaAnalysisTabView.scss';
import {
  MiniKeyValEntry,
  readAllKeyValuesForPhaseByAlpha
} from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import AlphaTabProperty from './AlphaTabProperty';
import AlphaTabPrincing from './AlphaTabPrincing';
import AlphaTabCluster from './AlphaTabCluster';
import { generateEnlargedLV } from '../../../Helper/Util/LvCalculator';
import AlphaAnalysisOverview from './AlphaAnalysisOverview';
import { isKeyParticipationKey } from '../../../Helper/Util/IdGeneratorHelper';

type AlphaAnalysisTabViewProps = {
  phase: TimelineStep;
};

export default function AlphaAnalysisTabView({
  phase
}: AlphaAnalysisTabViewProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);
  const [imgLookup, setImgLookup] = useState<Map<string, ImageType>>(new Map());
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [keyValLookup, setKeyValLookup] = useState();
  const [lvLookup, setLvLookup] = useState<Map<string, EnlargedLvPosition[]>>(
    new Map()
  );
  const [rawLV, setRawLV] = useState<LV[]>([]);
  const [currentServiceProvider, setCurrentServiceProvider] = useState<
    AuthUserType[]
  >([]);
  const [epLookup, setEpLookup] = useState<Map<string, EpPosition>>(new Map());
  const [currentEpTree, setCurrentEpTree] = useState<any>();
  const [currentLvTree, setCurrentLvTree] = useState<any>();
  const [properties, setProperties] = useState<LiegenschaftsData[]>([]);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    setDataIsLoading(true);
    const propertyIds: string[] = phase.liegenschaftIds
      ? phase.liegenschaftIds
      : [];
    const providerIds: string[] = phase.userIds ? phase.userIds : [];
    readAllServiceProvider()
      .then((userEntries: any) => {
        let prov = _.filter(userEntries, (entry) =>
          _.includes(providerIds, entry.id)
        );
        prov = _.sortBy(prov, ['organisation', 'username']);
        readEpCatalogForPhase(phase.id)
          .then((epData: any) => {
            const epLookup: any = setupEpLookup(epData);
            let currEpTree: any = generateEpTreeFromPositions(epData.data);
            currEpTree = currEpTree ? currEpTree : [];
            currEpTree = removeAllHiddenCategoriesFromEpTree(currEpTree, false);
            let lvTree = generateLVTreeFromEPPositions(epData.data);
            readAllLiegenschaften()
              .then((properties: any) => {
                readAllLvCatalogsForPhase(phase.id)
                  .then((dataLvs: any) => {
                    const allLvs: LV[] = _.filter(dataLvs.data, (entry) =>
                      _.includes(propertyIds, entry.info.propertyId)
                    );
                    if (allLvs.length !== propertyIds.length) {
                      if (toast.current) {
                        toast.current.show({
                          severity: 'info',
                          summary: 'Liegenschaften ohne Leistungsverzeichnis',
                          detail:
                            'Leider wurde noch nicht zu allen Liegenschaften Leistungsverzeichnisse hinterlegt. Bitte vervollständigen Sie die LVs über den entsprechenden Tab um aussagekräftige Ergebisse zu erhalten.',
                          sticky: true,
                          closable: true,
                          life: DURATION_NOTIFICATION_ERROR_LONG
                        });
                      }
                    }
                    let lvLook = new Map<string, any>();
                    allLvs.forEach((lv: LV) => {
                      if (lv && lv.data && lv.info) {
                        //init enlarged LV for each property
                        const property = _.find(
                          properties,
                          function (pr: LiegenschaftsData) {
                            return pr.id === lv.info.propertyId;
                          }
                        );
                        if (property) {
                          //search bundesland
                          let region = null;
                          for (let i = 0; i < COUNTRIES.length; i++) {
                            const c = COUNTRIES[i];
                            const val = _.find(
                              c.regions,
                              function (reg: REGION) {
                                return reg.region === property.adresse!.region;
                              }
                            );
                            region = val ? val : region;
                          }
                          const blID = region ? region.id : '';
                          const enlargedLvPos = generateEnlargedLV(
                            lv.data,
                            property,
                            blID,
                            phase,
                            epLookup
                          );
                          lvLook.set(lv.info.propertyId, enlargedLvPos);
                        }
                      }
                    });
                    readAllKeyValuesForPhaseByAlpha(phase.id)
                      .then((keyvals: any) => {
                        let participatingProviderId: string[] = [];
                        let valueLookup: any = new Map();
                        if (keyvals && keyvals.length > 0) {
                          keyvals.forEach((kv: MiniKeyValEntry) => {
                            let entry = valueLookup.get(kv.key);
                            entry = entry ? entry : {};
                            entry[kv.organisationId] = kv.value;
                            valueLookup.set(kv.key, entry);
                            //create rejectedOffers
                            if (isKeyParticipationKey(kv.key)) {
                              if (kv.value === 'true') {
                                participatingProviderId.push(kv.organisationId);
                              }
                            }
                          });
                        }
                        //filter provider by rejection
                        prov = _.filter(prov, (entry) =>
                          _.includes(participatingProviderId, entry.id)
                        );
                        setLvLookup(lvLook);
                        setRawLV(allLvs);
                        setProperties(
                          _.filter(properties, (entry) =>
                            _.includes(propertyIds, entry.id)
                          )
                        );
                        setCurrentServiceProvider(prov);
                        setCurrentEpTree(currEpTree);
                        setCurrentLvTree(lvTree);
                        setDataIsLoading(false);
                        setKeyValLookup(valueLookup);
                        setEpLookup(epLookup);
                      })
                      .catch((error) => {
                        if (toast.current) {
                          toast.current.show({
                            severity: 'error',
                            summary: error
                              ? errorToMsg(error)
                              : 'Laden der Dienstleisterdaten fehlgeschlagen',
                            detail:
                              'Beim Laden der Angebotsdaten der Dienstleister ist etwas schiefgelaufen. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.',
                            sticky: true,
                            closable: true,
                            life: DURATION_NOTIFICATION_ERROR_LONG
                          });
                        }
                      });
                  })
                  .catch((error) => {
                    if (toast.current) {
                      toast.current.show({
                        severity: 'error',
                        summary:
                          'Laden der Leistungsverzeichnisse fehlgeschlagen',
                        detail: error
                          ? errorToMsg(error)
                          : 'Beim Laden der Leistungsverzeichnisse ist etwas schiefgelaufen. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.',
                        sticky: true,
                        closable: true,
                        life: DURATION_NOTIFICATION_ERROR_LONG
                      });
                    }
                  });
              })
              .catch((error) => {
                if (toast.current) {
                  toast.current.show({
                    severity: 'error',
                    summary: 'Laden der Liegenschaften fehlgeschlagen',
                    detail: error
                      ? errorToMsg(error)
                      : 'Beim Laden der Liegenschaften ist etwas schiefgelaufen. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.',
                    sticky: true,
                    closable: true,
                    life: DURATION_NOTIFICATION_ERROR_LONG
                  });
                }
              });
          })
          .catch((error) => {
            if (toast.current) {
              toast.current.show({
                severity: 'error',
                summary: 'Laden des EP-Katalogs fehlgeschlagen',
                detail: error
                  ? errorToMsg(error)
                  : 'Beim Laden des hinterlegten EP-Katalogs ist etwas schiefgelaufen. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.',
                sticky: true,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            }
          });
      })
      .catch((error) => {
        if (toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Laden der Dienstleister fehlgeschlagen',
            detail: error
              ? errorToMsg(error)
              : 'Beim Laden der Dienstleister der Phase ist etwas schiefgelaufen. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.',
            sticky: true,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
      });
  }, [phase]);

  useEffect(() => {
    let mediaIds: string[] = [];
    for (let i = 0; i < properties.length; i++) {
      if (properties[i].hauptBildId) {
        mediaIds.push(properties[i].hauptBildId!);
      }
    }
    if (mediaIds.length > 0) {
      translateAlphaMediaIds(mediaIds)
        .then((data) => {
          setupMediaLookups(data);
        })
        .catch((error) => {
          if (toast && toast.current) {
            toast.current.show({
              severity: 'error',
              summary: 'Liegenschaftsbilder konnten nicht geladen werden ',
              detail: error
                ? errorToMsg(error)
                : 'Leider konnten die Liegenschaftsbilder nicht vollständig geladen werden. Bitte versuchen Sie es später erneut.',
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        });
    }
  }, [properties]);

  useEffect(() => {
    if (searchParams.get('lId')) {
      if (activeIndex !== 1) {
        setActiveIndex(1);
      }
    } else if (searchParams.get('cId')) {
      if (activeIndex !== 2) {
        setActiveIndex(2);
      }
    } else if (searchParams.get('pCom')) {
      if (activeIndex !== 3) {
        setActiveIndex(3);
      }
    } else {
      if (activeIndex !== 0) {
        setActiveIndex(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  ///////////////////////
  /////// HELPER ////////
  ///////////////////////

  function setupEpLookup(catalog: EP) {
    if (catalog && catalog.data) {
      const lookup = new Map();
      catalog.data.forEach((e) => {
        if (e.epCode) {
          lookup.set(e.epCode, e);
        }
      });
      return lookup;
    }
  }

  function setupMediaLookups(mediaData: any) {
    const imgLookup = new Map();
    if (mediaData) {
      mediaData.forEach((entry: any) => {
        if (entry.image) {
          const image = translateImageToData(entry.image);
          if (image) {
            imgLookup.set(image.id, image);
          }
        }
      });
    }
    setImgLookup(imgLookup);
  }

  ///////////////////////
  /// VIEW COMPONENTS ///
  ///////////////////////

  function injectPayload() {
    if (dataIsLoading) {
      return (
        <div className={'bee-page-spinner'}>
          <BeeLoadingSpinner strokeWidth={'3'} type={'primary'} />
        </div>
      );
    }
    return (
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => {
          if (e.index === 0) {
            //reset lId if selected tab is 0
            searchParams.delete('lId');
            searchParams.delete('cId');
            searchParams.delete('pCom');
            setSearchParams(searchParams);
          } else if (e.index === 1) {
            //autoselect first property if selected tab is 1
            if (properties && properties.length > 0) {
              const lId = properties[0].id ? properties[0].id : 'no-prop';
              searchParams.set('lId', lId);
              searchParams.delete('cId');
              searchParams.delete('pCom');
              setSearchParams(searchParams);
            }
          } else if (e.index === 2) {
            searchParams.delete('lId');
            searchParams.delete('pCom');
            searchParams.set('cId', 'new');
            setSearchParams(searchParams);
          } else if (e.index === 3) {
            searchParams.delete('lId');
            searchParams.delete('cId');
            searchParams.set('pCom', 'true');
            setSearchParams(searchParams);
          }
        }}
      >
        <TabPanel header="Gesamtübersicht" leftIcon="icon-chart">
          <AlphaAnalysisOverview
            properties={properties}
            imageLookup={imgLookup}
            lvData={lvLookup}
            rawLVs={rawLV}
            serviceProvider={currentServiceProvider}
            keyValues={keyValLookup}
            epLookup={epLookup}
            epTree={currentEpTree}
            phase={phase}
          />
        </TabPanel>
        <TabPanel
          header="Nach Liegenschaften"
          disabled={dataIsLoading}
          leftIcon="icon-company"
        >
          <AlphaTabProperty
            phase={phase}
            properties={properties}
            imageLookup={imgLookup}
            lvData={lvLookup}
            serviceProvider={currentServiceProvider}
            keyValues={keyValLookup}
            shortenLvTree={currentLvTree}
          />
        </TabPanel>
        <TabPanel
          header="Clusteransicht"
          disabled={dataIsLoading}
          leftIcon="pi pi-chart-pie"
        >
          <AlphaTabCluster
            phase={phase}
            properties={properties}
            epTree={currentEpTree}
            lvLookup={lvLookup}
            serviceProvider={currentServiceProvider}
            keyValues={keyValLookup}
          />
        </TabPanel>
        <TabPanel
          header="Nach Rahmenpreisen"
          disabled={dataIsLoading}
          leftIcon="pi pi-chart-bar"
        >
          <AlphaTabPrincing
            phase={phase}
            properties={properties}
            serviceProvider={currentServiceProvider}
            keyValues={keyValLookup}
            epTree={currentEpTree}
          />
        </TabPanel>
      </TabView>
    );
  }

  return (
    <div className={'alpha-analysis-tab-view mt-6'}>
      {injectPayload()}
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
