import axios from 'axios';
import _ from 'lodash';
import { DocType } from '../../UI/Pages/DLPages/DLPriceInputPage';
import { authAxios } from './AuthServiceHelper';
import { URL_TO_MEDIA_API, URL_TO_MEDIA_SERVER } from '../Statics/Constants';
import { GebaeudeData, LiegenschaftsData } from './LiegenschaftenNetworkHelper';
import { ErrorResponseType } from './LoginNetworkHelper';

export type DocResponse = {
  access?: string;
  access_expire?: Date;
  copyright: string;
  createdAt: Date;
  createdFromUser: string;
  id: string;
  title: string;
  type: string;
  url: string;
  filename: string;
  fileSize: number;
};

export type ImageResponse = {
  id: string;
  title: string;
  copyright: string;
  createdAt: Date;
  createdFromUser: string;
  orientation: string;
  dominantColor: string;
  keywords: string;
  filename: string;
  fileSize: number;
  versions: {
    id: string;
    getvLabel: string;
    height: number;
    width: number;
    url: string;
    type: string;
    access?: string;
    access_expire?: Date;
  }[];
};

export type ReadMediaArrayResponse = {
  document: DocResponse;
  image: ImageResponse;
};
export type ReadMediaResponse = {
  //FIXME optional fields
  access: string;
  access_expire: Date;
  copyright: string;
  createdAt: Date;
  createdFromUser: string;
  id: string;
  title: string;
  type: string;
  url: string;
};

type UploadDocResponseType = {
  data: ReadMediaResponse[];
};

type ReadMediaIdResponseType = {
  data: { data: ReadMediaArrayResponse[] };
};

type DownloadDocResponseType = {};

export function extractMediaIds(data: {
  hauptBildId?: string;
  hauptPlanId?: string;
  mediaIds?: string[];
}) {
  const mediaIds = [];
  //extract ids from property
  if (data) {
    if (data.hauptBildId) {
      mediaIds.push(data.hauptBildId);
    }
    if (data.hauptPlanId) {
      mediaIds.push(data.hauptPlanId);
    }
    if (data.mediaIds) {
      mediaIds.push(...data.mediaIds);
    }
  }

  return mediaIds;
}

export function extractAllMediaIds(
  property?: LiegenschaftsData,
  buildings?: GebaeudeData[] | null
) {
  const mediaIds = [];
  //extract ids from property
  if (property) {
    if (property.hauptBildId) {
      mediaIds.push(property.hauptBildId);
    }
    if (property.hauptPlanId) {
      mediaIds.push(property.hauptPlanId);
    }
    if (property.mediaIds) {
      mediaIds.push(...property.mediaIds);
    }
  }
  //extract ids from buildings
  if (buildings) {
    buildings.forEach((building) => {
      if (building) {
        if (building.hauptBildId) {
          mediaIds.push(building.hauptBildId);
        }
        if (building.hauptPlanId) {
          mediaIds.push(building.hauptPlanId);
        }
        if (building.mediaIds) {
          mediaIds.push(...building.mediaIds);
        }
      }
    });
  }

  return mediaIds;
}

export const uploadDLDocumentToServer = (file: File, title: string | null) => {
  return new Promise((resolve, reject) => {
    const uploadUrl = URL_TO_MEDIA_API + '/v1/doc';

    //FIXME:types
    let fd: any = new FormData();
    fd.append('file', file);
    fd.append('title', title);
    fd.append('public', false); //WICHTIG!!!!
    fd.append('copyright', null);
    fd.append('keywords', null);
    authAxios
      .put(uploadUrl, fd)
      .then((result: any) => {
        resolve(result.data);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

export const translateDLMediaIds = (mediaIds: string[], userId: string) => {
  return new Promise((resolve, reject) => {
    if (mediaIds && mediaIds.length > 0) {
      authAxios({
        method: 'post',
        url: URL_TO_MEDIA_API + '/v1/' + userId + '/media',
        data: { ids: mediaIds }
      })
        .then((result: ReadMediaIdResponseType) => {
          resolve(result.data.data);
        })
        .catch((error: ErrorResponseType) => {
          if (error && error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    } else {
      resolve([]);
    }
  });
};

//UPLOAD DOCUMENT
export const uploadDocumentToServer = (
  file: File,
  isPublic: boolean,
  title: string | null
) => {
  return new Promise<ReadMediaResponse[] | any>((resolve, reject) => {
    const uploadUrl = URL_TO_MEDIA_API + '/v1/doc';

    //FIXME:types
    let fd: any = new FormData();
    fd.append('file', file);
    fd.append('title', title);
    fd.append('public', isPublic);
    fd.append('copyright', null);
    fd.append('keywords', null);
    authAxios
      .put(uploadUrl, fd)
      .then((result: UploadDocResponseType) => {
        resolve(result.data);
      })
      .catch((error: ErrorResponseType) => {
        console.log(error);
        if (error && error.response) {
          reject(error.response);
        } else {
          reject('');
        }
      });
  });
};

//TRANSLATE MEDIA IDS
//FIXME:Types
export const translateMediaIds = (
  mediaIds: string[],
  userId: string | null
) => {
  return new Promise((resolve, reject) => {
    if (mediaIds && mediaIds.length > 0) {
      authAxios({
        method: 'post',
        url: URL_TO_MEDIA_API + '/v1/' + userId + '/media',
        data: { ids: mediaIds }
      })
        .then((result: ReadMediaIdResponseType) => {
          resolve(result.data.data);
        })
        .catch((error: ErrorResponseType) => {
          if (error && error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    } else {
      resolve([]);
    }
  });
};

//TRANSLATE MEDIA IDS
//FIXME:Types
export const translateAlphaMediaIds = (mediaIds: string[]) => {
  return new Promise((resolve, reject) => {
    if (mediaIds && mediaIds.length > 0) {
      authAxios({
        method: 'post',
        url: URL_TO_MEDIA_API + '/v1/media',
        data: { ids: mediaIds }
      })
        .then((result: ReadMediaIdResponseType) => {
          resolve(result.data.data);
        })
        .catch((error: ErrorResponseType) => {
          console.log('ERROR');
          console.log(error);
          if (error && error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    } else {
      resolve([]);
    }
  });
};

//DOWNLOAD DOCUMENT
export const downloadDocumentFromServer = (
  src: string,
  secretKey?: string | null
) => {
  if (secretKey) {
    return new Promise((resolve, reject) => {
      authAxios({
        method: 'get',
        url: src,
        responseType: 'blob',
        headers: { 'x-bee-secure': secretKey }
      })
        .then((result: any) => {
          resolve(result.data);
        })
        .catch((error: ErrorResponseType) => {
          console.log(error);
          if (error && error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      authAxios({
        method: 'get',
        url: src,
        responseType: 'blob'
      })
        .then((result: any) => {
          resolve(result.data);
        })
        .catch((error: ErrorResponseType) => {
          console.log(error);
          if (error && error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
};

//UPLOAD IMAGE
export const uploadImageToServer = (
  file: File,
  isPublic: boolean,
  title: string | null
) => {
  return new Promise<ImageResponse | any>((resolve, reject) => {
    const uploadUrl = URL_TO_MEDIA_API + '/v1/img';
    let fd: any = new FormData();
    fd.append('file', file);
    fd.append('title', title);
    fd.append('public', isPublic);
    fd.append('copyright', null);
    fd.append('keywords', null);
    console.log('upload images');
    console.log(file);
    console.log(title);
    authAxios
      .put(uploadUrl, fd)
      .then((result: { data: ImageResponse }) => {
        resolve(result.data);
      })
      .catch((error: ErrorResponseType) => {
        console.log(error);
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

export function convertDocResponse(data: ReadMediaArrayResponse[]) {
  let docs: DocType[] = [];
  data.forEach(function (document: ReadMediaArrayResponse) {
    const doc = document.document;
    docs.push({
      id: doc.id,
      title: doc.title,
      fileSrc: URL_TO_MEDIA_SERVER + doc.url,
      timestamp: new Date(doc.createdAt),
      type: doc.type,
      copyright: doc.copyright,
      secured: doc.access ? true : false,
      fileKey: doc.access ? doc.access : undefined,
      filename: doc.filename,
      size: doc.fileSize
    });
  });

  return docs;
}

/* MEDIA */
export function translateImageIdsToData(imgs: ReadMediaArrayResponse[]) {
  let convertedImages = [];
  if (imgs) {
    for (let i = 0; i < imgs.length; i++) {
      const currImg = imgs[i].image;
      //FIXME find medium, find origin sonst
      if (currImg.versions) {
        const mediumVersion = _.find(currImg.versions, function (version: any) {
          return version.vLabel === 'MEDIUM';
        });
        const originVersion = _.find(currImg.versions, function (version: any) {
          return version.vLabel === 'ORIGIN';
        });

        if (mediumVersion) {
          convertedImages.push({
            id: currImg.id,
            url: URL_TO_MEDIA_SERVER + mediumVersion.url,
            key: mediumVersion.access,
            title: '',
            alt: '',
            copyright: '',
            secured: mediumVersion.access ? true : false,
            showPlaceholder: false
          });
        } else if (originVersion) {
          convertedImages.push({
            id: currImg.id,
            url: URL_TO_MEDIA_SERVER + originVersion.url,
            key: originVersion.access,
            title: '',
            alt: '',
            copyright: '',
            secured: originVersion.access ? true : false,
            showPlaceholder: false
          });
        }
      }
    }
  }

  return convertedImages;
}

export function translateImageToData(img: any) {
  if (img) {
    const currImg = img;
    for (let i = 0; i < currImg.versions.length; i++) {
      const version = currImg.versions[i];
      if (version && version.vLabel) {
        if (version.vLabel === 'MEDIUM') {
          return {
            id: currImg.id,
            url: URL_TO_MEDIA_SERVER + version.url,
            key: version.access,
            title: '',
            alt: '',
            copyright: '',
            secured: version.access ? true : false,
            showPlaceholder: false
          };
        }
        //FIXME keine medium version
      }
    }
  }
  return null;
}

export const pingMediaService = () => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: URL_TO_MEDIA_API + '/v1/about_api'
    })
      .then((result: any) => {
        resolve(result.data.data);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};
