import React from 'react';
import { Button } from 'primereact/button';
import './BeeOutlinedButton.scss';

type BeeOutlinedButtonProps = {
  id?: string;
  label: string;
  disabled?: boolean;
  type?: string;
  isLoading?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export function BeeOutlinedButton({
  id,
  label,
  disabled,
  type,
  isLoading,
  onClick
}: BeeOutlinedButtonProps) {
  //classNames
  const defaultClassNames = 'p-button-outlined';

  const typeClassNames =
    type === 'primary'
      ? ' bee-outlined-btn-primary'
      : type === 'secondary'
      ? ' p-button-secondary bee-outlined-btn-secondary'
      : type === 'danger'
      ? ' p-button-danger bee-outlined-btn-danger'
      : type === 'light'
      ? ' bee-outlined-btn-light'
      : ' bee-outlined-btn-primary';

  const classNames = defaultClassNames + typeClassNames;

  function clicked(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (!disabled && onClick) {
      onClick(e);
    }
  }

  return (
    <Button
      disabled={disabled}
      loading={isLoading ? true : false}
      label={label}
      className={classNames}
      onClick={(e) => clicked(e)}
    />
  );
}

export default BeeOutlinedButton;
