import { Dialog } from 'primereact/dialog';
import React, { createRef, useState } from 'react';

import Dropzone from 'react-dropzone';

import { ENTER, ESCAPE } from '../../../Helper/Statics/KeyCodes';
import fileSizeSI from '../../../Helper/Util/fileSizeConverter';
import BeeButton from '../../Atoms/BeeButton';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeContentParagraph from '../../Atoms/BeeContentParagraph';
import BeeLinkButton from '../../Atoms/BeeLinkButton';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import BeeValidatedTextInput from '../../Atoms/BeeValidatedTextInput';
import { FileUploadType } from '../../Pages/DLPages/DLPriceInputPage';
import './BeeUploadDialog.scss';

type BeeUploadDialogProps = {
  type: string;
  visible: boolean;
  disabled: boolean;
  locale: string;
  header: string;
  info?: string;
  titleLabel: string;
  titleVisible: boolean;
  copyrightLabel: string;
  copyrightVisible: boolean;
  dropzoneTitle: string;
  dropzoneDescription: string;
  dropzoneAddLabel: string;
  dropzoneFormats: any;
  progressVisible: boolean;
  progressMessage: string;
  errorVisible: boolean;
  errorHeadline: string | undefined;
  errorDescription: string | undefined;
  onHide: () => void;
  onUpload: ({
    file,
    name,
    copyright,
    originalFileName
  }: FileUploadType) => void;
};

export function BeeUploadDialog({
  type,
  visible,
  disabled,
  locale,
  header,
  info,
  titleLabel,
  titleVisible,
  copyrightLabel,
  copyrightVisible,
  dropzoneTitle,
  dropzoneDescription,
  dropzoneAddLabel,
  dropzoneFormats,
  progressVisible,
  progressMessage,
  errorVisible,
  errorHeadline,
  errorDescription,
  onHide,
  onUpload
}: BeeUploadDialogProps) {
  const dropzoneRef: any = createRef();
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const [fileTitle, setFileTitle] = useState<string | null>('');
  const [fileCopyright, setFileCopyright] = useState<string | null>('');

  //classNames
  const typeClassNames =
    type === 'primary'
      ? ' bee-upload-dialog-primary'
      : type === 'secondary'
      ? ' bee-upload-dialog-secondary'
      : ' bee-upload-dialog-primary';
  const classNames = 'bee-upload-dialog' + typeClassNames;

  // EXTERNAL CALLBACKS
  function hide() {
    if (onHide) {
      onHide();
      setSelectedFile(null);
    }
  }

  function accept() {
    if (validate()) {
      if (onUpload) {
        onUpload({
          file: selectedFile,
          name: fileTitle,
          copyright: fileCopyright,
          originalFileName: selectedFile?.name
        });
      }
    }
  }

  // INTERNAL & VALIDATION
  function validate() {
    if (selectedFile) {
      return true;
    }
    return false;
  }

  function onKeyPress(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.keyCode === ENTER && selectedFile) {
      accept();
    }
    if (e.keyCode === ESCAPE) {
      hide();
    }
  }

  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (dropzoneRef && dropzoneRef.current) {
      let node = dropzoneRef.current;
      node.open();
    }
  };

  // INJECTED VIEWS
  const injectInfoIfGiven = () => {
    if (info) {
      return (
        <BeeContentParagraph text={info} size={'medium'} type={'default'} />
      );
    } else {
      return null;
    }
  };

  const injectEmptyDropTemplate = () => {
    return (
      <div className="bee-upload-dialog-empty-drop">
        <BeeContentHeadline
          headline={'h4'}
          label={dropzoneTitle}
          type={'primary'}
        />
        <BeeContentParagraph
          text={dropzoneDescription}
          size={'small'}
          type={'default'}
        />
        <BeeButton
          label={dropzoneAddLabel}
          disabled={disabled}
          type={'primary'}
          isLoading={false}
          onClick={openDialog}
        ></BeeButton>
        <div className={'bee-upload-dialog-formats'}>
          {'Unterstützte Formate sind: ' +
            getFileFormatsAsString(dropzoneFormats)}
        </div>
        <div className={'bee-upload-dialog-info'}>
          <span>
            {' '}
            Achtung: Hochzuladende Dateien dürfen eine maximale Dateigröße von{' '}
          </span>
          <span className={'bold'}> 50MB</span>
          <span> nicht übersteigen.</span>
        </div>
      </div>
    );
  };

  function getFileFormatsAsString(dropzoneFormats: any) {
    let string = '';
    Object.keys(dropzoneFormats).forEach((format) => {
      string = string + dropzoneFormats[format] + ', ';
    });
    return string;
  }

  const injectSelectedDropTemplate = () => {
    if (selectedFile) {
      return (
        <div className="bee-upload-dialog-selected-drop">
          <BeeContentHeadline
            headline={'h4'}
            label={'"' + selectedFile.name + '" ' + 'wurde ausgewählt'}
            type={'primary'}
          />
          <i className="pi pi-file" />
          <BeeButton
            label={'Datei ändern'}
            disabled={disabled}
            type={'primary'}
            isLoading={false}
            onClick={openDialog}
          ></BeeButton>
          <div className={'bee-upload-dialog-file-info'}>
            <span>
              {'Dateigröße: '}
              {fileSizeSI(selectedFile.size)}
            </span>
            <span>
              {' Letzte Änderung: '}
              {new Date(selectedFile.lastModified).toLocaleDateString(locale)}
            </span>
          </div>
        </div>
      );
    } else {
      return injectEmptyDropTemplate();
    }
  };

  const injectProgressDropTemplate = () => {
    return (
      <div className="bee-upload-dialog-progress">
        <BeeLoadingSpinner strokeWidth={'3'} type={'mixed'} />
        <div className={'bee-upload-dialog-progress-msg'}>
          {progressMessage}
        </div>
      </div>
    );
  };

  const injectErrorDropTemplate = () => {
    return (
      <div className="bee-upload-dialog-error">
        <BeeContentHeadline
          headline={'h4'}
          label={errorHeadline ? errorHeadline : ''}
        />
        <div className="grid">
          <i className="col-fixed col-align-center pi pi-exclamation-triangle" />
          <p className="col col-align-center bee-upload-dialog-error-descr">
            {errorDescription}
          </p>
        </div>
      </div>
    );
  };

  const injectTitleAndCopyrightInputs = () => {
    if (titleVisible || copyrightVisible) {
      return (
        <div>
          <p>{'Weitere Informationen:'}</p>
          {titleVisible ? (
            <BeeValidatedTextInput
              label={titleLabel}
              value={fileTitle ? fileTitle : ''}
              disabled={disabled}
              formstate={'neutral'}
              readOnly={progressVisible}
              required={false}
              onChange={(e) => setFileTitle(e.target.value)}
            />
          ) : null}
          {copyrightVisible ? (
            <BeeValidatedTextInput
              label={copyrightLabel}
              value={fileCopyright ? fileCopyright : ''}
              disabled={disabled}
              formstate={'neutral'}
              readOnly={progressVisible}
              required={false}
              onChange={(e) => setFileCopyright(e.target.value)}
            />
          ) : null}
        </div>
      );
    }
  };

  const injectDropZone = () => {
    return (
      <Dropzone
        ref={dropzoneRef}
        noClick
        noKeyboard
        accept={dropzoneFormats}
        disabled={disabled}
        onDrop={(files: any) => {
          if (files && files.length > 0) {
            console.log(files[0]);
            setSelectedFile(files[0]);
            setFileTitle(files[0] ? files[0].name : null);
          }
        }}
      >
        {({
          getRootProps,
          getInputProps
        }: {
          getRootProps: any;
          getInputProps: any;
        }) => {
          return (
            <div className="container">
              <div
                {...getRootProps({ className: 'bee-upload-dialog-dropzone' })}
              >
                <input {...getInputProps()} />
                {errorVisible
                  ? injectErrorDropTemplate()
                  : selectedFile
                  ? progressVisible
                    ? injectProgressDropTemplate()
                    : injectSelectedDropTemplate()
                  : injectEmptyDropTemplate()}
              </div>
            </div>
          );
        }}
      </Dropzone>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <BeeLinkButton
          label={'Abbrechen'}
          raised={false}
          disabled={progressVisible}
          type={'primary'}
          onClick={() => hide()}
        />
        <BeeButton
          label={'Hochladen'}
          disabled={!selectedFile || progressVisible}
          type={type}
          onClick={() => accept()}
        />
      </div>
    );
  };

  const renderErrorFooter = () => {
    return (
      <div>
        <BeeButton
          label={'Schließen'}
          disabled={false}
          type={type}
          onClick={() => hide()}
        />
      </div>
    );
  };

  return (
    <div onKeyDown={(e) => onKeyPress(e)} tabIndex={0}>
      <Dialog
        className={classNames}
        header={header}
        visible={visible}
        modal={true}
        footer={!errorVisible ? renderFooter() : renderErrorFooter()}
        onHide={() => hide()}
      >
        {injectInfoIfGiven()}
        {injectDropZone()}
        {selectedFile && !progressVisible && !errorVisible
          ? injectTitleAndCopyrightInputs()
          : null}
      </Dialog>
    </div>
  );
}

export default BeeUploadDialog;
