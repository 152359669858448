import _ from 'lodash';
import { DataView } from 'primereact/dataview';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LiegenschaftsData } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import { ImageType } from '../../Pages/AlphaPages/AlphaLiegenschaftVerwaltung';
import LiegenschaftDialog from '../Dialogs/LiegenschaftDialog';
import './AlphaAnalysisProperties.scss';
import AlphaAnalysisLiegenschaftCard from './AlphaAnalysisLiegenschaftCard';
import {
  EnlargedLvPosition,
  EpPosition,
  LV
} from '../../../Helper/ApiHelper/LvEpNetworkHelper';
import { AuthUserType } from '../../../Helper/ApiHelper/LoginNetworkHelper';
import { TimelineStep } from '../../Atoms/BeeTimeline';
import DownloadCombinedPositions from '../DownloadCombinedPositions';

type AlphaAnalysisPropertiesProps = {
  properties?: LiegenschaftsData[];
  allProperties?: LiegenschaftsData[];
  serviceProvider: AuthUserType[];
  imageLookup?: Map<string, ImageType>;
  lvData: Map<string, EnlargedLvPosition[]>;
  rawLVs: LV[];
  epLookup?: Map<string, EpPosition>;
  epTree: any;
  phase: TimelineStep;
  keyValues: any;
  disabled: boolean;
};

export default function AlphaAnalysisProperties({
  properties,
  allProperties,
  serviceProvider,
  imageLookup,
  lvData,
  rawLVs,
  epLookup,
  epTree,
  phase,
  keyValues,
  disabled
}: AlphaAnalysisPropertiesProps) {
  const [currProperty, setCurrProperty] = useState<LiegenschaftsData>();
  const [vDialog, setVDialog] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  function renderDataViewtemplate(property: LiegenschaftsData) {
    const propId: string = property?.id ? property.id : 'no-id';
    let image = null;
    if (property.hauptBildId) {
      image = imageLookup?.get(property.hauptBildId);
    }
    return (
      <div className={'alpha-analysyis-ls-card '}>
        <AlphaAnalysisLiegenschaftCard
          property={property}
          serviceProvider={serviceProvider}
          image={image ? image : null}
          lvData={lvData.get(property.id!)}
          keyValues={keyValues}
          activateHover={!disabled}
          onClick={() => {
            if (!disabled && searchParams.get('lId') !== propId) {
              searchParams.set('lId', propId);
              setSearchParams(searchParams);
            }
          }}
          onClickInfo={() => {
            setCurrProperty(property);
            setVDialog(true);
          }}
        />
      </div>
    );
  }

  const injectRegionSlice = (
    region: string,
    properties?: LiegenschaftsData[]
  ) => {
    return (
      <div key={'dlOverviewProp_' + region}>
        <h2>BUNDESLAND: {region}</h2>
        <div>
          <DataView
            value={properties}
            itemTemplate={renderDataViewtemplate}
            header={null}
            emptyMessage={'Keine Liegenschaften verfügbar!'}
          />
        </div>
      </div>
    );
  };

  function injectSlices(properties: LiegenschaftsData[]) {
    let allContainedRegions: string[] = [];
    const regions: Map<string, LiegenschaftsData[]> = new Map();
    for (let i = 0; i < properties.length; i++) {
      if (properties[i] && properties[i].adresse) {
        allContainedRegions.push(properties[i].adresse!.region);
        let data = regions.get(properties[i].adresse!.region);
        data = data ? data : [];
        data.push(properties[i]);
        regions.set(properties[i].adresse!.region, data);
      }
    }
    //minimize allContainedRegions and sort alphabetically
    allContainedRegions = _.sortBy(_.uniq(allContainedRegions));
    return allContainedRegions.map((region) => {
      return injectRegionSlice(region, regions.get(region));
    });
  }

  return (
    <div className={'dl-overview-properties'}>
      {properties ? injectSlices(properties) : null}
      {vDialog ? (
        <LiegenschaftDialog
          property={currProperty}
          visible={vDialog}
          onHide={() => setVDialog(false)}
        />
      ) : null}
    </div>
  );
}
