import { useId } from 'react-id-generator';
import './BeeContentHeadline.scss';

type BeeContentHeadlineProps = {
  id?: string;
  label: string;
  headline: string;
  type?: string;
};

export function BeeContentHeadline({
  id,
  label,
  headline,
  type
}: BeeContentHeadlineProps) {
  const genId = useId(1, 'bee-content-headline-')[0];
  const currentId = id ? id : genId;

  //classNames
  const classNames =
    type === 'primary'
      ? 'bee-headline bee-headline-primary'
      : type === 'secondary'
      ? 'bee-headline bee-headline-secondary'
      : 'bee-headline bee-headline-primary';

  return headline === 'h1' ? (
    <h1 id={currentId} className={classNames}>
      {label}
    </h1>
  ) : headline === 'h2' ? (
    <h2 id={currentId} className={classNames}>
      {label}
    </h2>
  ) : headline === 'h3' ? (
    <h3 id={currentId} className={classNames}>
      {label}
    </h3>
  ) : headline === 'h4' ? (
    <h4 id={currentId} className={classNames}>
      {label}
    </h4>
  ) : headline === 'h5' ? (
    <h5 id={currentId} className={classNames}>
      {label}
    </h5>
  ) : (
    <h1 id={currentId} className={classNames}>
      {label}
    </h1>
  );
}

export default BeeContentHeadline;
