import _ from 'lodash';
import { TimelineStep } from '../../UI/Atoms/BeeTimeline';
import { LiegenschaftsData } from '../ApiHelper/LiegenschaftenNetworkHelper';
import {
  EnlargedLvPosition,
  EpPosition,
  LvPosition
} from '../ApiHelper/LvEpNetworkHelper';
import {
  DEFAULT_CALCULATION_MULTIPLIER,
  DEFAULT_FACTOR,
  LeistungsSlices
} from '../Statics/Constants';
import {
  generateBlFactorId,
  generateEpEntryId,
  generateLsFactorId,
  generateLsInMarktgebietId
} from '../Util/IdGeneratorHelper';

export function calculateGP(
  amount: number,
  priceValue: number,
  blValue: number,
  lsValue: number,
  nValue: number
) {
  const fakEp = calculateFakEp(priceValue, blValue, lsValue, nValue);
  return amount * fakEp;
}

export function calculateFakEp(
  priceValue: number,
  blValue: number,
  lsValue: number,
  nValue: number
) {
  return _.round(
    (priceValue * blValue * lsValue * nValue) /
      (DEFAULT_FACTOR * DEFAULT_FACTOR * DEFAULT_FACTOR)
  );
}

export function calculateSlices(
  enlargedLvPositions: EnlargedLvPosition[],
  values: any
) {
  const lookup = new Map<string, number>();
  LeistungsSlices.forEach((e: any) => {
    if (e.tag) {
      e.tag.forEach((tag: any) => {
        lookup.set(tag, 0);
      });
    }
  });
  if (enlargedLvPositions) {
    enlargedLvPositions.forEach((pos) => {
      const tag: string = pos.sliceTag ? pos.sliceTag : '';
      const amount: number = pos.amount ? pos.amount : 0;
      const price = values[pos.epPriceId];
      const blFactor = values[pos.blFactorId];
      const lsFactor = values[pos.lsFactorId];
      const nFactor = values[pos.nFactorId];
      //hier nicht gebraucht
      // const mFactor = values[pos.mFactorId];
      const priceValue: number = price && price.value ? price.value : 0;
      const blValue: number =
        blFactor && blFactor.value ? blFactor.value : DEFAULT_FACTOR;
      const lsValue: number =
        lsFactor && lsFactor.value ? lsFactor.value : DEFAULT_FACTOR;
      const nValue: number =
        nFactor && nFactor.value ? nFactor.value : DEFAULT_FACTOR;
      let sum: number =
        calculateGP(amount, priceValue, blValue, lsValue, nValue) /
        DEFAULT_CALCULATION_MULTIPLIER;
      //if its an inactive optionalPosition, set GP (sum) to 0
      if (pos.optionalPosition && !pos.optionalPositionActive) {
        sum = 0;
      }
      if (tag) {
        let val = lookup.get(tag);
        val = val ? val + sum : sum;
        lookup.set(tag, val);
      }
    });
  }
  return lookup;
}

export function filterArrayByHavingLvPosLeafs(array: any) {
  const getChildren = (result: any, object: any) => {
    if (object.className === 'lv-pos') {
      result.push(object);
      return result;
    }
    if (Array.isArray(object.children)) {
      const children = object.children.reduce(getChildren, []);
      if (children.length) result.push({ ...object, children });
    }
    return result;
  };
  return array.reduce(getChildren, []);
}

export function generateEnlargedLV(
  lv: LvPosition[],
  property: LiegenschaftsData,
  blID: string,
  phase: TimelineStep,
  epLookup?: Map<string, EpPosition>
) {
  //create slice lookup
  const sliceLookup = new Map();
  LeistungsSlices.forEach((e: any) => {
    if (e.tag) {
      e.tag.forEach((tag: any) => {
        sliceLookup.set(tag, e);
      });
    }
  });
  //enlarge lv positions
  let enlargedLvPositions: EnlargedLvPosition[] = [];
  if (epLookup && lv) {
    lv.forEach((lvPos) => {
      if (lvPos.epCode) {
        const epPos = epLookup.get(lvPos.epCode);
        if (epPos) {
          const slice = sliceLookup.get(epPos.sliceTag);
          if (slice) {
            enlargedLvPositions.push({
              id: lvPos.id,
              serialNumber: lvPos.serialNumber,
              identificationNumber: lvPos.identificationNumber,
              amount: lvPos.amount,
              description: lvPos.description,
              optionalPosition: lvPos.optionalPosition,
              optionalPositionActive: lvPos.optionalPositionActive,
              epCode: lvPos.epCode,
              //connections to EP
              leftInt: epPos.leftInt,
              rightInt: epPos.rightInt,
              sliceTag: epPos.sliceTag,
              unit: epPos.unit,
              epPriceId: generateEpEntryId(phase, lvPos.epCode),
              blFactorId: generateBlFactorId(phase, blID, slice),
              lsFactorId: generateLsFactorId(phase, property, false, slice),
              nFactorId: generateLsFactorId(phase, property, true, slice),
              mFactorId: generateLsInMarktgebietId(phase, property)
            });
          }
        }
      }
    });
  }
  //sort by serialNumber
  return _.sortBy(enlargedLvPositions, ['serialNumber']);
}
