import { Card } from 'primereact/card';
import { useId } from 'react-id-generator';
//theme
import BeeImage from '../../Atoms/BeeImage';
import { ImageType } from '../../Pages/AlphaPages/AlphaLiegenschaftVerwaltung';
import './AlphaLiegenschaftEingabeCard.scss';

export type LiegenschaftType = {
  id: string;
  organisationId: string;
  createdAt: string;
  name: string;
  nummer: string;
  beschreibung: string;
  anmerkung: string;
  eigentuemer: string;
  verantwortlicher: string;
  hauptBildId: string;
  hauptPlanId: string;
  mediaIds: string[];
  adresse: {
    strasse: string;
    hausnummer: string;
    postleitzahl: string;
    stadt: string;
    region: string;
    land: string;
    longitude: number;
    latitude: number;
  };
  gebaeudeIds: string[];
};

type AlphaLiegenschaftEingabeCardProps = {
  liegenschaft: LiegenschaftType;
  image: ImageType;
  activateHover: boolean;
  onClick: any;
};

export function AlphaLiegenschaftEingabeCard({
  liegenschaft,
  image,
  activateHover,
  onClick
}: AlphaLiegenschaftEingabeCardProps) {
  const currentId = useId(1, 'alpha-liegenschaft-eingabe-prev-')[0];

  //classNames
  const activateHoverClassNames = activateHover
    ? 'alpha-liegenschaft-eingabe-prev-hover'
    : 'alpha-liegenschaft-eingabe-prev-noHover';
  const orientationClassName = 'alpha-liegenschaft-eingabe-prev-card ';

  const classNames =
    'alpha-liegenschaft-eingabe-prev ' +
    orientationClassName +
    activateHoverClassNames;

  const cardHeadlineClassNames =
    'alpha-liegenschaft-eingabe-prev-card-headline';
  const cardImgClassNames = 'alpha-liegenschaft-eingabe-prev-card-img';
  const cardTableClassNames = 'alpha-liegenschaft-eingabe-prev-card-table';

  function clicked(e: any) {
    if (onClick) {
      onClick(e);
    }
  }

  const injectHeadline = () => {
    return (
      <>
        {liegenschaft.adresse.stadt ? (
          <div>{liegenschaft.adresse.stadt}</div>
        ) : (
          <div></div>
        )}
        <h2>{liegenschaft.name}</h2>
        {liegenschaft.nummer ? <div>{liegenschaft.nummer}</div> : <div></div>}
      </>
    );
  };

  const injectImage = () => {
    if (image && liegenschaft.hauptBildId) {
      return (
        <BeeImage
          img_url={image.url}
          img_key={image.key}
          alt={image.alt}
          title={image.title}
          copyright={image.copyright}
          isSecured={image.secured ? image.secured : false}
          showDefault={false}
          showPlaceholder={
            image.showPlaceholder ? image.showPlaceholder : false
          }
        />
      );
    } else if (liegenschaft.hauptBildId) {
      //show loader
      return (
        <BeeImage
          img_url={''}
          img_key={''}
          alt={''}
          title={''}
          copyright={''}
          isSecured={false}
          showDefault={false}
          showPlaceholder={true}
        />
      );
    } else {
      //show default property image
      return (
        <BeeImage
          img_url={''}
          img_key={''}
          alt={''}
          title={''}
          copyright={''}
          isSecured={false}
          showDefault={true}
          showPlaceholder={false}
        />
      );
    }
  };

  const injectCardLayout = () => {
    return (
      <>
        <div onClick={(e) => clicked(e)}>
          <div className={cardHeadlineClassNames}>{injectHeadline()}</div>

          <div className={cardImgClassNames}>
            {injectImage()}
            <table className={cardTableClassNames}>
              <tbody>
                <tr>
                  <td>
                    {liegenschaft.adresse.strasse +
                      ' ' +
                      liegenschaft.adresse.hausnummer}
                  </td>
                </tr>
                <tr>
                  <td>
                    {liegenschaft.adresse.postleitzahl +
                      ' ' +
                      liegenschaft.adresse.stadt}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };

  return (
    <Card id={currentId} className={classNames}>
      {injectCardLayout()}
    </Card>
  );
}

export default AlphaLiegenschaftEingabeCard;
