import _ from 'lodash';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { Phase } from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import { ENTER_KEY, ESCAPE_KEY } from '../../../Helper/Statics/KeyCodes';
import BeeButton from '../../Atoms/BeeButton';
import BeeValidatedDateInput from '../../Atoms/BeeValidatedDateInput';
import BeeValidatedTextInput from '../../Atoms/BeeValidatedTextInput';
import './AlphaPhaseDialog.scss';

type AlphaPhaseDialogProps = {
  data?: Phase;
  phasen: Phase[];
  visible: boolean;
  readOnly: boolean;

  onHide: () => void;
  onAdapt: (data: Phase) => void;
};

export default function AlphaPhaseDialog({
  data,
  phasen,
  visible,
  readOnly,
  onHide,
  onAdapt
}: AlphaPhaseDialogProps) {
  const [title, setTitle] = useState<string>('');
  const [errorTitle, setErrorTitle] = useState<string>('');
  const [start, setStart] = useState<any>();
  const [errorStart, setErrorStart] = useState<string>('');
  const [end, setEnd] = useState<any>();
  const [errorEnd, setErrorEnd] = useState<string>('');
  const [errorDialog, setErrorDialog] = useState<string>('');
  const toast = useRef<Toast>(null);

  const startTime = 8;
  const endTime = 2;
  const startTimeMinutes = 0;
  const timeZoneOffset = 2;

  useEffect(() => {
    if (data) {
      setTitle(data.title);
      setStart(data.start);
      setEnd(data.end);
    }
  }, [data]);

  function adaptEntry() {
    if (onAdapt && isInputValid()) {
      let newPhase: any = {};
      if (data) {
        newPhase = _.cloneDeep(data);
        newPhase.title = title;
        newPhase.start = start;
        newPhase.end = end;
      } else {
        newPhase.id = 'no_id';
        newPhase.title = title;
        newPhase.start = start;
        newPhase.end = end;
      }

      if (isIntersectionValid(newPhase)) {
        onAdapt(newPhase);
      }
    }
  }
  function isInputValid() {
    setErrorTitle('');
    setErrorStart('');
    setErrorEnd('');
    if (!title) {
      setErrorTitle('Bitte geben Sie einen Namen an');
    }
    if (!start) {
      setErrorStart('Bitte geben Sie ein Startdatum an');
    }
    if (!end) {
      setErrorEnd('Bitte geben Sie ein Enddatum an');
    }
    if (title && start && end) {
      return true;
    } else {
      return false;
    }
  }
  function isIntersectionValid(newPhase: Phase) {
    //new Phase cannot start while other hasnt ended
    //new phase cannot end after other has started
    let allPhases = _.cloneDeep(phasen);
    if (newPhase.id) {
      allPhases = _.remove(allPhases, function (phase: Phase) {
        return phase.id !== newPhase.id;
      });
    }

    if (allPhases) {
      let checkStart =
        newPhase && newPhase.start
          ? new Date(
              new Date(newPhase.start).setHours(startTime - timeZoneOffset)
            )
          : undefined;

      const checkEnd =
        newPhase && newPhase.end
          ? new Date(new Date(newPhase.end).setHours(endTime - timeZoneOffset))
          : null;
      for (let i = 0; i < allPhases.length; i++) {
        if (allPhases[i]) {
          const from = allPhases[i].start
            ? new Date(allPhases[i].start!)
            : null;
          const to = allPhases[i].end ? new Date(allPhases[i].end!) : null;

          //Start
          if (
            checkStart &&
            from &&
            to &&
            checkStart >= from &&
            checkStart < to
          ) {
            //'error Phase darf nicht innerhalb einer anderen beginnen'

            setErrorDialog(
              'Phasen dürfen sich in ihren Daten nicht überschneiden. Bitte wähle ein Startdatum das außerhalb der bisherigen Phasen liegt.'
            );
            return false;
          }
          //End
          if (checkEnd && from && to && checkEnd > from && checkEnd <= to) {
            //'error Phase darf nicht innerhalb einer anderen enden');

            setErrorDialog(
              'Phasen dürfen sich in ihren Daten nicht überschneiden. Bitte wähle ein Enddatum das außerhalb der bisherigen Phasen liegt.'
            );

            return false;
          }
          if (
            checkStart &&
            from &&
            to &&
            checkEnd &&
            checkStart < from &&
            checkEnd > to
          ) {
            //'error phase darf keine andere umschließen');
            setErrorDialog(
              'Phasen dürfen sich in ihren Daten nicht überschneiden. Bitte wähle Start- und Enddatum so, dass keine andere Phase umschlossen wird.'
            );

            return false;
          }
        }
      }
      //'Phase startet und ended außerhalb einer anderen');
      return true;
    }
  }

  function hide() {
    if (onHide) {
      onHide();
    }
  }

  function onKeyPress(e: React.KeyboardEvent<HTMLDivElement>) {
    if ((e.key === ESCAPE_KEY && visible) || (e.key === ENTER_KEY && visible)) {
      hide();
    }
  }

  function injectButtonRow() {
    return (
      <div className={'phase-btns flex justify-content-between'}>
        <BeeButton
          label={'Abbrechen'}
          disabled={false}
          type={'secondary'}
          isLoading={false}
          onClick={() => hide()}
        />
        {!readOnly ? (
          <BeeButton
            label={data ? 'Phase ändern' : 'Phase anlegen'}
            disabled={false}
            type={'primary'}
            isLoading={false}
            onClick={() => adaptEntry()}
          />
        ) : null}
      </div>
    );
  }

  function injectTitle() {
    return (
      <div>
        <BeeValidatedTextInput
          label={'Name'}
          value={title}
          disabled={false}
          errorLabel={errorTitle}
          formstate={errorTitle ? 'error' : 'neutral'}
          readOnly={readOnly}
          required={true}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
    );
  }

  function injectStart() {
    return (
      <div>
        <BeeValidatedDateInput
          label={'Start-Datum'}
          value={start ? new Date(start) : null}
          disabled={false}
          errorLabel={errorStart}
          formstate={errorStart ? 'error' : 'neutral'}
          readOnly={readOnly}
          required={true}
          onChange={(e: any) => {
            //fixme timezone startuhrzeit 00:00
            let date = new Date(e.target.value.setHours(startTime));
            date = new Date(date.setMinutes(startTimeMinutes));

            setStart(date);
          }}
        />
      </div>
    );
  }

  function injectEnd() {
    return (
      <div>
        <BeeValidatedDateInput
          label={'End-Datum'}
          value={end ? new Date(end) : null}
          disabled={false}
          errorLabel={errorEnd ? errorEnd : errorDialog ? errorDialog : null}
          formstate={errorEnd || errorDialog ? 'error' : 'neutral'}
          readOnly={readOnly}
          required={true}
          onChange={(e: any) => {
            //fixme timezone enduhrzeit 23:59
            let date = new Date(e.target.value.setHours(endTime));
            setEnd(date);
          }}
        />
      </div>
    );
  }

  return (
    <div onKeyDown={(e) => onKeyPress(e)}>
      <Dialog
        header={'Phase anlegen'}
        visible={visible}
        className={'alpha-phase-dialog'}
        modal={true}
        onHide={() => hide()}
      >
        <div>
          {injectTitle()}
          {injectStart()}
          {injectEnd()}
          {injectButtonRow()}
        </div>
      </Dialog>
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
