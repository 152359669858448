import _ from 'lodash';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import {
  Phase,
  readAllPhases
} from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import { readAllLiegenschaften } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import {
  MinimizedEpCatalog,
  generateEpTreeFromPositions,
  readEpCatalogForPhase,
  readEpCatalogMetaForAllPhases,
  readLvCatalogMetaForPhase,
  removeAllHiddenCategoriesFromEpTree
} from '../../../Helper/ApiHelper/LvEpNetworkHelper';
import {
  DURATION_NOTIFICATION_ERROR_LONG,
  DURATION_NOTIFICATION_SUCCESS_LONG
} from '../../../Helper/Statics/Constants';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import EpLvLiegenschaftLV from '../../Molecules/LvEp/EpLvLiegenschaftLV';
import EpLvPhase from '../../Molecules/LvEp/EpLvPhase';
import './AlphaSetupTabLvVerwaltung.scss';
import { handlePromisesAndIgnoreErrors } from '../../../Helper/Util/PromiseHelper';

export function AlphaSetupTabLvVerwaltung() {
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(false);
  const [liegenschaften, setLiegenschaften] = useState<any>([]);
  const [phases, setPhases] = useState<Phase[]>([]);
  const [epLookup, setEpLookup] = useState<Map<string, any>>();
  //refs
  const toast = useRef<Toast>(null);

  ///////////
  // SETUP //
  ///////////
  useEffect(() => {
    setDataIsLoading(true);
    //load all liegenschaften
    readAllLiegenschaften()
      .then((ls: any) => {
        let liegenschaften = [...ls];
        setLiegenschaften(liegenschaften);
        readAllPhases()
          .then((phases: any) => {
            let phaseVals: Phase[] = [...phases];
            loadEpCatalogAndLVsAsync(phaseVals);
          })
          .catch(() => {
            //ERROR phasen lesen fehler
            setDataIsLoading(false);
          });
      })
      .catch(() => {
        //ERROR liegenschaften fehler
        setDataIsLoading(false);
      });
  }, []);

  function loadEpCatalog(phases: Phase[]) {
    let epPromises: Promise<any>[] = [];
    phases.forEach((phase) => {
      epPromises.push(readEpCatalogForPhase(phase.id));
    });
    handlePromisesAndIgnoreErrors(epPromises).then((payload: any) => {
      let epCLookup = new Map<string, any>();
      if (payload && payload.fulfilled) {
        payload.fulfilled.forEach((c: any) => {
          if (c.value && c.value.data && c.value.info) {
            let currCatalog: any = generateEpTreeFromPositions(c.value.data);
            currCatalog = currCatalog ? currCatalog : [];
            currCatalog = removeAllHiddenCategoriesFromEpTree(
              currCatalog,
              false
            );
            epCLookup.set(c.value.info.phaseId, currCatalog);
          }
        });
      }
      setEpLookup(epCLookup);
    });
  }

  function loadEpCatalogAndLVsAsync(phases: Phase[]) {
    setDataIsLoading(true);
    //clean phases
    phases.forEach((p) => {
      p.lvs = [];
      p.ep = undefined;
    });
    readEpCatalogMetaForAllPhases()
      .then((epCatalogs: any) => {
        let epCats: MinimizedEpCatalog[] = [...epCatalogs];
        //search for phaseVals entry
        epCats.forEach((epE) => {
          if (epE && epE.phaseId) {
            //search for phaseVals entry
            for (let i = 0; i < phases.length; i++) {
              if (phases[i].id === epE.phaseId) {
                phases[i].ep = epE;
                break;
              }
            }
          }
        });
        //für jede phase einen minimalen lv ziehen!!!
        let promises: any = [];
        phases.forEach((p) => {
          promises.push(readLvCatalogMetaForPhase(p.id));
        });
        Promise.all(promises)
          .then((entries) => {
            if (entries) {
              entries.forEach((phArray) => {
                if (phArray && phArray.length > 0) {
                  const pId = phArray[0].phaseId;
                  for (let i = 0; i < phases.length; i++) {
                    if (phases[i].id === pId) {
                      phases[i].lvs = phArray;
                      break;
                    }
                  }
                }
              });
            }
            loadEpCatalog(phases);
            //finally set all data
            setPhases(phases);

            setDataIsLoading(false);
          })
          .catch(() => {
            //Error loading LVs-Catalog for Phases
            setDataIsLoading(false);
          });
      })
      .catch(() => {
        //Error loading EP-Catalog for Phases
        setDataIsLoading(false);
      });
  }

  ////////////////
  // UI METHODS //
  ////////////////

  function generatePhases() {
    const pay = phases;
    let data = [];
    data.push({ type: 'nonPhase', header: 'Liegenschaft' });
    data.push({ type: 'nonPhase', header: 'Adresse' });
    pay.forEach((entry) => data.push(entry));
    return data;
  }

  function generateLiegenschaftLvUploadTemplate(
    liegenschaftId: string,
    phaseId: string
  ) {
    const phase = _.find(phases, function (e: Phase) {
      return e.id === phaseId;
    });
    return (
      <EpLvLiegenschaftLV
        phase={phase}
        propertyId={liegenschaftId}
        onAdaptData={(headline, message) => {
          toast.current?.show({
            severity: 'success',
            summary: headline,
            detail: message,
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_SUCCESS_LONG
          });
          loadEpCatalogAndLVsAsync(phases);
        }}
      />
    );
  }

  const bodyTemplate = (
    data: any,
    options: ColumnBodyOptions,
    phaseId: string
  ) => {
    if (options) {
      if (options.field === 'field_0') {
        return (
          <div className={'lv-table-liegenschaft'}>
            <div className={'lv-number'}>{data.nummer}</div>
            <div>{data.name}</div>
          </div>
        );
      } else if (options.field === 'field_1') {
        return (
          <div>
            <div>
              <span>{data.adresse ? data.adresse.strasse : null}</span>{' '}
              <span>{data.adresse ? data.adresse.hausnummer : null}</span>
              <div></div>
              <span>
                {data.adresse ? data.adresse.postleitzahl : null}
              </span>{' '}
              <span>{data.adresse ? data.adresse.stadt : null}</span>
            </div>
          </div>
        );
      } else {
        return generateLiegenschaftLvUploadTemplate(data.id, phaseId);
      }
    }
  };

  const headerTemplate = (data: any, number: any) => {
    if (data.type === 'nonPhase') {
      return data.header;
    } else {
      return (
        <EpLvPhase
          phase={data}
          epTree={epLookup?.get(data.id)}
          onAdaptData={(headline, message) => {
            toast.current?.show({
              severity: 'success',
              summary: headline,
              detail: message,
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_SUCCESS_LONG
            });
            loadEpCatalogAndLVsAsync(phases);
          }}
        />
      );
    }
  };

  const dynamicColumns = generatePhases().map((col: any, i) => {
    const phaseId = col && col.id ? col.id : null;
    return (
      <Column
        body={(data: any, options: ColumnBodyOptions) =>
          bodyTemplate(data, options, phaseId)
        }
        className={
          col.type === 'nonPhase'
            ? col.header.replace(' ', '_') + '-col lv-nonPhase'
            : col.title.replace(' ', '_') + '-col lv-phase'
        }
        style={{ width: phaseId ? '5em' : '10em' }}
        frozen={phaseId ? false : true}
        alignFrozen="left"
        header={headerTemplate(col, i)}
      />
    );
  });

  function injectDescription() {
    return (
      <div className={'mb-3'}>
        Bitte laden Sie in folgender Tabelle für jede Phase einen EP-Katalog
        hoch. Anschließend kann für jede Liegenschaft ein Leistungsverzeichnis
        auf Basis der hochgeladenen EP-Leistungen hinterlegt werden. Ist ein
        Phase noch nicht abgeschlossen können Leistungsverzeichnisse geupdated
        werden. Für bereits abgelaufene Phasen können die Leistungsverzeichnisse
        nicht mehr verändert werden.
      </div>
    );
  }

  return (
    <div className={'alpha-lv-verwaltung m-2 mt-4 ml-1 p-2'}>
      <div className={''}>{injectDescription()}</div>
      {!dataIsLoading ? (
        <div className={'lv-verwaltung-table'}>
          <DataTable
            value={liegenschaften}
            scrollable
            scrollDirection="horizontal"
            emptyMessage={'Keine Liegenschaften vorhanden'}
          >
            {dynamicColumns}
          </DataTable>
        </div>
      ) : (
        <BeeLoadingSpinner strokeWidth={'3'} type={'secondary'} />
      )}
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
