import _ from 'lodash';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { ENTER_KEY, ESCAPE_KEY } from '../../../Helper/Statics/KeyCodes';
import './AlphaConfigEpTreeDialog.scss';

import { User, UserRight } from '../../../Helper/ApiHelper/AuthServiceHelper';
import BeeButton from '../../Atoms/BeeButton';
import BeeTextInput from '../../Atoms/BeeTextInput';
import BeeValidatedCheckboxTriState from '../../Atoms/BeeValidatedCheckboxTriState';

import {
  DURATION_NOTIFICATION_ERROR_LONG,
  USER_RIGHT_DLREAD,
  USER_RIGHT_DLWRITE_KEYVAL,
  USER_RIGHT_DLWRITE_MEDIA,
  VALIDATE_EMAIL_REGEX
} from '../../../Helper/Statics/Constants';
import BeeValidatedTextInput from '../../Atoms/BeeValidatedTextInput';
import { TreeTable } from 'primereact/treetable';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { EpPosition } from '../../../Helper/ApiHelper/LvEpNetworkHelper';
import TreeNode from 'primereact/treenode';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import { isBefore } from '../../../Helper/Util/TimelineStepHelper';
import { Phase } from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import { FileUploadType } from '../../Pages/DLPages/DLPriceInputPage';

type AlphaConfigEpTreeDialogProps = {
  data?: any;
  phase: Phase;
  visible: boolean;
  readOnly: boolean;
  onHide: () => void;
  onDelete: () => void;
  onReplace: () => void;
  onDownload: () => void;
};

export default function AlphaConfigEpTreeDialog({
  data,
  phase,
  visible,
  readOnly,
  onHide,
  onDelete,
  onReplace,
  onDownload
}: AlphaConfigEpTreeDialogProps) {
  const toast = useRef<Toast>(null);

  function hide() {
    onHide();
  }
  function replaceEp() {
    onReplace();
  }
  function deleteEp() {
    onDelete();
  }
  function downloadEp() {
    onDownload();
  }

  function onKeyPress(e: React.KeyboardEvent<HTMLDivElement>) {
    if ((e.key === ESCAPE_KEY && visible) || (e.key === ENTER_KEY && visible)) {
      hide();
    }
  }
  const titleTemplate = (node: any) => {
    const epEntry: EpPosition = node.data;
    return (
      <div className={'epTreeDialog-table-title'}>
        <div className={'epTreeDialog-ep-code'}>{epEntry.epCode}</div>
        <div>{`[${epEntry.number}] ${epEntry.title}`}</div>
      </div>
    );
  };

  function injectFooter() {
    return (
      <div className={'user-btns flex justify-content-end'}>
        <BeeButton
          label={'Schließen'}
          disabled={false}
          type={'primary'}
          isLoading={false}
          onClick={() => hide()}
        />
      </div>
    );
  }

  function injectButtonRow() {
    const phaseStarted: boolean =
      phase && phase.start && isBefore(new Date(), new Date(phase.start))
        ? true
        : false;
    const phaseEnded: boolean =
      phase && phase.end && isBefore(new Date(), new Date(phase.end))
        ? true
        : false;

    if (phaseStarted && !phaseEnded) {
      //phase is right now
      return (
        <div className={'user-btns flex justify-content-between'}>
          {!readOnly ? injectReplaceBtn() : null}
          {injectDownloadBtn()}
        </div>
      );
    } else if (phaseStarted && phaseEnded) {
      return (
        <div className={'user-btns flex justify-content-between'}>
          {injectDownloadBtn()}
        </div>
      );
    } else if (!phaseStarted && !phaseEnded) {
      return (
        <div className={'user-btns flex justify-content-between'}>
          <div>
            {' '}
            {!readOnly ? injectDeleteBtn() : null}
            {!readOnly ? injectReplaceBtn() : null}
          </div>

          {injectDownloadBtn()}
        </div>
      );
    } else {
      return <></>;
    }
  }

  function injectDeleteBtn() {
    return (
      <BeeButton
        label={
          <>
            <i className="icon-trash-can"></i>
            <span>{'EP-Katalog löschen'}</span>
          </>
        }
        disabled={false}
        type={'danger'}
        isLoading={false}
        onClick={() => deleteEp()}
      />
    );
  }

  function injectReplaceBtn() {
    return (
      <BeeButton
        label={'EP-Katalog ersetzen'}
        disabled={false}
        type={'secondary'}
        isLoading={false}
        onClick={() => replaceEp()}
      />
    );
  }
  function injectDownloadBtn() {
    return (
      <BeeButton
        label={
          <>
            <i className="icon-download-1"></i>
            <span>{'EP-Katalog herunterladen'}</span>
          </>
        }
        disabled={false}
        type={'secondary'}
        isLoading={false}
        onClick={() => downloadEp()}
      />
    );
  }

  function calculateRowClassName(rowData: TreeNode) {
    return {
      'priceInput-row-category-tabIndex': rowData.data.type === 'category',
      'priceInput-row-position-noTabIndex': rowData.data.type === 'position'
    };
  }

  return (
    <div onKeyDown={(e) => onKeyPress(e)}>
      <Dialog
        header={'EP-Katalog bearbeiten'}
        visible={visible}
        className={'alpha-configEpTree-dialog'}
        modal={true}
        blockScroll={true}
        footer={injectFooter}
        onHide={() => hide()}
      >
        <>
          <TreeTable
            value={data}
            rowClassName={(rowData: TreeNode) => calculateRowClassName(rowData)}
            className={'ep-table'}
            emptyMessage={'Keine Position gefunden'}
          >
            <Column
              columnKey={'configEpTree-title-col'}
              body={titleTemplate}
              header={
                <div className={'configEpTree-table-title'}>
                  <div>{'Position'}</div>
                </div>
              }
              className={'ep-position-col'}
              expander
            />
          </TreeTable>
          {injectButtonRow()}
        </>
      </Dialog>
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
