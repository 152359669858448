import _ from 'lodash';
import { TreeSelectSelectionKeys } from 'primereact/treeselect';
import { useEffect, useState } from 'react';
import { LiegenschaftsData } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import {
  DEFAULT_FACTOR,
  DROPDOWN_LIEGENSCHAFT_SORT,
  LIEGENSCHAFT_SORT_ADDRESS,
  LIEGENSCHAFT_SORT_BUILDING_NO,
  LIEGENSCHAFT_SORT_CITY,
  LIEGENSCHAFT_SORT_NAME
} from '../../../Helper/Statics/Constants';
import BeeDropDown from '../../Atoms/BeeDropDown';
import BeeSearchBar from '../../Atoms/BeeSearchBar';
import BeeTreeSelectLiegenschaften from '../../Atoms/BeeTreeSelectLiegenschaften';
import EmptySearchView from '../../Organisms/EmptySearchView';
import { ImageType } from '../../Pages/AlphaPages/AlphaLiegenschaftVerwaltung';
import './AlphaAnalysisOverview.scss';
import AlphaAnalysisProperties from '../../Molecules/Alpha/AlphaAnalysisProperties';
import { AuthUserType } from '../../../Helper/ApiHelper/LoginNetworkHelper';
import {
  EnlargedLvPosition,
  EpPosition,
  LV
} from '../../../Helper/ApiHelper/LvEpNetworkHelper';
import { TimelineStep } from '../../Atoms/BeeTimeline';
import DownloadCombinedPositions, {
  CalcLvPosition
} from '../../Molecules/DownloadCombinedPositions';
import { calculateGP } from '../../../Helper/Util/LvCalculator';

type AlphaAnalysisOverviewProps = {
  properties: LiegenschaftsData[];
  imageLookup?: Map<string, ImageType>;
  serviceProvider: AuthUserType[];
  keyValues: any;
  lvData: Map<string, EnlargedLvPosition[]>;
  rawLVs: LV[];
  epLookup?: Map<string, EpPosition>;
  epTree: any;
  phase: TimelineStep;
};

export default function AlphaAnalysisOverview({
  properties,
  imageLookup,
  serviceProvider,
  keyValues,
  lvData,
  rawLVs,
  epLookup,
  epTree,
  phase
}: AlphaAnalysisOverviewProps) {
  const [search, setSearch] = useState<any>('');
  const [sort, setSort] = useState<any>(LIEGENSCHAFT_SORT_CITY);
  const [visibleData, setVisibleData] = useState<LiegenschaftsData[]>();
  const [currentFilter, setCurrentFilter] =
    useState<TreeSelectSelectionKeys>(null);
  const [exportPriceLookup, setExportPriceLookup] =
    useState<Map<string, CalcLvPosition[]>>();

  useEffect(() => {
    setVisibleData(properties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties]);

  useEffect(() => {
    console.log(lvData);
    if (lvData) {
      const lookup: Map<string, CalcLvPosition[]> = new Map();
      lvData.forEach(function (value, key) {
        if (value && serviceProvider) {
          value.forEach((lvPos: EnlargedLvPosition) => {
            let posArray: CalcLvPosition[] = [];
            serviceProvider.forEach((dl: AuthUserType) => {
              let notAllPrices: boolean = false;
              let notInArea: boolean = false;
              const amount = lvPos.amount ? lvPos.amount : 0;
              const priceRaw = keyValues.get(lvPos.epPriceId)
                ? keyValues.get(lvPos.epPriceId)[dl.id]
                : null;
              let priceVal = 0;
              if (!priceRaw) {
                notAllPrices = true;
              } else {
                priceVal = parseInt(priceRaw);
              }
              const blFac = parseInt(
                keyValues.get(lvPos.blFactorId) &&
                  keyValues.get(lvPos.blFactorId)[dl.id]
                  ? keyValues.get(lvPos.blFactorId)[dl.id]
                  : DEFAULT_FACTOR
              );
              const lsFac = parseInt(
                keyValues.get(lvPos.lsFactorId) &&
                  keyValues.get(lvPos.lsFactorId)[dl.id]
                  ? keyValues.get(lvPos.lsFactorId)[dl.id]
                  : DEFAULT_FACTOR
              );
              const nFac = parseInt(
                keyValues.get(lvPos.nFactorId) &&
                  keyValues.get(lvPos.nFactorId)[dl.id]
                  ? keyValues.get(lvPos.nFactorId)[dl.id]
                  : DEFAULT_FACTOR
              );
              const blVal = blFac ? blFac : DEFAULT_FACTOR;
              const lsVal = lsFac ? lsFac : DEFAULT_FACTOR;
              const nVal = nFac ? nFac : DEFAULT_FACTOR;
              const mVal: boolean =
                keyValues.get(lvPos.mFactorId) &&
                keyValues.get(lvPos.mFactorId)[dl.id] === 'false'
                  ? false
                  : true;
              if (!mVal) {
                notInArea = true;
              }
              posArray.push({
                providerId: dl.id,
                gp: calculateGP(amount, priceVal, blVal, lsVal, nVal),
                noPriceGiven: notAllPrices,
                notInMarketArea: notInArea
              });
            });
            lookup.set(lvPos.id, posArray);
          });
        }
      });
      setExportPriceLookup(lookup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lvData, serviceProvider]);

  ///////////////////////
  /////// HELPER ////////
  ///////////////////////

  function filterUsingSearchTermSortAndFilter(
    search: string,
    sort: any,
    currentFilter: TreeSelectSelectionKeys
  ) {
    console.log(currentFilter);
    setSearch(search);
    setSort(sort);
    setCurrentFilter(currentFilter);
    if (!search || search === '') {
      if (!properties) {
        setVisibleData([]);
      } else {
        filterUsingFilter(currentFilter, sort, properties);
      }
    } else {
      if (properties && properties.length > 0) {
        let result = _.cloneDeep(properties);
        result = _.filter(result, function (ls: any) {
          const attr = [];
          if (ls.adresse) {
            attr.push(_.toLower(ls.adresse?.stadt ? ls.adresse.stadt : ''));
            attr.push(_.toLower(ls.adresse?.strasse ? ls.adresse.strasse : ''));
            attr.push(
              _.toLower(ls.adresse?.postleitzahl ? ls.adresse.postleitzahl : '')
            );
            attr.push(
              _.toLower(ls.adresse?.hausnummer ? ls.adresse.hausnummer : '')
            );
          }
          attr.push(_.toLower(ls.nummer ? ls.nummer : ''));
          attr.push(_.toLower(ls.name ? ls.name : ''));
          return _.includes(attr.join(' '), _.toLower(search));
        });
        filterUsingFilter(currentFilter, sort, result);
      }
    }
  }

  function filterUsingFilter(
    currentFilter: any,
    sort: any,
    payload: LiegenschaftsData[]
  ) {
    if (!currentFilter) {
      sortCurrentData(sort, payload);
    } else {
      let result = _.cloneDeep(payload);
      result = _.filter(result, function (ls: LiegenschaftsData) {
        const id: string = ls && ls.id ? ls.id : '';
        if (
          ls &&
          currentFilter &&
          currentFilter[id] &&
          currentFilter[id] &&
          currentFilter[id].checked === true
        ) {
          return true;
        }
        return false;
      });
      sortCurrentData(sort, result);
    }
  }

  function sortCurrentData(sort: any, payload: LiegenschaftsData[]) {
    let ls = _.cloneDeep(payload);
    //sort by choice
    switch (sort) {
      case LIEGENSCHAFT_SORT_NAME:
        ls = _.sortBy(ls, ['name', 'adresse.stadt']);
        break;
      case LIEGENSCHAFT_SORT_CITY:
        ls = _.sortBy(ls, ['adresse.stadt']);
        break;
      case LIEGENSCHAFT_SORT_ADDRESS:
        ls = _.sortBy(ls, ['adresse.strasse', 'adresse.hausnummer']);
        break;
      case LIEGENSCHAFT_SORT_BUILDING_NO:
        ls = _.sortBy(ls, ['nummer', 'adresse.stadt']);
        break;
      default:
    }
    setVisibleData(ls);
  }

  ///////////////////////
  /// VIEW COMPONENTS ///
  ///////////////////////

  const injectSearchFilter = () => {
    return (
      <div className="grid overview-search-area">
        <div className="col col-4">
          <>{console.log(properties)}</>
          <>{console.log(currentFilter)}</>
          <BeeTreeSelectLiegenschaften
            label={'Liegenschaften'}
            value={currentFilter}
            options={properties}
            disabled={false}
            formstate={'neutral'}
            readOnly={false}
            onChange={(filter: TreeSelectSelectionKeys) =>
              filterUsingSearchTermSortAndFilter(search, sort, filter)
            }
          />
        </div>
        <div className="col col-4">
          <BeeDropDown
            label={'Sortieren'}
            value={sort}
            options={DROPDOWN_LIEGENSCHAFT_SORT}
            disabled={false}
            formstate={'neutral'}
            readOnly={false}
            required={false}
            onChange={(sort: string) =>
              filterUsingSearchTermSortAndFilter(search, sort, currentFilter)
            }
          />
        </div>
        <div className="col col-4">
          <BeeSearchBar
            label={'Suche'}
            value={search}
            disabled={false}
            readOnly={false}
            required={false}
            formstate={'neutral'}
            type={'primary'}
            onSearch={(term: string) =>
              filterUsingSearchTermSortAndFilter(term, sort, currentFilter)
            }
          />
        </div>
      </div>
    );
  };

  return (
    <div className={'dl-tab-overview m-3'}>
      {injectSearchFilter()}
      {visibleData && visibleData.length > 0 ? (
        <>
          <DownloadCombinedPositions
            properties={properties}
            lvs={rawLVs}
            epLookup={epLookup}
            epTree={epTree}
            phase={phase}
            serviceProvider={serviceProvider}
            exportPrices={exportPriceLookup}
          />
          <AlphaAnalysisProperties
            properties={visibleData}
            allProperties={properties}
            serviceProvider={serviceProvider}
            imageLookup={imageLookup}
            lvData={lvData}
            rawLVs={rawLVs}
            epTree={epTree}
            phase={phase}
            keyValues={keyValues}
            disabled={properties ? false : true}
            epLookup={epLookup}
          />
        </>
      ) : (
        <>
          <DownloadCombinedPositions
            properties={properties}
            lvs={rawLVs}
            epLookup={epLookup}
            epTree={epTree}
            phase={phase}
            serviceProvider={serviceProvider}
            exportPrices={exportPriceLookup}
          />
          <EmptySearchView
            searchTerm={search}
            filterCat={currentFilter}
            onResetSearch={() =>
              filterUsingSearchTermSortAndFilter('', sort, currentFilter)
            }
            onResetFilter={() =>
              filterUsingSearchTermSortAndFilter(search, sort, '')
            }
          />
        </>
      )}
    </div>
  );
}
