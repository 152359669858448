import _ from 'lodash';
import { Column } from 'primereact/column';
import { TreeTable } from 'primereact/treetable';
import { useEffect, useRef, useState } from 'react';
import {
  DEFAULT_CALCULATION_MULTIPLIER,
  DEFAULT_FACTOR,
  DURATION_NOTIFICATION_ERROR_LONG,
  LEISTUNGSART,
  LeistungsSlices,
  MAX_NUMBER_OF_DECIMALS
} from '../../../Helper/Statics/Constants';
import { calculateFakEp } from '../../../Helper/Util/LvCalculator';
import BeeCurrencyInput from '../../Atoms/BeeCurrencyInput';
import './DLPropertyLV.scss';
import BeeButton from '../../Atoms/BeeButton';
import { generateAwardDlRequest } from '../../../Helper/Util/AwardedLvHelper';
import { generateAwardedLV } from '../../../Helper/ApiHelper/LvEpNetworkHelper';
import fileDownload from 'js-file-download';
import { Toast } from 'primereact/toast';
import { LiegenschaftsData } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import { ReactComponent as PriceInputIncompleteSVG } from '../../../Style/Icons/svgs/icons8-incompletePrice.svg';
import { Tooltip } from 'primereact/tooltip';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

type DLPropertyLVProps = {
  phaseId: string;
  lvTree: any;
  property: LiegenschaftsData;
  readOnly: boolean;
  disabled: boolean;
};

export default function DLPropertyLV({
  phaseId,
  lvTree,
  property,
  disabled,
  readOnly
}: DLPropertyLVProps) {
  const [processingLV, setProcessingLV] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const toast = useRef<Toast>(null);

  useEffect(() => {
    if (lvTree) {
      setData(lvTree);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lvTree]);

  //////////////
  /// HELPER ///
  //////////////

  function generateLV() {
    if (!disabled && !processingLV) {
      setProcessingLV(true);
      const request = generateAwardDlRequest(property, lvTree);
      generateAwardedLV(request, phaseId, property.id!, 'excel')
        .then((file: any) => {
          let filename =
            'LV [' +
            property.nummer +
            ']_' +
            property!.name +
            '_' +
            new Date().toLocaleDateString('de-DE') +
            '.xlsx';
          if (filename) {
            fileDownload(file, filename);
          }
        })
        .catch((error) => {
          toast.current?.show({
            severity: 'error',
            summary: 'LV konnten nicht erstellt werden.',
            detail:
              'Die Generierung des LV ist leider schiefgelaufen. Bitte versuchen Sie es später erneut oder wenden sich an den Kundensupport. ',
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        })
        .finally(() => {
          setProcessingLV(false);
        });
    }
  }

  ///////////////////////
  /// VIEW COMPONENTS ///
  ///////////////////////

  const posTemplate = (node: any, column: any) => {
    if (node && node.data) {
      if (node.className !== 'lv-pos') {
        return <span>{'[' + node.data.number + '] ' + node.data.title}</span>;
      } else {
        return (
          <span className={'propertyLv-title'}>
            <span>{'[' + node.data.posNumber + '] ' + node.data.title}</span>
            <div>
              {node.data.epCode ? (
                <span className={'dl-property-lv-epCode subtitle'}>
                  <b>{'EP-Code: '}</b>
                  <span>{node.data.epCode}</span>
                </span>
              ) : null}
              {node.data.epCode && node.data.aks ? ' | ' : ''}
              {node.data.aks ? (
                <span className={'dl-property-lv-aks subtitle'}>
                  <b>{'AKS-Nr: '}</b>
                  <span>{node.data.aks}</span>
                </span>
              ) : null}
            </div>
          </span>
        );
      }
    }
  };

  const iconTemplate = (node: any, column: any) => {
    const tag = node.data.tag ? node.data.tag : null;
    let currIcon = '';
    if (tag) {
      const leistungsslice = _.find(
        LeistungsSlices,
        function (slice: LEISTUNGSART) {
          return _.includes(slice.tag, tag);
        }
      );
      if (leistungsslice) {
        currIcon = leistungsslice.icon;
      }
    }
    return currIcon ? <i className={currIcon}></i> : null;
  };

  const typeTemplate = (node: any, column: any) => {
    if (node && node.data) {
      if (node.className !== 'lv-pos') {
        return <span></span>;
      } else {
        console.log(node);
        if (node.data.optionalPosition) {
          return (
            <>
              <div className="dl-property-lv-type subtitle">
                {'Bedarfspos.'}
              </div>
              <div>
                {node.data.optionalPositionActive ? 'Aktiv' : 'Inaktiv'}
              </div>
            </>
          );
        } else {
          return <span></span>;
        }
      }
    }
  };

  const amountTemplate = (node: any, column: any) => {
    if (node && node.data) {
      if (node.className !== 'lv-pos') {
        return <span></span>;
      } else {
        return (
          <>
            <div className="dl-property-lv-unit">{node.data.unit}</div>
            <div>{node.data.amount}</div>
          </>
        );
      }
    }
  };

  function sumGpOfAllLeaves(tree: any) {
    let sum = 0;
    let incomplete = false;
    function traverse(node: any) {
      if (node.children && node.children.length > 0) {
        node.children.forEach(traverse);
      } else if (node.className === 'lv-pos' && node.data) {
        if (
          !node.data.optionalPosition ||
          (node.data.optionalPosition && node.data.optionalPositionActive)
        ) {
          sum += node.data.gp;
          if (node.data.noPriceGiven) {
            incomplete = true;
          }
        }
      }
    }
    traverse(tree);
    return { sum, incomplete };
  }

  const fakEpTemplate = (node: any, column: any) => {
    if (node && node.data) {
      if (node.className !== 'lv-pos') {
        return <span></span>;
      } else {
        const susF = node.data.nFactor ? node.data.nFactor : DEFAULT_FACTOR;
        const lsF = node.data.lsFactor ? node.data.lsFactor : DEFAULT_FACTOR;
        const blF = node.data.blFactor ? node.data.blFactor : DEFAULT_FACTOR;
        const fakEp = node.data.ep
          ? calculateFakEp(node.data.ep, blF, lsF, susF)
          : 0;
        return (
          <>
            <BeeCurrencyInput
              value={
                node.data.noPriceGiven
                  ? null
                  : _.divide(fakEp, DEFAULT_CALCULATION_MULTIPLIER)
              }
              disabled={false}
              minFractionDigits={MAX_NUMBER_OF_DECIMALS}
              maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
              formstate={'neutral'}
              readOnly={true}
              required={false}
            />
            {node.noPriceGiven ? ( //fixme
              <>
                <PriceInputIncompleteSVG className="priceInputIncomplete" />
                <Tooltip target=".priceInputIncomplete" position="left">
                  unvollständige Preiseingabe
                </Tooltip>
              </>
            ) : null}
          </>
        );
      }
    }
  };

  const gpTemplate = (node: any, column: any) => {
    if (node && node.data) {
      if (node.className !== 'lv-pos') {
        const val = sumGpOfAllLeaves(node);
        return (
          <>
            <BeeCurrencyInput
              value={
                val
                  ? _.divide(val.sum, DEFAULT_CALCULATION_MULTIPLIER)
                  : val === 0
                  ? 0
                  : null
              }
              disabled={false}
              minFractionDigits={MAX_NUMBER_OF_DECIMALS}
              maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
              formstate={'neutral'}
              readOnly={true}
              required={false}
            />
            {val.incomplete ? (
              <>
                <PriceInputIncompleteSVG className="priceInputIncomplete" />
                <Tooltip target=".priceInputIncomplete" position="left">
                  unvollständige Preiseingabe
                </Tooltip>
              </>
            ) : null}
          </>
        );
      } else {
        if (node.data.optionalPosition && !node.data.optionalPositionActive) {
          return (
            <BeeCurrencyInput
              value={null}
              disabled={false}
              minFractionDigits={MAX_NUMBER_OF_DECIMALS}
              maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
              formstate={'none'}
              readOnly={true}
            />
          );
        } else {
          return (
            <>
              <BeeCurrencyInput
                value={
                  !node.data.noPriceGiven
                    ? node.data.gp
                      ? _.divide(node.data.gp, DEFAULT_CALCULATION_MULTIPLIER)
                      : node.data.gp === 0
                      ? 0
                      : null
                    : null
                }
                disabled={false}
                minFractionDigits={MAX_NUMBER_OF_DECIMALS}
                maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
                formstate={'neutral'}
                readOnly={true}
                required={false}
              />
              {node.data.noPriceGiven ? (
                <>
                  <PriceInputIncompleteSVG className="priceInputIncomplete" />
                  <Tooltip target=".priceInputIncomplete" position="left">
                    unvollständige Preiseingabe
                  </Tooltip>
                </>
              ) : null}
            </>
          );
        }
      }
    }
  };

  const footerGP = () => {
    const virtualRoot = {
      children: data
    };
    const val = sumGpOfAllLeaves(virtualRoot);
    return (
      <>
        <BeeCurrencyInput
          value={
            val
              ? _.divide(val.sum, DEFAULT_CALCULATION_MULTIPLIER)
              : val === 0
              ? 0
              : null
          }
          disabled={false}
          minFractionDigits={MAX_NUMBER_OF_DECIMALS}
          maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
          formstate={'neutral'}
          readOnly={true}
          required={false}
        />
        {val.incomplete ? (
          <>
            <PriceInputIncompleteSVG className="priceInputIncomplete" />
            <Tooltip target=".priceInputIncomplete" position="left">
              unvollständige Preiseingabe
            </Tooltip>
          </>
        ) : null}
      </>
    );
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column colSpan={4} className={'propertyLv-title-col'} />
        <Column
          footer={footerGP()}
          colSpan={2}
          className={'propertyLv-gp-col'}
          columnKey={'propertyLv-gp-col'}
        />
      </Row>
    </ColumnGroup>
  );

  const injectPayload = () => {
    return (
      <div
        className={
          disabled ? 'dl-property-lv disabled-clickable' : 'dl-property-lv'
        }
      >
        <TreeTable
          value={data}
          className={'propertyLv-table'}
          rowClassName={(rowData) => {
            return {
              disabled:
                rowData.data.optionalPosition &&
                !rowData.data.optionalPositionActive
            };
          }}
          readOnly={readOnly || disabled}
          emptyMessage={'Keine Position gefunden'}
          footerColumnGroup={footerGroup}
        >
          <Column
            columnKey={'propertyLv-title-col'}
            header={'Position'}
            className={'propertyLv-title-col'}
            body={posTemplate}
            expander
          />
          <Column
            columnKey={'propertyLv-icon-col'}
            body={iconTemplate}
            header={''}
            className={'propertyLv-icon-col sm-invisible'}
          />
          <Column
            columnKey={'propertyLv-type-col'}
            className={'propertyLv-type-col'}
            body={typeTemplate}
            header={'Typ'}
          />
          <Column
            columnKey={'propertyLv-amount-col'}
            className={'propertyLv-amount-col'}
            body={amountTemplate}
            header={'Menge'}
          />
          <Column
            columnKey={'propertyLv-fakEp-col'}
            className={'propertyLv-fakEp-col'}
            body={fakEpTemplate}
            header={'Ep (faktoriert)'}
          />
          <Column
            columnKey={'propertyLv-gp-col'}
            className={'propertyLv-gp-col'}
            body={gpTemplate}
            header={'GP'}
          />
        </TreeTable>
        <div className="flex justify-content-end mt-2">
          <BeeButton
            label={'LV herunterladen'}
            disabled={disabled || !lvTree || lvTree.length < 1 || processingLV}
            type={'secondary'}
            isLoading={processingLV}
            onClick={() => generateLV()}
          />
        </div>
        <Toast ref={toast} position={'top-right'} />
      </div>
    );
  };

  return injectPayload();
}
