import { Skeleton } from 'primereact/skeleton';
import { useId } from 'react-id-generator';

import './BeeSkeleton.scss';

type BeeSkeletonProps = {
  id?: string;
  shape?: string;
  size?: string;
  width?: string;
  height?: string;
  animated?: boolean;
};

export function BeeSkeleton({
  id,
  shape,
  size,
  width,
  height,
  animated
}: BeeSkeletonProps) {
  const currentId = useId(1, 'bee-skeleton-')[0];
  const animation = animated ? 'wave' : 'none';

  //classNames
  const circleClassNames = 'bee-skeleton-circle';
  const rectangleClassNames = 'bee-skeleton-rectangle';

  const circle = (
    <Skeleton
      className={circleClassNames}
      id={currentId}
      shape="circle"
      size={size}
      animation={animation}
    />
  );

  const rectangle = (
    <Skeleton
      className={rectangleClassNames}
      id={currentId}
      shape="rectangle"
      animation={animation}
      width={width}
      height={height}
    />
  );

  return shape === 'circle' ? circle : rectangle;
}

export default BeeSkeleton;
