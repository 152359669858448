import FileSaver from 'file-saver';
import _ from 'lodash';
import { TimelineStep } from '../../UI/Atoms/BeeTimeline';
import { BLKeyValueStore } from '../../UI/Molecules/DL/DLPriceInput';
import {
  DEFAULT_CALCULATION_MULTIPLIER,
  LeistungsSlices,
  SERVER_RESPONSE_ERROR,
  SERVER_RESPONSE_PENDING
} from '../Statics/Constants';
import {
  generateEpEntryId,
  generateTargetPriceId
} from '../Util/IdGeneratorHelper';
import { LiegenschaftsData } from '../ApiHelper/LiegenschaftenNetworkHelper';

const ExcelJS = require('exceljs');

const excelTpSheetName = 'Zielpreise';
const excelTpPropertyHeader = 'Liegenschaft';
const excelTpPropertyAdressStreet = 'Straße';
const excelTpPropertyAdressNumber = 'Nr.';
const excelTpPropertyAdressplz = 'PLZ';
const excelTpPropertyAdressCity = 'Stadt';
const excelTpPropertyNumber = 'Nummer';
export const excelTpPropertyIdHeader = 'LiegenschaftsId';

const excelTpPropColIndex = 2;
const excelTpPropAdressStreetIndex = 3;
const excelTpPropAdressNumberIndex = 4;
const excelTpPropAdressPlzIndex = 5;
const excelTpPropAdressCityIndex = 3;
const excelTpPropNumberIndex = 1;
const excelTpPropIdColIndex = 4;

export function exportAlphaTpExcel(
  properties: LiegenschaftsData[],
  targetPriceLookup: Map<string, any>,
  phaseId: string,
  phase: any
) {
  return new Promise(async (resolve, reject) => {
    const workbook = new ExcelJS.Workbook();
    //metadata
    workbook.creator = '';
    workbook.lastModifiedBy = '';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();
    workbook.views = [
      {
        x: 0,
        y: 0,
        width: 10000,
        height: 20000,
        firstSheet: 0,
        activeTab: 0,
        visibility: 'visible'
      }
    ];
    // Force workbook calculation on load
    workbook.calcProperties.fullCalcOnLoad = true;

    //create Sheets
    let sheetTp = workbook.addWorksheet(excelTpSheetName, {
      properties: { defaultColWidth: 14 }
    });

    // make worksheets visible
    sheetTp.state = 'visible';

    //col widhts
    sheetTp = setUpCols(sheetTp);

    //col header
    sheetTp = createSheetTpHeader(sheetTp, phase);

    //content
    if (properties && targetPriceLookup) {
      createTpContent(properties, targetPriceLookup, phaseId, sheetTp);
    }

    // write to a file
    workbook.xlsx
      .writeBuffer()
      .then((buffer: any) =>
        FileSaver.saveAs(new Blob([buffer]), `${Date.now()}_Zielpreise.xlsx`)
      )
      .catch((err: any) => console.log('Error writing excel export', err));
  });
}

function setUpCols(worksheet: any) {
  //propertyname
  worksheet.getColumn(excelTpPropColIndex).width = 35;

  //propertynumber
  worksheet.getColumn(excelTpPropNumberIndex).width = 15;

  //propertyadresse
  /*     worksheet.getColumn(excelTpPropAdressStreetIndex).width = 20;
  worksheet.getColumn(excelTpPropAdressNumberIndex).width = 7;
  worksheet.getColumn(excelTpPropAdressPlzIndex).width = 10; */
  worksheet.getColumn(excelTpPropAdressCityIndex).width = 15;

  //propid
  worksheet.getColumn(excelTpPropIdColIndex).width = 25;

  //leistungsslices
  for (let i = 0; i < LeistungsSlices.length; i++) {
    worksheet.getColumn(getSliceIndex(i)).width = 17;
    worksheet.getColumn(getSliceIndex(i)).numFmt = '#,###0.000 [$€-1]';
  }

  return worksheet;
}

function createSheetTpHeader(worksheet: any, phase: any) {
  /*  metainfo */
  const metaInfoRow = worksheet.addRow();
  metaInfoRow.getCell(1).value = 'DWS-Zielpreiseingabe';
  // metaInfoRow.getCell(3).value = 'Phase: ' + phase.title;
  metaInfoRow.getCell(5).value =
    'Datum: ' + new Date().toLocaleDateString('de-DE');

  //empty row
  const emptyRow = worksheet.addRow();
  emptyRow.getCell(1).value = '';

  //header for table
  const headerRow = worksheet.addRow();

  //property
  headerRow.getCell(excelTpPropColIndex).value = excelTpPropertyHeader;
  headerRow.getCell(excelTpPropNumberIndex).value = excelTpPropertyNumber;
  /*     headerRow.getCell(excelTpPropAdressStreetIndex).value =
    excelTpPropertyAdressStreet;
  headerRow.getCell(excelTpPropAdressNumberIndex).value =
    excelTpPropertyAdressNumber;
  headerRow.getCell(excelTpPropAdressPlzIndex).value = excelTpPropertyAdressplz; */
  headerRow.getCell(excelTpPropAdressCityIndex).value =
    excelTpPropertyAdressCity;

  //propid
  headerRow.getCell(excelTpPropIdColIndex).value = excelTpPropertyIdHeader;

  //leistungsslices
  for (let i = 0; i < LeistungsSlices.length; i++) {
    headerRow.getCell(getSliceIndex(i)).value = LeistungsSlices[
      i
    ].title.replace('management', 'mgmt');
  }

  //style header
  const backgroundColorHeadline = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--primary-color');
  const colorHeadline = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--primary-color-text');

  metaInfoRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'FFFFFF'
    }
  };
  metaInfoRow.font = {
    bold: true,
    color: { argb: '000000' }
  };

  emptyRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'FFFFFF'
    },
    bgColor: {
      argb: 'FFFFFF'
    }
  };

  headerRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: backgroundColorHeadline.replace('#', '').replace(' ', '')
    }
  };
  headerRow.font = {
    bold: true,
    color: { argb: colorHeadline.replace('#', '').replace(' ', '') }
  };
  headerRow.height = 50;
  headerRow.border = {
    bottom: { style: 'thick', color: { argb: 'FFFFFFFF' } }
  };

  return worksheet;
}

function createTpContent(
  properties: LiegenschaftsData[],
  targetPriceLookup: any,
  phaseId: string,
  worksheet: any
) {
  if (properties && properties.length > 0) {
    for (let i = 0; i < properties.length; i++) {
      const currProp = properties[i];

      const propId = currProp.id ? currProp.id : '';
      const newRow = worksheet.addRow();

      //title
      newRow.getCell(excelTpPropColIndex).value = currProp.name
        ? currProp.name
        : '';
      //number
      newRow.getCell(excelTpPropNumberIndex).value = currProp.nummer
        ? currProp.nummer
        : '';
      //id
      newRow.getCell(excelTpPropIdColIndex).value = propId;

      newRow.getCell(excelTpPropIdColIndex).font = {
        color: { argb: '808080' }
      };
      //Adress
      if (currProp.adresse) {
        /*    newRow.getCell(excelTpPropAdressStreetIndex).value = currProp.adresse
          .strasse
          ? currProp.adresse.strasse
          : null;
        newRow.getCell(excelTpPropAdressNumberIndex).value = currProp.adresse
          .hausnummer
          ? currProp.adresse.hausnummer
          : null;
        newRow.getCell(excelTpPropAdressPlzIndex).value = currProp.adresse
          .postleitzahl
          ? currProp.adresse.postleitzahl
          : null; */
        newRow.getCell(excelTpPropAdressCityIndex).value = currProp.adresse
          .stadt
          ? currProp.adresse.stadt
          : null;
      }

      //slices
      if (LeistungsSlices) {
        for (let j = 0; j < LeistungsSlices.length; j++) {
          const leistungsId = LeistungsSlices[j].id;
          const targetPriceId = generateTargetPriceId(
            phaseId,
            propId,
            leistungsId
          );

          const colIndexSlice = getSliceIndex(j);

          if (targetPriceLookup.get(targetPriceId))
            //slicevalue
            newRow.getCell(colIndexSlice).value = targetPriceLookup.get(
              targetPriceId
            )
              ? _.divide(
                  targetPriceLookup.get(targetPriceId),
                  DEFAULT_CALCULATION_MULTIPLIER
                )
              : targetPriceLookup.get(targetPriceId) === 0
              ? 0
              : null;
        }
      }
      //Styling
      /*   const primaryColor = getComputedStyle(
        document.documentElement
      ).getPropertyValue('--primary-light-color');
      const secondaryColor = getComputedStyle(
        document.documentElement
      ).getPropertyValue('--secondary-color');
      const greyColor = getComputedStyle(
        document.documentElement
      ).getPropertyValue('--surface-700');
 */
      /*    let calculatedColor = '';
        const opacity = (tree[i].depth / treeDepth) * 100;
        if (tree[i].depth === 1) {
          calculatedColor = primaryColor.replace('#', '').replace(' ', '');
          newRow.font = { color: { argb: 'FFFFFF' }, bold: true };
        } else if (tree[i].depth === 2) {
          calculatedColor =
            'FF' + secondaryColor.replace('#', '').replace(' ', '');
        } else {
          calculatedColor = shadeColor(
            greyColor.replace('#', '').replace(' ', ''),
            opacity
          );
        } */

      /*  newRow.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: calculatedColor
          },
          bgColor: {
            argb: 'ffffff'
          }
        }; */

      /*   newRow.fill = {
          type: 'pattern',
          pattern: 'solid',

          fgColor: {
            argb: 'ffffff'
          },
          bgColor: {
            argb: 'ffffff'
          }
        }; */

      for (let j = 0; j < LeistungsSlices.length; j++) {
        const colIndexSlice = getSliceIndex(j);

        newRow.getCell(colIndexSlice).border = {
          top: { style: 'medium' },
          left: { style: 'medium' },
          bottom: { style: 'medium' },
          right: { style: 'medium' }
        };
        newRow.getCell(colIndexSlice).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFF2F2F2' },
          bgColor: { argb: 'FFF2F2F2' }
        };
        newRow.getCell(colIndexSlice).font = {
          bold: true
        };
      }
    }
  }

  return worksheet;
}

/////////////////
///// Helper ////
/////////////////
function getSliceIndex(index: number) {
  return excelTpPropIdColIndex + index + 2;
}
