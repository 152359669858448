import _ from 'lodash';
import { CascadeSelect } from 'primereact/cascadeselect';
import { SelectItem } from 'primereact/selectitem';
import TreeNode from 'primereact/treenode';
import { useEffect, useState } from 'react';
import { useId } from 'react-id-generator';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { LiegenschaftsData } from '../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import { EMPTY_FIELD_PLACEHOLDER } from '../../Helper/Statics/Constants';
import './BeeCascadeSelectLiegenschaften.scss';
import BeeTextInput from './BeeTextInput';

type BeeCascadeSelectLiegenschaftenProps = {
  id?: string;
  label?: any;
  value: any;
  options?: LiegenschaftsData[];
  disabled?: boolean;
  formstate?: string;
  readOnly?: boolean;
};

export function BeeCascadeSelectLiegenschaften({
  id,
  label,
  value,
  options,
  disabled,
  readOnly
}: BeeCascadeSelectLiegenschaftenProps) {
  const [data, setData] = useState<SelectItem[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const genId = useId(1, 'bee-drop-down-')[0];
  const currentId = id ? id : genId;
  const inputId = currentId + 'input';

  useEffect(() => {
    setData(reworkPayload(options));
  }, [options]);

  function reworkPayload(options?: LiegenschaftsData[]) {
    if (options) {
      const pay: any[] = [];
      //sort array by land, region, city
      const sorted: LiegenschaftsData[] = _.sortBy(options, [
        'adresse.land',
        'adresse.region',
        'adresse.stadt',
        'nummer'
      ]);
      //loop and build tree
      for (let i = 0; i < sorted.length; i++) {
        const property: LiegenschaftsData = sorted[i];
        const countryName = property.adresse?.land;
        const regionName = property.adresse?.region;
        const cityName = property.adresse?.stadt;
        if (pay.length === 0) {
          const countryId = uuidv4();
          const regionId = uuidv4();
          const cityId = uuidv4();
          //create this country
          //create this region
          //create this city
          pay.push({
            id: countryId,
            name: countryName,
            data: countryName,
            regions: [
              {
                id: regionId,
                name: regionName,
                data: regionName,
                cities: [
                  {
                    id: cityId,
                    name: cityName,
                    data: cityName,
                    properties: [
                      {
                        id: property.id,
                        name: property.nummer + ' ' + property.name,
                        data: property
                      }
                    ]
                  }
                ]
              }
            ]
          });
        } else {
          const entry = _.find(pay, function (e: TreeNode) {
            return e.data === countryName;
          });
          if (entry) {
            //we found the country => test region
            const region = _.find(entry.regions, function (e: TreeNode) {
              return e.data === regionName;
            });
            if (region) {
              //we found region => test city
              const city = _.find(region.cities, function (e: TreeNode) {
                return e.data === cityName;
              });
              if (city) {
                //if property is not contained > create it
                if (
                  !_.find(city.properties, function (e: TreeNode) {
                    return e.id === property.id;
                  })
                ) {
                  city.properties?.push({
                    id: property.id,
                    name: property.nummer + ' ' + property.name,
                    data: property
                  });
                }
              } else {
                const cityId = uuidv4();
                // add city to this region
                region.cities?.push({
                  id: cityId,
                  name: cityName,
                  data: cityName,
                  properties: [
                    {
                      id: property.id,
                      name: property.nummer + ' ' + property.name,
                      data: property
                    }
                  ]
                });
              }
            } else {
              const regionId = uuidv4();
              const cityId = uuidv4();
              // add region to this entry
              // add city to this entry
              entry.regions?.push({
                id: regionId,
                name: regionName,
                data: regionName,
                cities: [
                  {
                    id: cityId,
                    name: cityName,
                    data: cityName,
                    properties: [
                      {
                        id: property.id,
                        name: property.nummer + ' ' + property.name,
                        data: property
                      }
                    ]
                  }
                ]
              });
            }
          } else {
            const countryId = uuidv4();
            const regionId = uuidv4();
            const cityId = uuidv4();
            //create this country
            //create this region
            //create this city
            pay.push({
              id: countryId,
              name: countryName,
              data: countryName,
              regions: [
                {
                  id: regionId,
                  name: regionName,
                  data: regionName,
                  cities: [
                    {
                      id: cityId,
                      name: cityName,
                      data: cityName,
                      properties: [
                        {
                          id: property.id,
                          name: property.nummer + ' ' + property.name,
                          data: property
                        }
                      ]
                    }
                  ]
                }
              ]
            });
          }
        }
      }
      return pay;
    } else {
      return [];
    }
  }

  const renderPlaceholder = () => {
    return (
      <BeeTextInput
        label={label}
        value={EMPTY_FIELD_PLACEHOLDER}
        disabled={disabled}
        readOnly={true}
        required={false}
        onChange={() => {}}
      />
    );
  };

  function convertValue(value: LiegenschaftsData) {
    return value
      ? {
          id: value.id,
          data: value,
          name: value.nummer + ' ' + value.name
        }
      : null;
  }

  return (
    <>
      {readOnly && !value ? (
        renderPlaceholder()
      ) : (
        <span
          id={currentId}
          className={'p-float-label bee-cascadeSelect-liegenschaften'}
        >
          <CascadeSelect
            value={convertValue(value)}
            options={data}
            optionLabel={'name'}
            optionGroupLabel={'name'}
            optionGroupChildren={['regions', 'cities', 'properties']}
            placeholder={'Liegenschaft auswählen'}
            onChange={(event) => {
              if (value.id !== event.value.data.id) {
                searchParams.set('lId', event.value.data.id);
                setSearchParams(searchParams);
              }
            }}
          />
          {label ? <label htmlFor={inputId}>{label}</label> : null}
        </span>
      )}
    </>
  );
}

export default BeeCascadeSelectLiegenschaften;
