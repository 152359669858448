import { useState } from 'react';
import { useId } from 'react-id-generator';
import BeeErrorLabel from './BeeErrorLabel';
import BeeInfoLabel from './BeeInfoLabel';
import './BeeNutzungsartMix.scss';
import BeeSlider from './BeeSlider';

type BeeNutzungsartMixProps = {
  label?: string;
  errorLabel?: string | null;
  neutralLabel?: string | null;
  validLabel?: string | null;
  aOffice: number;
  aRetail: number;
  aWohnen: number;
  aLogistik: number;
  aSonstige: number;
  type: string;
  forceRerenderToggle?: boolean;
  disabled?: boolean;
  formstate?: string;
  readOnly?: boolean;
  required?: boolean;
  onChange: (e: {
    anteilOffice: number;
    anteilRetail: number;
    anteilWohnen: number;
    anteilLogistik: number;
    anteilSonstige: number;
  }) => void;
};

export function BeeNutzungsartMix({
  label,
  disabled,
  forceRerenderToggle,
  aOffice,
  aRetail,
  aWohnen,
  aLogistik,
  aSonstige,
  type,
  formstate,
  errorLabel,
  neutralLabel,
  validLabel,
  readOnly,
  required,
  onChange
}: BeeNutzungsartMixProps) {
  const currentId = useId(1, 'bee-nutzungsart-mix-')[0];

  const [anteilOffice, setAnteilOffice] = useState(aOffice);
  const [anteilRetail, setAnteilRetail] = useState(aRetail);
  const [anteilWohnen, setAnteilWohnen] = useState(aWohnen);
  const [anteilLogistik, setAnteilLogistik] = useState(aLogistik);
  const [anteilSonstige, setAnteilSonstige] = useState(aSonstige);
  const [forceRerender, setForceRerender] = useState(readOnly);

  //classNames
  const classNames = 'bee-nutzungsart-mix';
  const requiredClassNames = 'bee-nutzungsart-mix-label-required';
  const requiredStarClassNames = 'bee-nutzungsart-mix-label-required-star';
  const labelClassNames = 'bee-nutzungsarten-mix-label';
  const titleColClassNames = 'bee-nutzungsarten-table-title';
  const percentageColClassNames = 'bee-nutzungsarten-table-percentage';
  let readOnlyClassNames = '';

  if (readOnly) {
    readOnlyClassNames = ' bee-nutzungsart-mix-readOnly';
  }
  const disabledClassNames = disabled ? ' bee-nutzungsart-mix-disabled' : '';

  const formStateClassNames =
    formstate === 'valid'
      ? ' bee-nutzungsart-mix-valid'
      : formstate === 'error'
      ? ' bee-nutzungsart-mix-error'
      : formstate === 'neutral'
      ? ' bee-nutzungsart-mix-neutral'
      : ' bee-nutzungsart-mix-neutral';

  const inputClassNames =
    'bee-nutzungsart-mix-input ' +
    readOnlyClassNames +
    disabledClassNames +
    formStateClassNames;

  const validatedLabelClassNames = 'bee-validated-nutzungsart-mix-label';
  const textSize = 'medium';
  const labelOffice = 'Office';
  const labelRetail = 'Retail';
  const labelWohnen = 'Wohnen';
  const labelLogistik = 'Logistik';
  const labelSonstige = 'Sonstige';

  function reportNewValues() {
    if (!disabled && onChange) {
      onChange({
        anteilOffice,
        anteilRetail,
        anteilWohnen,
        anteilLogistik,
        anteilSonstige
      });
    }
  }

  function changeAnteilOffice(value: number) {
    setAnteilOffice(value);
    let sum =
      anteilOffice +
      anteilRetail +
      anteilWohnen +
      anteilLogistik +
      anteilSonstige;
    let overflow = 100 - sum;
    adaptAnteilRetail(overflow);
  }

  function changeAnteilRetail(value: number) {
    setAnteilRetail(value);
    let sum =
      anteilOffice +
      anteilRetail +
      anteilWohnen +
      anteilLogistik +
      anteilSonstige;
    let overflow = 100 - sum;
    adaptAnteilWohnen(overflow);
  }

  function changeAnteilWohnen(value: number) {
    setAnteilWohnen(value);
    let sum =
      anteilOffice +
      anteilRetail +
      anteilWohnen +
      anteilLogistik +
      anteilSonstige;
    let overflow = 100 - sum;
    adaptAnteilLogistik(overflow);
  }

  function changeAnteilLogistik(value: number) {
    setAnteilLogistik(value);
    let sum =
      anteilOffice +
      anteilRetail +
      anteilWohnen +
      anteilLogistik +
      anteilSonstige;
    let overflow = 100 - sum;
    adaptAnteilSonstige(overflow);
  }

  function changeAnteilSonstige(value: number) {
    setAnteilSonstige(value);
    let sum =
      anteilOffice +
      anteilRetail +
      anteilWohnen +
      anteilLogistik +
      anteilSonstige;
    let overflow = 100 - sum;
    adaptAnteilOffice(overflow);
  }

  function adaptAnteilOffice(progress: number) {
    let change = anteilOffice + progress;
    setAnteilOffice(change < 0 ? 0 : change);
    if (change < 0) {
      adaptAnteilRetail(change);
    }
  }

  function adaptAnteilRetail(progress: number) {
    let change = anteilRetail + progress;
    setAnteilRetail(change < 0 ? 0 : change);
    if (change < 0) {
      adaptAnteilWohnen(change);
    }
  }

  function adaptAnteilWohnen(progress: number) {
    let change = anteilWohnen + progress;
    setAnteilWohnen(change < 0 ? 0 : change);
    if (change < 0) {
      adaptAnteilLogistik(change);
    }
  }

  function adaptAnteilLogistik(progress: number) {
    let change = anteilLogistik + progress;
    setAnteilLogistik(change < 0 ? 0 : change);
    if (change < 0) {
      adaptAnteilSonstige(change);
    }
  }

  function adaptAnteilSonstige(progress: number) {
    let change = anteilSonstige + progress;
    setAnteilSonstige(change < 0 ? 0 : change);
    if (change < 0) {
      adaptAnteilOffice(change);
    }
  }

  const injectSonstigeSlider = () => {
    return (
      <BeeSlider
        type={type}
        value={anteilSonstige}
        min={0}
        max={100}
        step={1}
        onChange={(e: any) => changeAnteilSonstige(e.value)}
        onSlideEnd={(e: any) => {
          changeAnteilSonstige(e.value);
          reportNewValues();
        }}
      />
    );
  };

  const injectLogistikSlider = () => {
    return (
      <BeeSlider
        type={type}
        value={anteilLogistik}
        min={0}
        max={100}
        step={1}
        onChange={(e: any) => changeAnteilLogistik(e.value)}
        onSlideEnd={(e: any) => {
          changeAnteilLogistik(e.value);
          reportNewValues();
        }}
      />
    );
  };

  const injectWohnenSlider = () => {
    return (
      <BeeSlider
        type={type}
        value={anteilWohnen}
        min={0}
        max={100}
        step={1}
        onChange={(e: any) => changeAnteilWohnen(e.value)}
        onSlideEnd={(e: any) => {
          changeAnteilWohnen(e.value);
          reportNewValues();
        }}
      />
    );
  };

  const injectRetailSlider = () => {
    return (
      <BeeSlider
        type={type}
        value={anteilRetail}
        min={0}
        max={100}
        step={1}
        onChange={(e: any) => changeAnteilRetail(e.value)}
        onSlideEnd={(e: any) => {
          changeAnteilRetail(e.value);
          reportNewValues();
        }}
      />
    );
  };

  const injectOfficeSlider = () => {
    return (
      <BeeSlider
        type={type}
        value={anteilOffice}
        min={0}
        max={100}
        step={1}
        onChange={(e: any) => changeAnteilOffice(e.value)}
        onSlideEnd={(e: any) => {
          changeAnteilOffice(e.value);
          reportNewValues();
        }}
      />
    );
  };

  const renderLabel = () => {
    return (
      <div className={validatedLabelClassNames}>
        {formstate === 'error' && errorLabel ? (
          <BeeErrorLabel size={textSize} label={errorLabel} />
        ) : formstate === 'valid' && validLabel ? (
          <BeeInfoLabel size={textSize} label={validLabel} type={'valid'} />
        ) : formstate === 'neutral' && neutralLabel ? (
          <BeeInfoLabel size={textSize} label={neutralLabel} type={'neutral'} />
        ) : null}
      </div>
    );
  };

  function forceRerenderValues() {
    if (forceRerenderToggle !== forceRerender) {
      setForceRerender(forceRerenderToggle);
      setAnteilOffice(aOffice);
      setAnteilRetail(aRetail);
      setAnteilWohnen(aWohnen);
      setAnteilLogistik(aLogistik);
      setAnteilSonstige(aSonstige);
    }
  }

  return (
    <div className={classNames}>
      <>{forceRerenderValues()}</>
      <label className={labelClassNames}>
        {label}
        {required && label ? (
          <span className={requiredClassNames}>
            <span className={requiredStarClassNames}> *</span>
          </span>
        ) : null}
      </label>
      <div id={currentId} className={inputClassNames}>
        <table>
          <tbody>
            <tr>
              <td className={titleColClassNames}>
                <label>{labelOffice}</label>
              </td>
              <td>{injectOfficeSlider()}</td>
              <td className={percentageColClassNames}>
                <label>{anteilOffice + '%'}</label>
              </td>
            </tr>
            <tr>
              <td className={titleColClassNames}>
                <label>{labelRetail}</label>
              </td>
              <td>{injectRetailSlider()}</td>
              <td className={percentageColClassNames}>
                <label>{anteilRetail + '%'}</label>
              </td>
            </tr>
            <tr>
              <td className={titleColClassNames}>
                <label>{labelWohnen}</label>
              </td>
              <td>{injectWohnenSlider()}</td>
              <td className={percentageColClassNames}>
                <label>{anteilWohnen + '%'}</label>
              </td>
            </tr>
            <tr>
              <td className={titleColClassNames}>
                <label>{labelLogistik}</label>
              </td>
              <td>{injectLogistikSlider()}</td>
              <td className={percentageColClassNames}>
                <label>{anteilLogistik + '%'}</label>
              </td>
            </tr>
            <tr>
              <td className={titleColClassNames}>
                <label>{labelSonstige}</label>
              </td>
              <td>{injectSonstigeSlider()}</td>
              <td className={percentageColClassNames}>
                <label>{anteilSonstige + '%'}</label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {formstate != 'none' && (errorLabel || neutralLabel || validLabel)
        ? renderLabel()
        : null}
    </div>
  );
}

export default BeeNutzungsartMix;
