import { Card } from 'primereact';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VALIDATE_EMAIL_REGEX } from '../../../Helper/Statics/Constants';
import { ENTER } from '../../../Helper/Statics/KeyCodes';
import { PATH_ROOT } from '../../../Helper/Statics/Routes';
import BeeButton from '../../Atoms/BeeButton';
import BeeLinkButton from '../../Atoms/BeeLinkButton';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import BeeValidatedPasswordInput from '../../Atoms/BeeValidatedPasswordInput';
import BeeValidatedTextInput from '../../Atoms/BeeValidatedTextInput';
import './LoginForm.scss';

type LoginProps = {
  loginError: string;
  showProgress: boolean;
  onLogin: (username: string, password: string) => void;
  onForgotPw: () => void;
};

function LoginForm({
  loginError,
  showProgress,
  onLogin,
  onForgotPw
}: LoginProps) {
  //Component state
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  //ERRORS
  const [usernameError, setUsernameError] = useState<string>('');

  let navigate = useNavigate();

  function login(): void {
    // can only be send when input is not null (disabled btn)
    if (isInputValid()) {
      onLogin(username, password);
    }
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.keyCode === ENTER && !e.altKey) {
      login();
    }
  }

  function isInputValid(): boolean {
    const isRegexConform = VALIDATE_EMAIL_REGEX.test(username);

    if (!username || !isRegexConform) {
      setUsernameError('Bitte geben Sie eine valide E-Mail-Adresse ein.');
      return false;
    } else {
      setUsernameError('');
      return true;
    }
  }

  function forgotPw(): void {
    onForgotPw();
  }
  function clickLogo() {
    navigate(PATH_ROOT);
  }

  function injectHeadline(): JSX.Element {
    return (
      <div
        className={'logo'}
        onClick={() => {
          clickLogo();
        }}
      />
    );
  }

  function injectLoginContent(): JSX.Element {
    return (
      <div>
        <div className="field">
          <BeeValidatedTextInput
            label={'E-Mail'}
            errorLabel={usernameError}
            value={username}
            disabled={false}
            readOnly={false}
            required={false}
            formstate={usernameError ? 'error' : 'none'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setUsername(e.target.value)
            }
          />
        </div>
        <div className="password" onKeyDown={(e) => handleKeyDown(e)}>
          <BeeValidatedPasswordInput
            label={'Passwort'}
            errorLabel={loginError ? loginError : null}
            value={password}
            disabled={false}
            showPwStrength={false}
            showHint={false}
            formstate={loginError ? 'error' : 'none'}
            readOnly={false}
            required={false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
          />
        </div>
      </div>
    );
  }

  function injectLoginActions(): JSX.Element {
    return (
      <>
        <div className={'forgotPw-btn'}>
          <BeeLinkButton
            label={'Passwort vergessen?'}
            raised={false}
            disabled={false}
            type={'secondary'}
            onClick={() => forgotPw()}
          />
        </div>
        <div className={'login-btn'}>
          <BeeButton
            label={'Login'}
            disabled={!(username && password)}
            type={'primary'}
            onClick={() => login()}
          />
          {showProgress ? (
            <BeeLoadingSpinner type="secondary" strokeWidth="3" />
          ) : null}
        </div>
      </>
    );
  }

  return (
    <div className={'o-login-form form grid justify-content-center'}>
      <div className="col-12 sm:col-7 md:col-6 lg:col-5 xl:col-4">
        <Card>
          {injectHeadline()}
          {injectLoginContent()}
          {injectLoginActions()}
        </Card>
      </div>
    </div>
  );
}

export default LoginForm;
