import _ from 'lodash';
import { authAxios } from './AuthServiceHelper';
import {
  DL_EP_INPUT_DISABLE_FILTER_ID,
  LV_DEPTH_EP_CATALOG,
  URL_TO_LVEP_SERVER
} from '../Statics/Constants';
import { ErrorResponseType } from './LoginNetworkHelper';
import { errorMsgToString } from './ErrorMessages';

export type MinimizedEpCatalog = {
  id: string;
  phaseId: string;
  createdAt?: string;
  filename?: string;
};

export type MinimizedLvCatalog = {
  id: string;
  phaseId: string;
  propertyId: string;
  createdAt?: string;
  filename?: string;
};

export type LvPosition = {
  id: string;
  createdAt: string;
  serialNumber?: string;
  identificationNumber?: string;
  amount?: number;
  description?: string;
  optionalPosition: boolean;
  optionalPositionActive: boolean;
  epCode: string;
};

export type EnlargedLvPosition = {
  id: string;
  serialNumber?: string;
  identificationNumber?: string;
  amount?: number;
  description?: string;
  optionalPosition: boolean;
  optionalPositionActive: boolean;
  epCode: string;
  //connections to EP
  leftInt: number;
  rightInt: number;
  unit?: string;
  sliceTag?: string;
  epPriceId: string;
  blFactorId: string;
  lsFactorId: string;
  nFactorId: string;
  mFactorId: string;
};

export type ConnectedLvPosition = {
  id: string;
  amount?: number;
  propertyId?: string;
};

export type EpPosition = {
  id: string;
  leftInt: number;
  rightInt: number;
  number?: string;
  title?: string;
  shortTitle?: string;
  epCode?: string;
  unit?: string;
  preText?: string;
  postText?: string;
  sliceTag?: string;
  hidden: boolean;
  connectedLvPositions?: ConnectedLvPosition[];
};

export type LV = {
  info: MinimizedLvCatalog;
  data: LvPosition[];
};

export type EP = {
  info: MinimizedEpCatalog;
  data: EpPosition[];
};

export type AwardAddressInfo = {
  strasse: string;
  hausnummer: string;
  postleitzahl: string;
  stadt: string;
  region: string;
  land: string;
};

export type AwardPropertyInfo = {
  id: string;
  nummer: string;
  name: string;
  beschreibung: string;
  anmerkung: string;
  eigentuemer: string;
  verantwortlicher: string;
  adresse: AwardAddressInfo;
};

export type AwardPosition = {
  id: string;
  amount: number | null;
  ep: number | null;
  gp: number | null;
  notInMarketArea: boolean;
  noPriceGiven: boolean;
};

export type AwardServiceProvider = {
  organisationName: string;
  positions: AwardPosition[];
};

export type AwardCategory = {
  categories: AwardCategory[] | null;
  epCatalogId: string;
  lvPositionIds: string[];
};

export type AwardRequest = {
  liegenschaft: AwardPropertyInfo;
  serviceProvider: AwardServiceProvider[];
  rootCategories: AwardCategory[];
};

/**
 * This method reads the meta information of EP-Catalog for all phases
 */
export const readEpCatalogMetaForAllPhases = () => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'get',
      url: URL_TO_LVEP_SERVER + '/v1/epCatalog'
    })
      .then((result: any) => {
        let answer: MinimizedEpCatalog[] = result.data.data;
        resolve(answer);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

/**
 * This method reads all LV-Catalog Meta information for all properties
 * @param phaseId
 * @returns
 */
export const readLvCatalogMetaForPhase = (phaseId: String) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'get',
      url: URL_TO_LVEP_SERVER + '/v1/phase/' + phaseId + '/lv'
    })
      .then((result: any) => {
        console.log('Received:');
        console.log(result.data);
        let answer: MinimizedLvCatalog[] = result.data.data;
        resolve(answer);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

/**
 * This method is for downloading the LV for a given property and phase as excel
 * @param phaseId
 * @param propertyId
 * @returns
 */
export const readLvCatalogAsExcelForProperty = (
  phaseId: String,
  propertyId: String
) => {
  return new Promise((resolve, reject) => {
    const src =
      URL_TO_LVEP_SERVER +
      '/v1/phase/' +
      phaseId +
      '/property/' +
      propertyId +
      '/lv/excel';
    authAxios({
      method: 'get',
      url: src,
      responseType: 'blob'
    })
      .then((response) => {
        const entry = response.data;
        resolve(entry);
      })
      .catch((error) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

/**
 * This method uploads the given excel to create a new LV Catalog for the given Property and Phase
 * @param phaseId
 * @param propertyId
 * @param file
 * @returns
 */
export const createNewLvCatalogForPropertyFromExcel = (
  phaseId: String,
  propertyId: String,
  file: any
) => {
  return new Promise((resolve, reject) => {
    console.log('SEND ==>');
    console.log(file);
    const src =
      URL_TO_LVEP_SERVER +
      '/v1/phase/' +
      phaseId +
      '/property/' +
      propertyId +
      '/lv/excel';
    const fd = new FormData();
    fd.append('file', file);
    authAxios
      .put(src, fd)
      .then((res) => {
        const entry: LV = res.data;
        resolve(entry);
      })
      .catch((error) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

/**
 * This method reads the whole LV for the given property and phase
 * @param phaseId
 * @param propertyId
 * @returns
 */
export const readLvCatalogForProperty = (
  phaseId: String,
  propertyId: String
) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'get',
      url:
        URL_TO_LVEP_SERVER +
        '/v1/phase/' +
        phaseId +
        '/property/' +
        propertyId +
        '/lv'
    })
      .then((result: any) => {
        console.log('Received:');
        console.log(result);
        if (result && result.data) {
          let answer: LV = result.data;
          resolve(answer);
        } else {
          resolve(undefined);
        }
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

/**
 * This method reads the whole LV for all properties in the given phase
 * @param phaseId
 * @returns
 */
export const readAllLvCatalogsForPhase = (phaseId: string) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'get',
      url: URL_TO_LVEP_SERVER + '/v1/phase/' + phaseId + '/lv/full'
    })
      .then((result: any) => {
        console.log('Received:');
        console.log(result);
        if (result && result.data) {
          let answer: LV[] = result.data;
          resolve(answer);
        } else {
          resolve(undefined);
        }
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

export const deleteLvCatalogForProperty = (
  phaseId: String,
  propertyId: String,
  lvId: String
) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'delete',
      url:
        URL_TO_LVEP_SERVER +
        '/v1/phase/' +
        phaseId +
        '/property/' +
        propertyId +
        '/lv/' +
        lvId
    })
      .then(() => {
        console.log('success:');
        resolve('Success');
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

/**
 * This method is for downloading the EP Catalog for a given phase as excel
 * @param phaseId
 * @returns
 */
export const readEpCatalogAsExcelForProperty = (phaseId: String) => {
  return new Promise((resolve, reject) => {
    const src =
      //URL_TO_LVEP_SERVER + '/v1/phase/' + phaseId + '/epCatalog/excel';//
      'http://localhost:13018/v1/phase/' + phaseId + '/epCatalog/excel';

    authAxios({
      method: 'get',
      url: src,
      responseType: 'blob'
    })
      .then((response) => {
        const entry = response.data;
        resolve(entry);
      })
      .catch((error) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

/**
 * This method uploads the given excel to create a new EP Catalog for the given Phase
 * @param phaseId
 * @param file
 * @returns
 */
export const createNewEpCatalogForPropertyFromExcel = (
  phaseId: String,
  file: any
) => {
  return new Promise((resolve, reject) => {
    console.log('SEND ==>');
    const src =
      URL_TO_LVEP_SERVER + '/v1/phase/' + phaseId + '/epCatalog/excel';
    const fd = new FormData();
    fd.append('file', file);
    authAxios
      .put(src, fd)
      .then((res) => {
        console.log('Received:');
        console.log(res.data);
        const entry: EP = res.data;
        resolve(entry);
      })
      .catch((error) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

/**
 * This method replaces the current ep Catalog with the given excel for the given Phase
 * @param phaseId
 * @param file
 * @returns
 */
export const replaceEpCatalogForPhaseFromExcel = (
  phaseId: String,
  file: any
) => {
  return new Promise((resolve, reject) => {
    console.log('SEND ==>');
    const src =
      URL_TO_LVEP_SERVER + '/v1/phase/' + phaseId + '/epCatalog/excel';
    const fd = new FormData();
    fd.append('file', file);
    authAxios
      .patch(src, fd)
      .then((res) => {
        console.log('Received:');
        console.log(res.data);
        const entry: EP = res.data;
        resolve(entry);
      })
      .catch((error) => {
        console.log(error);
        reject(errorMsgToString(error));
      });
  });
};

/**
 * This method reads the whole EP for the given phase
 * @param phaseId
 * @returns
 */
export const readEpCatalogForPhase = (phaseId: String) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'get',
      url: URL_TO_LVEP_SERVER + '/v1/phase/' + phaseId + '/epCatalog'
    })
      .then((result: any) => {
        console.log('Received:');
        console.log(result.data);
        let answer: EP = result.data;
        resolve(answer);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

export const deleteEpCatalogForPhase = (phaseId: String, catalogId: String) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'delete',
      url:
        URL_TO_LVEP_SERVER + '/v1/phase/' + phaseId + '/epCatalog/' + catalogId
    })
      .then(() => {
        console.log('success:');
        resolve('Success');
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

export const generateAwardedLV = (
  data: AwardRequest,
  phaseId: string,
  propertyId: string,
  type: string
) => {
  return new Promise((resolve, reject) => {
    const url =
      /*  'http://localhost:13018' + */
      URL_TO_LVEP_SERVER +
      '/v1/phase/' +
      phaseId +
      '/property/' +
      propertyId +
      '/awarding';
    authAxios
      .put(url, data, {
        params: {
          type: type
        },
        responseType: 'blob'
      })
      .then((response) => {
        const entry = response.data;
        resolve(entry);
      })
      .catch((error) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

////////////
// HELPER //
////////////

export const generateLVTreeFromEPPositions = (positions: EpPosition[]) => {
  if (positions) {
    //search boundaries
    let smallestLeftInt = 1000000;
    let largestRightInt = 0;
    positions.forEach((entry) => {
      if (entry.leftInt < smallestLeftInt) {
        smallestLeftInt = entry.leftInt;
      }
      if (entry.rightInt > largestRightInt) {
        largestRightInt = entry.rightInt;
      }
    });
    //create surrounding node
    let result = {
      key: 'delete',
      depth: 0,
      data: {
        leftInt: smallestLeftInt - 1,
        rightInt: largestRightInt + 1
      },
      children: null
    };
    //sort values
    const data: EpPosition[] = _.sortBy(positions, [
      function (entry: EpPosition) {
        return entry.leftInt;
      }
    ]);
    for (let i = 0; i < data.length; i++) {
      const entry: EpPosition = data[i];
      let parent = searchTreePosition(result, entry.leftInt, entry.rightInt);
      // if entry is hidden - virtually enlarge parent depth by 1
      if (entry.hidden) {
        parent.depth = parent.depth + 1;
      }
      if (parent.depth + 1 <= LV_DEPTH_EP_CATALOG) {
        parent.children = parent.children ? parent.children : [];
        parent.children.push({
          key: entry.id,
          depth: parent.depth + 1,
          className: 'lv-' + (parent.depth + 1),
          data: {
            title: entry.title,
            shortTitle: entry.shortTitle,
            number: entry.number,
            leftInt: entry.leftInt,
            rightInt: entry.rightInt
          }
        });
      }
    }
    return result.children;
  }
  return null;
};

export const generateEpTreeFromPositions = (positions: EpPosition[]) => {
  if (positions) {
    //search boundaries
    let smallestLeftInt = 1000000;
    let largestRightInt = 0;
    positions.forEach((entry) => {
      if (entry.leftInt < smallestLeftInt) {
        smallestLeftInt = entry.leftInt;
      }
      if (entry.rightInt > largestRightInt) {
        largestRightInt = entry.rightInt;
      }
    });
    //create surrounding node
    let result = {
      key: 'delete',
      depth: 0,
      data: {
        leftInt: smallestLeftInt - 1,
        rightInt: largestRightInt + 1
      },
      children: null
    };
    //sort values
    let data: EpPosition[] = _.sortBy(positions, [
      function (entry: EpPosition) {
        return entry.leftInt;
      }
    ]);
    for (let i = 0; i < data.length; i++) {
      let entry: EpPosition = data[i];
      let parent = searchTreePosition(result, entry.leftInt, entry.rightInt);
      let tags: string[] = extractTags(entry);
      parent.children = parent.children ? parent.children : [];
      parent.children.push({
        key: entry.id,
        depth: parent.depth + 1,
        className: entry.epCode ? 'p' : 'c-' + (parent.depth + 1),
        data: {
          title: entry.title,
          shortTitle: entry.shortTitle,
          type: entry.epCode ? 'position' : 'category',
          number: entry.number,
          hidden: entry.hidden,
          epCode: entry.epCode,
          unit: entry.unit,
          leftInt: entry.leftInt,
          rightInt: entry.rightInt,
          postText: entry.postText,
          tags: tags
        }
      });
    }
    return result.children;
  }
  return null;
};

function extractTags(entry: EpPosition) {
  let result = [];
  let added: boolean = false;
  if (entry && entry.sliceTag) {
    if (entry.sliceTag) {
      result.push(entry.sliceTag);
    }
    if (entry.connectedLvPositions && entry.connectedLvPositions.length > 0) {
      entry.connectedLvPositions.forEach((e: ConnectedLvPosition) => {
        if (e.propertyId) {
          added = true;
          result.push(e.propertyId);
        }
      });
    }
  }
  //if no prop id was added
  if (!added) {
    result.push(DL_EP_INPUT_DISABLE_FILTER_ID);
  }
  return result;
}

export function removeAllHiddenCategoriesFromEpTree(
  data: any[],
  lvMode: boolean
) {
  if (data) {
    let result = [];
    for (let x = 0; x < data.length; x++) {
      const node = data[x];
      let adapted = clearHiddenCategoriesInNode(node, lvMode);
      if (adapted) {
        result.push(adapted);
      }
    }
    return result;
  }
  return null;
}

function clearHiddenCategoriesInNode(node: any, lvMode: boolean) {
  if (node) {
    //remove all hidden categories by copy all children to it`s parent
    let result = _.cloneDeep(node);
    let parent = null;
    do {
      parent = searchParentCategoryWithHiddenChild(result, lvMode);
      if (parent) {
        //copy children to parent's children
        let newChildren = [];
        for (let x = 0; x < parent.children.length; x++) {
          const c = parent.children[x];
          if (
            c.data.type === 'category' &&
            (c.data.hidden === true ||
              (lvMode &&
                LV_DEPTH_EP_CATALOG !== -1 &&
                c.depth > LV_DEPTH_EP_CATALOG))
          ) {
            //if child has children to copy
            if (c.children && c.children.length > 0) {
              for (let a = 0; a < c.children.length; a++) {
                newChildren.push(c.children[a]);
              }
            }
          } else {
            newChildren.push(c);
          }
        }
        parent.children = newChildren;
      }
    } while (parent !== null);
    return result;
  }
  return null;
}

function searchParentCategoryWithHiddenChild(
  root: any,
  lvMode: boolean
): any | null {
  if (root.children && testArrayIfCatIsHidden(root.children, lvMode)) {
    return root;
  } else if (root.children != null) {
    let i;
    let result = null;
    for (i = 0; result == null && i < root.children.length; i++) {
      result = searchParentCategoryWithHiddenChild(root.children[i], lvMode);
    }
    return result;
  }
  return null;
}

function testArrayIfCatIsHidden(data: any[], lvMode: boolean) {
  for (let i = 0; i < data.length; i++) {
    const element = data[i];
    if (element && element.data) {
      if (element.data.type === 'category') {
        if (element.data.hidden === true) {
          return true;
        }
        //lv cleaning
        if (lvMode && LV_DEPTH_EP_CATALOG !== -1) {
          if (element.depth > LV_DEPTH_EP_CATALOG) {
            return true;
          }
        }
      }
    }
  }
  return false;
}

export function searchTreePosition(
  root: any,
  leftInt: number,
  rightInt: number
): any {
  if (root.data.leftInt < leftInt && root.data.rightInt > rightInt) {
    if (root.children) {
      let pay = null;
      for (let x = 0; pay == null && x < root.children.length; x++) {
        pay = searchTreePosition(root.children[x], leftInt, rightInt);
      }
      if (pay) {
        return pay;
      } else {
        return root;
      }
    } else {
      return root;
    }
  } else {
    return null;
  }
}
