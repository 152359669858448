import FileSaver from 'file-saver';
import _ from 'lodash';
import { TimelineStep } from '../../UI/Atoms/BeeTimeline';
import { BLKeyValueStore } from '../../UI/Molecules/DL/DLPriceInput';
import {
  DEFAULT_CALCULATION_MULTIPLIER,
  LeistungsSlices,
  OUTPUT_FILE_HEADLINE,
  SERVER_RESPONSE_ERROR,
  SERVER_RESPONSE_PENDING
} from '../Statics/Constants';
import { generateEpEntryId } from '../Util/IdGeneratorHelper';
import { calculateTreeDepth, shadeColor } from './ExcelExportTooling';

const ExcelJS = require('exceljs');

const excelDlSheetName = 'Rahmenpreise';
const excelDlEpNameHeader = 'Ep-Position';
export const excelDlPriceHeader = 'Rahmenpreis';
const excelDlTagHeader = 'Leistungstyp';
export const excelDlEpCodeHeader = 'Ep-Code';
const excelDlUnitHeader = 'Einheit';
const excelDlPostTextHeader = 'Nachtext';

export function exportDlEpExcel(
  tree: any,
  keyValueStore: {
    [key: string]: BLKeyValueStore;
  },
  phase: TimelineStep,
  isTemplate: boolean
) {
  return new Promise(async (resolve, reject) => {
    const workbook = new ExcelJS.Workbook();
    //metadata
    workbook.creator = '';
    workbook.lastModifiedBy = '';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();
    workbook.views = [
      {
        x: 0,
        y: 0,
        width: 10000,
        height: 20000,
        firstSheet: 0,
        activeTab: 0,
        visibility: 'visible'
      }
    ];
    // Force workbook calculation on load
    workbook.calcProperties.fullCalcOnLoad = true;

    //create Sheets
    let sheetDlEp = workbook.addWorksheet(excelDlSheetName, {
      properties: { defaultColWidth: 14 }
    });

    // make worksheets visible
    sheetDlEp.state = 'visible';

    //col widhts
    sheetDlEp = setUpCols(tree, sheetDlEp);

    //col header
    sheetDlEp = createSheetDlEpHeader(tree, sheetDlEp, phase);

    //content
    if (tree) {
      const treeDepth = calculateTreeDepth(tree) - 1;
      const colIndexPrice = getColIndexForPrice(treeDepth);
      createDlEpContent(
        tree,
        keyValueStore,
        phase,
        treeDepth,
        [],
        colIndexPrice,
        isTemplate,
        sheetDlEp
      );
    }

    // write to a file
    workbook.xlsx
      .writeBuffer()
      .then((buffer: any) =>
        FileSaver.saveAs(new Blob([buffer]), `${Date.now()}_Rahmenpreise.xlsx`)
      )
      .catch((err: any) => console.log('Error writing excel export', err));
  });
}

function setUpCols(tree: any, worksheet: any) {
  let treedepth = calculateTreeDepth(tree) - 1;

  /*   columnwidths/styles */
  for (let i = 1; i < treedepth + 2; i++) {
    worksheet.getColumn(i).key = 'depth-' + i;
    worksheet.getColumn(i).width = 3;
  }
  //price
  const priceIndex = getColIndexForPrice(treedepth);
  worksheet.getColumn(priceIndex).key = 'price';
  worksheet.getColumn(priceIndex).width = 14;
  worksheet.getColumn(priceIndex).numFmt = '#,###0.000 [$€-1]';
  //tag
  worksheet.getColumn(getColIndexForTag(treedepth)).key = 'typ';
  //epcode
  worksheet.getColumn(getColIndexForEp(treedepth)).key = 'ep';
  //unit
  worksheet.getColumn(getColIndexForUnit(treedepth)).key = 'ep';
  worksheet.getColumn(getColIndexForUnit(treedepth)).width = 10;
  //postText
  worksheet.getColumn(getColIndexForPostText(treedepth)).key = 'ep';
  worksheet.getColumn(getColIndexForPostText(treedepth)).width = 25;

  return worksheet;
}

function createSheetDlEpHeader(tree: any, worksheet: any, phase: TimelineStep) {
  let treedepth = calculateTreeDepth(tree) - 1;

  /*  metainfo */
  const metaInfoRow = worksheet.addRow();
  metaInfoRow.getCell(1).value = OUTPUT_FILE_HEADLINE;
  metaInfoRow.getCell(getColIndexForEp(treedepth) - 2).value =
    'Phase: ' + phase.title;
  metaInfoRow.getCell(getColIndexForPrice(treedepth)).value =
    'Datum: ' + new Date().toLocaleDateString('de-DE');

  //empty row
  const emptyRow = worksheet.addRow();
  emptyRow.getCell(1).value = '';

  //header for table
  const headerRow = worksheet.addRow();

  //ep title
  headerRow.getCell(1).value = excelDlEpNameHeader;
  //price
  headerRow.getCell(getColIndexForPrice(treedepth)).value = excelDlPriceHeader;
  //tag
  headerRow.getCell(getColIndexForTag(treedepth)).value = excelDlTagHeader;
  //epcode
  headerRow.getCell(getColIndexForEp(treedepth)).value = excelDlEpCodeHeader;
  //unit
  headerRow.getCell(getColIndexForUnit(treedepth)).value = excelDlUnitHeader;
  //postText
  headerRow.getCell(getColIndexForPostText(treedepth)).value =
    excelDlPostTextHeader;

  //style header
  const backgroundColorHeadline = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--primary-color');

  const colorHeadline = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--primary-color-text');

  metaInfoRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'FFFFFF'
    }
  };
  metaInfoRow.font = {
    bold: true,
    color: { argb: '000000' }
  };

  emptyRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'FFFFFF'
    },
    bgColor: {
      argb: 'FFFFFF'
    }
  };

  headerRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: backgroundColorHeadline.replace('#', '').replace(' ', '')
    }
  };
  headerRow.font = {
    bold: true,
    color: { argb: 'FFFFFF' }
  };
  headerRow.height = 50;
  headerRow.border = {
    bottom: { style: 'thick', color: { argb: 'FFFFFFFF' } }
  };

  return worksheet;
}

function createDlEpContent(
  data: any,
  keyValueStore: {
    [key: string]: BLKeyValueStore;
  },
  phase: TimelineStep,
  treeDepth: number,
  parentTitles: { title: string; depth: number }[],
  colIndexPrice: number,
  isTemplate: boolean,
  worksheet: any
) {
  if (data && data.length > 0) {
    let newParentTitles: { title: string; depth: number }[] = [];

    for (let i = 0; i < data.length; i++) {
      const valueId = generateEpEntryId(phase, data[i].data.epCode);
      const valueObject =
        keyValueStore && keyValueStore[valueId] ? keyValueStore[valueId] : null;

      if (data[i] && data[i].data && data[i].data.title) {
        const newRow = worksheet.addRow();
        //title

        const currentTitle = data[i].data.number
          ? '[' + data[i].data.number + '] ' + data[i].data.title
          : data[i].data.title;
        const newParentTitle = { title: currentTitle, depth: data[i].depth };
        newParentTitles = [...parentTitles, newParentTitle];

        for (let i = 0; i < newParentTitles.length; i++) {
          newRow.getCell(newParentTitles[i].depth).value =
            newParentTitles[i].title;
        }
        newRow.getCell(data[i].depth).value = currentTitle;

        if (valueObject) {
          if (!isTemplate) {
            if (
              valueObject.process !== SERVER_RESPONSE_PENDING &&
              valueObject.process !== SERVER_RESPONSE_ERROR
            ) {
              //price
              newRow.getCell(colIndexPrice).value =
                valueObject && valueObject.value
                  ? _.divide(valueObject.value, DEFAULT_CALCULATION_MULTIPLIER)
                  : valueObject.value === 0
                  ? 0
                  : null;
            } else {
              newRow.getCell(colIndexPrice).value =
                'Fehler: Der Export wurde ausgelöst bevor der Wert gespeichert werden konnte, bitte exportieren Sie das Dokument erneut';
            }
          }
          //find tag and translate it into slice title
          const slice = _.find(LeistungsSlices, function (sl) {
            return _.intersection(sl.tag, data[i].data.tags).length > 0;
          });
          //tag
          newRow.getCell(getColIndexForTag(treeDepth)).value = slice
            ? slice.title
            : null;
          newRow.getCell(getColIndexForTag(treeDepth)).font = {
            color: { argb: '808080' }
          };
          //ep
          newRow.getCell(getColIndexForEp(treeDepth)).value = data[i].data
            .epCode
            ? data[i].data.epCode
            : null;
          newRow.getCell(getColIndexForEp(treeDepth)).font = {
            color: { argb: '808080' }
          };
          //unit
          newRow.getCell(getColIndexForUnit(treeDepth)).value = data[i].data
            .unit
            ? data[i].data.unit
            : null;
          //posttext
          newRow.getCell(getColIndexForPostText(treeDepth)).value = data[i].data
            .postText
            ? data[i].data.postText
            : null;
        }
        // style row depending on tree depth
        let calculatedColor = '';
        if (data[i].data && data[i].data.type !== 'position') {
          const opacity = (data[i].depth / treeDepth) * 100;
          if (data[i].depth === 1) {
            const primaryColor = getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary-light-color');
            calculatedColor = primaryColor.replace('#', '').replace(' ', '');
            newRow.font = { color: { argb: 'FFFFFF' }, bold: true };
          } else if (data[i].depth === 2) {
            const secondaryColor = getComputedStyle(
              document.documentElement
            ).getPropertyValue('--secondary-color');
            calculatedColor =
              'FF' + secondaryColor.replace('#', '').replace(' ', '');
          } else {
            const greyColor = getComputedStyle(
              document.documentElement
            ).getPropertyValue('--surface-700');
            calculatedColor = shadeColor(
              greyColor.replace('#', '').replace(' ', ''),
              opacity
            );
          }
        } else {
          calculatedColor = 'ffffff';

          newRow.border = {
            bottom: { style: 'thin', color: { argb: 'eeeeee' } }
          };
          newRow.getCell(colIndexPrice).border = {
            top: { style: 'medium' },
            left: { style: 'medium' },
            bottom: { style: 'medium' },
            right: { style: 'medium' }
          };
          newRow.getCell(colIndexPrice).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF2F2F2' },
            bgColor: { argb: 'FFF2F2F2' }
          };
          newRow.getCell(colIndexPrice).font = {
            bold: true
          };
        }
        newRow.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: calculatedColor
          },
          bgColor: {
            argb: 'ffffff'
          }
        };

        //style fontcolor for filtercategories
        if (data[i].depth) {
          for (let k = 1; k < data[i].depth; k++) {
            const cell = newRow.getCell(k);
            cell.font = {
              color: { argb: calculatedColor }
            };
          }
        }
      }
      if (data[i] && data[i].children) {
        createDlEpContent(
          data[i].children,
          keyValueStore,
          phase,
          treeDepth,
          newParentTitles,
          colIndexPrice,
          isTemplate,
          worksheet
        );
      }
    }
  }
  return worksheet;
}

/////////////////
///// Helper ////
/////////////////

function getColIndexForPrice(treedepth: number) {
  return treedepth + 12;
}
function getColIndexForTag(treedepth: number) {
  return treedepth + 5;
}
function getColIndexForEp(treedepth: number) {
  return treedepth + 6;
}
function getColIndexForUnit(treedepth: number) {
  return treedepth + 10;
}
function getColIndexForPostText(treedepth: number) {
  return treedepth + 11;
}
