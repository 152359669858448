import {
  InputNumber,
  InputNumberValueChangeParams
} from 'primereact/inputnumber';
import { useId } from 'react-id-generator';
import { EMPTY_FIELD_PLACEHOLDER } from '../../Helper/Statics/Constants';
import './BeeCurrencyInput.scss';
import BeeTextInput from './BeeTextInput';

type BeeCurrencyInputProps = {
  id?: string;
  label?: string;
  value?: number | null;
  disabled?: boolean;
  minFractionDigits?: number;
  maxFractionDigits?: number;

  formstate?: string;
  readOnly?: boolean;
  required?: boolean;
  onChange?: (e: InputNumberValueChangeParams) => void;
  onFocusOut?: (e: number | undefined) => void;
};

export function BeeCurrencyInput({
  id,
  label,
  value,
  disabled,
  minFractionDigits,
  maxFractionDigits,
  formstate,
  readOnly,
  required,
  onChange,
  onFocusOut
}: BeeCurrencyInputProps) {
  const genId = useId(1, 'bee-cur-input-')[0];
  const currentId = id ? id : genId;

  //classNames
  const formStateClassNames =
    formstate === 'valid'
      ? ' bee-cur-input-field-valid'
      : formstate === 'error'
      ? ' bee-cur-input-field-error'
      : formstate === 'neutral'
      ? ' bee-cur-input-field-neutral'
      : ' bee-cur-input-field-neutral';

  let readOnlyClassNames = '';

  if (readOnly) {
    readOnlyClassNames = ' bee-cur-input-field-readOnly';
    disabled = true;
  }
  const inputClassNames =
    'bee-cur-input-field' + formStateClassNames + readOnlyClassNames;
  const containerClassNames = 'p-float-label bee-cur-input-container';

  //label
  const labelClassNames = 'bee-cur-input-label';

  function change(e: InputNumberValueChangeParams) {
    if (!disabled && onChange) {
      onChange(e);
    }
  }
  function focusOut(e: number | undefined) {
    if (!disabled && onFocusOut) {
      onFocusOut(e);
    }
  }

  const renderPlaceholder = () => {
    return (
      <BeeTextInput
        label={label}
        value={EMPTY_FIELD_PLACEHOLDER}
        disabled={disabled}
        readOnly={true}
        required={required}
        onChange={() => {}}
      />
    );
  };

  return (
    <>
      {readOnly && !(value || value === 0) ? (
        renderPlaceholder()
      ) : (
        <span
          id={currentId}
          className={'p-input-icon-right ' + containerClassNames}
        >
          <span className="currency-euro-sign">€</span>
          <InputNumber
            id={currentId + 'input'}
            value={value}
            disabled={disabled}
            minFractionDigits={minFractionDigits}
            maxFractionDigits={maxFractionDigits}
            mode="decimal"
            inputClassName={inputClassNames}
            onValueChange={(e) => change(e)}
            onBlur={(e) => {
              let newInt =
                e && e.target && e.target.value
                  ? e.target.value.replaceAll('.', '')
                  : undefined;
              newInt = newInt ? newInt.replace(',', '.') : undefined;
              newInt ? focusOut(parseFloat(newInt)) : focusOut(undefined);
            }}
          />
          {label ? (
            <label htmlFor={currentId + 'input'} className={labelClassNames}>
              {label}
              {required && label ? (
                <span className="bee-cur-input-label-required">
                  <span className="bee-cur-input-label-required-star"> *</span>
                </span>
              ) : null}
            </label>
          ) : null}
        </span>
      )}
    </>
  );
}

export default BeeCurrencyInput;
