import { TimelineStep } from '../../UI/Atoms/BeeTimeline';
import { LiegenschaftsData } from '../ApiHelper/LiegenschaftenNetworkHelper';
import {
  LEISTUNGSART,
  NAMING_LS_IN_MARKTGEBIET,
  NAMING_SUSTAINABILITY_FACTOR
} from '../Statics/Constants';
import _ from 'lodash';

////////////
// HELPER //
////////////

export function isKeyParticipationKey(key: string) {
  return key.startsWith('participation_');
}

export function generateParticipationKey(phaseId: string) {
  return 'participation_' + phaseId;
}

export function isKeyAwardingKey(key: string) {
  return key.startsWith('awarding_');
}

export function generateAwardingKey(
  phaseId: string,
  propertyId: string,
  sliceId: string
) {
  return 'awarding_' + phaseId + '_' + propertyId + '_' + sliceId;
}

export function generateTargetPriceKey(phaseId: string) {
  return phaseId + '_goals';
}

export function generateTargetPriceId(
  phaseId: string,
  propertyId: string,
  sliceId: string
) {
  return phaseId + '_' + propertyId + '_' + sliceId + '_target';
}

export function generateBlFactorId(
  phase: TimelineStep,
  regionId: String,
  leistung: LEISTUNGSART
) {
  return simpleGenerateBlFactorId(phase.id, regionId, leistung.id);
}

export function simpleGenerateBlFactorId(
  phaseId: String,
  regionId: String,
  leistungssliceId: String
) {
  return phaseId + '_' + regionId + '_' + leistungssliceId;
}

export function generateLsFactorId(
  phase: TimelineStep,
  property: LiegenschaftsData,
  sustainabilityFactor: boolean,
  leistung: LEISTUNGSART
) {
  return simpleGenerateLsFactorId(
    phase.id,
    property.id!,
    sustainabilityFactor,
    leistung.id
  );
}

export function simpleGenerateLsFactorId(
  phaseId: String,
  propertyId: String,
  sustainabilityFactor: boolean,
  leistungssliceId: String
) {
  if (sustainabilityFactor) {
    return (
      phaseId +
      '_' +
      propertyId +
      '_' +
      leistungssliceId +
      '_' +
      NAMING_SUSTAINABILITY_FACTOR
    );
  }
  return phaseId + '_' + propertyId + '_' + leistungssliceId;
}

export function generateLsInMarktgebietId(
  phase: TimelineStep,
  property: LiegenschaftsData
) {
  return simpleGenerateLsInMarktgebietId(phase.id, property.id!);
}

export function simpleGenerateLsInMarktgebietId(
  phaseId: String,
  propertyId: string
) {
  return phaseId + '_' + propertyId + NAMING_LS_IN_MARKTGEBIET;
}

export function generateEpEntryId(phase: TimelineStep, epCode: string) {
  return simpleGenerateEpEntryId(phase.id, epCode);
}

export function simpleGenerateEpEntryId(phaseId: String, epCode: string) {
  return phaseId + '_' + epCode;
}

export function generateAlphaFilterId(phaseId: string) {
  return phaseId + '_filter';
}

export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
