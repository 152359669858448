import _ from 'lodash';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LiegenschaftsData } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import { AuthUserType } from '../../../Helper/ApiHelper/LoginNetworkHelper';
import {
  EnlargedLvPosition,
  generateAwardedLV,
  searchTreePosition
} from '../../../Helper/ApiHelper/LvEpNetworkHelper';
import BeeCascadeSelectLiegenschaften from '../../Atoms/BeeCascadeSelectLiegenschaften';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import { TimelineStep } from '../../Atoms/BeeTimeline';
import AlphaPropertyDetail from '../../Molecules/Alpha/AlphaPropertyDetail';
import Error404 from '../../Molecules/Error404';
import { ImageType } from '../../Pages/AlphaPages/AlphaLiegenschaftVerwaltung';
import './AlphaTabProperty.scss';
import AlphaPropertyLV from '../../Molecules/Alpha/AlphaPropertyLV';
import BeeDropDown from '../../Atoms/BeeDropDown';
import {
  ALPHA_DL_INCOMPLETE_PRICES,
  ALPHA_DL_NOT_IN_MARKETAREA,
  ALPHA_DL_NO_PRICE,
  DEFAULT_FACTOR,
  DL_EP_INPUT_DISABLE_FILTER_ID,
  DURATION_NOTIFICATION_ERROR_LONG,
  LEISTUNGSART,
  LeistungsSlices
} from '../../../Helper/Statics/Constants';
import {
  calculateFakEp,
  calculateGP,
  filterArrayByHavingLvPosLeafs
} from '../../../Helper/Util/LvCalculator';
import BeeButton from '../../Atoms/BeeButton';
import { generateAwardRequest } from '../../../Helper/Util/AwardedLvHelper';
import fileDownload from 'js-file-download';

type AlphaTabPropertyProps = {
  phase: TimelineStep;
  properties: LiegenschaftsData[];
  imageLookup: Map<string, ImageType>;
  serviceProvider: AuthUserType[];
  keyValues: any;
  lvData: Map<string, EnlargedLvPosition[]>;
  shortenLvTree: any;
};

export default function AlphaTabProperty({
  phase,
  properties,
  imageLookup,
  serviceProvider,
  keyValues,
  lvData,
  shortenLvTree
}: AlphaTabPropertyProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [property, setProperty] = useState<LiegenschaftsData>();
  const [noProperty, setNoProperty] = useState<boolean>(false);
  const [enlargedLV, setEnlargedLV] = useState<EnlargedLvPosition[]>([]);
  const [participationLookup, setParticipationLookup] = useState<
    Map<string, string>
  >(new Map());
  const [lvTree, setLvTree] = useState<any>();
  const toast = useRef<Toast>(null);
  const [selectedProvider, setSelectedProvider] = useState<AuthUserType[]>([]);
  const [filter, setFilter] = useState<LEISTUNGSART>();
  const [allFilter, setAllFilter] = useState<LEISTUNGSART[]>();
  const [generatingLv, setGeneratingLv] = useState<boolean>(false);

  useEffect(() => {
    const propertyId = searchParams.get('lId');
    if (phase && !(property && property.id === propertyId)) {
      //init filter
      const filterSlices: LEISTUNGSART[] = LeistungsSlices;
      let filter: LEISTUNGSART[] = [
        {
          id: DL_EP_INPUT_DISABLE_FILTER_ID,
          icon: '',
          title: 'Keine Filterung',
          tag: [],
          sustainability: false
        }
      ];
      for (let i = 0; i < filterSlices.length; i++) {
        filter.push(filterSlices[i]);
      }
      //init property
      const property = _.find(properties, function (pr: LiegenschaftsData) {
        return pr.id === propertyId;
      });
      let lv = lvData && propertyId ? lvData.get(propertyId) : [];
      lv = lv ? lv : [];
      if (property) {
        setProperty(property);
        setNoProperty(false);
        setEnlargedLV(lv);
        filterContent(lv, filter[0]);
      } else {
        setProperty(undefined);
        setNoProperty(true);
      }
      setAllFilter(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phase, searchParams, properties]);

  ///////////////////////
  /////// HELPER ////////
  ///////////////////////

  function filterContent(
    completeLV: EnlargedLvPosition[],
    filter: LEISTUNGSART
  ) {
    const filterTags = filter && filter.tag ? filter.tag : [];
    let filteredPayload =
      filter && filter.id === DL_EP_INPUT_DISABLE_FILTER_ID
        ? completeLV
        : _.filter(completeLV, function (p) {
            if (p.sliceTag) {
              return filterTags.includes(p.sliceTag);
            }
            return false;
          });
    setFilter(filter);
    let filteredTree = calculateLV(shortenLvTree, filteredPayload);
    if (filteredTree) {
      filteredTree = filterArrayByHavingLvPosLeafs(filteredTree);
    }
    setLvTree(filteredTree);
  }

  function calculateLV(
    shortenLvTree: any,
    enlargedLvPositions: EnlargedLvPosition[]
  ) {
    if (shortenLvTree && enlargedLvPositions) {
      let lvTableTree = _.cloneDeep(shortenLvTree);
      let providerParticipation = new Map<string, string>();
      //lvPos are sorted
      for (let x = 0; x < enlargedLvPositions.length; x++) {
        const lvPos = enlargedLvPositions[x];
        let parent = null;
        //search ep node
        for (let i = 0; i < lvTableTree.length; i++) {
          parent = searchTreePosition(
            lvTableTree[i],
            lvPos.leftInt,
            lvPos.rightInt
          );
          if (parent) {
            break;
          }
        }
        parent.children = parent.children ? parent.children : [];
        const amount: number = lvPos.amount ? lvPos.amount : 0;
        parent.children.push({
          key: lvPos.id,
          className: 'lv-pos',
          data: {
            tag: lvPos.sliceTag,
            values: calculateEpForProviders(
              lvPos.blFactorId,
              lvPos.lsFactorId,
              lvPos.nFactorId,
              lvPos.mFactorId,
              lvPos.epPriceId,
              amount,
              providerParticipation
            ),
            amount: amount,
            epCode: lvPos.epCode,
            title: lvPos.description,
            posNumber: lvPos.serialNumber,
            optionalPosition: lvPos.optionalPosition,
            optionalPositionActive: lvPos.optionalPositionActive,
            aks: lvPos.identificationNumber,
            unit: lvPos.unit
          }
        });
      }
      setParticipationLookup(providerParticipation);
      return lvTableTree;
    }
    return [];
  }

  function search(lookupKey: string) {
    return keyValues.get(lookupKey);
  }

  function calculateEpForProviders(
    blFactor: string,
    lsFactor: string,
    nFactor: string,
    mFactor: string,
    epPriceId: string,
    amount: number,
    providerParticipation: Map<string, string>
  ) {
    let answer: any = {};
    const blFacValue = search(blFactor);
    const lsFacValue = search(lsFactor);
    const nFacValue = search(nFactor);
    const mFacValue = search(mFactor);
    const priceValue = search(epPriceId);
    serviceProvider.forEach((p) => {
      if (mFacValue && mFacValue[p.id] && mFacValue[p.id] === 'false') {
        providerParticipation.set(p.id, ALPHA_DL_NOT_IN_MARKETAREA);
        answer[p.id] = ALPHA_DL_NOT_IN_MARKETAREA;
      } else if (
        !priceValue ||
        !priceValue[p.id] ||
        priceValue[p.id] === 'null'
      ) {
        if (!providerParticipation.get(p.id)) {
          providerParticipation.set(p.id, ALPHA_DL_INCOMPLETE_PRICES);
        }
        answer[p.id] = ALPHA_DL_NO_PRICE;
      } else {
        const price = parseInt(priceValue[p.id]);
        const blF = parseInt(
          blFacValue && blFacValue[p.id] ? blFacValue[p.id] : DEFAULT_FACTOR
        );
        const lsF = parseInt(
          lsFacValue && lsFacValue[p.id] ? lsFacValue[p.id] : DEFAULT_FACTOR
        );
        const nF = parseInt(
          nFacValue && nFacValue[p.id] ? nFacValue[p.id] : DEFAULT_FACTOR
        );
        const blFac = blF ? blF : DEFAULT_FACTOR;
        const lsFac = lsF ? lsF : DEFAULT_FACTOR;
        const nFac = nF ? nF : DEFAULT_FACTOR;
        answer[p.id] = {};
        answer[p.id].ep = calculateFakEp(price, blFac, lsFac, nFac);
        answer[p.id].gp = calculateGP(amount, price, blFac, lsFac, nFac);
      }
    });
    return answer;
  }

  function generateLVs() {
    if (property) {
      setGeneratingLv(true);
      const request = generateAwardRequest(property, selectedProvider, lvTree);
      generateAwardedLV(request, phase.id, property.id!, 'excel')
        .then((file: any) => {
          let filename =
            selectedProvider && selectedProvider.length < 2
              ? 'LV [' +
                property.nummer +
                ']_' +
                property!.name +
                '_' +
                new Date().toLocaleDateString('de-DE') +
                '.xlsx'
              : 'Preisspiegel [' +
                property.nummer +
                ']_' +
                property!.name +
                '_' +
                new Date().toLocaleDateString('de-DE') +
                '.xlsx';
          if (filename) {
            fileDownload(file, filename);
          }
        })
        .catch((error) => {
          toast.current?.show({
            severity: 'error',
            summary: 'LVs konnten nicht erstellt werden.',
            detail:
              'Die Generierung der LVs ist leider schiefgelaufen. Bitte versuchen Sie es später erneut oder wenden sich an den Kundensupport. ',
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        })
        .finally(() => {
          setGeneratingLv(false);
        });
    }
  }

  ///////////////////////
  /// VIEW COMPONENTS ///
  ///////////////////////

  function injectSelect() {
    return (
      <BeeCascadeSelectLiegenschaften
        label={'Liegenschaften'}
        value={property}
        options={properties}
        disabled={false}
        formstate={'neutral'}
        readOnly={false}
      />
    );
  }

  const injectPayload = () => {
    const propArray: LiegenschaftsData[] = [];
    if (property) {
      propArray.push(property);
    }
    let image = null;
    if (property && property.hauptBildId) {
      image = imageLookup?.get(property.hauptBildId);
    }
    return (
      <div className={'alpha-tab-property grid'}>
        <div className={'col-12'}> {injectSelect()} </div>
        {property ? (
          <div className={'col-12'}>
            <AlphaPropertyDetail
              property={property}
              image={image ? image : undefined}
              provider={serviceProvider}
              participationLookup={participationLookup}
              selectedProvider={selectedProvider}
              onSelectProvider={(e: any) => setSelectedProvider(e)}
            />
          </div>
        ) : null}
        <div className={'col-12 dl-tab-property-lv'}>
          <div>
            <BeeContentHeadline
              label={'Leistungsverzeichnis'}
              headline={'h2'}
              type={'primary'}
            />
            <div>
              Bitte wählen Sie in obiger Auswahlbox die Dienstleister aus, deren
              Preise Sie für diese Liegenschaft miteinander vergleichen möchten.
              Die Tabelle zeigt das für die Liegenschaft hinterlegte
              Leistungsverzeichnis sowie die Angebote der Dienstleister.
            </div>
            <div className={'alpha-tab-prop-posFilter'}>
              <BeeDropDown
                label={
                  <span>
                    <i className={'icon-filter'} />
                    <span>{'Positionen'}</span>
                  </span>
                }
                value={filter}
                options={allFilter}
                optionLabel={'title'}
                disabled={false}
                formstate={'neutral'}
                readOnly={false}
                required={false}
                onChange={(item: any) => filterContent(enlargedLV, item)}
              />
            </div>
            <AlphaPropertyLV
              lvTree={lvTree}
              selectedProvider={selectedProvider}
            />
            {filter && filter.id !== DL_EP_INPUT_DISABLE_FILTER_ID ? (
              <div className="error-color mb-2 mt-3 flex justify-content-end">
                <i className="icon-error"></i>
                Achtung beim Herunterladen des Preisspiegels: Aufgrund der
                aktuellen Filterung enthält der LV nicht alle Positionen!
              </div>
            ) : null}
            <div className="flex justify-content-end">
              <BeeButton
                label={
                  selectedProvider && selectedProvider.length < 2
                    ? 'LV herunterladen'
                    : 'Preisspiegel herunterladen'
                }
                disabled={
                  !lvTree || !selectedProvider || !(selectedProvider.length > 0)
                }
                type={'secondary'}
                isLoading={generatingLv}
                onClick={() => generateLVs()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={'m-2'}>
      {noProperty ? <Error404 /> : injectPayload()}
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
