import { useRef, useState } from 'react';
import './DownloadCombinedPositions.scss';
import {
  GebaeudeData,
  LiegenschaftsData,
  readGebaeudeById
} from '../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import { EpPosition, LV } from '../../Helper/ApiHelper/LvEpNetworkHelper';
import { TimelineStep } from '../Atoms/BeeTimeline';
import { handlePromisesAndIgnoreErrors } from '../../Helper/Util/PromiseHelper';
import { exportDlPropertiesExcel } from '../../Helper/StorageHelper/ExcelExportDlProperties';
import BeeButton from '../Atoms/BeeButton';
import _ from 'lodash';
import { Toast } from 'primereact/toast';
import { DURATION_NOTIFICATION_ERROR_LONG } from '../../Helper/Statics/Constants';
import { AuthUserType } from '../../Helper/ApiHelper/LoginNetworkHelper';

type DownloadCombinedPositionsProps = {
  properties?: LiegenschaftsData[];
  lvs: LV[];
  epLookup?: Map<string, EpPosition>;
  epTree: any;
  phase: TimelineStep;
  serviceProvider?: AuthUserType[];
  exportPrices?: Map<string, CalcLvPosition[]>;
};

type FlatEpPos = {
  number: string;
  title: string;
  depth: number;
  leftInt: number;
};

export type CalcLvPosition = {
  providerId: string;
  gp: number;
  noPriceGiven: boolean;
  notInMarketArea: boolean;
};

export default function DownloadCombinedPositions({
  properties,
  lvs,
  epLookup,
  epTree,
  phase,
  serviceProvider,
  exportPrices
}: DownloadCombinedPositionsProps) {
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(false);
  const toast = useRef<Toast>(null);

  function generateDownload() {
    setDataIsLoading(true);
    //get all Buildings for all properties
    let buildPromises = [];
    if (properties && epLookup) {
      for (let i = 0; i < properties.length; i++) {
        let prop = properties[i];
        if (prop.gebaeudeIds && prop.gebaeudeIds.length) {
          for (let j = 0; j < prop.gebaeudeIds.length; j++) {
            buildPromises.push(readGebaeudeById(prop.id!, prop.gebaeudeIds[j]));
          }
        }
      }
      handlePromisesAndIgnoreErrors(buildPromises).then((data) => {
        const bLookup = new Map<string, GebaeudeData>();
        if (data && data.fulfilled) {
          for (let x = 0; x < data.fulfilled.length; x++) {
            const building = data.fulfilled[x].value;
            bLookup.set(building.id, building);
          }
        }
        let lookup = new Map<string, any[]>();
        for (let k = 0; k < epTree.length; k++) {
          const arr: any = flattenTree(epTree[k], []);
          if (arr && arr.length > 0) {
            for (let l = 0; l < arr.length; l++) {
              lookup.set(arr[l].value, _.sortBy(arr[l].path, ['leftInt']));
            }
          }
        }
        exportDlPropertiesExcel(
          properties,
          lvs,
          bLookup,
          phase,
          epLookup,
          lookup,
          epTree,
          serviceProvider,
          exportPrices
        )
          .then(() => console.log('done'))
          .catch((error) => {
            console.log(error);
            if (toast && toast.current) {
              toast.current?.show({
                severity: 'error',
                summary: 'Fehler beim Download',
                detail:
                  'Leider konnten der Export nicht erstellt werden. Bitte versuchen Sie es später erneut.',
                sticky: false,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            }
          })
          .finally(() => setDataIsLoading(false));
      });
    }
  }

  function flattenTree(epTree: any, paths: FlatEpPos[]) {
    const flattened: any = [];
    function traverse(node: any, currentPath: any) {
      if (!node.children || node.children.length === 0) {
        currentPath.push(cInFlatEpPos(node));
        flattened.push({ path: currentPath, value: node.data.epCode });
      } else {
        node.children.forEach((child: any) => {
          traverse(child, [...currentPath, cInFlatEpPos(node)]);
        });
      }
    }
    traverse(epTree, []);
    return flattened;
  }

  function cInFlatEpPos(node: any) {
    if (node) {
      const pay: FlatEpPos = {
        number: node.data.number,
        title: node.data.title,
        depth: node.depth,
        leftInt: node.data.leftInt
      };
      return pay;
    }
    return null;
  }

  return (
    <div className={'download-combindes-positions'}>
      <BeeButton
        label={
          <>
            <i className="icon-download"></i>
            {'Portfolioübersicht herunterladen'}
          </>
        }
        disabled={!properties || !epLookup || dataIsLoading}
        isLoading={dataIsLoading}
        type={'secondary'}
        onClick={() => generateDownload()}
      />
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
