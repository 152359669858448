import _ from 'lodash';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import './AlphaVergabeProviderTable.scss';
import { LiegenschaftsData } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import { AuthUserType } from '../../../Helper/ApiHelper/LoginNetworkHelper';
import {
  DEFAULT_CALCULATION_MULTIPLIER,
  LEISTUNGSART,
  MAX_NUMBER_OF_DECIMALS,
  MIN_NUMBER_OF_DECIMALS
} from '../../../Helper/Statics/Constants';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { KeyValEntry } from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import { generateAwardingKey } from '../../../Helper/Util/IdGeneratorHelper';
import { ReactComponent as MarktgebietIncompleteSVG } from '../../../Style/Icons/svgs/icons8-country-warning.svg';
import { ReactComponent as PriceInputIncompleteSVG } from '../../../Style/Icons/svgs/icons8-incompletePrice.svg';
import { Tooltip } from 'primereact/tooltip';
import BeeCurrencyInput from '../../Atoms/BeeCurrencyInput';

type AlphaVergabeProviderTableProps = {
  phaseId: string;
  selectedProperties: LiegenschaftsData[];
  selectedProvider: AuthUserType[];
  selectedSlices: LEISTUNGSART[];
  lookup: any;
  awarding: Map<string, KeyValEntry>;
};

type AwardingCalcType = {
  count: number;
  sum: number;
  notAllInMarket: boolean;
  notAllPrices: boolean;
};

export default function AlphaVergabeProviderTable({
  phaseId,
  selectedProperties,
  selectedProvider,
  selectedSlices,
  lookup,
  awarding
}: AlphaVergabeProviderTableProps) {
  const [data, setData] = useState<Map<string, AwardingCalcType>>(new Map());
  const [provider, setProvider] = useState<AuthUserType[]>([]);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    if (
      selectedProperties &&
      selectedProvider &&
      selectedSlices &&
      lookup &&
      awarding
    ) {
      let currData: Map<string, AwardingCalcType> = new Map();
      selectedProperties.forEach((prop: LiegenschaftsData) => {
        selectedSlices.forEach((sl: LEISTUNGSART) => {
          const awardingKey = generateAwardingKey(phaseId, prop.id!, sl.id);
          const award = awarding.get(awardingKey);
          if (award) {
            const awardedProviderId = award.value;
            //test if selected provider contained awarded
            if (_.some(selectedProvider, { id: awardedProviderId })) {
              const val = lookup[prop.id!][sl.id][awardedProviderId];
              let awd = currData.get(awardedProviderId);
              awd = awd
                ? awd
                : {
                    count: 0,
                    sum: 0,
                    notAllInMarket: false,
                    notAllPrices: false
                  };
              awd.count = awd.count + 1;
              awd.sum = awd.sum + val.sum;
              if (val.notInMarket) {
                awd.notAllInMarket = true;
              }
              if (val.inComplete) {
                awd.notAllPrices = true;
              }
              currData.set(awardedProviderId, awd);
            }
          }
        });
      });
      //sort provider using currData
      let tmp: any = [];
      let unsafe: any = [];
      let noAward: any = [];
      selectedProvider.forEach((p: AuthUserType) => {
        if (currData.get(p.id)) {
          const c = {
            provider: p,
            val: currData.get(p.id)?.sum
          };
          if (
            currData.get(p.id)?.notAllPrices ||
            currData.get(p.id)?.notAllInMarket
          ) {
            unsafe.push(c);
          } else {
            tmp.push(c);
          }
        } else {
          noAward.push({
            provider: p,
            val: null
          });
        }
      });
      //sort
      tmp = _.orderBy(tmp, 'val', 'desc');
      unsafe = _.orderBy(unsafe, 'val', 'desc');
      noAward = _.orderBy(noAward, 'p.organisation', 'asc');
      tmp.push(...unsafe);
      tmp.push(...noAward);
      const sortedProviders: AuthUserType[] = [];
      for (let x = 0; x < tmp.length; x++) {
        sortedProviders.push(tmp[x].provider);
      }
      setProvider(sortedProviders);
      setData(currData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperties, selectedProvider, selectedSlices, lookup, awarding]);

  ////////////////
  // UI METHODS //
  ////////////////

  const providerTemplate = (entry: any, options: ColumnBodyOptions) => {
    if (options && entry) {
      return (
        <div>
          <div>{entry.organisation}</div>
          <div className="provider-name">
            <span>{entry.firstname}</span> <span>{entry.lastname}</span>
          </div>
        </div>
      );
    }
  };

  const paketeTemplate = (entry: any, options: ColumnBodyOptions) => {
    if (options && entry && data) {
      const pid = entry.id;
      const val = data.get(pid);
      if (val) {
        return <div>{val.count}</div>;
      } else {
        return <div>keine Pakete erhalten</div>;
      }
    }
  };

  const vergabeTemplate = (entry: any, options: ColumnBodyOptions) => {
    if (options && entry && data) {
      const pid = entry.id;
      const val = data.get(pid);
      if (val) {
        return (
          <div>
            {' '}
            <BeeCurrencyInput
              value={
                val.sum
                  ? _.divide(val.sum, DEFAULT_CALCULATION_MULTIPLIER)
                  : val.sum === 0
                  ? 0
                  : null
              }
              disabled={false}
              minFractionDigits={MAX_NUMBER_OF_DECIMALS}
              maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
              formstate={'none'}
              readOnly={true}
            />
          </div>
        );
      } else {
        return <div>keine Pakete erhalten</div>;
      }
    }
  };

  const infoTemplate = (entry: any, options: ColumnBodyOptions) => {
    if (options && entry && data) {
      const pid = entry.id;
      const val = data.get(pid);
      if (val) {
        return (
          <div className="flex">
            {val.notAllPrices ? (
              <div className="mr-2">
                {' '}
                <PriceInputIncompleteSVG className="priceInputIncomplete" />
                <Tooltip target=".priceInputIncomplete" position="left">
                  unvollständige Preiseingabe
                </Tooltip>
              </div>
            ) : null}
            {val.notAllInMarket ? (
              <div>
                {' '}
                <MarktgebietIncompleteSVG className="marktgebietIncomplete" />
                <Tooltip target=".marktgebietIncomplete" position="left">
                  Nicht im Marktgebiet
                </Tooltip>
              </div>
            ) : null}
          </div>
        );
      } else {
        return;
      }
    }
  };

  const injectTable = () => {
    return (
      <DataTable
        value={provider}
        className="alpha-vergabe-provider-datatable"
        emptyMessage={'Keine Dienstleister vorhanden'}
      >
        <Column
          className="alpha-vergabe-provider-dt-dl-col"
          body={providerTemplate}
          header={'Dienstleister'}
        />
        <Column
          className="alpha-vergabe-provider-dt-bundle-col"
          body={paketeTemplate}
          header={'Vergebene Leistungspakete'}
        />
        <Column
          className="alpha-vergabe-provider-dt-sum-col"
          body={vergabeTemplate}
          header={'Vergabesumme'}
        />
        <Column
          className="alpha-vergabe-provider-dt-info-col"
          body={infoTemplate}
          header={''}
        />
      </DataTable>
    );
  };

  return (
    <div className="alpha-vergabe-provider-table">
      <BeeContentHeadline
        label={'Pakete für Dienstleister'}
        headline={'h2'}
        type={'secondary'}
      />
      <div className="mb-3">
        Die nachfolgende Tabelle zeigt eine Übersicht über die in obiger
        Übersicht vergebenen Pakete pro Dienstleister sowie die an Ihn vergebene
        Gesamtsumme an Leistungen.
      </div>
      {injectTable()}
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
