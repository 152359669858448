import BeeIcon from '../Atoms/BeeIcon';
import './NavBar.scss';

type NavBarProps = {
  navPoints: navPointType[];
  expanded: boolean;
  onClickLabel: (navPoint: navPointType) => void;
  onClickExpand: () => void;
};

export type navPointType = { label: string; iconName: string };

export default function NavBar({
  navPoints,
  expanded,
  onClickExpand,
  onClickLabel
}: NavBarProps) {
  function renderNavPoints() {
    return navPoints.map((navPoint) => (
      <li
        key={navPoint.label}
        className={'navLink'}
        onClick={() => {
          onClickLabel(navPoint);
        }}
      >
        <BeeIcon
          iconClass={navPoint.iconName}
          type={'secondary'}
          size={'medium'}
        />
        {expanded && <span className={'navLink-text'}>{navPoint.label}</span>}
      </li>
    ));
  }

  return (
    <nav className={expanded ? 'sideNav expanded' : 'sideNav'}>
      <ul className={'sideNavUL'}>
        <li
          key={'navBar-expander'}
          className={'expander'}
          onClick={() => {
            onClickExpand();
          }}
        >
          <BeeIcon
            iconClass={expanded ? 'icon-double-left' : 'icon-right'}
            type={'secondary'}
            size={'medium'}
          />
        </li>
        {renderNavPoints()}
      </ul>
    </nav>
  );
}
