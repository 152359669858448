import { MinimizedEpCatalog, MinimizedLvCatalog } from './LvEpNetworkHelper';
import _ from 'lodash';
import { ErrorResponseType } from './LiegenschaftenNetworkHelper';
import { URL_TO_KEYVAL_SERVER } from '../Statics/Constants';
import { authAxios } from './AuthServiceHelper';

export type Phase = {
  id: string;
  title: string;
  start?: string;
  end?: string;
  liegenschaftIds?: string[];
  userIds?: string[];
  publicMediaIds?: string[];
  //connected lvs
  lvs?: MinimizedLvCatalog[];
  //connected ep
  ep?: MinimizedEpCatalog;
};

type KeyValEntryArray = {
  data: KeyValEntry[];
};

export type KeyValEntry = {
  id: string;
  organisationId: string;
  createdAt?: any;
  key: string;
  value?: any;
  phaseId: string;
};

export type MiniKeyValEntry = {
  organisationId: string;
  key: string;
  value?: any;
};

//read all phases
export const readAllPhases = () => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'get',
      url: URL_TO_KEYVAL_SERVER + '/v1/phase'
    })
      .then((result: any) => {
        console.log('Received:');
        console.log(result.data);
        let answer: Phase[] = result.data.data;
        //sort answer by startDate
        resolve(_.sortBy(answer, ['start', 'end']));
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//create new phase
export const createNewPhase = (phase: Phase) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'put',
      url: URL_TO_KEYVAL_SERVER + '/v1/phase',
      data: phase
    })
      .then((result: any) => {
        console.log('Received:');
        console.log(result.data);
        let answer: Phase = result.data;
        //sort answer by startDate
        resolve(answer);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//update  phase
export const updatePhase = (phaseId: string, phase: Phase) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'put',
      url: URL_TO_KEYVAL_SERVER + '/v1/phase/' + phaseId,
      data: phase
    })
      .then((result: any) => {
        console.log('Received:');
        console.log(result.data);
        let answer: Phase = result.data;
        resolve(answer);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//delete  phase
export const deletePhaseById = (phaseId: string) => {
  console.log('deletephase');
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'delete',
      url: URL_TO_KEYVAL_SERVER + '/v1/phase/' + phaseId
    })
      .then((result: any) => {
        console.log('Received:');
        console.log('phase should be deleted');
        resolve(result);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//read all stored keyValueEntries for Orga & Phase
export const readAllStoredKeyValuesForOrgaAndPhase = (
  orgaId: string,
  phaseId: string
) => {
  console.log('CALL: readAllStoredKeyValuesForOrgaAndPhase');
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'get',
      url:
        URL_TO_KEYVAL_SERVER +
        '/v1/orga/' +
        orgaId +
        '/phase/' +
        phaseId +
        '/keyVal'
    })
      .then((result: any) => {
        console.log('Received:');
        console.log(result.data);
        let answer: KeyValEntry[] = result.data.data;
        resolve(answer);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//create keyValueEntry for Orga & Phase
export const createNewKeyValueForOrgaAndPhase = (
  orgaId: string,
  phaseId: string,
  key: string,
  value?: string | null
) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'put',
      url:
        URL_TO_KEYVAL_SERVER +
        '/v1/orga/' +
        orgaId +
        '/phase/' +
        phaseId +
        '/keyVal',
      data: {
        key: key,
        value: value
      }
    })
      .then((result: { data: KeyValEntry }) => {
        console.log('Received:');
        console.log(result.data);
        let answer: KeyValEntry = result.data;
        resolve(answer);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//create keyValueEntry for Orga & Phase FOR ALPHA ONLY WITHOUT PHASE TEST
export const createNewKeyValueForOrgaAndPhaseForAlpha = (
  orgaId: string,
  phaseId: string,
  key: string,
  value?: string | null
) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'put',
      url:
        URL_TO_KEYVAL_SERVER +
        '/v1/alpha/orga/' +
        orgaId +
        '/phase/' +
        phaseId +
        '/keyVal',
      data: {
        key: key,
        value: value
      }
    })
      .then((result: { data: KeyValEntry }) => {
        console.log('Received:');
        console.log(result.data);
        let answer: KeyValEntry = result.data;
        resolve(answer);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//update stored keyValueEntries for Orga & Phase
export const updateKeyValueForOrgaAndPhase = (
  orgaId: string,
  phaseId: string,
  keyValId: string,
  key: string,
  value?: string | null
) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'put',
      url:
        URL_TO_KEYVAL_SERVER +
        '/v1/orga/' +
        orgaId +
        '/phase/' +
        phaseId +
        '/keyVal/' +
        keyValId,
      data: {
        key: key,
        value: value
      }
    })
      .then((result: any) => {
        console.log('Received:');
        console.log(result.data);
        let answer: KeyValEntry = result.data;
        resolve(answer);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//update stored keyValueEntries for Orga & Phase FOR ALPHA ONLY WITHOUT PHASE TEST
export const updateKeyValueForOrgaAndPhaseForAlpha = (
  orgaId: string,
  phaseId: string,
  keyValId: string,
  key: string,
  value?: string | null
) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'put',
      url:
        URL_TO_KEYVAL_SERVER +
        '/v1/alpha/orga/' +
        orgaId +
        '/phase/' +
        phaseId +
        '/keyVal/' +
        keyValId,
      data: {
        key: key,
        value: value
      }
    })
      .then((result: any) => {
        console.log('Received:');
        console.log(result.data);
        let answer: KeyValEntry = result.data;
        resolve(answer);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//update stored keyValueEntries for Orga & Phase
export const updateOrCreateBatchKeyValueForOrgaAndPhase = (
  orgaId: string,
  phaseId: string,
  data: { data: { id: string; key: string; value: string }[] }
) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'put',
      url:
        URL_TO_KEYVAL_SERVER +
        '/v1/orga/' +
        orgaId +
        '/phase/' +
        phaseId +
        '/batch/keyVal/',
      data: data
    })
      .then((result: any) => {
        console.log('Received:');
        console.log(result.data);
        let answer: KeyValEntry[] = result.data.data;
        resolve(answer);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//delete keyValueEntry for Orga & Phase
export const deleteKeyValueForOrgaAndPhase = (
  orgaId: string,
  phaseId: string,
  keyValId: string
) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'delete',
      url:
        URL_TO_KEYVAL_SERVER +
        '/v1/orga/' +
        orgaId +
        '/phase/' +
        phaseId +
        '/keyVal/' +
        keyValId
    })
      .then((result: any) => {
        console.log('Delete complete:');
        resolve('Deleted');
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//delete keyValueEntry for Orga & Phase FOR ALPHA ONLY WITHOUT PHASE TEST
export const deleteKeyValueForOrgaAndPhaseForAlpha = (
  orgaId: string,
  phaseId: string,
  keyValId: string
) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'delete',
      url:
        URL_TO_KEYVAL_SERVER +
        '/v1/alpha/orga/' +
        orgaId +
        '/phase/' +
        phaseId +
        '/keyVal/' +
        keyValId
    })
      .then((result: any) => {
        console.log('Delete complete:');
        resolve('Deleted');
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//read all public keyValues
export const readAllPublicKeyValues = () => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'get',
      url: URL_TO_KEYVAL_SERVER + '/v1/keyVal'
    })
      .then((result: any) => {
        console.log('Received:');
        console.log(result.data);
        let answer: KeyValEntry[] = result.data.data;
        resolve(answer);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//create new public KeyValue
export const createNewPublicKeyValue = (key: string, value: string) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'put',
      url: URL_TO_KEYVAL_SERVER + '/v1/keyVal',
      data: { key: key, value: value }
    })
      .then((result: any) => {
        console.log('Received:');
        console.log(result.data);
        let answer: KeyValEntry = result.data;
        resolve(answer);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//get public KeyValue by Id
export const readPublicKeyValueById = (keyValId: string) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'get',
      url: URL_TO_KEYVAL_SERVER + '/v1/keyVal/' + keyValId
    })
      .then((result: any) => {
        console.log('Received:');
        console.log(result.data);
        let answer: KeyValEntry = result.data;
        resolve(answer);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//update public KeyValue by Id
export const updatePublicKeyValueEntryById = (
  keyValId: string,
  key: string,
  value: string
) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'put',
      url: URL_TO_KEYVAL_SERVER + '/v1/keyVal/' + keyValId,
      data: { key: key, value: value }
    })
      .then((result: any) => {
        console.log('Received:');
        console.log(result.data);
        let answer: KeyValEntry = result.data;
        resolve(answer);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//delete public KeyValue by Id
export const deletePublicKeyValueEntryById = (keyValId: string) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'delete',
      url: URL_TO_KEYVAL_SERVER + '/v1/keyVal/' + keyValId
    })
      .then((result: any) => {
        console.log('Delete complete:');
        resolve('Deleted');
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

///////////////
// ALPHA API //
///////////////

//get all keys for all organisation for alpha-token
export const readAllKeyValuesForPhaseByAlpha = (phaseId: string) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'get',
      url: URL_TO_KEYVAL_SERVER + '/v1/alpha/phase/' + phaseId
    })
      .then((result: any) => {
        console.log('Received:');
        console.log(result.data);
        let tmp: KeyValEntryArray[] = result.data.data;
        let answer: MiniKeyValEntry[] = [];
        tmp.forEach((kva) => {
          if (kva.data) {
            kva.data.forEach((e) => {
              answer.push({
                organisationId: e.organisationId,
                key: e.key,
                value: e.value
              });
            });
          }
        });
        resolve(answer);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//get all keys for all organisation for alpha-token
export const readAllKeyValuesForPhaseAndOrgaByAlpha = (
  phaseId: string,
  organisationId: string
) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'get',
      url:
        URL_TO_KEYVAL_SERVER +
        '/v1/alpha/phase/' +
        phaseId +
        '/orga/' +
        organisationId
    })
      .then((result: any) => {
        console.log('Received:');
        console.log(result.data);
        const tmp: KeyValEntry[] = result.data.data;
        resolve(tmp);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};
