import 'primeicons/primeicons.css';
import React from 'react';
import { useId } from 'react-id-generator';
import './BeeIcon.scss';

type BeeIconProps = {
  id?: string;
  iconClass: string;
  type?: string;
  size?: string;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export function BeeIcon({ id, iconClass, type, size, onClick }: BeeIconProps) {
  const genId = useId(1, 'bee-icon-')[0];
  const currentId = id ? id : genId;

  //classNames
  const iconClassnames =
    type === 'primary'
      ? ' bee-icon-primary'
      : type === 'secondary'
      ? ' bee-icon-secondary'
      : type === 'light'
      ? ' bee-icon-light'
      : ' bee-icon-primary';

  const sizeClassname =
    size === 'small'
      ? ' bee-icon-small'
      : size === 'large'
      ? ' bee-icon-large'
      : size === 'xxLarge'
      ? ' bee-icon-xxLarge'
      : ' bee-icon-large';

  const className = iconClass + ' bee-icon' + iconClassnames + sizeClassname;

  function clicked(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    if (onClick) {
      onClick(e);
    }
  }

  return <i id={currentId} className={className} onClick={(e) => clicked(e)} />;
}

export default BeeIcon;
