import _ from 'lodash';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import TreeNode from 'primereact/treenode';
import { TreeTable, TreeTableExpandedKeysType } from 'primereact/treetable';
import { useEffect, useRef, useState } from 'react';
import flatten from 'tree-flatten';
import { arrayToString } from '../../../Helper/ApiHelper/ErrorMessages';
import {
  createNewKeyValueForOrgaAndPhase,
  KeyValEntry,
  updateKeyValueForOrgaAndPhase,
  updateOrCreateBatchKeyValueForOrgaAndPhase
} from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import { readAllLiegenschaften } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import {
  EP,
  EpPosition,
  generateEpTreeFromPositions,
  removeAllHiddenCategoriesFromEpTree
} from '../../../Helper/ApiHelper/LvEpNetworkHelper';
import { uploadDocumentToServer } from '../../../Helper/ApiHelper/MediaNetworkHelper';
import {
  DEFAULT_CALCULATION_MULTIPLIER,
  DL_EP_INPUT_DISABLE_FILTER_ID,
  DURATION_NOTIFICATION_ERROR_LONG,
  LEISTUNGSART,
  LeistungsSlices,
  MAX_NUMBER_OF_DECIMALS,
  MIN_NUMBER_OF_DECIMALS,
  SERVER_RESPONSE_ERROR,
  SERVER_RESPONSE_PENDING,
  SERVER_RESPONSE_SUCCESS
} from '../../../Helper/Statics/Constants';
import { exportDlEpExcel } from '../../../Helper/StorageHelper/ExcelExportDlEpHelper';
import { importDlEpExcel } from '../../../Helper/StorageHelper/ExcelImportDlEpHelper';
import { generateEpEntryId } from '../../../Helper/Util/IdGeneratorHelper';
import { extractUserId } from '../../../Helper/Util/JwtHelper';
import BeeChip from '../../Atoms/BeeChip';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeCurrencyInput from '../../Atoms/BeeCurrencyInput';
import BeeDropDown from '../../Atoms/BeeDropDown';
import BeeIcon from '../../Atoms/BeeIcon';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import BeeMultiSelect from '../../Atoms/BeeMultiSelect';
import BeeOutlinedButton from '../../Atoms/BeeOutlinedButton';
import { TimelineStep } from '../../Atoms/BeeTimeline';
import BeeValidatedCurrencyInput from '../../Atoms/BeeValidatedCurrencyInput';
import { FileUploadType } from '../../Pages/DLPages/DLPriceInputPage';
import BeeUploadDialog from '../Dialogs/BeeUploadDialog';
import './DLPriceInput.scss';
import {
  PATH_DETAIL_LIEGENSCHAFT,
  PATH_DL_OVERVIEW
} from '../../../Helper/Statics/Routes';
import { Link } from 'react-router-dom';
import BeeLinkButton from '../../Atoms/BeeLinkButton';

type DLPriceInputProps = {
  readOnly: boolean;
  phase: TimelineStep;
  keyValues: KeyValEntry[];
  epCatalog: EP;
};

export type BLKeyValueStore = {
  id: string;
  value: number | null;
  process: string | null;
};

type FilterOptionProps = { label: string; value: string };

function DLPriceInput({
  readOnly,
  phase,
  keyValues,
  epCatalog
}: DLPriceInputProps) {
  const [catalog, setCatalog] = useState<any>([]);
  const [filteredNodes, setFilteredNodes] = useState([]);
  const [filter, setFilter] = useState<LEISTUNGSART>();
  const [filterLS, setFilterLS] = useState<any>([]);
  const [allFilter, setAllFilter] = useState<LEISTUNGSART[]>();
  const [allFilterLS, setAllFilterLS] = useState<FilterOptionProps[]>();
  const [lsLoadingError, setLsLoadingError] = useState<boolean>(false);
  const [hideEmptyPositions, setHideEmptyPositions] = useState(false);
  const [expanded, setExpanded] = useState<TreeTableExpandedKeysType>();
  const [keyValueStore, setKeyValueStore] = useState<{
    [key: string]: BLKeyValueStore;
  }>({});
  //dialog
  const [v_UploadDlEp, setV_UploadDlEp] = useState<boolean>(false);
  const [epUploadProg, setEpUploadProg] = useState<boolean>(false);
  const [epUploadError, setEpUploadError] = useState<string>('');
  const [epUploadErrorDescr, setEpUploadErrorDescr] = useState<string>('');
  const toast = useRef<Toast>(null);

  //extract userId
  let userId: string = 'no_user';
  const extracted = extractUserId();
  userId = extracted ? extracted : userId;

  useEffect(() => {
    if (phase && keyValues && epCatalog) {
      let currCatalog: any = generateEpTreeFromPositions(epCatalog.data);
      currCatalog = currCatalog ? currCatalog : [];
      currCatalog = removeAllHiddenCategoriesFromEpTree(currCatalog, false);
      //setup filter
      const filterSlices: LEISTUNGSART[] = LeistungsSlices;
      let filter: LEISTUNGSART[] = [
        {
          id: DL_EP_INPUT_DISABLE_FILTER_ID,
          icon: '',
          title: 'Keine Filterung',
          tag: [],
          sustainability: false
        }
      ];
      for (let i = 0; i < filterSlices.length; i++) {
        filter.push(filterSlices[i]);
      }
      //setup liegenschaften
      const dataFilterLs: FilterOptionProps[] = [];
      dataFilterLs.push({
        label: 'Leistungen zukünftiger Ausschreibungen',
        value: DL_EP_INPUT_DISABLE_FILTER_ID
      });
      if (phase.liegenschaftIds && phase.liegenschaftIds.length > 0) {
        const propertyIds: string[] = phase.liegenschaftIds;
        readAllLiegenschaften()
          .then((props: any) => {
            let properties = _.filter(props, (entry) =>
              _.includes(propertyIds, entry.id)
            );
            setLsLoadingError(false);
            for (let i = 0; i < properties.length; i++) {
              dataFilterLs.push({
                label: properties[i].nummer + ' - ' + properties[i].name,
                value: properties[i].id
              });
            }
          })
          .catch((error) => {
            setLsLoadingError(true);
            toast.current?.show({
              severity: 'info',
              summary: 'Fehler beim Laden der Liegenschaften',
              detail:
                'Leider konnten die Liegenschaften nicht vollständig geladen werden. Die Filterung der Anzeige nach Liegenschaften ist dadurch eingeschränkt.' +
                error,
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          });
      } else {
        //there are no connected properties in phase
        setLsLoadingError(true);
      }
      //setup keyVal entries
      const values: any = {};
      if (epCatalog.data) {
        epCatalog.data.forEach((entry) => {
          //only if entry has epCode => add to value lookup
          if (entry.epCode) {
            const key: string = generateEpEntryId(phase, entry.epCode);
            const valObj = _.find(keyValues, function (o: any) {
              return o.key === key;
            });
            if (valObj) {
              //data for keyval entry are available
              values[key] = {};
              values[key].id = valObj.id;
              values[key].value = valObj.value
                ? parseFloat(valObj.value)
                : null;
              values[key].process = null;
            } else {
              //no entry on server -> initialize with null
              values[key] = {};
              values[key].id = null;
              values[key].value = null;
              values[key].process = null;
            }
          }
        });
      }
      setAllFilterLS(dataFilterLs);
      setAllFilter(filter);
      setCatalog(currCatalog);
      filterContent(currCatalog, null, false, filter[0]);
      setKeyValueStore(values);
      expandAtLevel(2, currCatalog);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phase, keyValues, epCatalog]);

  useEffect(() => {
    const epPosRows: any = document.getElementsByClassName(
      'priceInput-row-position-noTabIndex'
    );
    for (let i = 0; i < epPosRows.length; i++) {
      epPosRows[i].tabIndex = -1;
    }
  }, [expanded]);

  ////////////////////
  // BUSINESS LOGIC //
  ////////////////////

  function filterArray(array: any, tags: any) {
    const getChildren = (result: any, object: any) => {
      //if tags array contains any tag from filter
      const entryTags = object.data && object.data.tags ? object.data.tags : [];
      if (_.intersection(entryTags, tags).length > 0) {
        result.push(object);
        return result;
      }
      if (Array.isArray(object.children)) {
        const children = object.children.reduce(getChildren, []);
        if (children.length) result.push({ ...object, children });
      }
      return result;
    };
    return array.reduce(getChildren, []);
  }

  function filterContent(
    catalog: any,
    filterLs: any,
    hideEmptyPositions: boolean,
    filter?: LEISTUNGSART
  ) {
    if (catalog) {
      const filterTags = filter && filter.tag ? filter.tag : [];
      let filteredPayload =
        filter && filter.id === DL_EP_INPUT_DISABLE_FILTER_ID
          ? catalog
          : filterArray(catalog, filterTags);
      if (hideEmptyPositions && filterLs && filterLs.length > 0) {
        filteredPayload = filterArray(filteredPayload, filterLs);
      }
      setFilter(filter);
      setFilterLS(filterLs);
      setHideEmptyPositions(hideEmptyPositions);
      setFilteredNodes(filteredPayload);
    }
  }

  const expandAtLevel = (depth: number | null, catalog: any) => {
    let keys: any = {};
    if (depth) {
      let tmp: any = [];
      if (catalog && catalog.length > 0) {
        catalog.forEach((e: any) => tmp.push(...flatten(e, 'children')));
        //search for depth
        tmp.forEach((x: any) => {
          if (_.startsWith(x.className, 'c-')) {
            const iDepth: any = _.replace(x.className, 'c-', '');
            if (iDepth <= depth) {
              keys[x.key] = true;
            }
          }
        });
      }
    }
    setExpanded(keys);
  };

  ////////////////
  //// HELPER ////
  ////////////////

  function changeCellInput(val: number | null, valueKey: string) {
    let currValue = val || val === 0 ? val : null;
    if (
      keyValueStore &&
      keyValueStore[valueKey] &&
      (keyValueStore[valueKey].value !== currValue ||
        keyValueStore[valueKey].process === SERVER_RESPONSE_ERROR)
    ) {
      const dbId = keyValueStore[valueKey].id;
      setKeyValueStore(
        (previousInputs: { [key: string]: BLKeyValueStore }) => ({
          ...previousInputs,
          [valueKey]: {
            id: dbId,
            value: currValue,
            process: SERVER_RESPONSE_PENDING
          }
        })
      );
      if (dbId) {
        updateKeyValueForOrgaAndPhase(
          userId,
          phase.id,
          dbId,
          valueKey,
          '' + currValue
        )
          .then((responseValue) => {
            updateKeyValStore(
              dbId,
              valueKey,
              currValue,
              SERVER_RESPONSE_SUCCESS
            );
          })
          .catch(() =>
            updateKeyValStore(dbId, valueKey, currValue, SERVER_RESPONSE_ERROR)
          );
      } else {
        createNewKeyValueForOrgaAndPhase(
          userId,
          phase.id,
          valueKey,
          '' + currValue
        )
          .then((responseValue: any) => {
            const responseObject: KeyValEntry = responseValue;
            updateKeyValStore(
              responseObject.id,
              valueKey,
              currValue,
              SERVER_RESPONSE_SUCCESS
            );
          })
          .catch(() =>
            //dbId is initial value // null
            updateKeyValStore(dbId, valueKey, currValue, SERVER_RESPONSE_ERROR)
          );
      }
    }
  }

  function updateKeyValStore(
    id: string,
    valueKey: string,
    val: number | null,
    progress: string
  ) {
    setKeyValueStore((previousInputs: any) => ({
      ...previousInputs,
      [valueKey]: { id: id, value: val, process: progress }
    }));
  }

  function importEpPricesExcel(data: FileUploadType) {
    setEpUploadProg(true);
    const file = data && data.file ? data.file : null;
    importDlEpExcel(data)
      .then((keyVals: any) => {
        console.log(keyVals);
        let errorEpNotExistent: string[] = [];
        let errorEpNotExistentRows: number[] = [];
        let errorNoImportedEpRows: number[] = [];
        let validEntries = [];
        const orgaId = extractUserId() ? extractUserId() : '';
        const phaseId = phase.id;
        //validation
        for (let i = 0; i < keyVals.length; i++) {
          if (keyVals[i].epCode) {
            const valueId = generateEpEntryId(phase, keyVals[i].epCode);
            if (keyValueStore[valueId]) {
              // existing keyval entry
              const entry = {
                id: keyValueStore[valueId].id,
                key: valueId,
                value:
                  keyVals[i].price || keyVals[i].price === 0
                    ? '' + keyVals[i].price * 1000
                    : ''
              };
              validEntries.push(entry);
            } else {
              // ebkph code does not exist in current tree
              errorEpNotExistent.push(keyVals[i].epCode);
              errorEpNotExistentRows.push(keyVals[i].row);
            }
          } else if (
            !keyVals[i].epCode &&
            (keyVals[i].price || keyVals[i].price === 0) &&
            keyVals[i].row
          ) {
            //no ep code imported for specific given price
            errorNoImportedEpRows.push(keyVals[i].row);
          }
        }
        if (validEntries && validEntries.length > 0) {
          const dataToSave = { data: validEntries };
          updateOrCreateBatchKeyValueForOrgaAndPhase(
            orgaId ? orgaId : '',
            phaseId,
            dataToSave
          )
            .then((data: any) => {
              const newValues: KeyValEntry[] = _.cloneDeep(data);
              //update keyValStore
              let keyValStore = _.cloneDeep(keyValueStore);
              for (let i = 0; i < newValues.length; i++) {
                if (keyValStore[newValues[i].key]) {
                  keyValStore[newValues[i].key].id = newValues[i].id;
                  keyValStore[newValues[i].key].value = newValues[i].value
                    ? parseFloat(newValues[i].value)
                    : null;
                  keyValStore[newValues[i].key].process = null;
                } else {
                  //fixme epcode not found, was checked before though
                }
              }
              if (
                errorEpNotExistent &&
                errorEpNotExistent.length > 0 &&
                errorNoImportedEpRows &&
                errorNoImportedEpRows.length > 0
              ) {
                //both errors
                if (toast.current) {
                  toast.current.show([
                    {
                      severity: 'warn',
                      summary: 'Nicht alle Preise konnten importiert werden',
                      detail:
                        'Die Leistungen mit den Ep-Codes: ' +
                        arrayToString(errorEpNotExistent) +
                        ' existieren in dem aktuellen Leistungsverzeichnis nicht. Die Preise in den Zeilen: ' +
                        arrayToString(errorEpNotExistentRows) +
                        ' konnten deshalb nicht hochgeladen werden.',
                      sticky: true,
                      closable: true,
                      life: DURATION_NOTIFICATION_ERROR_LONG
                    },
                    {
                      severity: 'warn',
                      summary: 'Nicht alle Preise konnten importiert werden',
                      detail:
                        'Die hochgeladene Excel Tabelle enthält Preise ohne Ep-Code. Für den Import ist eine eindeutige Zuordnung von Preis zu EP-Code notwendig. Die Preise in den Zeilen: ' +
                        arrayToString(errorNoImportedEpRows) +
                        ' wurden deshalb nicht importiert.',
                      sticky: true,
                      closable: true,
                      life: DURATION_NOTIFICATION_ERROR_LONG
                    }
                  ]);
                }
              } else if (errorEpNotExistent && errorEpNotExistent.length > 0) {
                //only error ep not existent
                if (toast.current) {
                  toast.current.show({
                    severity: 'warn',
                    summary: 'Nicht alle Preise konnten importiert werden',
                    detail:
                      'Die Leistungen mit den Ep-Codes: ' +
                      arrayToString(errorEpNotExistent) +
                      ' existieren in dem aktuellen Leistungsverzeichnis nicht. Die Preise in den Zeilen: ' +
                      arrayToString(errorEpNotExistentRows) +
                      ' konnten deshalb nicht hochgeladen werden.',
                    sticky: true,
                    closable: true,
                    life: DURATION_NOTIFICATION_ERROR_LONG
                  });
                }
              } else if (
                errorNoImportedEpRows &&
                errorNoImportedEpRows.length > 0
              ) {
                //only error not importet eprows
                if (toast.current) {
                  toast.current.show({
                    severity: 'warn',
                    summary: 'Nicht alle Preise konnten importiert werden',
                    detail:
                      'Die hochgeladene Excel Tabelle enthält Preise ohne Ep-Code. Für den Import ist eine eindeutige Zuordnung von Preis zu EP-Code notwendig. Die Preise in den Zeilen: ' +
                      arrayToString(errorNoImportedEpRows) +
                      ' wurden deshalb nicht importiert.',
                    sticky: true,
                    closable: true,
                    life: DURATION_NOTIFICATION_ERROR_LONG
                  });
                }
              }
              //after validateion safe file as document on mediaserver
              //fire and forget
              if (file) {
                const mediaTitle =
                  'DL_EP_UPLOAD_' +
                  extractUserId() +
                  '_' +
                  new Date().toJSON().replaceAll(':', '_').replaceAll('.', '-');
                uploadDocumentToServer(file, false, mediaTitle).then(
                  (result) => {
                    console.log('file saved on server');
                    console.log(result);
                  }
                );
              }
              setKeyValueStore(keyValStore);
              setV_UploadDlEp(false);
              setEpUploadError('');
              setEpUploadErrorDescr('');
              setEpUploadProg(false);
            })
            .catch((error) => {
              //fails bei keiner eineindeutigkeit von ids?keys?
              setEpUploadError(
                'Speichern der importierten Preise auf dem Server fehlgeschlagen'
              );
              console.log(error);
              setEpUploadErrorDescr(
                'Das Speichern der Preise auf dem Server ist fehlhgeschlagen. Bitte überprüfen Sie Ihre Internetverbindung. Sollte das Problem weiterhin bestehen bleiben wenden Sie sich bitte an den Kundenservice.'
              );
              setEpUploadProg(false);
            });
        } else {
          //no valid entries to save
          if (
            errorEpNotExistent &&
            errorEpNotExistent.length > 0 &&
            errorNoImportedEpRows &&
            errorNoImportedEpRows.length > 0
          ) {
            //both errors
            if (toast.current) {
              toast.current.show([
                {
                  severity: 'warn',
                  summary: 'Nicht alle Preise konnten importiert werden',
                  detail:
                    'Die Leistungen mit den Ep-Codes: ' +
                    arrayToString(errorEpNotExistent) +
                    ' existieren in dem aktuellen Leistungsverzeichnis nicht. Die Preise in den Zeilen: ' +
                    arrayToString(errorEpNotExistentRows) +
                    ' konnten deshalb nicht hochgeladen werden.',
                  sticky: true,
                  closable: true,
                  life: DURATION_NOTIFICATION_ERROR_LONG
                },
                {
                  severity: 'warn',
                  summary: 'Nicht alle Preise konnten importiert werden',
                  detail:
                    'Die hochgeladene Excel Tabelle enthält Preise ohne Ep-Code. Für den Import ist eine eindeutige Zuordnung von Preis zu EP-Code notwendig. Die Preise in den Zeilen: ' +
                    arrayToString(errorNoImportedEpRows) +
                    ' wurden deshalb nicht importiert.',
                  sticky: true,
                  closable: true,
                  life: DURATION_NOTIFICATION_ERROR_LONG
                }
              ]);
            }
          } else if (errorEpNotExistent && errorEpNotExistent.length > 0) {
            //only error ep not existent
            if (toast.current) {
              toast.current.show({
                severity: 'warn',
                summary: 'Nicht alle Preise konnten importiert werden',
                detail:
                  'Die Leistungen mit den Ep-Codes: ' +
                  arrayToString(errorEpNotExistent) +
                  ' existieren in dem aktuellen Leistungsverzeichnis nicht. Die Preise in den Zeilen: ' +
                  arrayToString(errorEpNotExistentRows) +
                  ' konnten deshalb nicht hochgeladen werden.',
                sticky: true,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            }
          } else if (
            errorNoImportedEpRows &&
            errorNoImportedEpRows.length > 0
          ) {
            //only error not importet eprows
            if (toast.current) {
              toast.current.show({
                severity: 'warn',
                summary: 'Nicht alle Preise konnten importiert werden',
                detail:
                  'Die hochgeladene Excel Tabelle enthält Preise ohne Ep-Code. Für den Import ist eine eindeutige Zuordnung von Preis zu EP-Code notwendig. Die Preise in den Zeilen: ' +
                  arrayToString(errorNoImportedEpRows) +
                  ' wurden deshalb nicht importiert.',
                sticky: true,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            }
          }
          setV_UploadDlEp(false);
          setEpUploadError('');
          setEpUploadErrorDescr('');
          setEpUploadProg(false);
        }
      })
      .catch((error) => {
        //fails bei keiner eineindeutigkeit von ids?keys?
        setEpUploadError('Importieren der Preise fehlgeschlagen');
        console.log(error);
        setEpUploadErrorDescr(error);
        setEpUploadProg(false);
      });
  }

  ///////////////
  // UI METHODS//
  ///////////////

  function renderNumberInput(rowData: any) {
    const valueId = generateEpEntryId(phase, rowData.epCode);
    const valueObject =
      keyValueStore && keyValueStore[valueId] ? keyValueStore[valueId] : null;
    if (rowData.type === 'position') {
      return valueObject ? (
        <BeeCurrencyInput
          value={
            valueObject.value
              ? _.divide(valueObject.value, DEFAULT_CALCULATION_MULTIPLIER)
              : valueObject.value === 0
              ? 0
              : null
          }
          disabled={false}
          minFractionDigits={MAX_NUMBER_OF_DECIMALS}
          maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
          formstate={'neutral'}
          readOnly={false}
          required={false}
          onChange={(e) => {
            e.target.value !== null
              ? changeCellInput(
                  _.round(
                    _.multiply(e.target.value, DEFAULT_CALCULATION_MULTIPLIER)
                  ),
                  valueId
                )
              : changeCellInput(null, valueId);
          }}
        />
      ) : (
        injectLoadingSpinner()
      );
    }
    return null;
  }

  function injectLoadingSpinner() {
    return (
      <span className={'load-factor pending'}>
        <BeeLoadingSpinner strokeWidth={'3'} type={'primary'} />
      </span>
    );
  }

  function injectHeadline() {
    return (
      <BeeContentHeadline
        label={'Preiseingabe'}
        headline={'h2'}
        type={'primary'}
      />
    );
  }

  function injectDescription() {
    return (
      <div>
        <div>
          <span>
            Die kalkulierten EPs bilden die Basis der Rahmenpreisvereinbarung.
            Bitte achten Sie auf ein mittleres Preissegment für die Eingabe.
          </span>{' '}
          <span>
            Zu- und Abschläge für Tarifgebiete können über den Bundeslandfaktor,
            Synergien oder Erschwernisse für einzelne Liegenschaften auf Ebene
            des Liegenschaftsfaktors abgebildet werden. Der Liegenschaftsfaktor
            ist dafür auf der Übersicht über eine Liegenschaft anzugeben.
          </span>
        </div>
      </div>
    );
  }

  function injectLiegenschaftenSelect() {
    return (
      <BeeMultiSelect
        label={
          <span>
            <i className={'icon-filter'} />
            <span>{'Liegenschaften'}</span>
          </span>
        }
        maxSelectedLabels={3}
        selectedItemsLabel={'{} Items ausgewählt'}
        selectionLimit={5}
        value={filterLS}
        options={allFilterLS}
        optionLabel={'label'}
        disabled={lsLoadingError}
        formstate={'neutral'}
        readOnly={lsLoadingError}
        required={false}
        onChange={(item) => filterContent(catalog, item, true, filter)}
      />
    );
  }

  function injectFilter() {
    return (
      <BeeDropDown
        label={
          <span>
            <i className={'icon-filter'} />
            <span>{'Positionen'}</span>
          </span>
        }
        value={filter}
        options={allFilter}
        optionLabel={'title'}
        disabled={false}
        formstate={'neutral'}
        readOnly={false}
        required={false}
        onChange={(item: any) =>
          filterContent(catalog, filterLS, hideEmptyPositions, item)
        }
      />
    );
  }

  function injectCompletion() {
    let max = 0;
    let current = 0;
    if (keyValueStore) {
      Object.keys(keyValueStore).forEach(function (key) {
        max++;
        const entry: BLKeyValueStore = keyValueStore[key];
        if (
          entry.id &&
          (entry.value || entry.value === 0) &&
          entry.process !== SERVER_RESPONSE_ERROR
        ) {
          current++;
        }
      });
    }
    const iconClass = current === max ? 'icon-check' : 'icon-warning';
    return (
      <div className={'tooltip-completion-chip'}>
        {' '}
        <BeeChip
          label={current + '/' + max}
          iconClass={iconClass}
          removable={false}
          type={'primary'}
        />
        <Tooltip
          target=".tooltip-completion-chip"
          position={'left'}
          mouseTrack
          mouseTrackLeft={10}
        >
          {'Aktuell wurden ' +
            current +
            ' von maximal ' +
            max +
            ' vorhandenen Positionen bepreist.'}
        </Tooltip>
      </div>
    );
  }

  const titleTemplate = (node: any) => {
    const epEntry: EpPosition = node.data;
    return (
      <div className={'priceInput-table-title'}>
        <div className={'priceInput-ep-code'}>{epEntry.epCode}</div>
        <div>{`[${epEntry.number}] ${epEntry.title}`}</div>
      </div>
    );
  };

  const iconTemplateGeneric = (node: any, column: any) => {
    let possibleIcons: any = [];
    const tmp = node.data;
    const allSlices: LEISTUNGSART[] = LeistungsSlices;
    allSlices.forEach((slice) => {
      for (const sTag in slice.tag) {
        if (_.includes(tmp.tags, sTag)) {
          possibleIcons.push({
            icon: slice.icon,
            title: slice.title
          });
          break;
        }
      }
    });
    return (
      <div key={'dl-priceinput-icon_' + node.key}>
        {possibleIcons.map((entry: any) => {
          return (
            <span title={entry.title}>
              {' '}
              <BeeIcon iconClass={entry.icon} type={'primary'} size={'small'} />
            </span>
          );
        })}
      </div>
    );
  };

  const unitTemplate = (node: any) => {
    const epEntry: EpPosition = node.data;
    return (
      <div className={'priceInput-unit'}>
        <div>{epEntry.unit}</div>
      </div>
    );
  };

  const rahmenpreisTemplate = (rowData: any) => {
    if (rowData.data && rowData.data.type === 'position') {
      const valueId = generateEpEntryId(phase, rowData.data.epCode);
      const valueObject =
        keyValueStore && keyValueStore[valueId] ? keyValueStore[valueId] : null;
      if (valueObject && (valueObject.value || valueObject.value === 0)) {
        const classNames =
          valueObject.process === SERVER_RESPONSE_ERROR
            ? 'tooltip-rahmenpreiseingabe-error rahmenpreis-viewMode '
            : 'rahmenpreis-viewMode ';
        return (
          <div
            key={'dl-priceinput-price-input_' + valueId}
            className={classNames}
          >
            {' '}
            <div className={'priceInput-table-postText'}>
              {rowData.data.postText}
            </div>
            <div>
              <BeeValidatedCurrencyInput
                value={_.divide(
                  valueObject.value,
                  DEFAULT_CALCULATION_MULTIPLIER
                )}
                disabled={true}
                minFractionDigits={MIN_NUMBER_OF_DECIMALS}
                maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
                formstate={'neutral'}
                readOnly={readOnly ? false : true}
              />
            </div>
            {valueObject.process === SERVER_RESPONSE_PENDING ? (
              <span className={'status-priceChange pending'}>
                <BeeLoadingSpinner strokeWidth={'3'} type={'primary'} />
              </span>
            ) : valueObject.process === SERVER_RESPONSE_ERROR ? (
              <span className={'status-priceChange error'}>
                <BeeIcon
                  iconClass={'pi pi-exclamation-triangle'}
                  type={'primary'}
                />
                <Tooltip
                  target=".tooltip-rahmenpreiseingabe-error"
                  position={'left'}
                  mouseTrack
                  mouseTrackLeft={10}
                >
                  {
                    'Die Änderung konnte nicht gespeichert werden. Bitte prüfen Sie Ihre Internetverbindung.'
                  }
                </Tooltip>
              </span>
            ) : (
              <span></span>
            )}
          </div>
        );
      } else {
        return (
          <>
            <div
              key={'dl-priceinput-price-input_' + valueId}
              className={'rahmenpreis-viewMode emptyValue'}
            >
              <div className={'priceInput-table-postText'}>
                {rowData.data.postText}
              </div>
              <div> Kein Preis</div>
            </div>
          </>
        );
      }
    } else {
      return null;
    }
  };

  function calculateRowClassName(rowData: TreeNode) {
    return {
      'priceInput-row-category-tabIndex': rowData.data.type === 'category',
      'priceInput-row-position-noTabIndex': rowData.data.type === 'position'
    };
  }

  function injectInputTable() {
    return (
      <TreeTable
        value={filteredNodes}
        expandedKeys={expanded}
        rowClassName={(rowData: TreeNode) => calculateRowClassName(rowData)}
        className={'ep-table'}
        emptyMessage={'Keine Position gefunden'}
        onToggle={(e) => setExpanded(e.value)}
      >
        <Column
          field={'title'}
          columnKey={'priceinput-title-col'}
          body={titleTemplate}
          header={
            <div className={'ep-table-title'}>
              <div>{'Position'}</div>
            </div>
          }
          className={'ep-position-col '}
          expander
        />
        <Column
          columnKey={'priceinput-icon-col'}
          body={iconTemplateGeneric}
          header={''}
          className={'filter-tags-col'}
        />
        <Column
          columnKey={'priceinput-unit-col'}
          body={unitTemplate}
          header={'Einheit'}
          className={'unit-col'}
        />
        <Column
          columnKey={'priceinput-input-col'}
          header={'Rahmenpreis'}
          body={rahmenpreisTemplate}
          className={'rahmenpreis-col text-right'}
          editor={(col: ColumnEditorOptions) => {
            return !readOnly &&
              !(
                keyValueStore &&
                keyValueStore[generateEpEntryId(phase, col.rowData.id)]
                  ?.process === SERVER_RESPONSE_PENDING
              )
              ? renderNumberInput(col.rowData)
              : rahmenpreisTemplate({ data: col.rowData });
          }}
        />
      </TreeTable>
    );
  }

  function injectExport() {
    return (
      <BeeOutlinedButton
        label={'Preise exportieren'}
        type={'secondary'}
        onClick={async () => {
          const notSavedKeyVals = _.filter(
            keyValueStore,
            function (keyVal: BLKeyValueStore) {
              return (
                keyVal.process === SERVER_RESPONSE_ERROR ||
                keyVal.process === SERVER_RESPONSE_PENDING
              );
            }
          );
          //unsaved changes toast
          //fixme should be triggered after export but doesnt work
          if (notSavedKeyVals && notSavedKeyVals.length > 0) {
            if (toast.current) {
              toast.current?.show({
                severity: 'warn',
                summary: 'Ungespeicherte Änderungen',
                detail:
                  'Einige Änderungen sind noch nicht auf dem Server gepeichert. Diese Werte können aktuell nicht heruntergeladen werden. Bitte exportieren Sie die Datei erneut sobald der aktuelle Stand gespeichert ist (keine Loader oder Fehler hinter den Preisen sichtbar).',
                sticky: true,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            }
          }
          try {
            console.log(catalog); //FIXME remove
            await exportDlEpExcel(catalog, keyValueStore, phase, false);
          } catch (e) {
            if (toast.current) {
              toast.current.show({
                severity: 'error',
                summary: 'Export fehlgeschlagen',
                detail:
                  'Beim Exportieren der Excel ist etwas fehlgeschlagen. Bitte versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.',
                sticky: false,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            }
          }
        }}
      />
    );
  }

  function injectExportTemplate() {
    return (
      <div>
        <span>Eine leere Importvorlage können Sie</span>
        <BeeLinkButton
          label={'hier'}
          type={'secondary'}
          onClick={() => {
            exportDlEpExcel(catalog, keyValueStore, phase, true).catch((e) => {
              if (toast.current) {
                toast.current.show({
                  severity: 'error',
                  summary: 'Export fehlgeschlagen',
                  detail:
                    'Beim Exportieren der Excel ist etwas fehlgeschlagen. Bitte versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.',
                  sticky: false,
                  closable: true,
                  life: DURATION_NOTIFICATION_ERROR_LONG
                });
              }
            });
          }}
        />{' '}
        <span> herunterladen.</span>
      </div>
    );
  }

  function injectImportData() {
    return (
      <>
        {readOnly ? null : (
          <BeeOutlinedButton
            label={'Preise importieren'}
            type={'secondary'}
            onClick={() => {
              setEpUploadError('');
              setEpUploadErrorDescr('');
              setEpUploadProg(false);
              setV_UploadDlEp(true);
            }}
          />
        )}
      </>
    );
  }
  function injectFileUploadDialog() {
    return (
      <BeeUploadDialog
        type={'primary'}
        visible={v_UploadDlEp}
        disabled={false}
        locale={'de-DE'}
        header={'Rahmenpreise hochladen'}
        info={''}
        titleLabel={'Bezeichnung'}
        titleVisible={true}
        copyrightLabel={''}
        copyrightVisible={false}
        dropzoneTitle={'Upload'}
        dropzoneDescription={
          'Bitte ziehen Sie die gewünschte Datei in diesen Bereich oder klicken Sie hinzufügen'
        }
        dropzoneAddLabel={'Klicken zum Hinzufügen'}
        dropzoneFormats={['xls', 'xlsx']}
        progressVisible={epUploadProg}
        progressMessage={'Dokument wird hochgeladen'}
        errorVisible={epUploadError ? true : false}
        errorHeadline={epUploadError}
        errorDescription={epUploadErrorDescr}
        onHide={() => setV_UploadDlEp(false)}
        onUpload={(data) => importEpPricesExcel(data)}
      />
    );
  }

  return (
    <div className={'dl-priceInput'}>
      <div>
        <div className={'priceInput-headline'}>
          <div>{injectHeadline()}</div>
        </div>
        <div className={'priceInput-descr'}>
          <div>{injectDescription()}</div>
        </div>
        <div className="grid filter-area">
          <div className="filter col-12 sm:col-12 md:col-6 lg:col-3 xl:col-3">
            {injectFilter()}
          </div>
          <div className="liegenschaften-select col-12 sm:col-12 md:col-6 lg:col-3 xl:col-3">
            {injectLiegenschaftenSelect()}
          </div>

          <div className="input-completion col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
            {injectCompletion()}
          </div>
        </div>
        <div>{injectInputTable()}</div>
        <div className="grid priceInput-table-actions">
          <div className="col-fixed">{injectImportData()}</div>
          <div className="col-fixed">{injectExport()}</div>
          <div className="col-12 template-btn">{injectExportTemplate()}</div>
        </div>
      </div>
      <>{v_UploadDlEp ? injectFileUploadDialog() : null}</>
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}

export default DLPriceInput;
