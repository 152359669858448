import _ from 'lodash';
import { Toast } from 'primereact/toast';
import { TreeSelectSelectionKeys } from 'primereact/treeselect';
import { useEffect, useRef, useState } from 'react';
import {
  KeyValEntry,
  readAllStoredKeyValuesForOrgaAndPhase
} from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import { LiegenschaftsData } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import { EpPosition, LV } from '../../../Helper/ApiHelper/LvEpNetworkHelper';
import {
  COUNTRIES,
  DROPDOWN_LIEGENSCHAFT_SORT,
  DURATION_NOTIFICATION_ERROR_LONG,
  LEISTUNGSART,
  LeistungsSlices,
  LIEGENSCHAFT_SORT_ADDRESS,
  LIEGENSCHAFT_SORT_BUILDING_NO,
  LIEGENSCHAFT_SORT_CITY,
  LIEGENSCHAFT_SORT_NAME,
  REGION
} from '../../../Helper/Statics/Constants';
import {
  generateBlFactorId,
  generateEpEntryId,
  generateLsFactorId,
  generateLsInMarktgebietId
} from '../../../Helper/Util/IdGeneratorHelper';
import { extractUserId } from '../../../Helper/Util/JwtHelper';
import {
  calculateSlices,
  generateEnlargedLV
} from '../../../Helper/Util/LvCalculator';
import BeeDropDown from '../../Atoms/BeeDropDown';
import BeeSearchBar from '../../Atoms/BeeSearchBar';
import { TimelineStep } from '../../Atoms/BeeTimeline';
import BeeTreeSelectLiegenschaften from '../../Atoms/BeeTreeSelectLiegenschaften';
import DLOverviewProperties from '../../Molecules/DL/DLOverviewProperties';
import EmptySearchView from '../../Organisms/EmptySearchView';
import { ImageType } from '../../Pages/AlphaPages/AlphaLiegenschaftVerwaltung';
import DLOverviewAnalytics from '../../Pages/DLPages/DLOverviewAnalytics';
import './DLTabOverview.scss';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import DownloadCombinedPositions from '../../Molecules/DownloadCombinedPositions';

type DLTabOverviewProps = {
  phase: TimelineStep;
  properties: LiegenschaftsData[];
  imageLookup: Map<string, ImageType>;
  epLookup?: Map<string, EpPosition>;
  epTree?: any;
  lvs: LV[];
};

export default function DLTabOverview({
  phase,
  properties,
  imageLookup,
  epLookup,
  epTree,
  lvs
}: DLTabOverviewProps) {
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<any>('');
  const [fatalError, setFatalError] = useState<boolean>(false);
  const [sort, setSort] = useState<any>(LIEGENSCHAFT_SORT_CITY);
  const [visibleData, setVisibleData] = useState<LiegenschaftsData[]>();
  const [currentFilter, setCurrentFilter] =
    useState<TreeSelectSelectionKeys>(null);
  const toast = useRef<Toast>(null);
  const [sliceLookup, setSliceLookup] = useState<
    Map<string, Map<string, number>>
  >(new Map());
  const [marketAreaLookup, setMarketAreaLookup] = useState<
    Map<string, boolean>
  >(new Map());

  //extract userId
  let userId: string = 'no_user';
  const extracted = extractUserId();
  userId = extracted ? extracted : userId;

  useEffect(() => {
    if (phase && properties) {
      setDataIsLoading(true);
      setFatalError(false);
      readAllStoredKeyValuesForOrgaAndPhase(userId, phase.id)
        .then((data: any) => {
          const keyValues = [...data];
          initKeyValueStore(keyValues, properties, lvs, phase);
        })
        .catch(() => {
          setFatalError(true);
          toast.current?.show({
            severity: 'error',
            summary: 'Daten können nicht geladen werden.',
            detail:
              'Das Laden der von Ihnen eingegebenen Preise und Faktoren ist schiefgelaufen. Bitte versuchen Sie es später erneut oder wenden sich an den Kundensupport. ',
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phase, lvs, properties]);

  ///////////////////////
  /////// HELPER ////////
  ///////////////////////

  function initKeyValueStore(
    keyValues: KeyValEntry[],
    properties: LiegenschaftsData[],
    lvs: LV[],
    phase: TimelineStep
  ) {
    const values: any = {};
    const lookup = new Map();
    const mLookup = new Map();
    if (phase && properties) {
      //for each property
      for (let i = 0; i < properties.length; i++) {
        const property = properties[i];
        if (property.adresse) {
          //search bundesland
          let region = null;
          for (let i = 0; i < COUNTRIES.length; i++) {
            const val = _.find(COUNTRIES[i].regions, function (reg: REGION) {
              return reg.region === property.adresse!.region;
            });
            region = val ? val : region;
          }
          const blID = region ? region.id : '';
          LeistungsSlices.forEach((slice: LEISTUNGSART) => {
            //for each slice BL-FACTOR
            const blKey: string = generateBlFactorId(phase, blID, slice);
            const valObj = _.find(keyValues, function (o: any) {
              return o.key === blKey;
            });
            if (valObj) {
              //data for keyval entry are available
              values[blKey] = {};
              values[blKey].id = valObj.id;
              values[blKey].value = parseInt(valObj.value);
              values[blKey].process = null;
            } else {
              //no entry on server -> initialize with null
              values[blKey] = {};
              values[blKey].id = null;
              values[blKey].value = null;
              values[blKey].process = null;
            }
            //for each slice LS-Factor
            const lKey: string = generateLsFactorId(
              phase,
              property,
              false,
              slice
            );
            const lValObj = _.find(keyValues, function (o: any) {
              return o.key === lKey;
            });
            if (lValObj) {
              //data for keyval entry are available
              values[lKey] = {};
              values[lKey].id = lValObj.id;
              values[lKey].value = parseInt(lValObj.value);
              values[lKey].process = null;
            } else {
              //no entry on server -> initialize with null
              values[lKey] = {};
              values[lKey].id = null;
              values[lKey].value = null;
              values[lKey].process = null;
            }
            //for each slice SUSTAIN-Factor
            const lsKey: string = generateLsFactorId(
              phase,
              property,
              true,
              slice
            );
            const lsValObj = _.find(keyValues, function (o: any) {
              return o.key === lsKey;
            });
            if (lsValObj) {
              //data for keyval entry are available
              values[lsKey] = {};
              values[lsKey].id = lsValObj.id;
              values[lsKey].value = parseInt(lsValObj.value);
              values[lsKey].process = null;
            } else {
              //no entry on server -> initialize with null
              values[lsKey] = {};
              values[lsKey].id = null;
              values[lsKey].value = null;
              values[lsKey].process = null;
            }
          });
          //test if property is marketarea
          const marketKey: string = generateLsInMarktgebietId(phase, property);
          const marketValObj = _.find(keyValues, function (o: any) {
            return o.key === marketKey;
          });
          if (marketValObj) {
            //data for keyval entry are available
            values[marketKey] = {};
            values[marketKey].id = marketValObj.id;
            values[marketKey].value =
              marketValObj.value === 'false' ? 'false' : 'true';
            values[marketKey].process = null;
          } else {
            //no entry on server -> initialize with null
            values[marketKey] = {};
            values[marketKey].id = null;
            values[marketKey].value = 'true';
            values[marketKey].process = null;
          }
          mLookup.set(
            property.id,
            values[marketKey].value === 'false' ? false : true
          );
          //find keyValues fpr pricing
          if (epLookup) {
            epLookup.forEach((ep) => {
              //only if entry has epCode => add to value lookup
              if (ep.epCode) {
                const key: string = generateEpEntryId(phase, ep.epCode);
                const valObj = _.find(keyValues, function (o: any) {
                  return o.key === key;
                });
                if (valObj) {
                  //data for keyval ep are available
                  values[key] = {};
                  values[key].id = valObj.id;
                  values[key].value = valObj.value
                    ? parseInt(valObj.value)
                    : null;
                  values[key].process = null;
                } else {
                  //no ep on server -> initialize with null
                  values[key] = {};
                  values[key].id = null;
                  values[key].value = null;
                  values[key].process = null;
                }
              }
            });
          }
          //calculate priced LV sums if LV exists
          const currLV = _.find(lvs, function (lv: LV) {
            return lv.info.propertyId === property.id;
          });
          if (currLV && currLV.data) {
            lookup.set(
              property.id,
              calculateSlices(
                generateEnlargedLV(
                  currLV.data,
                  property,
                  blID,
                  phase,
                  epLookup
                ),
                values
              )
            );
          }
        }
      }
    }
    setSliceLookup(lookup);
    setMarketAreaLookup(mLookup);
    setVisibleData(properties);
    setDataIsLoading(false);
  }

  function filterUsingSearchTermSortAndFilter(
    search: string,
    sort: any,
    currentFilter: TreeSelectSelectionKeys
  ) {
    setSearch(search);
    setSort(sort);
    setCurrentFilter(currentFilter);
    if (!search || search === '') {
      if (!properties) {
        setVisibleData([]);
      } else {
        filterUsingFilter(currentFilter, sort, properties);
      }
    } else {
      if (properties && properties.length > 0) {
        let result = _.cloneDeep(properties);
        result = _.filter(result, function (ls: any) {
          const attr = [];
          if (ls.adresse) {
            attr.push(_.toLower(ls.adresse?.stadt ? ls.adresse.stadt : ''));
            attr.push(_.toLower(ls.adresse?.strasse ? ls.adresse.strasse : ''));
            attr.push(
              _.toLower(ls.adresse?.postleitzahl ? ls.adresse.postleitzahl : '')
            );
            attr.push(
              _.toLower(ls.adresse?.hausnummer ? ls.adresse.hausnummer : '')
            );
          }
          attr.push(_.toLower(ls.nummer ? ls.nummer : ''));
          attr.push(_.toLower(ls.name ? ls.name : ''));
          return _.includes(attr.join(' '), _.toLower(search));
        });
        filterUsingFilter(currentFilter, sort, result);
      }
    }
  }

  function filterUsingFilter(
    currentFilter: any,
    sort: any,
    payload: LiegenschaftsData[]
  ) {
    if (!currentFilter) {
      sortCurrentData(sort, payload);
    } else {
      let result = _.cloneDeep(payload);
      result = _.filter(result, function (ls: LiegenschaftsData) {
        const id: string = ls && ls.id ? ls.id : '';
        if (
          ls &&
          currentFilter &&
          currentFilter[id] &&
          currentFilter[id] &&
          currentFilter[id].checked === true
        ) {
          return true;
        }
        return false;
      });
      sortCurrentData(sort, result);
    }
  }

  function sortCurrentData(sort: any, payload: LiegenschaftsData[]) {
    let ls = _.cloneDeep(payload);
    //sort by choice
    switch (sort) {
      case LIEGENSCHAFT_SORT_NAME:
        ls = _.sortBy(ls, ['name', 'adresse.stadt']);
        break;
      case LIEGENSCHAFT_SORT_CITY:
        ls = _.sortBy(ls, ['adresse.stadt']);
        break;
      case LIEGENSCHAFT_SORT_ADDRESS:
        ls = _.sortBy(ls, ['adresse.strasse', 'adresse.hausnummer']);
        break;
      case LIEGENSCHAFT_SORT_BUILDING_NO:
        ls = _.sortBy(ls, ['nummer', 'adresse.stadt']);
        break;

      default:
    }
    setVisibleData(ls);
  }

  function calculateLvData() {
    const lvData = new Map<string, number>();
    if (visibleData) {
      visibleData.forEach((p) => {
        //test if property has LV and is in marketArea
        const propId: string = p?.id ? p.id : 'no-id';
        const lvValues = sliceLookup?.get(propId);
        const inMarket: boolean = marketAreaLookup?.get(propId)
          ? marketAreaLookup.get(propId)!
          : false;
        if (lvValues && inMarket) {
          lvValues.forEach((value, key) => {
            let newVal = lvData.get(key);
            newVal = newVal ? newVal : 0;
            newVal += value;
            lvData.set(key, newVal);
          });
        }
      });
    }
    return lvData;
  }

  ///////////////////////
  /// VIEW COMPONENTS ///
  ///////////////////////

  const injectSearchFilter = () => {
    return (
      <div className="grid overview-search-area">
        {
          //fixme filtercat readable string
        }
        <div className="col col-4">
          <BeeTreeSelectLiegenschaften
            label={'Liegenschaften'}
            value={currentFilter}
            options={properties}
            disabled={false}
            formstate={'neutral'}
            readOnly={false}
            onChange={(filter: TreeSelectSelectionKeys) =>
              filterUsingSearchTermSortAndFilter(search, sort, filter)
            }
          />
        </div>
        <div className="col col-4">
          <BeeDropDown
            label={'Sortieren'}
            value={sort}
            options={DROPDOWN_LIEGENSCHAFT_SORT}
            disabled={false}
            formstate={'neutral'}
            readOnly={false}
            required={false}
            onChange={(sort: string) =>
              filterUsingSearchTermSortAndFilter(search, sort, currentFilter)
            }
          />
        </div>
        <div className="col col-4">
          <BeeSearchBar
            label={'Suche'}
            value={search}
            disabled={false}
            readOnly={false}
            required={false}
            formstate={'neutral'}
            type={'primary'}
            onSearch={(term: string) =>
              filterUsingSearchTermSortAndFilter(term, sort, currentFilter)
            }
          />
        </div>
      </div>
    );
  };

  return (
    <div className={'dl-tab-overview m-3'}>
      {dataIsLoading ? (
        <div className={'bee-page-spinner'}>
          <BeeLoadingSpinner strokeWidth={'3'} type={'primary'} />
        </div>
      ) : (
        <>
          {injectSearchFilter()}
          {fatalError ? (
            <>
              Es konnten keine Daten geladen werden. Bitte überprüfen Sie Ihre
              Internetverbindung und versuchen Sie es erneut oder wenden Sie
              sich an den Kundenservice
            </>
          ) : visibleData && visibleData.length > 0 ? (
            <>
              <DLOverviewAnalytics
                lvData={calculateLvData()}
                showTable={true}
                chartType="pie"
                disabled={properties ? false : true}
                readOnly={false}
              />
              <DownloadCombinedPositions
                properties={properties}
                lvs={lvs}
                epLookup={epLookup}
                epTree={epTree}
                phase={phase}
              />
              <DLOverviewProperties
                properties={visibleData}
                imageLookup={imageLookup}
                sliceLookup={sliceLookup}
                marketAreaLookup={marketAreaLookup}
                disabled={properties ? false : true}
              />
            </>
          ) : (
            <>
              <DownloadCombinedPositions
                properties={properties}
                lvs={lvs}
                epLookup={epLookup}
                epTree={epTree}
                phase={phase}
              />
              <EmptySearchView
                searchTerm={search}
                filterCat={currentFilter}
                onResetSearch={() =>
                  filterUsingSearchTermSortAndFilter('', sort, currentFilter)
                }
                onResetFilter={() =>
                  filterUsingSearchTermSortAndFilter(search, sort, '')
                }
              />
            </>
          )}
        </>
      )}
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
