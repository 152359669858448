import { MultiSelect } from 'primereact/multiselect';
import { useId } from 'react-id-generator';
import { EMPTY_FIELD_PLACEHOLDER } from '../../Helper/Statics/Constants';
import './BeeMultiSelect.scss';
import BeeTextInput from './BeeTextInput';

type BeeMultiSelectProps = {
  id?: string;
  label?: any;
  maxSelectedLabels?: number;
  selectedItemsLabel?: string;
  selectionLimit?: number;
  value: any;
  options: any;
  optionLabel?: string;
  disabled?: boolean;
  formstate?: string;
  readOnly?: boolean;
  required?: boolean;
  onChange: (e: string[]) => void;
};

export function BeeMultiSelect({
  id,
  label,
  maxSelectedLabels,
  selectedItemsLabel,
  selectionLimit,
  value,
  options,
  optionLabel,
  disabled,
  formstate,
  readOnly,
  required,
  onChange
}: BeeMultiSelectProps) {
  const genId = useId(1, 'bee-multi-select-')[0];
  const currentId = id ? id : genId;
  const inputId = currentId + 'input';

  //classNames
  const formstateClassnames =
    formstate === 'valid'
      ? 'bee-multi-select bee-multi-select-valid'
      : formstate === 'error'
      ? 'bee-multi-select bee-multi-select-error'
      : formstate === 'neutral'
      ? 'bee-multi-select bee-multi-select-neutral'
      : 'bee-multi-select bee-multi-select-neutral';

  let readOnlyClassnames = '';
  if (readOnly) {
    readOnlyClassnames = ' bee-multi-select-readOnly';
    disabled = true;
  }

  const classNames = formstateClassnames + readOnlyClassnames;
  const containerClassNames = 'p-float-label bee-multi-select-container';
  const requiredClassName = 'bee-multi-select-label-required';
  const requiredStarClassName = 'bee-multi-select-label-required-star';

  function change(item: string[]) {
    if (!disabled && onChange) {
      onChange(item);
    }
  }

  const renderPlaceholder = () => {
    return (
      <BeeTextInput
        label={label}
        value={EMPTY_FIELD_PLACEHOLDER}
        disabled={disabled}
        readOnly={true}
        required={required}
        onChange={() => {}}
      />
    );
  };

  return (
    <>
      {readOnly && !value ? (
        renderPlaceholder()
      ) : (
        <span id={currentId} className={containerClassNames}>
          <MultiSelect
            id={inputId}
            value={value}
            maxSelectedLabels={maxSelectedLabels ? maxSelectedLabels : 3}
            selectedItemsLabel={selectedItemsLabel}
            selectionLimit={selectionLimit ? selectionLimit : undefined}
            panelHeaderTemplate={<div />}
            options={options}
            showClear={true}
            optionLabel={optionLabel}
            disabled={disabled}
            className={classNames}
            onChange={(e) => change(e.value)}
          />
          {label ? (
            <label htmlFor={inputId}>
              {label}
              {required && label ? (
                <span className={requiredClassName}>
                  <span className={requiredStarClassName}> *</span>
                </span>
              ) : null}
            </label>
          ) : null}
        </span>
      )}
    </>
  );
}

export default BeeMultiSelect;
