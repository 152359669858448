import fileDownload from 'js-file-download';
import _ from 'lodash';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import {
  createNewKeyValueForOrgaAndPhase,
  KeyValEntry,
  updateKeyValueForOrgaAndPhase
} from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import {
  convertDocResponse,
  downloadDocumentFromServer,
  ReadMediaArrayResponse,
  translateDLMediaIds,
  uploadDLDocumentToServer
} from '../../../Helper/ApiHelper/MediaNetworkHelper';
import {
  ACCEPTED_FILE_FORMATS,
  DURATION_NOTIFICATION_ERROR_LONG,
  NAMING_USER_MEDIA,
  URL_TO_MEDIA_SERVER
} from '../../../Helper/Statics/Constants';
import { extractUserId } from '../../../Helper/Util/JwtHelper';
import { handlePromisesAndIgnoreErrors } from '../../../Helper/Util/PromiseHelper';

import { zipDocs } from '../../../Helper/StorageHelper/ZipHelper';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeOutlinedButton from '../../Atoms/BeeOutlinedButton';
import { TimelineStep } from '../../Atoms/BeeTimeline';
import { DocType, FileUploadType } from '../../Pages/DLPages/DLPriceInputPage';
import BeeDocumentTable from '../BeeDocumentTable';
import BeeDeletionDialog from '../Dialogs/BeeDeletionDialog';
import BeeUploadDialog from '../Dialogs/BeeUploadDialog';
import './DLUploadDocuments.scss';

type DLUploadDocumentsProps = {
  readOnly: boolean;
  phase: TimelineStep;
  keyValues: KeyValEntry[];
};

export function DLUploadDocuments({
  readOnly,
  phase,
  keyValues
}: DLUploadDocumentsProps) {
  const [documents, setDocuments] = useState<DocType[]>([]);
  const [isLoadingDownloadDocs, setIsLoadingDownloadDocs] =
    useState<boolean>(false);
  const [keyValueServerId, setKeyValueServerId] = useState<string | null>();
  const [loadDocsError, setLoadDocsError] = useState<boolean>(false);
  const [saveDocsError, setSaveDocsError] = useState<boolean>(false);

  //dialogs
  const [v_DocDialog, setV_DocDialog] = useState<boolean>(false);
  const [docUploadProg, setDocUploadProg] = useState<boolean>(false);
  const [docUploadError, setDocUploadError] = useState<string>();
  const [v_DocDelDialog, setV_DocDelDialog] = useState(false);
  const [currDocId, setCurrDocId] = useState<string>();
  const toast = useRef<Toast>(null);

  function getKeyName() {
    return phase.id + NAMING_USER_MEDIA;
  }

  useEffect(() => {
    //userMediaids
    const userMediaEntry: KeyValEntry | undefined = _.find(
      keyValues,
      function (entry: KeyValEntry) {
        return entry.key === getKeyName();
      }
    );
    //save id of entry to update
    setKeyValueServerId(userMediaEntry ? userMediaEntry.id : null);
    const userMedia: string[] =
      userMediaEntry && userMediaEntry.value
        ? JSON.parse(userMediaEntry.value)
        : [];
    let userId = extractUserId();
    userId = userId ? userId : 'no_user';
    translateDLMediaIds(userMedia, userId)
      .then((data: any) => {
        const media: ReadMediaArrayResponse[] = _.cloneDeep(data);
        // documents
        let entries: DocType[] = [];
        if (media && media.length > 0) {
          entries = convertDocResponse(media);
        }
        setLoadDocsError(false);
        setDocuments(entries);
      })
      .catch((error) => {
        setLoadDocsError(true);
      });
  }, [phase, keyValues]);

  ////////////////////
  // BUSINESS LOGIC //
  ////////////////////

  function createNewDocument(data: FileUploadType) {
    if (data) {
      setDocUploadProg(true);
      if (data.file) {
        uploadDLDocumentToServer(data.file, data.name)
          .then((result: any) => {
            const entries = _.cloneDeep(documents);
            let entry: DocType = {
              id: result.id,
              title: result.title,
              fileSrc: URL_TO_MEDIA_SERVER + result.url,
              timestamp: new Date(result.createdAt),
              type: result.type,
              fileKey: result.access,
              copyright: result.copyright,
              secured: result.access ? true : false,
              filename: result.filename,
              size: result.fileSize
            };
            entries.push(entry);
            let entryIds = [];
            for (let i = 0; i < entries.length; i++) {
              const element = entries[i];
              entryIds.push(element.id);
            }
            setDocuments(entries);
            setDocUploadProg(false);
            setV_DocDialog(false);
            storeNewUserMediaAtServer(entryIds);
          })
          .catch((error) => {
            setDocUploadProg(false);
            setDocUploadError('Upload ist leider fehlgeschlagen.');
          });
      }
    }
  }

  function storeNewUserMediaAtServer(ids: string[]) {
    let userId = extractUserId();
    userId = userId ? userId : 'no_user';
    if (keyValueServerId) {
      updateKeyValueForOrgaAndPhase(
        userId,
        phase.id,
        keyValueServerId,
        getKeyName(),
        JSON.stringify(ids)
      )
        .then(() => {
          setSaveDocsError(false);
          //reload/test if all docs are contained?!
        })
        .catch(() => {
          //ERROR CASE: dataarray konnte nicht auf keyval server gespeichert werden
          //angezeigten daten anpassen // tabelle ausblenden => fehleranzeige
          setSaveDocsError(true);
          if (toast && toast.current) {
            toast.current.show({
              severity: 'error',
              summary: 'Der Upload ist fehlgeschlagen',
              detail:
                'Beim Speichern des Dokuments auf dem Server ist etwas schiefgelaufen. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        });
    } else {
      createNewKeyValueForOrgaAndPhase(
        userId,
        phase.id,
        getKeyName(),
        JSON.stringify(ids)
      )
        .then((answer: any) => {
          setKeyValueServerId(answer.id);
          setSaveDocsError(false);
          //reload/test if all docs are contained?!
        })
        .catch(() => {
          //ERROR CASE, was tun wir?
          //angezeigten daten anpassen // tabelle ausblenden => fehleranzeige
          setSaveDocsError(true);
          if (toast && toast.current) {
            toast.current.show({
              severity: 'error',
              summary: 'Der Upload ist fehlgeschlagen',
              detail:
                'Beim Speichern des Dokuments auf dem Server ist etwas schiefgelaufen. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        });
    }
  }

  function deleteDocument(entryId?: string) {
    const entries = _.cloneDeep(documents);
    const entryIds = [];
    const values = [];
    if (entryId) {
      for (let i = 0; i < entries.length; i++) {
        if (entryId !== entries[i].id) {
          entryIds.push(entries[i].id);
          values.push(entries[i]);
        }
      }
    }
    setDocuments(values);
    storeNewUserMediaAtServer(entryIds);
  }

  function downloadDocumentById(entryId: string) {
    const entry = _.find(documents, function (doc: DocType) {
      return doc.id === entryId;
    });
    if (entry) {
      downloadDocumentFromServer(entry.fileSrc, entry.fileKey)
        .then((response: any) => {
          let filename = entry.filename;
          if (filename) {
            fileDownload(response, filename);
          }
        })
        .catch((error) => {
          if (toast && toast.current) {
            toast.current?.show({
              severity: 'error',
              summary: 'Der Download ist fehlgeschlagen',
              detail:
                'Der Download des Dokuments ist fehlgeschlagen, bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        });
    }
  }

  function downloadDocumentsByIds(entryIds: string[]) {
    setIsLoadingDownloadDocs(true);
    let downloadDocsPromises = [];
    for (let i = 0; i < entryIds.length; i++) {
      const entry = _.find(documents, function (doc: DocType) {
        return doc.id === entryIds[i];
      });

      if (entry) {
        downloadDocsPromises.push(
          downloadDocumentFromServer(entry.fileSrc, entry.fileKey).then(
            (result) => {
              let filename = entry.filename;
              if (filename) {
                return { name: filename, blob: result };
              }
              //no filename
            }
          )
        );
      }
    }
    handlePromisesAndIgnoreErrors(downloadDocsPromises)
      .then((result: any) => {
        if (result.fulfilled && result.fulfilled.length > 0) {
          let docFiles = [];
          for (let j = 0; j < result.fulfilled.length; j++) {
            docFiles.push(result.fulfilled[j].value);
          }

          const date = new Date().toLocaleDateString('de-DE');
          //zip data
          zipDocs(docFiles, 'BereitgestellteUnterlagen_' + date + '.zip')
            .then(() => {
              //sucess
              console.log('success');
              if (toast && toast.current) {
                if (!result.rejected || result.rejected.length <= 0) {
                  toast.current?.show({
                    severity: 'success',
                    summary: 'Download erfolgreich abgeschlossen',
                    sticky: false,
                    closable: true,
                    life: DURATION_NOTIFICATION_ERROR_LONG
                  });
                } else {
                  toast.current?.show({
                    severity: 'warn',
                    summary:
                      'Der Download ist für einige Dokumente fehlgeschlagen',
                    detail:
                      'Der Download der Dokumente ist vereinzelt fehlgeschlagen, bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
                    //fixme filenames
                    sticky: false,
                    closable: true,
                    life: DURATION_NOTIFICATION_ERROR_LONG
                  });
                }
              }
              setIsLoadingDownloadDocs(false);
            })
            .catch((error) => {
              //Fixme zip didnt work
              console.log(error);
              if (toast && toast.current) {
                toast.current?.show({
                  severity: 'error',
                  summary: 'Der Download ist fehlgeschlagen',
                  detail:
                    'Die Dokumente konnten nicht gezipped und heruntergeladen werden. Bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
                  sticky: true,
                  closable: true,
                  life: DURATION_NOTIFICATION_ERROR_LONG
                });
              }
              setIsLoadingDownloadDocs(false);
            });
        } else if (result.rejected && result.rejected.length > 0) {
          //download failed for all documents
          if (toast && toast.current) {
            toast.current?.show({
              severity: 'error',
              summary: 'Der Download ist für alle Dokumente fehlgeschlagen',
              detail:
                'Der Server für den Download der Dokumente konnte nicht erreicht werden. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
              sticky: true,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
          setIsLoadingDownloadDocs(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (toast && toast.current) {
          toast.current?.show({
            severity: 'error',
            summary: 'Der Download ist fehlgeschlagen',
            detail:
              'Der Download der Dokumente ist leider fehlgeschlagen, bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
        setIsLoadingDownloadDocs(false);
      });
  }

  function injectFileUploadDialog(): JSX.Element {
    return (
      <BeeUploadDialog
        type={'primary'}
        visible={v_DocDialog}
        disabled={false}
        locale={'de-DE'}
        header={'Dokument hochladen'}
        info={''}
        titleLabel={'Bezeichnung'}
        titleVisible={true}
        copyrightLabel={''}
        copyrightVisible={false}
        dropzoneTitle={'Upload'}
        dropzoneDescription={
          'Bitte ziehen Sie die gewünschte Datei in diesen Bereich oder Klicken Sie hinzufügen'
        }
        dropzoneAddLabel={'Klicken zum Hinzufügen'}
        dropzoneFormats={ACCEPTED_FILE_FORMATS}
        progressVisible={docUploadProg}
        progressMessage={'Dokument wird hochgeladen'}
        errorVisible={docUploadError ? true : false}
        errorHeadline={docUploadError}
        errorDescription={docUploadError}
        onHide={() => setV_DocDialog(false)}
        onUpload={(data) => createNewDocument(data)}
      />
    );
  }

  function injectFileDeletionConfirmationDialog(): JSX.Element {
    const doc = _.find(documents, function (entry: DocType) {
      return entry.id === currDocId;
    });
    return (
      <BeeDeletionDialog
        visible={v_DocDelDialog}
        message={
          'Möchten Sie ' +
          (doc && doc.title ? doc.title : 'dieses Dokument') +
          ' wirklich löschen?'
        }
        acceptLabel={'Löschen'}
        rejectLabel={'Abbrechen'}
        header={'Dokument löschen'}
        type={'primary'}
        onAccept={() => {
          deleteDocument(currDocId);
          setV_DocDelDialog(false);
        }}
        onReject={() => setV_DocDelDialog(false)}
        onHide={() => setV_DocDelDialog(false)}
      />
    );
  }

  return (
    <div className={'dl-uploadDocuments'}>
      <div>
        <BeeContentHeadline
          label={'Dokumente Hochladen'}
          headline={'h2'}
          type={'primary'}
        />
      </div>
      <>
        {loadDocsError ? (
          <div className={'error'}>
            <i className={'icon-error'}></i>
            <span>
              {' '}
              {
                'Beim Laden der Dokumente ist etwas schiefgelaufen. Bitte aktualisieren Sie die Seite. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.'
              }
            </span>
          </div>
        ) : null}
      </>

      <>
        {saveDocsError ? (
          <div className={'error'}>
            <i className={'icon-error'}></i>
            <span>
              {' '}
              {
                'Beim letzten Upload eines Dokuments ist etwas schiefgelaufen. Bitte aktualisieren Sie die Seite. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.'
              }
            </span>
          </div>
        ) : null}
      </>
      <div className={'uploadDocuments-descr'}>
        Alle im Rahmen der Angebotsabgabe geforderten Dokumente müssen durch den
        Bieter nachfolgend hochgeladen werden.
      </div>
      <BeeDocumentTable
        type={'dark'}
        data={documents ? documents : []}
        readOnly={readOnly || loadDocsError || saveDocsError}
        disabled={loadDocsError || saveDocsError}
        showDownload={true}
        showDelete={true}
        enableBulkDownload={true}
        isBulkDownloadLoading={isLoadingDownloadDocs}
        onDownload={(id) => downloadDocumentById(id)}
        onDownloadAll={(ids) => downloadDocumentsByIds(ids)}
        onDelete={(id) => {
          setCurrDocId(id);
          setV_DocDelDialog(true);
        }}
      />
      {!readOnly && !loadDocsError ? (
        <div className={'add-doc-btn'}>
          <BeeOutlinedButton
            label={'Dokument hinzufügen'}
            disabled={readOnly}
            type={'secondary'}
            onClick={() => setV_DocDialog(true)}
          />
        </div>
      ) : null}
      {v_DocDialog ? injectFileUploadDialog() : null}
      {v_DocDelDialog ? injectFileDeletionConfirmationDialog() : null}
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
export default DLUploadDocuments;
