import { Image } from 'exceljs';
import _ from 'lodash';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { errorToMsg } from '../../../Helper/ApiHelper/ErrorMessages';
import {
  Adresse,
  createGebaeude,
  createLiegenschaft,
  deleteGebaeudeById,
  deleteLiegenschaftById,
  downloadAvailableBaualtersstufen,
  downloadAvailableEnergietypen,
  downloadAvailableGebaeudetypen,
  downloadAvailableHauptbetriebstage,
  downloadAvailableKlimatisierungen,
  downloadAvailableTechnisierungsgrade,
  GebaeudeData,
  LiegenschaftsData,
  readGebaeudeById,
  readLiegenschaftById,
  updateGebaeudeById,
  updateLiegenschaftById
} from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import {
  DocResponse,
  extractAllMediaIds,
  ImageResponse,
  ReadMediaArrayResponse,
  translateAlphaMediaIds,
  uploadDocumentToServer,
  uploadImageToServer
} from '../../../Helper/ApiHelper/MediaNetworkHelper';
import {
  ACCEPTED_FILE_FORMATS,
  COUNTRIES,
  DEFAULT_IMAGE_SIZE,
  DURATION_NOTIFICATION_ERROR_LONG,
  DURATION_NOTIFICATION_SUCCESS_LONG,
  liegenschaftCreatedToast,
  liegenschaftDeletedToast,
  URL_TO_MEDIA_SERVER
} from '../../../Helper/Statics/Constants';
import {
  PATH_ALPHA_PROPERTY,
  PATH_DETAIL_LIEGENSCHAFT
} from '../../../Helper/Statics/Routes';
import { generateZipName } from '../../../Helper/StorageHelper/FilenameHelper';
import { extractUserId } from '../../../Helper/Util/JwtHelper';
import BeeBreadcrumb from '../../Atoms/BeeBreadcrumb';
import BeeButton from '../../Atoms/BeeButton';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import BeeOutlinedButton from '../../Atoms/BeeOutlinedButton';
import AlphaAdressForm from '../../Molecules/Alpha/AlphaAdressForm';
import AlphaLiegenschaftGDForm, {
  LiegenschaftsGrunddatenType
} from '../../Molecules/Alpha/AlphaLiegenschaftGDForm';
import AlphaMediaForm from '../../Molecules/Alpha/AlphaMediaForm';
import BeeConfirmDialog from '../../Molecules/Dialogs/BeeConfirmDialog';
import BeeDeletionDialog from '../../Molecules/Dialogs/BeeDeletionDialog';
import BeeUploadDialog from '../../Molecules/Dialogs/BeeUploadDialog';
import AlphaGebaeudeForm, {
  Dropdown
} from '../../Organisms/Alpha/AlphaGebaeudeForm';
import { DocType, ImgType } from '../DLPages/DLPriceInputPage';
import './AlphaLiegenschaftPage.scss';

type GebaeudeErrorEntry = {
  id?: string;
  tempId?: string;
  errorName?: boolean;
  errorNummer?: boolean;
  errorStrasse?: boolean;
  errorHausnr?: boolean;
  errorPlz?: boolean;
  errorStadt?: boolean;
  errorRegion?: boolean;
  errorLand?: boolean;
};

export type GebaeudeDocs = {
  id?: string;
  tempId?: string;
  documents?: DocType[];
};
export type GebaeudeImgs = { id?: string; tempId?: string; images?: ImgType[] };

export default function AlphaLiegenschaftPage() {
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(false);
  const [dataIsSaving, setDataIsSaving] = useState<boolean>(false);
  const [liegenschaftsData, setLiegenschaftsData] =
    useState<LiegenschaftsData>();
  const [gebaeudeListe, setGebaeudeListe] = useState<GebaeudeData[]>();

  const [serverDataChanged, setServerDataChanged] = useState<boolean>(false);
  const [currGebaeude, setCurrGebaeude] = useState<GebaeudeData>();

  const [liegenschaftEName, setLiegenschaftEName] = useState<boolean>(false);
  const [liegenschaftENummer, setLiegenschaftENummer] =
    useState<boolean>(false);
  const [liegenschaftEStrasse, setLiegenschaftEStrasse] =
    useState<boolean>(false);
  const [liegenschaftEHausnr, setLiegenschaftEHausnr] =
    useState<boolean>(false);
  const [liegenschaftEPlz, setLiegenschaftEPlz] = useState<boolean>(false);
  const [liegenschaftEStadt, setLiegenschaftEStadt] = useState<boolean>(false);
  const [liegenschaftELand, setLiegenschaftELand] = useState<boolean>(false);
  const [liegenschaftERegion, setLiegenschaftERegion] =
    useState<boolean>(false);
  const [gebaeudeListeErrors, setGebaeudeListeErrors] = useState<any>();

  const [allBaualtersstufen, setAllBaualtersstufen] = useState<Dropdown>([]);
  const [allHauptbetriebstage, setAllHauptbetriebstage] = useState<Dropdown>(
    []
  );
  const [allTechnisierungsgrade, setAllTechnisierungsgrade] =
    useState<Dropdown>([]);
  const [allKlimatisierungen, setAllKlimatisierungen] = useState<Dropdown>([]);
  const [allGebaeudetypen, setAllGebaeudetypen] = useState<Dropdown>([]);
  const [allEnergiesteckbriefTypen, setAllEnergiesteckbriefTypen] =
    useState<Dropdown>([]);

  //dialogs
  const [v_CancleDialog, setV_CancleDialog] = useState<boolean>(false);
  const [v_DeleteGebDialog, setV_DeleteGebDialog] = useState<boolean>(false);
  const [v_DeleteLiegenschaftDialog, setV_DeleteLiegenschaftDialog] =
    useState<boolean>(false);
  const [v_DocDialog, setV_DocDialog] = useState<boolean>(false);
  const [v_DocDelDialog, setV_DocDelDialog] = useState<boolean>(false);
  const [v_ImgDialog, setV_ImgDialog] = useState<boolean>(false);
  const [v_ImgDelDialog, setV_ImgDelDialog] = useState<boolean>(false);

  //media
  const [docUploadProgressVisible, setDocUploadProgressVisible] =
    useState<boolean>(false);
  const [docUploadError, setDocUploadError] = useState<string>('');
  const [docUploadErrorDescription, setDocUploadErrorDescription] =
    useState<string>('');
  const [imgUploadProgressVisible, setImgUploadProgressVisible] =
    useState<boolean>(false);
  const [imgUploadError, setImgUploadError] = useState<string>('');
  const [imgUploadErrorDescription, setImgUploadErrorDescription] =
    useState<string>('');
  const [currImgId, setCurrImgId] = useState<string>('');
  const [currDocId, setCurrDocId] = useState<string>('');
  //lookups images, documents
  const [imgLookup, setImgLookup] = useState<Map<string, ImageResponse>>(
    new Map()
  );
  const [docLookup, setDocLookup] = useState<Map<string, DocResponse>>(
    new Map()
  );
  const [liegenschaftImgs, setLiegenschaftImgs] = useState<ImgType[] | null>();
  const [liegenschaftDocs, setLiegenschaftDocs] = useState<DocType[] | null>();
  const [gebaeudeImgs, setGebaeudeImgs] = useState<GebaeudeImgs[] | null>();
  const [gebaeudeDocs, setGebaeudeDocs] = useState<any>();
  const [gebaeudeMode, setGebaeudeMode] = useState<boolean>(false);

  //refs
  const toast = useRef<Toast>(null);

  //fixme
  const errorItem = useRef(null);

  //get token from url
  const searchParams = new URLSearchParams(window.location.search);
  const lid = searchParams.get('lid');
  let navigate = useNavigate();
  const location = useLocation();

  let newLiegenschaftsGD: LiegenschaftsGrunddatenType | null = null;
  let newLiegenschaftsAdresse: Adresse | null = null;

  useEffect(() => {
    if (lid) {
      setDataIsLoading(true);
      readLiegenschaftById(lid)
        .then((data: any) => {
          console.log(data);
          setLiegenschaftsData(data);

          if (data.gebaeudeIds && data.gebaeudeIds.length > 0) {
            let gebaeudePromises: any[] = [];

            for (let i = 0; i < data.gebaeudeIds.length; i++) {
              gebaeudePromises.push(readGebaeudeById(lid, data.gebaeudeIds[i]));
            }
            Promise.all(gebaeudePromises)
              .then((values: GebaeudeData[]) => {
                setGebaeudeListe(values);
                console.log(values);
                setServerDataChanged(false);

                //call all mediaIds
                const mediaIds = extractAllMediaIds(data, values);

                translateAlphaMediaIds(mediaIds)
                  .then((data: any) => {
                    setupMediaLookups(data);
                  })
                  .catch((error) => {
                    if (toast && toast.current) {
                      toast.current.show({
                        severity: 'error',
                        summary:
                          'Bilder konnten nicht geladen werden ' +
                          errorToMsg(error),
                        detail:
                          'Leider konnten die Bilder nicht vollständig geladen werden. Bitte versuchen Sie es später erneut.',
                        sticky: false,
                        closable: true,
                        life: DURATION_NOTIFICATION_ERROR_LONG
                      });
                    }
                  });

                setDataIsLoading(false);
              })
              .catch((error) => {
                //not all geb loaded
                setDataIsLoading(false);
                if (toast && toast.current) {
                  toast.current.show({
                    severity: 'error',
                    summary: 'Nicht alle Gebäude konnten geladen werden ',
                    detail:
                      'Leider konnten die Gebäude nicht vom Server geladen werden. Bitte prüfen Sie Ihre Internetverbindung. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice',
                    sticky: false,
                    closable: true,
                    life: DURATION_NOTIFICATION_ERROR_LONG
                  });
                }
              });
          } else {
            //call all mediaIds
            const mediaIds = extractAllMediaIds(data, null);
            translateAlphaMediaIds(mediaIds)
              .then((data: any) => {
                setupMediaLookups(data);
              })
              .catch((error) => {
                if (toast && toast.current) {
                  toast.current.show({
                    severity: 'error',
                    summary:
                      'Bilder konnten nicht geladen werden ' +
                      errorToMsg(error),
                    detail:
                      'Leider konnten die Bilder nicht vollständig geladen werden. Bitte versuchen Sie es später erneut.',
                    sticky: false,
                    closable: true,
                    life: DURATION_NOTIFICATION_ERROR_LONG
                  });
                }
              });
            setDataIsLoading(false);
          }
        })
        .catch((error) => {
          setDataIsLoading(false);
          if (toast && toast.current) {
            toast.current.show({
              severity: 'error',
              summary: 'Die Liegenschaft konnte nicht geladen werden',
              detail:
                'Leider konnten die Liegenschaft nicht vom Server geladen werden. Bitte prüfen Sie Ihre Internetverbindung. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice',
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        });
    } else {
      let orgaId = extractUserId();
      setLiegenschaftsData({
        tempId: uuidv4(),
        organisationId: orgaId ? orgaId : undefined,
        adresse: {
          land: COUNTRIES[0].country,
          strasse: '',
          hausnummer: '',
          postleitzahl: '',
          stadt: '',
          region: ''
        }
      });
    }

    downloadAvailableBaualtersstufen()
      .then((data) => {
        const baualtersstufenSelect = [];
        if (data) {
          for (let i = 0; i < data.length; i++) {
            baualtersstufenSelect.push({
              label: data[i],
              value: data[i]
            });
          }
        }
        setAllBaualtersstufen(baualtersstufenSelect);
      })
      .catch((error) => {
        if (toast && toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Die Baualtersstufen konnte nicht geladen werden',
            detail:
              'Leider konnten die verfügbaren Baualtersstufen nicht vom Server geladen werden. Bitte prüfen Sie Ihre Internetverbindung. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice',
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
      });
    downloadAvailableHauptbetriebstage()
      .then((data) => {
        const hauptBestriebstageSelect = [];
        if (data) {
          for (let i = 0; i < data.length; i++) {
            hauptBestriebstageSelect.push({
              label: data[i],
              value: data[i]
            });
          }
        }
        setAllHauptbetriebstage(hauptBestriebstageSelect);
      })
      .catch((error) => {
        if (toast && toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Die Hauptbetriebstage konnte nicht geladen werden',
            detail:
              'Leider konnten die verfügbaren Hauptbetriebstage nicht vom Server geladen werden. Bitte prüfen Sie Ihre Internetverbindung. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice',
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
      });
    downloadAvailableTechnisierungsgrade()
      .then((data) => {
        const technisierungsgradeSelect = [];
        if (data) {
          for (let i = 0; i < data.length; i++) {
            technisierungsgradeSelect.push({
              label: data[i],
              value: data[i]
            });
          }
        }
        setAllTechnisierungsgrade(technisierungsgradeSelect);
      })
      .catch((error) => {
        if (toast && toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Die Technisierungsgrade konnte nicht geladen werden',
            detail:
              'Leider konnten die verfügbaren Technisierungsgrade nicht vom Server geladen werden. Bitte prüfen Sie Ihre Internetverbindung. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice',
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
      });
    downloadAvailableKlimatisierungen()
      .then((data) => {
        const klimatisierungenSelect = [];
        if (data) {
          for (let i = 0; i < data.length; i++) {
            klimatisierungenSelect.push({
              label: data[i],
              value: data[i]
            });
          }
        }
        setAllKlimatisierungen(klimatisierungenSelect);
      })
      .catch((error) => {
        if (toast && toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Die Klimatisierungen konnte nicht geladen werden',
            detail:
              'Leider konnten die verfügbaren Klimatisierungen nicht vom Server geladen werden. Bitte prüfen Sie Ihre Internetverbindung. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice',
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
      });
    downloadAvailableGebaeudetypen()
      .then((data) => {
        const gebaeudetypenSelect = [];
        if (data) {
          for (let i = 0; i < data.length; i++) {
            gebaeudetypenSelect.push({
              label: data[i],
              value: data[i]
            });
          }
        }
        setAllGebaeudetypen(gebaeudetypenSelect);
      })
      .catch((error) => {
        if (toast && toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Die Gebäudetypen konnte nicht geladen werden',
            detail:
              'Leider konnten die verfügbaren Gebäudetypen nicht vom Server geladen werden. Bitte prüfen Sie Ihre Internetverbindung. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice',
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
      });
    downloadAvailableEnergietypen()
      .then((data) => {
        const energietypenSelect = [];
        if (data) {
          for (let i = 0; i < data.length; i++) {
            energietypenSelect.push({
              label: data[i],
              value: data[i]
            });
          }
        }
        setAllEnergiesteckbriefTypen(energietypenSelect);
      })
      .catch((error) => {
        if (toast && toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Die Energietypen konnte nicht geladen werden',
            detail:
              'Leider konnten die verfügbaren Energietypen nicht vom Server geladen werden. Bitte prüfen Sie Ihre Internetverbindung. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice',
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
      });
  }, [location.state?.showToast]);

  useEffect(() => {
    if (location && location.state && location.state?.showToast) {
      if (location.state?.showToast === liegenschaftCreatedToast) {
        toast.current?.show({
          severity: 'success',
          summary: 'Die Liegenschaft wurde gespeichert',
          detail: 'Die Liegenschaft wurde erfolgreich gespeichert',
          sticky: false,
          closable: true,
          life: DURATION_NOTIFICATION_SUCCESS_LONG
        });

        window.history.replaceState({}, document.title);
      }
    }
  }, [location.state?.showToast]);

  function setupMediaLookups(mediaData: ReadMediaArrayResponse[]) {
    const imgLookup = new Map<string, ImageResponse>();
    const docLookup = new Map();
    if (mediaData) {
      mediaData.forEach((entry: ReadMediaArrayResponse) => {
        if (entry.image) {
          const image = entry.image;
          imgLookup.set(image.id, image);
        } else if (entry.document) {
          const doc = entry.document;
          docLookup.set(doc.id, doc);
        }
      });
    }

    setImgLookup(imgLookup);
    setDocLookup(docLookup);
    //reset media caches
    setLiegenschaftImgs(null);
    setLiegenschaftDocs(null);
    setGebaeudeImgs(null);
    setGebaeudeDocs(null);
  }

  function getLiegenschaftImgs() {
    if (liegenschaftImgs) {
      return liegenschaftImgs;
    } else if (liegenschaftsData && imgLookup) {
      const imgArray = [] as ImgType[];
      //search all images
      const imgIds: string[] = [];
      if (liegenschaftsData) {
        if (liegenschaftsData.hauptBildId) {
          imgIds.push(liegenschaftsData.hauptBildId);
        }
        if (liegenschaftsData.mediaIds) {
          liegenschaftsData.mediaIds.forEach((entry: string) => {
            //check if it is image not doc
            if (imgLookup.has(entry)) {
              imgIds.push(entry);
            }
          });
        }
        //convert into images
        imgIds.forEach(function (imageId) {
          const entry = imgLookup.get(imageId);
          let version = null;
          if (entry) {
            for (let i = 0; i < entry.versions.length; i++) {
              const v: any = entry.versions[i];
              //default to first version
              if (!version) {
                version = v;
              }

              //search for medium pic
              //fixme normally it is getvLabel
              if (v && v.vLabel) {
                if (v.vLabel === DEFAULT_IMAGE_SIZE) {
                  version = v;
                }
              }
            }
            if (version) {
              imgArray.push({
                id: entry.id,
                url: URL_TO_MEDIA_SERVER + version.url,
                key: version.access ? version.access : '',
                alt: '',
                title: entry.title,
                copyright: entry.copyright,
                secured: version.access ? true : false,
                showPlaceholder: false
              });
            }
          }
        });
      }
      setLiegenschaftImgs(imgArray);
      return imgArray;
    } else {
      return [];
    }
  }

  function getGebaeudeImgs(gebaeude: GebaeudeData) {
    if (gebaeudeImgs) {
      let indexId = null;
      let indexTempId = null;
      indexId = _.findIndex(gebaeudeImgs, function (geb: GebaeudeImgs) {
        return geb.id === gebaeude.id;
      });
      indexTempId = _.findIndex(gebaeudeImgs, function (geb: GebaeudeImgs) {
        return geb.tempId === gebaeude.tempId;
      });

      if (indexId >= 0) {
        return gebaeudeImgs[indexId].images;
      } else if (indexTempId >= 0) {
        return gebaeudeImgs[indexTempId].images;
      } else if (gebaeude && imgLookup) {
        let imgArray = {
          id: gebaeude.id ? gebaeude.id : null,
          tempId: gebaeude.tempId ? gebaeude.tempId : null,
          images: buildImgArrayForGeb(gebaeude)
        };
        const currGebImages: any = _.cloneDeep(gebaeudeImgs);
        currGebImages.push(imgArray);

        setGebaeudeImgs(currGebImages);
        return imgArray.images;
      } else {
        return [];
      }
    } else if (gebaeude && imgLookup) {
      let imgArray = {
        id: gebaeude.id ? gebaeude.id : null,
        tempId: gebaeude.tempId ? gebaeude.tempId : null,
        images: buildImgArrayForGeb(gebaeude)
      };

      const currGebImages: any = gebaeudeImgs ? _.cloneDeep(gebaeudeImgs) : [];
      currGebImages.push(imgArray);

      setGebaeudeImgs(currGebImages);
      return imgArray.images;
    } else {
      return [];
    }
  }

  function buildImgArrayForGeb(gebaeude: GebaeudeData) {
    const imgArray = [] as ImgType[];
    //search all images
    const imgIds = [];
    if (gebaeude) {
      if (gebaeude.hauptBildId) {
        imgIds.push(gebaeude.hauptBildId);
      }
      if (gebaeude.mediaIds) {
        gebaeude.mediaIds.forEach((entry) => {
          if (imgLookup && imgLookup.has(entry)) {
            imgIds.push(entry);
          }
        });
      }
      //convert into images
      imgIds.forEach(function (imageId) {
        const entry = imgLookup ? imgLookup.get(imageId) : null;
        let version = null;
        if (entry) {
          for (let i = 0; i < entry.versions.length; i++) {
            const v = entry.versions[i];
            //default to first version
            if (!version) {
              version = v;
            }
            //search for medium pic
            if (v && v.getvLabel) {
              if (v.getvLabel === DEFAULT_IMAGE_SIZE) {
                version = v;
              }
            }
          }
          if (version) {
            imgArray.push({
              id: entry.id,
              url: URL_TO_MEDIA_SERVER + version.url,
              key: version.access ? version.access : '',
              alt: '',
              title: entry.title,
              copyright: entry.copyright,
              secured: version.access ? true : false,
              showPlaceholder: false
            });
          }
        }
      });
    }
    return imgArray;
  }

  function getLiegenschaftDocs() {
    if (liegenschaftDocs) {
      const currLiegenschaftenDocs = _.sortBy(liegenschaftDocs, [
        function (o: DocType) {
          return o.timestamp;
        }
      ]);
      return currLiegenschaftenDocs;
    } else if (liegenschaftsData && docLookup) {
      let docArray = [] as DocType[];
      //search all documents
      const docIds: string[] = [];
      if (liegenschaftsData) {
        if (liegenschaftsData.hauptPlanId) {
          docIds.push(liegenschaftsData.hauptPlanId);
        }
        if (liegenschaftsData.mediaIds) {
          liegenschaftsData.mediaIds.forEach((entry: string) => {
            if (docLookup.has(entry)) {
              docIds.push(entry);
            }
          });
        }
        //convert to documents
        docIds.forEach(function (docId) {
          const entry = docLookup.get(docId);
          if (entry) {
            docArray.push({
              id: entry.id,
              title: entry.title,
              filename: entry.filename,
              fileSrc: URL_TO_MEDIA_SERVER + entry.url,
              timestamp: new Date(entry.createdAt),
              size: entry.fileSize,
              type: entry.type,
              copyright: entry.copyright,
              secured: entry.access ? true : false,
              fileKey: entry.access
            });
          }
        });
      }

      docArray = _.sortBy(docArray, [
        function (o: DocType) {
          return o.timestamp;
        }
      ]);
      setLiegenschaftDocs(docArray);
      return docArray;
    } else {
      return [];
    }
  }

  function getGebaeudeDocs(gebaeude: GebaeudeData) {
    if (gebaeudeDocs) {
      let indexId = null;
      let indexTempId = null;
      indexId = _.findIndex(
        gebaeudeDocs,
        function (geb: { id?: string; tempId?: string; documents?: DocType }) {
          return geb.id === gebaeude.id;
        }
      );
      indexTempId = _.findIndex(
        gebaeudeDocs,
        function (geb: { id?: string; tempId?: string; documents?: DocType }) {
          return geb.tempId === gebaeude.tempId;
        }
      );

      if (indexId >= 0) {
        const currGebDocs = _.sortBy(gebaeudeDocs[indexId].documents, [
          function (o: DocType) {
            return o.timestamp;
          }
        ]);
        return currGebDocs;
      } else if (indexTempId >= 0) {
        const currGebDocs = _.sortBy(gebaeudeDocs[indexTempId].documents, [
          function (o: DocType) {
            return o.timestamp;
          }
        ]);
        return currGebDocs;
      } else if (gebaeude && docLookup) {
        let docArray = {
          id: gebaeude.id ? gebaeude.id : null,
          tempId: gebaeude.tempId ? gebaeude.tempId : null,
          documents: createDocArrayForGeb(gebaeude)
        };

        const currGebDocs = gebaeudeDocs ? _.cloneDeep(gebaeudeDocs) : [];
        currGebDocs.push(docArray);

        setGebaeudeDocs(currGebDocs);
        return docArray;
      } else {
        return [];
      }
    } else if (gebaeude && docLookup) {
      let docArray = {
        id: gebaeude.id ? gebaeude.id : null,
        tempId: gebaeude.tempId ? gebaeude.tempId : null,
        documents: createDocArrayForGeb(gebaeude)
      };

      const currGebDocs = gebaeudeDocs ? _.cloneDeep(gebaeudeDocs) : [];
      currGebDocs.push(docArray);

      setGebaeudeDocs(currGebDocs);
      return docArray;
    } else {
      return [];
    }
  }

  function createDocArrayForGeb(gebaeude: GebaeudeData) {
    const docArray = [] as DocType[];
    //search all documents
    const docIds = [];
    if (gebaeude) {
      if (gebaeude.hauptPlanId) {
        docIds.push(gebaeude.hauptPlanId);
      }
      if (gebaeude.mediaIds) {
        gebaeude.mediaIds.forEach((entry) => {
          if (docLookup && docLookup.has(entry)) {
            docIds.push(entry);
          }
        });
      }
      //convert to documents
      docIds.forEach(function (docId) {
        const entry = docLookup ? docLookup.get(docId) : '';
        if (entry) {
          docArray.push({
            id: entry.id,
            title: entry.title,
            filename: entry.filename,
            fileSrc: URL_TO_MEDIA_SERVER + entry.url,
            timestamp: new Date(entry.createdAt),
            size: entry.fileSize,
            type: entry.type,
            copyright: entry.copyright,
            secured: entry.access ? true : false,
            fileKey: entry.access
          });
        }
      });
    }
    return docArray;
  }

  function createNewDocument(data: any) {
    if (data) {
      setDocUploadProgressVisible(true);
      setDocUploadError('');
      uploadDocumentToServer(data.file, true, data.name)
        .then((document: any) => {
          if (document) {
            const adaptedDocs: any = gebaeudeMode
              ? gebaeudeDocs
                ? gebaeudeDocs
                : []
              : liegenschaftDocs
              ? liegenschaftDocs
              : [];

            const convertedDoc = {
              id: document.id,
              title: document.title,
              filename: document.filename,
              fileSrc: URL_TO_MEDIA_SERVER + document.url,
              timestamp: new Date(document.createdAt),
              size: document.fileSize,
              type: document.type,
              copyright: document.copyright,
              secured: document.access ? true : false,
              fileKey: document.access
            };

            if (gebaeudeMode && currGebaeude && currGebaeude.id) {
              let entry = _.find(adaptedDocs, function (o: GebaeudeDocs) {
                return o.id === currGebaeude.id;
              });

              if (entry) {
                if (entry.documents) {
                  entry.documents.push(convertedDoc);
                } else {
                  entry.documents = [convertedDoc];
                }
              } else {
                entry = {
                  id: currGebaeude.id,
                  documents: [convertedDoc]
                };
                adaptedDocs.push(entry);
              }

              //set ids of building
              let currGebaeudeListe: any = _.cloneDeep(gebaeudeListe);
              const index = _.findIndex(
                currGebaeudeListe,
                function (geb: GebaeudeData) {
                  return geb.id === currGebaeude.id;
                }
              );

              if (index >= 0) {
                if (!currGebaeudeListe[index].hauptPlanId) {
                  currGebaeudeListe[index].hauptPlanId = convertedDoc.id;
                } else {
                  if (currGebaeudeListe[index].mediaIds) {
                    currGebaeudeListe[index].mediaIds.push(convertedDoc.id);
                  } else {
                    currGebaeudeListe[index].mediaIds = [convertedDoc.id];
                  }
                }

                setGebaeudeListe(currGebaeudeListe);
              }

              setGebaeudeDocs(adaptedDocs);
            } else if (gebaeudeMode && currGebaeude && currGebaeude.tempId) {
              let entry = _.find(adaptedDocs, function (o: GebaeudeImgs) {
                return o.tempId === currGebaeude.tempId;
              });
              if (entry) {
                if (entry.documents) {
                  entry.documents.push(convertedDoc);
                } else {
                  entry.documents = [convertedDoc];
                }
              } else {
                const newDocEntry = {
                  tempId: currGebaeude.tempId,
                  documents: [convertedDoc]
                };
                adaptedDocs.push(newDocEntry);
              }

              //set ids of building
              let currGebaeudeListe: any = _.cloneDeep(gebaeudeListe);
              const index = _.findIndex(
                currGebaeudeListe,
                function (geb: GebaeudeData) {
                  return geb.tempId === currGebaeude.tempId;
                }
              );

              if (index >= 0) {
                if (!currGebaeudeListe[index].hauptPlanId) {
                  currGebaeudeListe[index].hauptPlanId = convertedDoc.id;
                } else {
                  if (currGebaeudeListe[index].mediaIds) {
                    currGebaeudeListe[index].mediaIds.push(convertedDoc.id);
                  } else {
                    currGebaeudeListe[index].mediaIds = [convertedDoc.id];
                  }
                }

                setGebaeudeListe(currGebaeudeListe);
              }

              setGebaeudeDocs(adaptedDocs);
            } else {
              adaptedDocs.push(convertedDoc);

              let currLiegenschaftsData = liegenschaftsData
                ? _.cloneDeep(liegenschaftsData)
                : createLiegenschaftTemplateToSave();

              if (!currLiegenschaftsData.hauptPlanId) {
                currLiegenschaftsData.hauptPlanId = convertedDoc.id;
              } else {
                if (currLiegenschaftsData.mediaIds) {
                  currLiegenschaftsData.mediaIds.push(convertedDoc.id);
                } else {
                  currLiegenschaftsData.mediaIds = [convertedDoc.id];
                }
              }
              console.log('new doc');
              console.log(convertedDoc);
              console.log(currLiegenschaftsData);
              //fixme test if it workst with state data
              setLiegenschaftsData(currLiegenschaftsData);
              setLiegenschaftDocs(adaptedDocs);
            }

            //add entry to documentLookup
            docLookup.set(document.id, document);
          }
          setDocUploadProgressVisible(false);
          setV_DocDialog(false);
        })
        .catch((error) => {
          setDocUploadProgressVisible(false);
          setDocUploadError('Upload ist leider fehlgeschlagen.');
          setDocUploadErrorDescription(
            'Der Upload ist leider fehlgeschlagen. Bitte beachten Sie, dass die Dateigröße 10MB nicht übersteigen darf. Achten Sie zusätzlich auf eine stabile Internetverbindung. Sollte der Fehler bestehen bleiben kontaktieren Sie bitte den Kundenservice.'
          );
        });
    }
  }

  function createNewImage(data: any) {
    if (data) {
      setImgUploadProgressVisible(true);
      setImgUploadError('');

      uploadImageToServer(data.file, true, data.name)
        .then((img: any) => {
          const image: ImageResponse = _.cloneDeep(img);
          if (image) {
            const adaptedImgs: any = gebaeudeMode
              ? gebaeudeImgs
                ? gebaeudeImgs
                : []
              : liegenschaftImgs
              ? liegenschaftImgs
              : [];

            let version = null;
            for (let i = 0; i < image.versions.length; i++) {
              const v = image.versions[i];
              //default to first version
              if (!version) {
                version = v;
              }
              //search for medium pic
              if (v && v.getvLabel) {
                if (v.getvLabel === 'ORIGIN') {
                  version = v;
                }
              }
            }
            if (version) {
              const convertedImage = {
                id: image.id,
                url: URL_TO_MEDIA_SERVER + version.url,
                key: version.access,
                alt: null,
                title: image.title,
                copyright: image.copyright,
                secured: version.access ? true : false,
                showPlaceholder: false
              };

              if (gebaeudeMode && currGebaeude && currGebaeude.id) {
                let entry = _.find(adaptedImgs, function (o: GebaeudeImgs) {
                  return o.id === currGebaeude.id;
                });

                if (entry) {
                  if (entry.images) {
                    entry.images.push(convertedImage);
                  } else {
                    entry.images = [convertedImage];
                  }
                } else {
                  entry = {
                    id: currGebaeude.id,
                    images: [convertedImage]
                  };
                  adaptedImgs.push(entry);
                }

                //set ids of building
                let currGebaeudeListe: any = _.cloneDeep(gebaeudeListe);
                const index = _.findIndex(
                  currGebaeudeListe,
                  function (geb: GebaeudeData) {
                    return geb.id === currGebaeude.id;
                  }
                );

                if (index >= 0) {
                  if (!currGebaeudeListe[index].hauptBildId) {
                    currGebaeudeListe[index].hauptBildId = convertedImage.id;
                  } else {
                    if (currGebaeudeListe[index].mediaIds) {
                      currGebaeudeListe[index].mediaIds.push(convertedImage.id);
                    } else {
                      currGebaeudeListe[index].mediaIds = [convertedImage.id];
                    }
                  }

                  setGebaeudeListe(currGebaeudeListe);
                }

                setGebaeudeImgs(adaptedImgs);
              } else if (gebaeudeMode && currGebaeude && currGebaeude.tempId) {
                let entry = _.find(adaptedImgs, function (o: GebaeudeImgs) {
                  return o.tempId === currGebaeude.tempId;
                });
                if (entry) {
                  if (entry.images) {
                    entry.images.push(convertedImage);
                  } else {
                    entry.images = [convertedImage];
                  }
                } else {
                  const newImgEntry = {
                    tempId: currGebaeude.tempId,
                    images: [convertedImage]
                  };
                  adaptedImgs.push(newImgEntry);
                }

                let currGebaeudeListe: any = _.cloneDeep(gebaeudeListe);
                const index = _.findIndex(
                  currGebaeudeListe,
                  function (geb: GebaeudeData) {
                    return geb.tempId === currGebaeude.tempId;
                  }
                );

                if (index >= 0) {
                  if (!currGebaeudeListe[index].hauptBildId) {
                    currGebaeudeListe[index].hauptBildId = convertedImage.id;
                  } else {
                    if (currGebaeudeListe[index].mediaIds) {
                      currGebaeudeListe[index].mediaIds.push(convertedImage.id);
                    } else {
                      currGebaeudeListe[index].mediaIds = [convertedImage.id];
                    }
                  }

                  setGebaeudeListe(currGebaeudeListe);
                }

                setGebaeudeImgs(adaptedImgs);
              } else {
                //liegenschaft
                adaptedImgs.push(convertedImage);

                let currLiegenschaftsData = liegenschaftsData
                  ? _.cloneDeep(liegenschaftsData)
                  : createLiegenschaftTemplateToSave();

                if (!currLiegenschaftsData.hauptBildId) {
                  currLiegenschaftsData.hauptBildId = convertedImage.id;
                } else {
                  if (currLiegenschaftsData.mediaIds) {
                    currLiegenschaftsData.mediaIds.push(convertedImage.id);
                  } else {
                    currLiegenschaftsData.mediaIds = [convertedImage.id];
                  }
                }

                console.log('new image');
                console.log(convertedImage);
                console.log(currLiegenschaftsData);
                setLiegenschaftsData(currLiegenschaftsData);
                setLiegenschaftImgs(adaptedImgs);
              }
            }
            //add entry to imageLookup
            imgLookup.set(image.id, image);
          }
          setImgUploadProgressVisible(false);
          setV_ImgDialog(false);
        })
        .catch((error: any) => {
          setImgUploadProgressVisible(false);
          setImgUploadError('Upload ist leider fehlgeschlagen.');
          setImgUploadErrorDescription(
            'Der Upload ist leider fehlgeschlagen. Bitte beachten Sie, dass die Dateigröße 10MB nicht übersteigen darf. Achten Sie zusätzlich auf eine stabile Internetverbindung. Sollte der Fehler bestehen bleiben kontaktieren Sie bitte den Kundenservice.'
          );
        });
    }
  }

  function deleteImage(entryId: string) {
    console.log('DELETEIMG');
    //remove image from stored Images

    if (gebaeudeMode) {
      let currGebaeudeImgArray: any = [];
      if (gebaeudeImgs && currGebaeude) {
        if (currGebaeude.id) {
          console.log('currGebaeude');
          const currGebaeudeImgs: any = _.cloneDeep(gebaeudeImgs);
          const index = _.findIndex(
            currGebaeudeImgs,
            function (entry: GebaeudeImgs) {
              return entry.id === currGebaeude.id;
            }
          );
          if (index >= 0) {
            const imgs =
              currGebaeudeImgs[index] && currGebaeudeImgs[index].images
                ? currGebaeudeImgs[index].images.filter(function (
                    item: ImgType
                  ) {
                    return item.id !== entryId;
                  })
                : [];

            currGebaeudeImgs[index].images = imgs;
            currGebaeudeImgArray = imgs;
            setGebaeudeImgs(currGebaeudeImgs);
          }
        } else if (currGebaeude.tempId) {
          const currGebaeudeImgs: any = _.cloneDeep(gebaeudeImgs);
          const index = _.findIndex(
            currGebaeudeImgs,
            function (entry: GebaeudeImgs) {
              return entry.tempId === currGebaeude.tempId;
            }
          );

          if (index >= 0) {
            const imgs =
              currGebaeudeImgs[index] && currGebaeudeImgs[index].images
                ? currGebaeudeImgs[index].images.filter(function (
                    item: ImgType
                  ) {
                    return item.id !== entryId;
                  })
                : [];
            currGebaeudeImgs[index].images = imgs;
            currGebaeudeImgArray = imgs;
            setGebaeudeImgs(currGebaeudeImgs);
          }
        }
      }
      //set gebaeudeToSave mit neuen ids
      if (currGebaeude && gebaeudeListe) {
        console.log('  gebaeudeListe');
        let currGebaeudeListe: any = _.cloneDeep(gebaeudeListe);
        const index = _.findIndex(
          currGebaeudeListe,
          function (geb: GebaeudeData) {
            return geb.id === currGebaeude.id;
          }
        );
        const tempIndex = _.findIndex(
          currGebaeudeListe,
          function (geb: GebaeudeData) {
            return geb.tempId === currGebaeude.tempId;
          }
        );

        if (index >= 0) {
          if (currGebaeudeListe[index].hauptBildId === entryId) {
            if (currGebaeudeImgArray && currGebaeudeImgArray.length > 0) {
              currGebaeudeListe[index].hauptBildId = currGebaeudeImgArray[0].id;
              currGebaeudeListe[index].mediaIds = currGebaeudeListe[index]
                .mediaIds
                ? currGebaeudeListe[index].mediaIds.filter(function (
                    item: string
                  ) {
                    return item !== currGebaeudeImgArray[0].id;
                  })
                : [];
            } else {
              currGebaeudeListe[index].hauptBildId = '';
            }
          } else {
            const imgs = currGebaeudeListe[index].mediaIds
              ? currGebaeudeListe[index].mediaIds.filter(function (
                  item: string
                ) {
                  return item !== entryId;
                })
              : [];
            currGebaeudeListe[index].mediaIds = imgs;
            console.log('delete img');
            console.log(imgs);
            console.log(currGebaeudeListe);
          }
          console.log('delete img');
          console.log(entryId);
          console.log(currGebaeudeListe);
          setGebaeudeListe(currGebaeudeListe);
        } else if (tempIndex >= 0) {
          if (currGebaeudeListe[tempIndex].hauptBildId === entryId) {
            if (currGebaeudeImgArray && currGebaeudeImgArray.length > 0) {
              currGebaeudeListe[tempIndex].hauptBildId =
                currGebaeudeImgArray[0].id;
              currGebaeudeListe[tempIndex].mediaIds = currGebaeudeListe[
                tempIndex
              ].mediaIds
                ? currGebaeudeListe[tempIndex].mediaIds.filter(function (
                    item: string
                  ) {
                    return item !== currGebaeudeImgArray[0].id;
                  })
                : [];
            } else {
              currGebaeudeListe[tempIndex].hauptBildId = '';
            }
          } else {
            const imgs = currGebaeudeListe[tempIndex].mediaIds
              ? currGebaeudeListe[tempIndex].mediaIds.filter(function (
                  item: string
                ) {
                  return item !== entryId;
                })
              : [];
            currGebaeudeListe[tempIndex].mediaIds = imgs;
            console.log('delete img');
            console.log(imgs);
            console.log(currGebaeudeListe);
          }
          console.log('delete img');
          console.log(entryId);
          console.log(currGebaeudeListe);
          setGebaeudeListe(currGebaeudeListe);
        }
      }
    } else {
      let currLiegenschaftsData = liegenschaftsData
        ? _.cloneDeep(liegenschaftsData)
        : createLiegenschaftTemplateToSave();

      if (currLiegenschaftsData.hauptBildId === entryId) {
        //fixme can only set new main img if i have the images -> media service responses
        if (liegenschaftImgs && liegenschaftImgs.length > 0) {
          currLiegenschaftsData.hauptBildId = liegenschaftImgs[0].id;
          currLiegenschaftsData.mediaIds = currLiegenschaftsData.mediaIds
            ? currLiegenschaftsData.mediaIds.filter(function (item: string) {
                return item !== liegenschaftImgs[0].id;
              })
            : [];
        } else {
          currLiegenschaftsData.hauptBildId = '';
        }
      } else {
        const imgs = currLiegenschaftsData.mediaIds
          ? currLiegenschaftsData.mediaIds.filter(function (item: string) {
              return item !== entryId;
            })
          : [];
        currLiegenschaftsData.mediaIds = imgs;
      }
      console.log('deleteliegehschafts img');
      console.log(entryId);
      console.log(currLiegenschaftsData);
      setLiegenschaftsData(currLiegenschaftsData);

      if (liegenschaftImgs) {
        const imgs = liegenschaftImgs.filter(function (item) {
          return item.id !== entryId;
        });
        setLiegenschaftImgs(imgs);
      }
    }
    console.log('delete durch');
  }

  function deleteDocument(entryId: string) {
    //remove doc from stored documents
    if (gebaeudeMode) {
      if (gebaeudeDocs && currGebaeude) {
        if (currGebaeude.id) {
          let currGebaeudeDocs = _.cloneDeep(gebaeudeDocs);
          const index = _.findIndex(
            currGebaeudeDocs,
            function (entry: GebaeudeDocs) {
              return entry.id === currGebaeude.id;
            }
          );

          if (index >= 0) {
            const docs =
              currGebaeudeDocs[index] && currGebaeudeDocs[index].documents
                ? currGebaeudeDocs[index].documents.filter(function (
                    item: DocType
                  ) {
                    return item.id !== entryId;
                  })
                : [];

            currGebaeudeDocs[index].documents = docs;

            setGebaeudeDocs(currGebaeudeDocs);
          }
        } else if (currGebaeude.tempId) {
          let currGebaeudeDocs = _.cloneDeep(gebaeudeDocs);
          const index = _.findIndex(
            currGebaeudeDocs,
            function (entry: GebaeudeDocs) {
              return entry.tempId === currGebaeude.tempId;
            }
          );

          if (index >= 0) {
            const docs =
              currGebaeudeDocs[index] && currGebaeudeDocs[index].documents
                ? currGebaeudeDocs[index].documents.filter(function (
                    item: DocType
                  ) {
                    return item.id !== entryId;
                  })
                : [];
            currGebaeudeDocs[index].documents = docs;

            setGebaeudeDocs(currGebaeudeDocs);
          }
        }
      }
      if (currGebaeude && gebaeudeListe) {
        let currGebaeudeListe: any = _.cloneDeep(gebaeudeListe);
        const index = _.findIndex(
          currGebaeudeListe,
          function (geb: GebaeudeData) {
            return geb.id === currGebaeude.id;
          }
        );
        const tempIndex = _.findIndex(
          currGebaeudeListe,
          function (geb: GebaeudeData) {
            return geb.tempId === currGebaeude.tempId;
          }
        );

        if (index >= 0) {
          if (currGebaeudeListe[index].hauptPlanId === entryId) {
            currGebaeudeListe[index].hauptPlanId = '';
          } else {
            const docs = currGebaeudeListe[index].mediaIds
              ? currGebaeudeListe[index].mediaIds.filter(function (
                  item: string
                ) {
                  return item !== entryId;
                })
              : [];
            currGebaeudeListe[index].mediaIds = docs;
          }

          setGebaeudeListe(currGebaeudeListe);
        } else if (tempIndex >= 0) {
          if (currGebaeudeListe[tempIndex].hauptPlanId === entryId) {
            currGebaeudeListe[tempIndex].hauptPlanId = '';
          } else {
            const docs = currGebaeudeListe[tempIndex].mediaIds
              ? currGebaeudeListe[tempIndex].mediaIds.filter(function (
                  item: string
                ) {
                  return item !== entryId;
                })
              : [];
            currGebaeudeListe[tempIndex].mediaIds = docs;
          }

          setGebaeudeListe(currGebaeudeListe);
        }
      }
    } else {
      if (liegenschaftDocs) {
        const docs = liegenschaftDocs.filter(function (item) {
          return item.id !== entryId;
        });

        setLiegenschaftDocs(docs);

        let currLiegenschaft = liegenschaftsData
          ? _.cloneDeep(liegenschaftsData)
          : createLiegenschaftTemplateToSave();
        if (currLiegenschaft.hauptPlanId === entryId) {
          currLiegenschaft.hauptPlanId = '';
        } else {
          const docs = currLiegenschaft.mediaIds
            ? currLiegenschaft.mediaIds.filter(function (item: string) {
                return item !== entryId;
              })
            : [];
          currLiegenschaft.mediaIds = docs;
        }
        console.log(entryId);
        console.log(currLiegenschaft);

        setLiegenschaftsData(currLiegenschaft);
      }
    }
  }

  function convertDocumentIdToTitle(documentId: string) {
    const defaultValue = 'dieses Dokument';
    if (docLookup) {
      const val = docLookup.get(documentId);
      return val && val.title ? val.title : defaultValue;
    }
    return defaultValue;
  }

  function convertImageIdToTitle(imageId: string) {
    const defaultValue = 'dieses Bild';
    if (imgLookup) {
      const val = imgLookup.get(imageId);
      return val && val.title ? val.title : defaultValue;
    }
    return defaultValue;
  }

  function cancle() {
    navigate(PATH_ALPHA_PROPERTY);
  }

  function save() {
    //ValidateLiegenschaft
    const isLiegenschaftValid = validateLiegenschaftsData();
    // Validate all buildings
    const isAllGebaeudeValid = validateGebaeude();

    if (!(isAllGebaeudeValid && isLiegenschaftValid)) {
      if (toast && toast.current) {
        toast.current.show({
          severity: 'error',
          summary: 'Die Liegenschaft konnte nicht gespeichert werden',
          detail: 'Bitte geben Sie alle benötigten Informationen an',
          sticky: false,
          closable: true,
          life: DURATION_NOTIFICATION_SUCCESS_LONG
        });
      }
      setDataIsSaving(false);
    } else {
      if (isLiegenschaftValid) {
        //liegenschaft speichern
        if (liegenschaftsData && liegenschaftsData.id) {
          saveLiegenschaft(
            liegenschaftsData.id,
            liegenschaftsData,
            isAllGebaeudeValid
          );
        } else {
          //create liegenschaft
          saveLiegenschaft(
            undefined,
            liegenschaftsData ? liegenschaftsData : null,
            isAllGebaeudeValid
          );
        }
      }
    }
  }

  function validateLiegenschaftsData() {
    let liegenschaftsdata = _.cloneDeep(liegenschaftsData);

    if (liegenschaftsdata) {
      //liegenschaft gd
      const errorName = !(
        liegenschaftsdata.name && liegenschaftsdata.name.length > 0
      );
      const errorNummer = !(
        liegenschaftsdata.nummer && liegenschaftsdata.nummer.length > 0
      );

      //liegenschaft adress

      const errorStrasse = !(
        liegenschaftsdata.adresse &&
        liegenschaftsdata.adresse.strasse &&
        liegenschaftsdata.adresse.strasse.length > 0
      );
      const errorHausnr = !(
        liegenschaftsdata.adresse &&
        liegenschaftsdata.adresse.hausnummer &&
        liegenschaftsdata.adresse.hausnummer.length > 0
      );
      const errorPlz = !(
        liegenschaftsdata.adresse &&
        liegenschaftsdata.adresse.postleitzahl &&
        liegenschaftsdata.adresse.postleitzahl.length > 0
      );
      const errorStadt = !(
        liegenschaftsdata.adresse &&
        liegenschaftsdata.adresse.stadt &&
        liegenschaftsdata.adresse.stadt.length > 0
      );
      const errorLand = !(
        liegenschaftsdata.adresse &&
        liegenschaftsdata.adresse.land &&
        liegenschaftsdata.adresse.land.length > 0
      );
      const errorRegion = !(
        liegenschaftsdata.adresse &&
        liegenschaftsdata.adresse.region &&
        liegenschaftsdata.adresse.region.length > 0
      );
      setLiegenschaftEName(errorName);
      setLiegenschaftENummer(errorNummer);
      setLiegenschaftEStrasse(errorStrasse);
      setLiegenschaftEHausnr(errorHausnr);
      setLiegenschaftEPlz(errorPlz);
      setLiegenschaftEStadt(errorStadt);
      setLiegenschaftELand(errorLand);
      setLiegenschaftERegion(errorRegion);

      if (
        !errorName &&
        !errorNummer &&
        !errorStrasse &&
        !errorHausnr &&
        !errorPlz &&
        !errorStadt &&
        !errorLand &&
        !errorRegion
      ) {
        return true;
      }
      return false;
    } else {
      //data from server -> should be valid
      return true;
    }
  }

  function validateGebaeude() {
    let errorArray: GebaeudeErrorEntry[] = [];
    if (gebaeudeListe && gebaeudeListe.length > 0) {
      for (let i = 0; i < gebaeudeListe.length; i++) {
        let errorEntry: GebaeudeErrorEntry = {};
        if (gebaeudeListe[i]) {
          //gebaeude gd
          const errorName = !(
            gebaeudeListe[i].name && gebaeudeListe[i].name.length > 0
          );
          const errorNummer = !(
            gebaeudeListe[i].nummer && gebaeudeListe[i].nummer.length > 0
          );

          //gebaeude adress

          const errorStrasse = !(
            gebaeudeListe[i].adresse &&
            gebaeudeListe[i].adresse!.strasse &&
            gebaeudeListe[i].adresse!.strasse!.length > 0
          );
          const errorHausnr = !(
            gebaeudeListe[i].adresse &&
            gebaeudeListe[i].adresse!.hausnummer &&
            gebaeudeListe[i].adresse!.hausnummer!.length > 0
          );
          const errorPlz = !(
            gebaeudeListe[i].adresse &&
            gebaeudeListe[i].adresse!.postleitzahl &&
            gebaeudeListe[i].adresse!.postleitzahl!.length > 0
          );
          const errorStadt = !(
            gebaeudeListe[i].adresse &&
            gebaeudeListe[i].adresse!.stadt &&
            gebaeudeListe[i].adresse!.stadt!.length > 0
          );
          const errorLand = !(
            gebaeudeListe[i].adresse &&
            gebaeudeListe[i].adresse!.land &&
            gebaeudeListe[i].adresse!.land!.length > 0
          );
          const errorRegion = !(
            gebaeudeListe[i].adresse &&
            gebaeudeListe[i].adresse!.region &&
            gebaeudeListe[i].adresse!.region!.length > 0
          );
          if (
            errorName ||
            errorNummer ||
            errorStrasse ||
            errorHausnr ||
            errorPlz ||
            errorStadt ||
            errorLand ||
            errorRegion
          ) {
            if (gebaeudeListe[i].id) {
              errorEntry.id = gebaeudeListe[i].id;
            } else if (gebaeudeListe[i].tempId) {
              errorEntry.tempId = gebaeudeListe[i].tempId;
            }

            errorEntry.errorName = errorName;
            errorEntry.errorNummer = errorNummer;
            errorEntry.errorStrasse = errorStrasse;
            errorEntry.errorHausnr = errorHausnr;
            errorEntry.errorPlz = errorPlz;
            errorEntry.errorStadt = errorStadt;
            errorEntry.errorLand = errorLand;
            errorEntry.errorRegion = errorRegion;

            errorArray.push(errorEntry);
          }
        }
      }

      if (errorArray && errorArray.length > 0) {
        //gebaeude with errors
        setGebaeudeListeErrors(errorArray);
        return false;
      }
      return true;
    }
    return true;
  }

  function deleteLiegenschaft() {
    if (liegenschaftsData && liegenschaftsData.id) {
      let lid = liegenschaftsData.id;
      //liegenschaft with id on server -> already exists

      deleteLiegenschaftById(lid)
        .then((data: any) => {
          navigate(PATH_ALPHA_PROPERTY, {
            state: { showToast: liegenschaftDeletedToast }
          });
        })
        .catch((errorMsg) => {
          if (toast && toast.current) {
            toast.current.show({
              severity: 'error',
              summary: 'Die Liegenschaft konnte nicht gelöscht werden',
              detail: errorMsg,
              sticky: true,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        });
    } else if (liegenschaftsData && liegenschaftsData.tempId) {
      navigate(PATH_ALPHA_PROPERTY, {
        state: { showToast: liegenschaftDeletedToast }
      });
    }

    //no liegenschaft id-> does not exist
  }

  function saveLiegenschaft(
    id: string | undefined,
    dataset: LiegenschaftsData | null,
    isAllGebaeudeValid: boolean
  ) {
    if (id && dataset) {
      if (dataset.tempId) {
        delete dataset.tempId;
      }
      console.log(dataset);
      updateLiegenschaftById(id, dataset)
        .then((data: any) => {
          //create gebaeude
          if (isAllGebaeudeValid && gebaeudeListe && gebaeudeListe.length > 0) {
            let gebaeudePromises = [];
            for (let i = 0; i < gebaeudeListe.length; i++) {
              const gebaeude = gebaeudeListe[i];
              console.log(gebaeude);
              gebaeudePromises.push(saveGebaeude(id, gebaeude));
            }

            Promise.all(gebaeudePromises)
              .then((values: any) => {
                setGebaeudeListe(values);
                setServerDataChanged(false);
                if (toast && toast.current) {
                  toast.current?.show({
                    severity: 'success',
                    summary: 'Die Liegenschaft wurde gespeichert',
                    detail: 'Die Liegenschaft wurde erfolgreich gespeichert',
                    sticky: false,
                    closable: true,
                    life: DURATION_NOTIFICATION_SUCCESS_LONG
                  });
                }
                setDataIsSaving(false);
              })
              .catch((error) => {
                //not all geb loaded
                if (toast && toast.current) {
                  toast.current.show({
                    severity: 'error',
                    summary: 'Nicht alle Gebäude konnten gespeichert werden ',
                    detail:
                      'Leider konnten die Gebäude nicht gespeichert werden. Bitte prüfen Sie Ihre Internetverbindung. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice',
                    sticky: false,
                    closable: true,
                    life: DURATION_NOTIFICATION_ERROR_LONG
                  });
                }
                setDataIsSaving(false);
              });
          } else {
            if (toast && toast.current) {
              toast.current?.show({
                severity: 'success',
                summary: 'Die Liegenschaft wurde gespeichert',
                detail: 'Die Liegenschaft wurde erfolgreich gespeichert',
                sticky: false,
                closable: true,
                life: DURATION_NOTIFICATION_SUCCESS_LONG
              });
            }
            setDataIsSaving(false);
          }

          setLiegenschaftsData(data);
          setServerDataChanged(false);
        })
        .catch((error) => {
          if (toast && toast.current) {
            toast.current.show({
              severity: 'error',
              summary: 'Die Liegenschaft konnte nicht gespeichert werden',
              detail:
                'Leider konnten die Liegenschaft nicht auf dem Server gespeichert werden. Bitte prüfen Sie Ihre Internetverbindung. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice',
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
          setDataIsSaving(false);
        });
    } else if (dataset) {
      if (dataset.tempId) {
        delete dataset.tempId;
      }
      createLiegenschaft(dataset)
        .then((data: any) => {
          if (isAllGebaeudeValid && gebaeudeListe && gebaeudeListe.length > 0) {
            let gebaeudePromises = [];
            for (let i = 0; i < gebaeudeListe.length; i++) {
              const gebaeude = gebaeudeListe[i];

              gebaeudePromises.push(saveGebaeude(data.id, gebaeude));
            }

            Promise.all(gebaeudePromises)
              .then((values) => {
                setDataIsSaving(false);
                if (data && data.id) {
                  navigate(PATH_DETAIL_LIEGENSCHAFT + '/?lid=' + data.id, {
                    state: { showToast: liegenschaftCreatedToast }
                  });
                }
              })
              .catch((error) => {
                //not all geb saved
                if (toast && toast.current) {
                  toast.current.show({
                    severity: 'error',
                    summary: 'Nicht alle Gebäude konnten gespeichert werden ',
                    detail:
                      'Leider konnten die Gebäude nicht gespeichert werden. Bitte prüfen Sie Ihre Internetverbindung. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice',
                    sticky: false,
                    closable: true,
                    life: DURATION_NOTIFICATION_ERROR_LONG
                  });
                }
                setDataIsSaving(false);
              });
          } else {
            if (data && data.id) {
              setDataIsSaving(false);
              navigate(PATH_DETAIL_LIEGENSCHAFT + '/?lid=' + data.id, {
                state: { showToast: liegenschaftCreatedToast }
              });
            }
          }

          setLiegenschaftsData(data);
          setServerDataChanged(false);
        })
        .catch((error) => {
          if (toast && toast.current) {
            toast.current.show({
              severity: 'error',
              summary: 'Die Liegenschaft konnte nicht gespeichert werden',
              detail:
                'Leider konnten die Liegenschaft nicht auf dem Server gespeichert werden. Bitte prüfen Sie Ihre Internetverbindung. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice',
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
          setDataIsSaving(false);
        });
    }
  }

  function saveGebaeude(liegenschaftsId: string, dataset: GebaeudeData) {
    if (dataset && dataset.id) {
      return new Promise((resolve, reject) => {
        updateGebaeudeById(liegenschaftsId, dataset.id!, dataset)
          .then((data: any) => {
            let currGebaeude: any = _.cloneDeep(gebaeudeListe);
            const index = _.findIndex(
              currGebaeude,
              function (geb: GebaeudeData) {
                return geb.id === data.id;
              }
            );

            //FIXME will be executed for every building
            currGebaeude[index] = data;

            setGebaeudeListe(currGebaeude);
            resolve(data);
          })
          .catch((error) => {
            reject();
          });
      });
    } else if (dataset.tempId) {
      return new Promise((resolve, reject) => {
        createGebaeude(liegenschaftsId, dataset)
          .then((data: any) => {
            let currGebaeude: any = _.cloneDeep(gebaeudeListe);
            const index = _.findIndex(
              currGebaeude,
              function (geb: GebaeudeData) {
                return geb.id === data.id;
              }
            );

            //FIXME will be executed for every building
            currGebaeude[index] = data;
            setGebaeudeListe(currGebaeude);
            resolve(data);
          })
          .catch((error) => {
            reject();
          });
      });
    }
  }

  function changeLiegenschaftGD(data: LiegenschaftsGrunddatenType) {
    //Set new data
    /*  newLiegenschaftsGD = data; */

    let newLiegenschaftsDataSet = liegenschaftsData
      ? _.cloneDeep(liegenschaftsData)
      : createLiegenschaftTemplateToSave();

    /*  let newLiegenschaftsDataSet =
      createLiegenschaftTemplateToSave() as LiegenschaftsData;
 */
    //add new data
    if (data && !(Object.keys(data).length === 0)) {
      newLiegenschaftsDataSet.nummer = data.nummer;
      newLiegenschaftsDataSet.name = data.name;
      newLiegenschaftsDataSet.beschreibung = data.beschreibung;
      newLiegenschaftsDataSet.anmerkung = data.anmerkung;
      newLiegenschaftsDataSet.eigentuemer = data.eigentuemer;
      newLiegenschaftsDataSet.verantwortlicher = data.verantwortlicher;
    }
    setLiegenschaftsData(newLiegenschaftsDataSet);
    setServerDataChanged(true);
  }

  function changeLiegenschaftAdress(data: Adresse) {
    //Set new data
    /*   newLiegenschaftsAdresse = data; */

    let liegenschaftsDataSet = liegenschaftsData
      ? _.cloneDeep(liegenschaftsData)
      : (createLiegenschaftTemplateToSave() as LiegenschaftsData);

    //add new data
    if (data && !(Object.keys(data).length === 0)) {
      liegenschaftsDataSet.adresse = data;
    }
    setLiegenschaftsData(liegenschaftsDataSet);
    setServerDataChanged(true);
  }

  function createLiegenschaftTemplateToSave() {
    let liegenschaftsDataSet = {} as LiegenschaftsData;
    //if liegenschaft already existed use metainfo
    if (liegenschaftsData && !(Object.keys(liegenschaftsData).length === 0)) {
      if (liegenschaftsData.id) {
        liegenschaftsDataSet.id = liegenschaftsData.id
          ? liegenschaftsData.id
          : '';
      }
      if (liegenschaftsData.tempId) {
        liegenschaftsDataSet.tempId = liegenschaftsData.tempId
          ? liegenschaftsData.tempId
          : '';
      }
      if (liegenschaftsData.organisationId) {
        liegenschaftsDataSet.organisationId = liegenschaftsData.organisationId
          ? liegenschaftsData.organisationId
          : '';
      }
      if (liegenschaftsData.createdAt) {
        liegenschaftsDataSet.createdAt = liegenschaftsData.createdAt
          ? liegenschaftsData.createdAt
          : undefined;
      }
    }

    //add liegenschafts GD
    if (newLiegenschaftsGD && Object.keys(newLiegenschaftsGD).length > 0) {
      liegenschaftsDataSet.nummer = newLiegenschaftsGD.nummer
        ? newLiegenschaftsGD.nummer
        : '';
      liegenschaftsDataSet.name = newLiegenschaftsGD.name
        ? newLiegenschaftsGD.name
        : '';
      liegenschaftsDataSet.beschreibung = newLiegenschaftsGD.beschreibung
        ? newLiegenschaftsGD.beschreibung
        : '';
      liegenschaftsDataSet.anmerkung = newLiegenschaftsGD.anmerkung
        ? newLiegenschaftsGD.anmerkung
        : '';
      liegenschaftsDataSet.eigentuemer = newLiegenschaftsGD.eigentuemer
        ? newLiegenschaftsGD.eigentuemer
        : '';
      liegenschaftsDataSet.verantwortlicher =
        newLiegenschaftsGD.verantwortlicher
          ? newLiegenschaftsGD.verantwortlicher
          : '';
    } else if (liegenschaftsData) {
      liegenschaftsDataSet.nummer = liegenschaftsData.nummer
        ? liegenschaftsData.nummer
        : '';
      liegenschaftsDataSet.name = liegenschaftsData.name
        ? liegenschaftsData.name
        : '';
      liegenschaftsDataSet.beschreibung = liegenschaftsData.beschreibung
        ? liegenschaftsData.beschreibung
        : '';
      liegenschaftsDataSet.anmerkung = liegenschaftsData.anmerkung
        ? liegenschaftsData.anmerkung
        : '';
      liegenschaftsDataSet.eigentuemer = liegenschaftsData.eigentuemer
        ? liegenschaftsData.eigentuemer
        : '';
      liegenschaftsDataSet.verantwortlicher =
        liegenschaftsData.verantwortlicher;
    }

    //add adress
    if (
      newLiegenschaftsAdresse &&
      !(Object.keys(newLiegenschaftsAdresse).length === 0)
    ) {
      liegenschaftsDataSet.adresse = {} as Adresse;
      liegenschaftsDataSet.adresse.strasse = newLiegenschaftsAdresse.strasse
        ? newLiegenschaftsAdresse.strasse
        : '';
      liegenschaftsDataSet.adresse.hausnummer =
        newLiegenschaftsAdresse.hausnummer
          ? newLiegenschaftsAdresse.hausnummer
          : '';
      liegenschaftsDataSet.adresse.postleitzahl =
        newLiegenschaftsAdresse.postleitzahl
          ? newLiegenschaftsAdresse.postleitzahl
          : '';
      liegenschaftsDataSet.adresse.stadt = newLiegenschaftsAdresse.stadt
        ? newLiegenschaftsAdresse.stadt
        : '';
      liegenschaftsDataSet.adresse.region = newLiegenschaftsAdresse.region
        ? newLiegenschaftsAdresse.region
        : '';
      liegenschaftsDataSet.adresse.land = newLiegenschaftsAdresse.land
        ? newLiegenschaftsAdresse.land
        : '';
    } else if (liegenschaftsData) {
      liegenschaftsDataSet.adresse = liegenschaftsData.adresse
        ? liegenschaftsData.adresse
        : undefined;
    }

    //fixme use from liegenschaftsdata bzw gebaeudedata
    if (liegenschaftsData) {
      liegenschaftsDataSet.hauptBildId = liegenschaftsData.hauptBildId
        ? liegenschaftsData.hauptBildId
        : undefined;

      liegenschaftsDataSet.hauptPlanId = liegenschaftsData.hauptPlanId
        ? liegenschaftsData.hauptPlanId
        : undefined;

      liegenschaftsDataSet.mediaIds = liegenschaftsData.mediaIds
        ? liegenschaftsData.mediaIds
        : undefined;
    }

    if (liegenschaftsData) {
      liegenschaftsDataSet.gebaeudeIds = liegenschaftsData.gebaeudeIds
        ? liegenschaftsData.gebaeudeIds
        : [];
    }

    return liegenschaftsDataSet;
  }

  function addGebaeude() {
    let currGebaeudeListe: any = [];

    currGebaeudeListe = gebaeudeListe ? _.cloneDeep(gebaeudeListe) : [];

    currGebaeudeListe.push({
      tempId: uuidv4(),
      adresse: { land: COUNTRIES[0].country }
    });

    setGebaeudeListe(currGebaeudeListe);
    setServerDataChanged(true);
  }

  function changeGebaeude(id: string, dataset: any) {
    if (id) {
      //update gebaeude
      let currGebaeudeListe: any = {};

      currGebaeudeListe = gebaeudeListe ? _.cloneDeep(gebaeudeListe) : [];

      //find building to update
      let gebIndexToUpdate = _.findIndex(
        currGebaeudeListe,
        function (gebaeude: any) {
          return gebaeude.id === id;
        }
      );
      currGebaeudeListe[gebIndexToUpdate] = dataset;

      //set saveable object
      setGebaeudeListe(currGebaeudeListe);
      setServerDataChanged(true);
    } else if (dataset && dataset.tempId) {
      //update newly created gebaeude (not on server yet)

      let currGebaeudeListe: any = {};

      currGebaeudeListe = gebaeudeListe ? _.cloneDeep(gebaeudeListe) : [];

      //find building to update
      let gebIndexToUpdate = _.findIndex(
        currGebaeudeListe,
        function (gebaeude: any) {
          return gebaeude.tempId === dataset.tempId;
        }
      );
      currGebaeudeListe[gebIndexToUpdate] = dataset;

      setGebaeudeListe(currGebaeudeListe);
      setServerDataChanged(true);
    }
  }

  function deleteGebaeude() {
    let lid =
      liegenschaftsData && liegenschaftsData.id ? liegenschaftsData.id : '';

    if (currGebaeude && currGebaeude.id) {
      //building with id on server -> already exists

      //fixme cannot be undone mit abbrechen
      deleteGebaeudeById(lid, currGebaeude.id)
        .then((data: any) => {
          if (gebaeudeListe && gebaeudeListe.length > 0) {
            let currGebaeudeListe = _.cloneDeep(gebaeudeListe);

            _.remove(currGebaeudeListe, function (gebaeude: any) {
              return gebaeude.id === currGebaeude.id;
            });

            setGebaeudeListe(currGebaeudeListe);
            setServerDataChanged(true);
          }

          if (toast && toast.current) {
            toast.current.show({
              severity: 'success',
              summary: 'Das Gebäude wurde erfolgreich gelöscht',
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_SUCCESS_LONG
            });
          }
        })
        .catch((error) => {
          if (toast && toast.current) {
            toast.current.show({
              severity: 'error',
              summary: 'Das Gebäude konnte nicht gelöscht werden',
              detail:
                'Leider konnten das Gebäude nicht gelöscht werden. Bitte prüfen Sie Ihre Internetverbindung. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice',
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        });
    } else if (currGebaeude && currGebaeude.tempId) {
      //building doesnt exist on server yet-> delete in ui
      if (gebaeudeListe && gebaeudeListe.length > 0) {
        let currGebaeudeListe = _.cloneDeep(gebaeudeListe);

        _.remove(currGebaeudeListe, function (gebaeude: any) {
          return gebaeude.tempId === currGebaeude.tempId;
        });

        setGebaeudeListe(currGebaeudeListe);
        setServerDataChanged(true);
      }

      if (toast && toast.current) {
        toast.current.show({
          severity: 'success',
          summary: 'Das Gebäude wurde gelöscht',
          detail: 'Das Gebäude wurde erfolgreich gelöscht',
          sticky: false,
          closable: true,
          life: DURATION_NOTIFICATION_SUCCESS_LONG
        });
      }

      //no building id --> building was never saved -> does not exist
    }
  }

  function renderBreadcrumb() {
    const breadcrumb = [
      {
        label: 'Alle Liegenschaften',
        /*   url: PATH_ALPHA_PROPERTY, */
        command: () => {
          if (serverDataChanged) {
            setV_CancleDialog(true);
          } else {
            cancle();
          }
        }
      },
      {
        label:
          liegenschaftsData && liegenschaftsData.name
            ? 'Liegenschaft ' + liegenschaftsData.name
            : 'Neue Liegenschaft'
      }
    ];
    const home = {
      icon: 'pi pi-home',
      command: () => {
        if (serverDataChanged) {
          setV_CancleDialog(true);
        } else {
          cancle();
        }
      }
    };

    return (
      <>
        <BeeBreadcrumb items={breadcrumb} home={home} type={'secondary'} />
      </>
    );
  }

  function renderLiegenschaftHeadline() {
    return (
      <>
        <BeeContentHeadline
          label={'Liegenschaft'}
          headline={'h1'}
          type={'primary'}
        />

        <div className={dataIsLoading ? 'visibleSpinner' : 'invisibleSpinner'}>
          <BeeLoadingSpinner strokeWidth={'3'} type={'secondary'} />
        </div>
      </>
    );
  }

  function renderButtons() {
    return (
      <>
        <span className={'cancle-btn'}>
          <BeeOutlinedButton
            label={'Abbrechen'}
            disabled={false}
            type={'secondary'}
            onClick={(e) => {
              if (serverDataChanged) {
                setV_CancleDialog(true);
              } else {
                cancle();
              }
            }}
          />
        </span>
        <span className={'save-btn'}>
          <BeeButton
            label={'Speichern'}
            disabled={dataIsSaving ? true : false}
            isLoading={dataIsSaving ? true : false}
            type={'primary'}
            onClick={(e) => {
              setDataIsSaving(true);
              save();
            }}
          />
        </span>
      </>
    );
  }
  function renderLiegenschaftGrunddaten() {
    let liegenschaftGD = {} as LiegenschaftsGrunddatenType;
    if (liegenschaftsData) {
      liegenschaftGD = {
        nummer: liegenschaftsData.nummer ? liegenschaftsData.nummer : '',
        name: liegenschaftsData.name ? liegenschaftsData.name : '',
        beschreibung: liegenschaftsData.beschreibung
          ? liegenschaftsData.beschreibung
          : '',
        anmerkung: liegenschaftsData.anmerkung
          ? liegenschaftsData.anmerkung
          : '',
        eigentuemer: liegenschaftsData.eigentuemer
          ? liegenschaftsData.eigentuemer
          : '',
        verantwortlicher: liegenschaftsData.verantwortlicher
          ? liegenschaftsData.verantwortlicher
          : ''
      };
    }
    return (
      <>
        <AlphaLiegenschaftGDForm
          data={liegenschaftGD}
          errorName={liegenschaftEName}
          errorNummer={liegenschaftENummer}
          disabled={false}
          readOnly={false}
          onChange={(data: LiegenschaftsGrunddatenType) =>
            changeLiegenschaftGD(data)
          }
        />
      </>
    );
  }
  function renderLiegenschaftMedia() {
    return (
      <>
        <AlphaMediaForm
          images={getLiegenschaftImgs()}
          documents={getLiegenschaftDocs()}
          readOnly={false}
          downloadFolderName={
            liegenschaftsData && liegenschaftsData.name
              ? generateZipName('Dokumente_' + liegenschaftsData.name)
              : 'Dokumente'
          }
          onDeleteImage={(imageId) => {
            setV_ImgDelDialog(true);
            setGebaeudeMode(false);
            setCurrImgId(imageId);
          }}
          onAddImage={() => {
            setV_ImgDialog(true);
            setImgUploadError('');
            setGebaeudeMode(false);
          }}
          onDeleteDocument={(documentId) => {
            setCurrDocId(documentId);
            setV_DocDelDialog(true);
            setGebaeudeMode(false);
          }}
          onAddDocument={() => {
            setDocUploadError('');
            setV_DocDialog(true);
            setGebaeudeMode(false);
          }}
        />
      </>
    );
  }
  function renderLiegenschaftAdresse() {
    return (
      <>
        <AlphaAdressForm
          data={
            liegenschaftsData && liegenschaftsData.adresse
              ? liegenschaftsData.adresse
              : undefined
          }
          errorStrasse={liegenschaftEStrasse}
          errorHausnr={liegenschaftEHausnr}
          errorPlz={liegenschaftEPlz}
          errorStadt={liegenschaftEStadt}
          errorRegion={liegenschaftERegion}
          errorLand={liegenschaftELand}
          disabled={false}
          readOnly={false}
          onChangeAdress={(newAdress) => changeLiegenschaftAdress(newAdress)}
        />
      </>
    );
  }
  function renderLiegenschaftDelete() {
    return (
      <>
        <BeeOutlinedButton
          label={'Liegenschaft löschen'}
          disabled={false}
          type={'danger'}
          onClick={(e) => {
            setV_DeleteLiegenschaftDialog(true);
          }}
        />
      </>
    );
  }

  function renderGebaeudeHeadline() {
    return (
      <BeeContentHeadline label={'Gebäude'} headline={'h1'} type={'primary'} />
    );
  }

  function renderAccordionTab(gebaeude: any, index: number) {
    const gebId = gebaeude && gebaeude.id ? gebaeude.id : null;
    const gebTempId = gebaeude && gebaeude.tempId ? gebaeude.tempId : null;
    let errorEntry: GebaeudeErrorEntry | null = null;
    if (gebaeudeListeErrors && gebaeudeListeErrors.length > 0) {
      if (gebId) {
        errorEntry = _.find(
          gebaeudeListeErrors,
          function (geb: GebaeudeErrorEntry) {
            return geb.id === gebId;
          }
        );
      } else if (gebTempId) {
        errorEntry = _.find(
          gebaeudeListeErrors,
          function (geb: GebaeudeErrorEntry) {
            return geb.tempId === gebaeude.tempId;
          }
        );
      }
    }
    const accTabKey = gebId
      ? 'accTab' + index + gebId
      : gebTempId
      ? 'accTab' + index + gebTempId
      : 'accTab' + index;

    return (
      <AccordionTab
        className={
          errorEntry && Object.keys(errorEntry).length > 0 ? 'geb-error' : 'geb'
        }
        key={accTabKey}
        header={gebaeude && gebaeude.name ? gebaeude.name : 'Neues Gebäude '}
      >
        <AlphaGebaeudeForm
          data={gebaeude}
          isErrorGebaeudeName={
            errorEntry && errorEntry.errorName ? errorEntry.errorName : false
          }
          isErrorGebaeudeNummer={
            errorEntry && errorEntry.errorNummer
              ? errorEntry.errorNummer
              : false
          }
          isErrorGebaeudeStrasse={
            errorEntry && errorEntry.errorStrasse
              ? errorEntry.errorStrasse
              : false
          }
          isErrorGebaeudeHausnr={
            errorEntry && errorEntry.errorHausnr
              ? errorEntry.errorHausnr
              : false
          }
          isErrorGebaeudePlz={
            errorEntry && errorEntry.errorPlz ? errorEntry.errorPlz : false
          }
          isErrorGebaeudeStadt={
            errorEntry && errorEntry.errorStadt ? errorEntry.errorStadt : false
          }
          isErrorGebaeudeRegion={
            errorEntry && errorEntry.errorRegion
              ? errorEntry.errorRegion
              : false
          }
          isErrorGebaeudeLand={
            errorEntry && errorEntry.errorLand ? errorEntry.errorLand : false
          }
          imagesGebaeude={getGebaeudeImgs(gebaeude)} //fixme
          documentsGebaeude={getGebaeudeDocs(gebaeude)} //fixme
          onDeleteImage={(imageId) => {
            setV_ImgDelDialog(true);
            setGebaeudeMode(true);
            setCurrImgId(imageId);
            setCurrGebaeude(gebaeude);
          }}
          onAddImage={() => {
            setCurrGebaeude(gebaeude);
            setV_ImgDialog(true);
            setImgUploadError('');
            setGebaeudeMode(true);
          }}
          onDeleteDocument={(documentId) => {
            setCurrDocId(documentId);
            setV_DocDelDialog(true);
            setGebaeudeMode(true);
            setCurrGebaeude(gebaeude);
          }}
          onAddDocument={() => {
            setDocUploadError('');
            setV_DocDialog(true);
            setGebaeudeMode(true);
            setCurrGebaeude(gebaeude);
          }}
          baualtersstufenOptions={allBaualtersstufen}
          betriebstageOptions={allHauptbetriebstage}
          technisierungsgradeOptions={allTechnisierungsgrade}
          klimatisierungenOptions={allKlimatisierungen}
          gebaeudetypenOptions={allGebaeudetypen}
          energieTypenOptions={allEnergiesteckbriefTypen}
          disabled={false} //fixme
          readOnly={false} //Fixme
          onChangeGebaeude={(dataset) => changeGebaeude(gebId, dataset)}
          onDeleteGebaeude={() => {
            setCurrGebaeude(gebaeude);
            setV_DeleteGebDialog(true);
          }}
        ></AlphaGebaeudeForm>
      </AccordionTab>
    );
  }

  function renderGebaeudeAkkordeon() {
    const accTabs =
      gebaeudeListe && gebaeudeListe.length > 0
        ? gebaeudeListe.map((gebaeude: any, i: number) =>
            renderAccordionTab(gebaeude, i)
          )
        : null;
    return (
      <>
        <Accordion multiple>{gebaeudeListe ? accTabs : null}</Accordion>
      </>
    );
  }

  function renderAddGebaeudeBtn() {
    return (
      <>
        <div className={'add-building-btn'}>
          <BeeOutlinedButton
            label={'Gebäude hinzufügen'}
            disabled={false}
            type={'secondary'}
            onClick={() => addGebaeude()}
          />
        </div>
      </>
    );
  }

  function renderFooterBtns() {
    return (
      <>
        <span className={'back-btn'}>
          <BeeOutlinedButton
            label={'Zurück'}
            disabled={false}
            type={'secondary'}
            onClick={(e) => {
              if (serverDataChanged) {
                setV_CancleDialog(true);
              } else {
                cancle();
              }
            }}
          />
        </span>
        <div>
          <span className={'cancle-btn'}>
            <BeeOutlinedButton
              label={'Abbrechen'}
              disabled={false}
              type={'secondary'}
              onClick={(e) => {
                if (serverDataChanged) {
                  setV_CancleDialog(true);
                } else {
                  cancle();
                }
              }}
            />
          </span>
          <span className={'save-btn'}>
            <BeeButton
              label={'Speichern'}
              disabled={dataIsSaving ? true : false}
              isLoading={dataIsSaving ? true : false}
              type={'primary'}
              onClick={(e) => {
                setDataIsSaving(true);
                save();
              }}
            />
          </span>
        </div>
      </>
    );
  }

  function injectGebDeletionDialog() {
    return (
      <BeeDeletionDialog
        visible={v_DeleteGebDialog}
        message={
          'Die Liegenschaft wird mit allen inkludierten Gebäuden unwiderruflich gelöscht.'
        }
        acceptLabel={'Löschen'}
        rejectLabel={'Abbrechen'}
        header={
          currGebaeude && currGebaeude.name
            ? 'Möchten Sie das Gebäude "' +
              currGebaeude.name +
              '" wirklich löschen?'
            : 'Möchten Sie das Gebäude wirklich löschen?'
        }
        type={'primary'}
        onAccept={() => deleteGebaeude()}
        onReject={() => {
          setV_DeleteGebDialog(false);
          setCurrGebaeude(undefined);
        }}
        onHide={() => {
          setV_DeleteGebDialog(false);
          setCurrGebaeude(undefined);
        }}
      />
    );
  }
  function injectLiegenschaftDeletionDialog() {
    return (
      <BeeDeletionDialog
        visible={v_DeleteLiegenschaftDialog}
        message={'Die Liegenschaft wird unwiderruflich gelöscht.'}
        acceptLabel={'Löschen'}
        rejectLabel={'Abbrechen'}
        header={
          liegenschaftsData && liegenschaftsData.name
            ? 'Möchten Sie die Liegenschaft "' +
              liegenschaftsData.name +
              '" wirklich löschen?'
            : 'Möchten Sie die Liegenschaft wirklich löschen?'
        }
        type={'primary'}
        onAccept={() => deleteLiegenschaft()}
        onReject={() => setV_DeleteLiegenschaftDialog(false)}
        onHide={() => setV_DeleteLiegenschaftDialog(false)}
      />
    );
  }

  const injectCancleConfirmation = () => {
    return (
      <BeeConfirmDialog
        visible={v_CancleDialog}
        message={
          'Möchten Sie wirklich zurück zur Liegenschaftsübersicht? Ungespeicherte Änderungen werden in diesem Fall verworfen!'
        }
        header={'Zurück zur Liegenschaftsübersicht'}
        type={'primary'}
        onAccept={() => {
          setV_CancleDialog(false);
          cancle();
        }}
        onReject={() => setV_CancleDialog(false)}
        onHide={() => setV_CancleDialog(false)}
        acceptLabel={'Zurück zur Übersicht'}
        rejectLabel={'Abbrechen'}
      />
    );
  };
  const injectFileUploadDialog = () => {
    return (
      <>
        <BeeUploadDialog
          type={'primary'}
          visible={v_DocDialog}
          disabled={false}
          locale={'de-DE'}
          header={'Dokument hochladen'}
          info=""
          titleLabel=""
          titleVisible={false}
          copyrightLabel=""
          copyrightVisible={false}
          dropzoneTitle={'Upload - Dokument '}
          dropzoneDescription={
            'Bitte ziehen Sie die gewünschte Datei in diesen Bereich oder klicken Sie hinzufügen'
          }
          dropzoneAddLabel={'Klicken zum Hinzufügen'}
          dropzoneFormats={ACCEPTED_FILE_FORMATS}
          progressVisible={docUploadProgressVisible}
          progressMessage={'Dokument wird hochgeladen'}
          errorVisible={docUploadError ? true : false}
          errorHeadline={docUploadError}
          errorDescription={docUploadErrorDescription}
          onHide={() => {
            setDocUploadError('');
            setV_DocDialog(false);
          }}
          onUpload={(data) => createNewDocument(data)}
        />
      </>
    );
  };
  const injectFileDeletionConfirmationDialog = () => {
    return (
      <BeeDeletionDialog
        visible={v_DocDelDialog}
        message={
          'Möchten Sie ' +
          convertDocumentIdToTitle(currDocId) +
          ' wirklich löschen?'
        }
        acceptLabel={'Löschen'}
        rejectLabel={'Abbrechen'}
        header={'Dokument löschen'}
        type={'primary'}
        onAccept={() => {
          if (currDocId) {
            deleteDocument(currDocId);
          }
          setV_DocDelDialog(false);
        }}
        onReject={() => setV_DocDelDialog(false)}
        onHide={() => setV_DocDelDialog(false)}
      />
    );
  };

  const injectImageUploadDialog = () => {
    return (
      <>
        <BeeUploadDialog
          type={'primary'}
          visible={v_ImgDialog}
          disabled={false}
          locale={'de-DE'}
          header={'Bild hochladen'}
          info=""
          titleLabel=""
          titleVisible={false}
          copyrightLabel=""
          copyrightVisible={false}
          dropzoneTitle={'Upload - Bild '}
          dropzoneDescription={
            'Bitte ziehen Sie die gewünschte Datei in diesen Bereich oder klicken Sie hinzufügen'
          }
          dropzoneAddLabel={'Klicken zum Hinzufügen'}
          dropzoneFormats={ACCEPTED_FILE_FORMATS}
          progressVisible={imgUploadProgressVisible}
          progressMessage={'Bild wird hochgeladen'}
          errorVisible={imgUploadError ? true : false}
          //fixme
          errorHeadline={imgUploadError}
          errorDescription={imgUploadErrorDescription}
          onHide={() => {
            setImgUploadError('');
            setV_ImgDialog(false);
          }}
          onUpload={(data) => createNewImage(data)}
        />
      </>
    );
  };

  const injectImageDeletionConfirmationDialog = () => {
    return (
      <BeeDeletionDialog
        visible={v_ImgDelDialog}
        message={
          'Möchten Sie ' +
          convertImageIdToTitle(currImgId) +
          ' wirklich löschen?'
        }
        acceptLabel={'Löschen'}
        rejectLabel={'Abbrechen'}
        header={'Bild löschen'}
        type={'primary'}
        onAccept={() => {
          if (currImgId) {
            deleteImage(currImgId);
          }
          setV_ImgDelDialog(false);
        }}
        onReject={() => setV_ImgDelDialog(false)}
        onHide={() => setV_ImgDelDialog(false)}
      />
    );
  };

  return (
    <div
      className={'alpha-liegenschaft-page mr-4 ml-4 pt-2 justify-content-start'}
    >
      <div className={'alpha-liegeschaft-form'}>
        <div className={'breadcrumb'}>{renderBreadcrumb()}</div>
        <div className={'liegenschaft-head'}>
          <div className={'headline'}>{renderLiegenschaftHeadline()}</div>
          <div className={'action-btns'}>{renderButtons()}</div>
        </div>

        <div className={'lsGd'}>{renderLiegenschaftGrunddaten()}</div>

        <div className={'lsAdresse'}>{renderLiegenschaftAdresse()}</div>
        <div className={'lsMedia'}>{renderLiegenschaftMedia()}</div>
      </div>
      <div className={'alpha-gebaeude-form'}>
        <div>{renderGebaeudeHeadline()}</div>
        <>{renderGebaeudeAkkordeon()}</>
        <div>{renderAddGebaeudeBtn()}</div>
      </div>
      <div className={'lsDelete'}>{renderLiegenschaftDelete()}</div>
      <div className={'liegenschaft-page-footer'}>{renderFooterBtns()}</div>
      <Toast ref={toast} position={'top-right'} />
      {/* dialoge */}
      <>{v_DeleteGebDialog ? injectGebDeletionDialog() : null}</>
      <>
        {v_DeleteLiegenschaftDialog ? injectLiegenschaftDeletionDialog() : null}
      </>
      <>{v_CancleDialog ? injectCancleConfirmation() : null}</>
      <> {v_DocDialog ? injectFileUploadDialog() : null}</>
      <> {v_DocDelDialog ? injectFileDeletionConfirmationDialog() : null}</>
      <> {v_ImgDialog ? injectImageUploadDialog() : null}</>
      <> {v_ImgDelDialog ? injectImageDeletionConfirmationDialog() : null}</>
    </div>
  );
}
