import axios from 'axios';
import { URL_TO_AUTH_API } from '../Statics/Constants';
import { authAxios } from './AuthServiceHelper';

import {
  DEFAULT_ERROR,
  ERROR_400,
  ERROR_429,
  ERROR_500
} from './ErrorMessages';

export type ErrorResponseType = {
  message?: string;
  status?: number;
  timestamp?: string;
  path?: string;
  response: {
    status: number;
  };
};

export type LoginUserResponse = {
  //returns refresh token
  data: {
    token: string;
  };
};

export type CallAccessTokenResponse = {
  //returns access token
  data: { token: string };
};
export type TwoFactorAuthentificationResponse = {
  //returns access token
  data: { token: string };
};

export type ReadUserDataResponse = {
  // returns user data
  data: string;
};

export type AuthUserType = {
  id: string;
  username: string;
  firstname?: string;
  lastname?: string;
  phone?: string;
  organisation?: string;
  organisationLogo?: string;
  type?: string;
  valid?: boolean;
  expire?: Date;
  registration: Date;
  refreshToken: any;
  restorePwToken: any;
  pendingTwoFactorToken: any;
  grantedRights: any;
};

//CALL LOGIN USER
export const callLoginUser = (username: string, password: string) => {
  return new Promise<LoginUserResponse>((resolve, reject) => {
    axios({
      method: 'put',
      //create two factor token
      url: URL_TO_AUTH_API + '/v1/public/authenticate',
      headers: {},
      data: { password: password, username: username }
    })
      .then((result: LoginUserResponse) => {
        resolve(result);
      })
      .catch((error: ErrorResponseType) => {
        console.log(error);
        let errorMsg = '';
        if (error && error.response) {
          if (error.response.status === 404) {
            errorMsg = 'Die angegebene Email oder das Passwort ist falsch.';
          } else if (error.response.status === 500) {
            errorMsg = ERROR_500;
          } else {
            errorMsg = DEFAULT_ERROR;
          }
          reject(errorMsg);
        } else if (error && error.status) {
          if (error.status === 404) {
            errorMsg = 'Die angegebene Email oder das Passwort ist falsch.';
          } else if (error.response.status === 500) {
            errorMsg = ERROR_500;
          } else {
            errorMsg = DEFAULT_ERROR;
          }
          reject(errorMsg);
        } else {
          errorMsg = DEFAULT_ERROR;
          reject(errorMsg);
        }
      });
  });
};

//CALL ACCESS TOKEN TO LOGIN USER
export const callAccessToken = (refreshToken: string) => {
  return new Promise<CallAccessTokenResponse>((resolve, reject) => {
    //call with normal axios -> no access token available
    axios({
      method: 'put',
      url: URL_TO_AUTH_API + '/v1/public/token/refresh',
      headers: { Authorization: `Bearer ${refreshToken}` },
      data: {}
    })
      .then((response: CallAccessTokenResponse) => {
        resolve(response);
      })
      .catch((error: ErrorResponseType) => {
        //FIXME: Error Msgs
        let errorMsg = '';
        if (error && error.response) {
          if (error.response.status === 400) {
            errorMsg = ERROR_400;
          } else if (error.response.status === 404) {
            errorMsg =
              'Ein Nutzer mit diesen Logindaten konnte nicht gefunden werden';
          } else if (error.response.status === 429) {
            errorMsg = ERROR_429;
          } else if (error.response.status === 500) {
            errorMsg = ERROR_500;
          } else {
            errorMsg = DEFAULT_ERROR;
          }
          reject(errorMsg);
        } else {
          reject(error);
        }
      });
  });
};

//CALL TWO FACTOR AUTHENTIFICATION
export const callTwoFactorAuthentification = (code: string, token: string) => {
  return new Promise<TwoFactorAuthentificationResponse>((resolve, reject) => {
    //call with normal axios -> no auth token available
    axios({
      method: 'put',
      url: URL_TO_AUTH_API + '/v1/public/token/validate',
      headers: {},
      data: { token: token, key: code }
    })
      .then((result: TwoFactorAuthentificationResponse) => {
        resolve(result);
      })
      .catch((error: ErrorResponseType) => {
        //FIXME: Error Msgs
        let errorMsg = '';
        if (error && error.response) {
          if (error.response.status === 400) {
            errorMsg = DEFAULT_ERROR;
          } else if (error.response.status === 404) {
            errorMsg =
              'Der eingegebene Code stimmt leider nicht mit dem Versendeten überein. Bitte versuchen Sie es erneut.';
          } else if (error.response.status === 500) {
            errorMsg = ERROR_500;
          } else {
            errorMsg = DEFAULT_ERROR;
          }
          reject(errorMsg);
        } else if (error && error.status) {
          if (error.status === 404) {
            errorMsg = 'Die angegebene Email oder das Passwort ist falsch.';
          } else if (error.response.status === 500) {
            errorMsg = ERROR_500;
          } else {
            errorMsg = DEFAULT_ERROR;
          }
          reject(errorMsg);
        } else {
          reject(error);
        }
      });
  });
};

//CALL TO SEND FORGOT PW MAIL
export const callSendForgotPwMail = (email: string) => {
  return new Promise((resolve, reject) => {
    //call with normal axios -> no auth token available
    axios({
      method: 'put',
      url: URL_TO_AUTH_API + '/v1/public/password/forgot',
      headers: {},
      data: { username: email }
    })
      //fixme returns only status code, result is not used
      .then((result: any) => {
        console.log('forgot pw');
        console.log(result);
        resolve(result);
      })
      .catch((error: ErrorResponseType) => {
        let errorMsg = '';
        if (error && error.response) {
          if (error.response.status === 400) {
            errorMsg = 'Die Anfrage konnte nicht verarbeitet werden';
          } else if (error.response.status === 500) {
            errorMsg =
              'Ein unerwarteter Fehler ist aufgetreten. Der Server konnte Ihre Anfrage nicht verarbeiten';
          } else {
            //default
            errorMsg = error.message ? error.message : '';
          }
          reject(errorMsg);
        } else {
          reject(error);
        }
      });
  });
};

//CALL TO RESET PW
export const callResetPw = (token: string, password: string) => {
  return new Promise((resolve, reject) => {
    //call with normal axios -> no auth token available

    axios({
      method: 'put',
      url: URL_TO_AUTH_API + '/v1/public/password/reset',
      headers: {},
      data: { token: token, newPassword: password }
    })
      //fixme returns only status code, result is not used
      .then((result: any) => {
        resolve(result);
      })
      .catch((error: ErrorResponseType) => {
        let errorMsg = '';
        if (error && error.response) {
          if (error.response.status === 400) {
            errorMsg = 'Die Anfrage konnte nicht verarbeitet werden';
          } else if (error.response.status === 404) {
            errorMsg =
              'Der Link ist abgelaufen. Bitte versuchen Sie es erneut über das Passwort vergessen Formular.';
          } else if (error.response.status === 500) {
            errorMsg =
              'Ein unerwarteter Fehler ist aufgetreten. Der Server konnte Ihre Anfrage nicht verarbeiten';
          } else {
            //default
            errorMsg = error.message ? error.message : '';
          }
          reject(errorMsg);
        } else {
          reject(error);
        }
      });
  });
};

export const readAllServiceProvider = () => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'get',
      url: URL_TO_AUTH_API + '/v1/alpha/serviceprovider'
    })
      .then((result) => {
        console.log('success:');
        let answer: AuthUserType[] = result.data.data;
        resolve(answer);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//create new serviceProvider
