import { ConfirmDialog } from 'primereact/confirmdialog';
//theme

import './BeeDeletionDialog.scss';

type BeeDeletionDialogProps = {
  visible: boolean;
  message: string;
  messageIcon?: string;
  acceptLabel: string;
  rejectLabel: string;
  header: string;
  type: string;
  onAccept: () => void;
  onReject: () => void;
  onHide: () => void;
};

export function BeeDeletionDialog({
  visible,
  message,
  messageIcon,
  acceptLabel,
  rejectLabel,
  header,
  type,
  onAccept,
  onReject,
  onHide
}: BeeDeletionDialogProps) {
  //classNames
  const typeClassNames =
    type === 'primary'
      ? ' bee-deletion-dialog-primary'
      : type === 'secondary'
      ? ' bee-deletion-dialog-secondary'
      : type === 'default'
      ? ' bee-deletion-dialog-default'
      : ' bee-deletion-dialog-default';

  const classNames = 'bee-deletion-dialog' + typeClassNames;
  const acceptClassName = 'p-button-danger';
  const acceptIcon = 'pi pi-trash';

  function hide() {
    if (onHide) {
      onHide();
    }
  }

  function accept() {
    if (onAccept) {
      onAccept();
    }
  }

  function reject() {
    if (onReject) {
      onReject();
    }
  }

  return (
    <ConfirmDialog
      className={classNames}
      visible={visible}
      message={message}
      header={header}
      icon={messageIcon}
      acceptClassName={acceptClassName}
      acceptIcon={acceptIcon}
      acceptLabel={acceptLabel}
      rejectLabel={rejectLabel}
      onHide={() => hide()}
      accept={() => accept()}
      reject={() => reject()}
    />
  );
}

export default BeeDeletionDialog;
