import { Divider } from 'primereact/divider';
import { Password } from 'primereact/password';
import React from 'react';
import { useId } from 'react-id-generator';
import { EMPTY_FIELD_PLACEHOLDER } from '../../Helper/Statics/Constants';
import './BeePasswordInput.scss';
import BeeTextInput from './BeeTextInput';

type BeePasswordInputProps = {
  id?: string;
  label?: string;
  value?: string;
  disabled?: boolean;
  showPwStrength?: boolean;
  showHint?: boolean;
  formstate?: string;
  readOnly?: boolean;
  required?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
export function BeePasswordInput({
  id,
  label,
  value,
  disabled,
  showPwStrength,
  showHint,
  formstate,
  readOnly,
  required,
  onChange
}: BeePasswordInputProps) {
  const genId = useId(1, 'bee-pw-input')[0];
  const currentId = id ? id : genId;

  //classNames
  const formStateClassNames =
    formstate === 'valid'
      ? 'bee-pw-input-valid'
      : formstate === 'error'
      ? 'bee-pw-input-error'
      : formstate === 'neutral'
      ? 'bee-pw-input-neutral'
      : 'bee-pw-input-neutral';

  let readOnlyClassNames = '';
  if (readOnly) {
    readOnlyClassNames = ' bee-pw-input-readOnly';
    disabled = true;
  }

  const inputClassNames = 'bee-pw-input ' + formStateClassNames;
  const containerClassNames =
    'p-float-label bee-pw-container' + readOnlyClassNames;

  //label
  const labelClassNames = 'bee-pw-input-label';
  const header = <h6>Pick a password</h6>;

  function change(e: React.ChangeEvent<HTMLInputElement>) {
    if (!disabled && onChange) {
      onChange(e);
    }
  }

  const renderPlaceholder = () => {
    return (
      <BeeTextInput
        label={label}
        value={EMPTY_FIELD_PLACEHOLDER}
        disabled={disabled}
        readOnly={true}
        required={required}
        onChange={() => {}}
      />
    );
  };

  const footer = (
    <React.Fragment>
      <Divider />
      <p className="mt-2">Suggestions</p>
      <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
        <li>At least one lowercase</li>
        <li>At least one uppercase</li>
        <li>At least one numeric</li>
        <li>Minimum 8 characters</li>
      </ul>
    </React.Fragment>
  );

  return (
    <>
      {readOnly && !value ? (
        renderPlaceholder()
      ) : (
        <span id={currentId} className={containerClassNames}>
          <Password
            id={currentId + 'input'}
            value={value}
            disabled={disabled}
            onChange={(e) => change(e)}
            inputClassName={inputClassNames}
            toggleMask
            feedback={showPwStrength}
            header={showHint ? header : null}
            footer={showHint ? footer : null}
          />
          {label ? (
            <label htmlFor={currentId + 'input'} className={labelClassNames}>
              {label}
              {required && label ? (
                <span className="bee-pw-input-label-required">
                  <span className="bee-pw-input-label-required-star"> *</span>
                </span>
              ) : null}
            </label>
          ) : null}
        </span>
      )}
    </>
  );
}

export default BeePasswordInput;
