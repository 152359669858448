import { Toast } from 'primereact/toast';
import { useRef, useState } from 'react';
import { LiegenschaftsData } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeIconButton from '../../Atoms/BeeIconButton';
import { ImageType } from '../../Pages/AlphaPages/AlphaLiegenschaftVerwaltung';
import LiegenschaftDialog from '../Dialogs/LiegenschaftDialog';
import './AlphaPropertyDetail.scss';
import { AuthUserType } from '../../../Helper/ApiHelper/LoginNetworkHelper';
import BeeListbox from '../../Atoms/BeeListbox';
import _ from 'lodash';

type AlphaPropertyDetailProps = {
  property: LiegenschaftsData;
  image?: ImageType;
  provider: AuthUserType[];
  participationLookup: Map<string, string>;
  selectedProvider: AuthUserType[];
  onSelectProvider: (e: any) => void; //function
};

export default function AlphaPropertyDetail({
  property,
  image,
  provider,
  participationLookup,
  selectedProvider,
  onSelectProvider
}: AlphaPropertyDetailProps) {
  const [v_LiegenschaftDialog, setV_LiegenschaftDialog] =
    useState<boolean>(false);
  const toast = useRef<Toast>(null);

  ///////////////////////
  ///    Callbacks    ///
  ///////////////////////

  ///////////////////////
  /// VIEW COMPONENTS ///
  ///////////////////////

  function injectPropertyHeadline(property: LiegenschaftsData) {
    return (
      <>
        <BeeContentHeadline
          label={property.nummer + ' - ' + property.name}
          headline={'h1'}
          type={'primary'}
        />
        <BeeIconButton
          iconClass={'icon-search'}
          rounded={true}
          disabled={false}
          type={'primary'}
          onClick={(e) => setV_LiegenschaftDialog(true)}
        />
      </>
    );
  }

  function injectPropertyInfo(property: LiegenschaftsData) {
    return (
      <>
        {property.adresse ? (
          <>
            <div>
              {property.adresse.strasse} {property.adresse.hausnummer}
            </div>
            <div>
              {property.adresse.postleitzahl} {property.adresse.stadt}
            </div>
            <div>{property.adresse.region}</div>
          </>
        ) : null}
      </>
    );
  }

  function optionRenderTemplate(option: any) {
    console.log(option);
    console.log(participationLookup);
    return (
      <div>
        {option ? (
          <>
            <div>
              <span>
                {option.organisation +
                  ' - ' +
                  option.firstname +
                  ' ' +
                  option.lastname}
              </span>
            </div>
            {option.info ? (
              <div className="listbox-info">
                <i className={'pi pi-info'}></i>
                <span>{option.info}</span>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    );
  }

  function injectDLSelection() {
    return (
      <div>
        <BeeListbox
          selectedValues={selectedProvider}
          grouped={false}
          options={provider}
          label={'Dienstleister'}
          filterLabel={'organisation'}
          optionRenderTemplate={(option: any) => {
            return optionRenderTemplate(option); //FIXME hier noch die icons für vollständigkeit etc mir rein rendern!!
          }}
          required={false}
          disabled={false}
          readOnly={false}
          onChangeSelectedOptions={(e) => onSelectProvider(e)}
        />
      </div>
    );
  }

  function injectPropertydialog() {
    return (
      <LiegenschaftDialog
        property={property}
        visible={v_LiegenschaftDialog}
        onHide={() => setV_LiegenschaftDialog(false)}
      />
    );
  }

  function injectPayload() {
    if (property) {
      return (
        <div className={'grid alpha-property-detail'}>
          <div className={'col property-detail'}>
            <div className={'property-detail-headline'}>
              {injectPropertyHeadline(property)}{' '}
            </div>
            <div className={'property-detail-info'}>
              {injectPropertyInfo(property)}
            </div>
          </div>
          <div
            className={
              'property-detail-dl-selection col sm:col-6 md:col-6 lg:col-4'
            }
          >
            {injectDLSelection()}
          </div>
          {v_LiegenschaftDialog ? injectPropertydialog() : null}
          <Toast ref={toast} position={'top-right'} />
        </div>
      );
    } else {
      return <div>FIXME FALLBACK</div>;
    }
  }

  return injectPayload();
}
