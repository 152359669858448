import FileSaver from 'file-saver';
import _ from 'lodash';
import { TimelineStep } from '../../UI/Atoms/BeeTimeline';
import {
  ALPHA_DL_INCOMPLETE_PRICES,
  ALPHA_DL_NOT_IN_MARKETAREA,
  ALPHA_DL_NO_PRICE,
  DEFAULT_CALCULATION_MULTIPLIER,
  OUTPUT_FILE_HEADLINE,
  tags
} from '../Statics/Constants';
import { EpPosition, LV } from '../ApiHelper/LvEpNetworkHelper';
import { calculateTreeDepth } from './ExcelExportTooling';
import {
  GebaeudeData,
  LiegenschaftsData
} from '../ApiHelper/LiegenschaftenNetworkHelper';
import { AuthUserType } from '../ApiHelper/LoginNetworkHelper';
import { CalcLvPosition } from '../../UI/Molecules/DownloadCombinedPositions';

const ExcelJS = require('exceljs');

const excelDlSheetName = 'Liegenschaftsübersicht';
const excelDlPropNumHeader = 'Liegenschaftsnummer';
const excelDlPropNameHeader = 'Liegenschaftsname';
const excelDlRegionHeader = 'Bundesland';
const excelDlCityHeader = 'Ort';
const excelDlStreetHeader = 'Straße';
const excelDlStreetNumHeader = 'Hausnummer';
const excelDlNumberOfBuildingsHeader = 'Anzahl Gebäude';
const excelDlBGFHeader = 'BGF';
const excelDlLvlOfTechHeader = 'Technisierungsgrad';
const excelDlYearHeader = 'Baujahr';
const excelDlPosNrHeader = 'PosNr';
const excelDlAksHeader = 'AKSNummer';
const excelDlAmountHeader = 'Masse';
const excelDlNameHeader = 'Bezeichnung';
const excelDlOptionalPosHeader = 'Bedarfsposition';
const excelDlOptionalPosActiveHeader = 'BedarfspositionAktiv';
const excelDlEpHeader = 'EP-Code';
const excelDlTagHeader = 'Tag';
const excelDlUnitHeader = 'Einheit';
const excelDlPretextHeader = 'Vortext';
const excelDlPosttextHeader = 'Nachtext';

//colindices
const propNumColIndex = 1;
const propNameColIndex = 2;
const RegionColIndex = 3;
const CityColIndex = 4;
const StreetColIndex = 5;
const StreetNumColIndex = 6;
const NumberOfBuildingsColIndex = 7;
const BGFColIndex = 8;
const LvlOfTechColIndex = 9;
const YearColIndex = 10;
const PosNrColIndex = 11;
const AksColIndex = 12;
const amountColIndex = 13;
const NameColIndex = 14;
const OptionalPosColIndex = 15;
const OptionalPosActiveColIndex = 16;
const EpColIndex = 17;
const TagColIndex = 18;
const initEpCatColIndex = 19;
const initServiceProvCol = 20;

export function exportDlPropertiesExcel(
  properties: LiegenschaftsData[],
  lvs: LV[],
  bLookup: Map<string, GebaeudeData>,
  phase: TimelineStep,
  epLookup: Map<string, EpPosition>,
  epCategoriesLookup: Map<
    string,
    { title: string; number: number; depth: number }[]
  >,
  epTree: any,
  serviceProvider?: AuthUserType[], //FIXME neu!!
  exportPrices?: Map<string, CalcLvPosition[]> //FIXME neu!!
) {
  return new Promise<void>(async (resolve, reject) => {
    const workbook = new ExcelJS.Workbook();
    //metadata
    workbook.creator = '';
    workbook.lastModifiedBy = '';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();
    workbook.views = [
      {
        x: 0,
        y: 0,
        width: 10000,
        height: 20000,
        firstSheet: 0,
        activeTab: 0,
        visibility: 'visible'
      }
    ];
    // Force workbook calculation on load
    workbook.calcProperties.fullCalcOnLoad = true;

    //create Sheets
    let sheetDlProp = workbook.addWorksheet(excelDlSheetName, {
      properties: { defaultColWidth: 14 }
    });

    // make worksheets visible
    sheetDlProp.state = 'visible';

    const treeDepth = calculateTreeDepth(epTree) - 1;

    //col widhts
    sheetDlProp = setUpCols(treeDepth, sheetDlProp, serviceProvider);
    //col header
    sheetDlProp = createSheetDlPropertiesHeader(
      treeDepth,
      sheetDlProp,
      phase,
      serviceProvider
    );
    //content
    createAlphaClusterContent(
      properties,
      lvs,
      bLookup,
      epCategoriesLookup,
      treeDepth,
      epLookup,
      sheetDlProp,
      serviceProvider,
      exportPrices
    );

    // write to a file

    workbook.xlsx
      .writeBuffer()
      .then((buffer: any) => {
        FileSaver.saveAs(
          new Blob([buffer]),
          `${Date.now()}_Cluster-Preisspiegel.xlsx`
        );
        resolve();
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}
function setUpCols(
  treeDepth: number,
  worksheet: any,
  serviceProvider?: AuthUserType[]
) {
  /*   columnwidths/styles */
  for (let i = 0; i < treeDepth; i++) {
    worksheet.getColumn(initEpCatColIndex + i).width = 30;
  }

  worksheet.getColumn(propNumColIndex).width = 18;
  worksheet.getColumn(StreetNumColIndex).width = 10;
  worksheet.getColumn(propNameColIndex).width = 20;
  worksheet.getColumn(YearColIndex).width = 10;
  worksheet.getColumn(AksColIndex).width = 20;
  worksheet.getColumn(amountColIndex).width = 10;
  worksheet.getColumn(NameColIndex).width = 25;
  worksheet.getColumn(OptionalPosActiveColIndex).width = 17;
  worksheet.getColumn(getColIndexForUnit(treeDepth)).width = 10;
  worksheet.getColumn(getColIndexForPretext(treeDepth)).width = 20;
  worksheet.getColumn(getColIndexForPosttext(treeDepth)).width = 20;
  if (serviceProvider && serviceProvider.length > 0) {
    for (let index = 0; index < serviceProvider.length; index++) {
      worksheet.getColumn(getColIndexInitDl(treeDepth) + index).width = 20;
      worksheet.getColumn(getColIndexInitDl(treeDepth) + index).numFmt =
        '#,###0.000 [$€-1]';
      worksheet.getColumn(getColIndexInitDl(treeDepth) + index).alignment = {
        horizontal: 'right'
      };
    }
  }

  return worksheet;
}

function createSheetDlPropertiesHeader(
  treeDepth: number,
  worksheet: any,
  phase: TimelineStep,
  serviceProvider?: AuthUserType[]
) {
  /*  metainfo */
  const metaInfoRow = worksheet.addRow();
  metaInfoRow.getCell(1).value = OUTPUT_FILE_HEADLINE;
  metaInfoRow.getCell(6).value = 'Phase: ' + phase.title;
  metaInfoRow.getCell(8).value =
    'Datum: ' + new Date().toLocaleDateString('de-DE');

  //empty row
  const emptyRow = worksheet.addRow();
  emptyRow.getCell(1).value = '';

  //header for table
  const headerRow = worksheet.addRow();

  //set values
  headerRow.getCell(propNumColIndex).value = excelDlPropNumHeader;
  headerRow.getCell(propNameColIndex).value = excelDlPropNameHeader;
  headerRow.getCell(RegionColIndex).value = excelDlRegionHeader;
  headerRow.getCell(CityColIndex).value = excelDlCityHeader;
  headerRow.getCell(StreetColIndex).value = excelDlStreetHeader;
  headerRow.getCell(StreetNumColIndex).value = excelDlStreetNumHeader;
  headerRow.getCell(NumberOfBuildingsColIndex).value =
    excelDlNumberOfBuildingsHeader;
  headerRow.getCell(BGFColIndex).value = excelDlBGFHeader;
  headerRow.getCell(LvlOfTechColIndex).value = excelDlLvlOfTechHeader;
  headerRow.getCell(YearColIndex).value = excelDlYearHeader;
  headerRow.getCell(PosNrColIndex).value = excelDlPosNrHeader;
  headerRow.getCell(AksColIndex).value = excelDlAksHeader;
  headerRow.getCell(amountColIndex).value = excelDlAmountHeader;
  headerRow.getCell(NameColIndex).value = excelDlNameHeader;
  headerRow.getCell(OptionalPosColIndex).value = excelDlOptionalPosHeader;
  headerRow.getCell(OptionalPosActiveColIndex).value =
    excelDlOptionalPosActiveHeader;
  headerRow.getCell(EpColIndex).value = excelDlEpHeader;
  headerRow.getCell(TagColIndex).value = excelDlTagHeader;

  for (let index = 0; index < treeDepth; index++) {
    headerRow.getCell(initEpCatColIndex + index).value =
      'Kategorie ' + (index + 1);
  }

  headerRow.getCell(getColIndexForUnit(treeDepth)).value = excelDlUnitHeader;
  headerRow.getCell(getColIndexForPretext(treeDepth)).value =
    excelDlPretextHeader;
  headerRow.getCell(getColIndexForPosttext(treeDepth)).value =
    excelDlPosttextHeader;

  if (serviceProvider && serviceProvider.length > 0) {
    for (let index = 0; index < serviceProvider.length; index++) {
      headerRow.getCell(getColIndexInitDl(treeDepth) + index).value =
        serviceProvider[index].organisation;
    }
  }

  //style header
  const backgroundColorHeadline = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--primary-color');

  const colorHeadline = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--primary-color-text');

  metaInfoRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'FFFFFF'
    }
  };
  metaInfoRow.font = {
    bold: true,
    color: { argb: '000000' }
  };

  emptyRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'FFFFFF'
    },
    bgColor: {
      argb: 'FFFFFF'
    }
  };

  headerRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: backgroundColorHeadline.replace('#', '').replace(' ', '')
    }
  };
  headerRow.font = {
    bold: true,
    color: { argb: 'FFFFFF' }
  };
  headerRow.height = 50;
  headerRow.border = {
    bottom: { style: 'thick', color: { argb: 'FFFFFFFF' } }
  };

  return worksheet;
}

function createAlphaClusterContent(
  properties: LiegenschaftsData[],
  lvs: LV[],
  bLookup: Map<string, GebaeudeData>,
  epCategoriesLookup: Map<
    string,
    { title: string; number: number; depth: number }[]
  >,
  treeDepth: number,
  epLookup: Map<string, EpPosition> | undefined,
  worksheet: any,
  serviceProvider?: AuthUserType[],
  exportPrices?: Map<string, CalcLvPosition[]>
) {
  //loop through all lvs
  if (lvs && lvs.length > 0) {
    for (let i = 0; i < lvs.length; i++) {
      if (lvs[i] && lvs[i].data && lvs[i].data.length > 0) {
        const lvPositions = lvs[i].data;
        const lvInfos = lvs[i].info;
        const propId = lvInfos?.propertyId;
        const property = _.find(properties, function (prop) {
          return prop.id === propId;
        });
        const buildingId = property?.gebaeudeIds
          ? property?.gebaeudeIds[0]
          : null;
        const building = buildingId ? bLookup.get(buildingId) : null;

        for (let j = 0; j < lvPositions.length; j++) {
          //create new row for every lv position
          const lvPos = lvPositions[j];
          const newRow = worksheet.addRow();

          //fill property data
          if (propId) {
            if (property) {
              newRow.getCell(propNumColIndex).value = property.nummer;
              newRow.getCell(propNameColIndex).value = property.name;
              newRow.getCell(RegionColIndex).value = property.adresse?.region;
              newRow.getCell(CityColIndex).value = property.adresse?.stadt;
              newRow.getCell(StreetColIndex).value = property.adresse?.strasse;
              newRow.getCell(StreetNumColIndex).value =
                property.adresse?.hausnummer;
              newRow.getCell(NumberOfBuildingsColIndex).value =
                property?.gebaeudeIds ? property.gebaeudeIds.length : 0;
              newRow.getCell(BGFColIndex).value = building?.bruttoGrundflaeche;
              newRow.getCell(LvlOfTechColIndex).value =
                building?.technisierungsgrad;
              newRow.getCell(YearColIndex).value = building?.baujahr;
            }
          }

          //Fill lv data
          const epCode = lvPos.epCode;
          newRow.getCell(PosNrColIndex).value = lvPos.serialNumber;
          //fixme
          newRow.getCell(AksColIndex).value = lvPos.identificationNumber;
          newRow.getCell(amountColIndex).value = lvPos.amount;
          newRow.getCell(NameColIndex).value = lvPos.description;
          newRow.getCell(OptionalPosColIndex).value = lvPos.optionalPosition
            ? 'Bedarfsposition'
            : '';
          newRow.getCell(OptionalPosActiveColIndex).value =
            lvPos.optionalPosition
              ? lvPos.optionalPositionActive
                ? 'Ja'
                : 'Nein'
              : null;
          newRow.getCell(EpColIndex).value = epCode;

          //fill position catalog data
          if (epCode && epLookup) {
            const epEntry = epLookup.get(epCode);
            if (epEntry) {
              const sliceCode = epEntry.sliceTag;

              const sliceTag: any =
                sliceCode && tags[sliceCode as keyof typeof tags]
                  ? tags[sliceCode as keyof typeof tags]
                  : null;

              newRow.getCell(TagColIndex).value = sliceTag;
              if (epCategoriesLookup && epCode) {
                const epCatTitles = epCategoriesLookup.get(epCode);
                if (epCatTitles) {
                  for (let k = 0; k < epCatTitles.length; k++) {
                    newRow.getCell(
                      initEpCatColIndex + epCatTitles[k].depth - 1
                    ).value = `[${epCatTitles[k].number}] ${epCatTitles[k].title}`;
                  }
                }
              }

              newRow.getCell(getColIndexForUnit(treeDepth)).value =
                epEntry.unit;
              newRow.getCell(getColIndexForPretext(treeDepth)).value =
                epEntry.preText;
              newRow.getCell(getColIndexForPosttext(treeDepth)).value =
                epEntry.postText;

              //fill DL prices
              if (serviceProvider && serviceProvider.length > 0) {
                for (let index = 0; index < serviceProvider.length; index++) {
                  const priceEntry = exportPrices?.get(lvPos.id);

                  const entry = _.find(priceEntry, function (o) {
                    return o.providerId === serviceProvider[index].id;
                  });

                  if (entry?.notInMarketArea) {
                    newRow.getCell(getColIndexInitDl(treeDepth) + index).value =
                      ALPHA_DL_NOT_IN_MARKETAREA;
                  } else if (entry?.noPriceGiven) {
                    newRow.getCell(getColIndexInitDl(treeDepth) + index).value =
                      ALPHA_DL_NO_PRICE;
                  } else if (entry?.gp) {
                    newRow.getCell(getColIndexInitDl(treeDepth) + index).value =
                      _.divide(entry.gp, DEFAULT_CALCULATION_MULTIPLIER);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
/////////////////
///// Helper ////
/////////////////

function getColIndexForUnit(treedepth: number) {
  return initEpCatColIndex + treedepth;
}
function getColIndexForPretext(treedepth: number) {
  return initEpCatColIndex + treedepth + 1;
}
function getColIndexForPosttext(treedepth: number) {
  return initEpCatColIndex + treedepth + 2;
}
function getColIndexInitDl(treedepth: number) {
  return initEpCatColIndex + treedepth + 3;
}
