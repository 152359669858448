import { ConfirmDialog } from 'primereact/confirmdialog';
import { useId } from 'react-id-generator';

import './BeeConfirmDialog.scss';

type BeeConfirmDialogProps = {
  visible: boolean;
  message: string;
  messageIcon?: string;
  acceptIcon?: string;
  rejectIcon?: string;
  acceptLabel: string;
  rejectLabel: string;
  header: string;
  type: string;
  onAccept: () => void;
  onReject: () => void;
  onHide: () => void;
};

export function BeeConfirmDialog({
  visible,
  message,
  messageIcon,
  acceptIcon,
  rejectIcon,
  acceptLabel,
  rejectLabel,
  header,
  type,
  onAccept,
  onReject,
  onHide
}: BeeConfirmDialogProps) {
  const currentId = useId(1, 'bee-confirm-dialog')[0];

  //classNames
  const typeClassNames =
    type === 'primary'
      ? ' bee-confirm-dialog-primary'
      : type === 'secondary'
      ? ' bee-confirm-dialog-secondary'
      : type === 'default'
      ? ' bee-confirm-dialog-default'
      : ' bee-confirm-dialog-default';
  const classNames = 'bee-confirm-dialog' + typeClassNames;

  function hide() {
    if (onHide) {
      onHide();
    }
  }

  function accept() {
    if (onAccept) {
      onAccept();
    }
  }
  function reject() {
    if (onReject) {
      onReject();
    }
  }

  return (
    <ConfirmDialog
      id={currentId}
      className={classNames}
      visible={visible}
      message={message}
      header={header}
      icon={messageIcon}
      acceptIcon={acceptIcon ? acceptIcon : null}
      rejectIcon={rejectIcon ? rejectIcon : null}
      acceptLabel={acceptLabel}
      rejectLabel={rejectLabel}
      onHide={() => hide()}
      accept={() => accept()}
      reject={() => reject()}
    />
  );
}

export default BeeConfirmDialog;
