import { Button } from 'primereact/button';
import React from 'react';
import { useId } from 'react-id-generator';
//icons
import 'primeicons/primeicons.css';
import './BeeIconButton.scss';

type BeeIconButtonProps = {
  id?: string;
  iconClass: string;
  rounded?: boolean;
  disabled?: boolean;
  type?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export function BeeIconButton({
  id,
  iconClass,
  rounded,
  disabled,
  type,
  onClick
}: BeeIconButtonProps) {
  const genId = useId(1, 'bee-icon-button-')[0];
  const currentId = id ? id : genId;

  //classNames
  const typeClassNames =
    type === 'primary'
      ? 'bee-icn-btn-primary'
      : type === 'secondary'
      ? 'p-button-secondary bee-icn-btn-secondary'
      : type === 'danger'
      ? 'p-button-danger bee-icn-btn-danger'
      : type === 'light'
      ? 'bee-icn-btn-light'
      : 'bee-icn-btn-primary';

  const isRoundedClassNames = rounded ? ' p-button-rounded' : '';
  const classNames = typeClassNames + isRoundedClassNames;

  function clicked(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (!disabled && onClick) {
      onClick(e);
    }
  }

  return (
    <Button
      disabled={disabled}
      id={currentId}
      className={classNames}
      icon={iconClass}
      onClick={(e) => clicked(e)}
    />
  );
}

export default BeeIconButton;
