import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { useRef, useState } from 'react';
import { LiegenschaftsData } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import {
  SERVER_RESPONSE_ERROR,
  SERVER_RESPONSE_PENDING
} from '../../../Helper/Statics/Constants';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeIcon from '../../Atoms/BeeIcon';
import BeeIconButton from '../../Atoms/BeeIconButton';
import BeeImage from '../../Atoms/BeeImage';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import BeeSwitch from '../../Atoms/BeeSwitch';
import { ImageType } from '../../Pages/AlphaPages/AlphaLiegenschaftVerwaltung';
import BeeConfirmDialog from '../Dialogs/BeeConfirmDialog';
import LiegenschaftDialog from '../Dialogs/LiegenschaftDialog';
import { FaktorKeyValueStore } from './DlBundeslandfaktoren';
import './DLPropertyDetail.scss';

type DLPropertyDetailProps = {
  property?: LiegenschaftsData;
  image?: ImageType;
  marktgebietCheck: FaktorKeyValueStore;
  readOnly: boolean;
  onChangeMarktgebietCheck: (value: boolean) => void;
};

export default function DLPropertyDetail({
  property,
  image,
  marktgebietCheck,
  readOnly,
  onChangeMarktgebietCheck
}: DLPropertyDetailProps) {
  const [v_LiegenschaftDialog, setV_LiegenschaftDialog] =
    useState<boolean>(false);
  const [v_MarktgebietChangeDialog, setV_MarktgebietChangeDialog] =
    useState<boolean>(false);
  //refs
  const toast = useRef<Toast>(null);

  ///////////////////////
  ///    Callbacks    ///
  ///////////////////////

  function changeMarktgebietCheck(value: boolean) {
    if (onChangeMarktgebietCheck) {
      onChangeMarktgebietCheck(value);
    }
  }

  ///////////////////////
  /// VIEW COMPONENTS ///
  ///////////////////////

  function injectPropertyImage(property: LiegenschaftsData) {
    if (image && property.hauptBildId) {
      return (
        <BeeImage
          img_url={image.url}
          img_key={image.key}
          alt={image.alt}
          title={image.title}
          copyright={image.copyright}
          isSecured={image.secured ? image.secured : false}
          showDefault={false}
          showPlaceholder={
            image.showPlaceholder ? image.showPlaceholder : false
          }
        />
      );
    } else if (property.hauptBildId) {
      //show loader
      return (
        <BeeImage
          img_url={''}
          img_key={''}
          alt={''}
          title={''}
          copyright={''}
          isSecured={false}
          showDefault={false}
          showPlaceholder={true}
        />
      );
    } else {
      //show default property image
      return (
        <BeeImage
          img_url={''}
          img_key={''}
          alt={''}
          title={''}
          copyright={''}
          isSecured={false}
          showDefault={true}
          showPlaceholder={false}
        />
      );
    }
  }

  function injectPropertyHeadline(property: LiegenschaftsData) {
    return (
      <>
        <BeeContentHeadline
          label={property.nummer + ' - ' + property.name}
          headline={'h1'}
          type={'primary'}
        />
        <BeeIconButton
          iconClass={'icon-search'}
          rounded={true}
          disabled={false}
          type={'primary'}
          onClick={(e) => setV_LiegenschaftDialog(true)}
        />
      </>
    );
  }

  function injectPropertyInfo(property: LiegenschaftsData) {
    return (
      <>
        {property.adresse ? (
          <>
            <div>
              {property.adresse.strasse} {property.adresse.hausnummer}
            </div>
            <div>
              {property.adresse.postleitzahl} {property.adresse.stadt}
            </div>
            <div>{property.adresse.region}</div>
          </>
        ) : null}
      </>
    );
  }

  function injectMarktgebietSwitch() {
    if (marktgebietCheck) {
      return (
        <>
          <BeeSwitch
            value={marktgebietCheck.value === 'true'}
            disabled={false}
            formstate={'primary'}
            stateFalse={'Diese Liegenschaft liegt in meinem Marktgebiet'}
            stateTrue={'Diese Liegenschaft liegt in meinem Marktgebiet'}
            readOnly={readOnly}
            onChange={(e) => {
              if (marktgebietCheck.process !== SERVER_RESPONSE_PENDING) {
                if (marktgebietCheck.value === 'true') {
                  setV_MarktgebietChangeDialog(true);
                } else {
                  changeMarktgebietCheck(e.value);
                }
              }
            }}
          />

          {marktgebietCheck.process === SERVER_RESPONSE_PENDING
            ? injectSaveProgress()
            : null}
          {marktgebietCheck.process === SERVER_RESPONSE_ERROR
            ? injectSaveError()
            : null}
        </>
      );
    }
    return null;
  }

  function injectSaveProgress() {
    return (
      <span className={'load-factor pending'}>
        <BeeLoadingSpinner strokeWidth={'3'} type={'primary'} />
      </span>
    );
  }

  function injectSaveError() {
    return (
      <>
        <span className={'status-factorChange error  tooltip-factor-bl'}>
          <BeeIcon iconClass={'pi pi-exclamation-triangle'} type={'primary'} />
          <span>{'Fehler'}</span>
        </span>
        <Tooltip target=".tooltip-factor-bl" mouseTrack mouseTrackLeft={10}>
          {
            'Die Änderung konnte nicht gespeichert werden. Bitte prüfen Sie Ihre Internetverbindung.'
          }
        </Tooltip>
      </>
    );
  }

  function injectPropertydialog() {
    return (
      <LiegenschaftDialog
        property={property}
        visible={v_LiegenschaftDialog}
        onHide={() => setV_LiegenschaftDialog(false)}
      />
    );
  }

  function injectMarktgebietChangeDialog() {
    return (
      <BeeConfirmDialog
        visible={v_MarktgebietChangeDialog}
        message={
          'Sind sie sicher, dass Sie diese Liegenschaft aus Ihrem Marktgebiet ausschließen möchten? Sie wird dadurch aus Ihrem Angebot entfernt.'
        }
        messageIcon={'pi pi-exclaimation'}
        acceptLabel={'Liegenschaft ausschließen'}
        rejectLabel={'Abbrechen'}
        header={'Liegenschaft aus dem Marktgebiet ausschließen'}
        type={'primary'}
        onAccept={
          () => changeMarktgebietCheck(false) //fixme readonly aktivieren
        }
        onReject={() => setV_MarktgebietChangeDialog(false)}
        onHide={() => setV_MarktgebietChangeDialog(false)}
      />
    );
  }

  function injectPayload() {
    if (property) {
      return (
        <div className={'grid dl-property-detail'}>
          <div className={'col lg:col-6 grid'}>
            <div className={'col-12  property-detail-headline'}>
              {injectPropertyHeadline(property)}{' '}
            </div>
            <div className={'col-12 property-detail-info'}>
              {injectPropertyInfo(property)}
            </div>
            <div className={'col-12 property-detail-switch'}>
              {injectMarktgebietSwitch()}
            </div>
          </div>

          <div className={'col col-6 md-invisible  property-detail-img'}>
            {injectPropertyImage(property)}
          </div>

          <> {v_LiegenschaftDialog ? injectPropertydialog() : null}</>
          <>
            {' '}
            {v_MarktgebietChangeDialog ? injectMarktgebietChangeDialog() : null}
          </>
          <Toast ref={toast} position={'top-right'} />
        </div>
      );
    } else {
      return <div>Keine Gebäudedaten gefunden</div>; //FIXME
    }
  }

  return injectPayload();
}
