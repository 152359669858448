import {
  InputNumber,
  InputNumberValueChangeParams
} from 'primereact/inputnumber';
import { useId } from 'react-id-generator';
import { EMPTY_FIELD_PLACEHOLDER } from '../../Helper/Statics/Constants';
import './BeeNumberSelect.scss';
import BeeTextInput from './BeeTextInput';

type BeeNumberSelectProps = {
  label?: string;
  value?: number;
  minFractionDigits?: number;
  maxFractionDigits?: number;
  stepSize: number;
  disabled?: boolean;
  grouping?: boolean;
  formstate?: string;
  readOnly?: boolean;
  required?: boolean;
  onChange: (e: InputNumberValueChangeParams) => void;
  onFocusOut: (e: number | undefined) => void;
};

export function BeeNumberSelect({
  label,
  value,
  minFractionDigits,
  maxFractionDigits,
  stepSize,
  disabled,
  grouping,
  formstate,
  readOnly,
  required,
  onChange,
  onFocusOut
}: BeeNumberSelectProps) {
  const currentId = useId(1, 'bee-num-select-')[0];
  const inputId = currentId + 'input';

  //classNames
  const formStateClassnames =
    formstate === 'valid'
      ? ' bee-num-select-valid'
      : formstate === 'error'
      ? ' bee-num-select-error'
      : formstate === 'neutral'
      ? ' bee-num-select-neutral'
      : ' bee-num-select-neutral';
  let readOnlyClassnames = '';
  if (readOnly) {
    readOnlyClassnames = ' bee-num-select-readOnly';
    disabled = true;
  }
  const containerClassnames = 'p-float-label bee-num-select-container';
  const classNames =
    containerClassnames + formStateClassnames + readOnlyClassnames;
  const requiredClassName = 'bee-num-select-label-required';
  const requiredStarClassName = 'bee-num-select-label-required-star';

  function change(e: InputNumberValueChangeParams) {
    if (!disabled && onChange) {
      onChange(e);
    }
  }
  function focusOut(e: number | undefined) {
    if (!disabled && onFocusOut) {
      onFocusOut(e);
    }
  }

  const renderPlaceholder = () => {
    return (
      <BeeTextInput
        label={label}
        value={EMPTY_FIELD_PLACEHOLDER}
        disabled={disabled}
        readOnly={true}
        required={required}
      />
    );
  };

  return (
    <>
      {readOnly && !value ? (
        renderPlaceholder()
      ) : (
        <span id={currentId} className={classNames}>
          <InputNumber
            id={inputId}
            value={value}
            disabled={disabled}
            mode="decimal"
            useGrouping={grouping}
            minFractionDigits={minFractionDigits}
            maxFractionDigits={maxFractionDigits}
            showButtons
            step={stepSize}
            onValueChange={(e) => change(e)}
            onBlur={(e) =>
              e && e.target && e.target.value
                ? focusOut(parseInt(e.target.value))
                : focusOut(undefined)
            }
          />
          {label ? (
            <label htmlFor={inputId}>
              {label}
              {required && label ? (
                <span className={requiredClassName}>
                  <span className={requiredStarClassName}> *</span>
                </span>
              ) : null}
            </label>
          ) : null}
        </span>
      )}
    </>
  );
}

export default BeeNumberSelect;
