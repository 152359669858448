import { useId } from 'react-id-generator';

import BeeGalleryImage from '../Atoms/BeeGalleryImage';
import { ImageType } from '../Pages/AlphaPages/AlphaLiegenschaftVerwaltung';
import './BeeFlatImageGallery.scss';

type BeeFlatImageGalleryProps = {
  images: any[];
  showAddOption: boolean;
  activateHover: boolean;
  readOnly: boolean;

  onClick?: (itemId: string) => void;
  onRemove?: (itemId: string) => void;
  onAdd?: () => void;
};

export function BeeFlatImageGallery({
  images,
  showAddOption,
  activateHover,
  readOnly,
  onClick,
  onRemove,
  onAdd
}: BeeFlatImageGalleryProps) {
  const currentId = useId(1, 'bee-flat-img-gallery-')[0];

  //classNames
  const imgEntryClassNames =
    'bee-flat-img-gallery-entry col-4 sm:col-3 md:col-2 lg:col-2 xl:col-1';
  const classNames = 'grid bee-flat-img-gallery';

  function onImageClick(id: string) {
    if (onClick) {
      onClick(id);
    }
  }

  function onImageRemove(id: string) {
    if (onRemove) {
      onRemove(id);
    }
  }

  function onImageAdd() {
    if (onAdd) {
      onAdd();
    }
  }

  const injectImage = (data: ImageType | null) => {
    const id = data ? data.id : '-1';
    return (
      <div className={imgEntryClassNames} key={'img_' + id}>
        <BeeGalleryImage
          readOnly={readOnly}
          activateHover={activateHover}
          imageUrl={data ? data.url : null}
          imageKey={data ? data.key : null}
          imageAlt={data ? data.alt : null}
          imageTitle={data ? data.title : null}
          imageCopyright={data ? data.copyright : null}
          isImageSecured={data ? data.secured : false}
          showPlaceHolder={data ? data.showPlaceholder : true}
          onClick={() => onImageClick(id)}
          onRemove={() => onImageRemove(id)}
          onAdd={() => onImageAdd()}
        />
      </div>
    );
  };

  const injectEntries = (images: ImageType[]) => {
    if (images) {
      let payload = [];
      if (showAddOption) {
        payload.push(injectImage(null));
      }
      for (let i = 0; i < images.length; i++) {
        const img = images[i];
        payload.push(injectImage(img));
      }
      return payload;
    }
  };

  return (
    <div id={currentId} className={classNames}>
      {injectEntries(images)}
    </div>
  );
}

export default BeeFlatImageGallery;
