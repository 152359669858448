import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import BeeIconButton from '../../Atoms/BeeIconButton';

import './AlphaFilterKonfigurationTable.scss';
import { BeeClusterFilter } from './BeeMultiListbox';

type AlphaFilterKonfigurationTableProps = {
  data: BeeClusterFilter[];
  disabled: boolean;
  readOnly: boolean;
  onDelete: (id: string) => void;
  onLoadConfig: (id: string) => void;
};

export default function AlphaFilterKonfigurationTable({
  data,
  disabled,
  readOnly,
  onDelete,
  onLoadConfig
}: AlphaFilterKonfigurationTableProps) {
  function loadConfig(id: string) {
    if (onLoadConfig && !disabled && !readOnly) {
      onLoadConfig(id);
    }
  }

  function deleteEntry(id: string) {
    if (onDelete && !disabled && !readOnly) {
      onDelete(id);
    }
  }

  function configTemplate(rowData: BeeClusterFilter) {
    if (rowData) {
      return <p headername={'Filterkonfigurationen'}>{rowData.title}</p>;
    }
  }

  function actionTemplate(rowData: BeeClusterFilter) {
    const rowDataId = rowData.id;
    if (rowData) {
      return (
        <div headername={''}>
          <BeeIconButton
            iconClass={'pi pi-play'}
            rounded={false}
            disabled={disabled || readOnly}
            type={'secondary'}
            onClick={() => loadConfig(rowDataId)}
          />
          <BeeIconButton
            iconClass={'pi pi-trash'}
            rounded={false}
            disabled={disabled || readOnly}
            type={'danger'}
            onClick={() => deleteEntry(rowDataId)}
          />
        </div>
      );
    }
  }

  return (
    <div>
      <div className={'alpha-filter-config-table'}>
        <DataTable
          value={data}
          className={'p-datatable-sm'}
          emptyMessage={'Keine Filterkonfiguration gespeichert.'}
        >
          <Column
            className={'config-name-col'}
            body={configTemplate}
            header={'Filterkonfigurationen'}
          />
          <Column className={'action-col'} body={actionTemplate} header={''} />
        </DataTable>
      </div>
    </div>
  );
}
