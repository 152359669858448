import { InputSwitch, InputSwitchChangeParams } from 'primereact/inputswitch';
import { useId } from 'react-id-generator';
import './BeeSwitch.scss';

type BeeSwitchProps = {
  id?: string;
  label?: string;
  value: any;
  disabled?: boolean;
  formstate?: string;
  stateFalse?: string;
  stateTrue?: string;
  readOnly?: boolean;
  required?: boolean;
  onChange: (e: InputSwitchChangeParams) => void;
};

export function BeeSwitch({
  id,
  label,
  value,
  disabled,
  formstate,
  stateFalse,
  stateTrue,
  required,
  readOnly,
  onChange
}: BeeSwitchProps) {
  const genId = useId(1, 'bee-switch-')[0];
  const currentId = id ? id : genId;
  const inputId = currentId + 'input';

  //classNames
  const formStateClassnames =
    formstate === 'valid'
      ? ' bee-switch-valid'
      : formstate === 'error'
      ? ' bee-switch-error'
      : formstate === 'neutral'
      ? ' bee-switch-neutral'
      : ' bee-switch-neutral';

  let readOnlyClassnames = '';
  if (readOnly) {
    readOnlyClassnames = ' bee-switch-readOnly';
    disabled = true;
  }

  const disabledClassNames = disabled ? ' bee-switch-disabled' : '';
  const classNames =
    'bee-switch' +
    formStateClassnames +
    readOnlyClassnames +
    disabledClassNames;

  const inputClassNames = 'bee-switch-input';
  const labelClassNames = label ? 'bee-switch-label' : '';
  const stateLabelClassName = 'bee-switch-value';
  const requiredClassName = 'bee-switch-label-required';
  const requiredStarClassName = 'bee-switch-label-required-star';

  function change(e: InputSwitchChangeParams) {
    if (!disabled && onChange) {
      onChange(e);
    }
  }

  return (
    <div id={currentId} className={classNames}>
      <div className={inputClassNames}>
        <InputSwitch
          inputId={inputId}
          checked={value}
          onChange={(e) => change(e)}
          disabled={disabled}
        />
        <label className={stateLabelClassName} htmlFor={inputId}>
          {value === true
            ? stateTrue
            : value === false
            ? stateFalse
            : stateFalse}
        </label>
      </div>
      {label ? (
        <label className={labelClassNames} htmlFor={inputId}>
          {label}
          {required && label ? (
            <span className={requiredClassName}>
              <span className={requiredStarClassName}> *</span>
            </span>
          ) : null}
        </label>
      ) : null}
    </div>
  );
}

export default BeeSwitch;
