import './AlphaDLVerlaufDocTable.scss';
import BeeDocumentTable from '../../Molecules/BeeDocumentTable';
import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { DURATION_NOTIFICATION_ERROR_LONG } from '../../../Helper/Statics/Constants';
import {
  convertDocResponse,
  downloadDocumentFromServer,
  translateAlphaMediaIds
} from '../../../Helper/ApiHelper/MediaNetworkHelper';
import { ReadMediaArrayResponse } from '../../../Helper/ApiHelper/MediaNetworkHelper';
import { DocType } from '../../Pages/DLPages/DLPriceInputPage';
import { Toast } from 'primereact/toast';
import fileDownload from 'js-file-download';
import { handlePromisesAndIgnoreErrors } from '../../../Helper/Util/PromiseHelper';
import { zipDocs } from '../../../Helper/StorageHelper/ZipHelper';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';

type AlphaDLVerlaufDocTableProps = {
  userMediaEntry: string[] | undefined;
};

export default function AlphaDLVerlaufDocTable({
  userMediaEntry
}: AlphaDLVerlaufDocTableProps) {
  const [translatedDocs, setTranslatedDocs] = useState<DocType[]>([]);
  const [isLoadingDocs, setIsLoadingDocs] = useState<boolean>(false);
  const [isLoadingDownloadDocs, setIsLoadingDownloadDocs] =
    useState<boolean>(false);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    setIsLoadingDocs(true);
    if (userMediaEntry && userMediaEntry.length > 0) {
      translateAlphaMediaIds(userMediaEntry)
        .then((data: any) => {
          const media: ReadMediaArrayResponse[] = _.cloneDeep(data);
          let entries: DocType[] = [];
          if (media && media.length > 0) {
            entries = convertDocResponse(media);
          }
          setIsLoadingDocs(false);
          setTranslatedDocs(entries);
        })
        .catch((error) => {
          setIsLoadingDocs(false);
          if (toast.current) {
            toast.current.show({
              severity: 'error',
              summary: 'Dokumente konnten nicht geladen werden',
              detail:
                'Leider konnten die Dokumente nicht geladen werden. Bitte versuchen Sie es später erneut. ',
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        });
    } else {
      setTranslatedDocs([]);
      setIsLoadingDocs(false);
    }
  }, [userMediaEntry]);

  function downloadDocumentById(entryId: string) {
    const entry = _.find(translatedDocs, function (doc: DocType) {
      return doc.id === entryId;
    });
    if (entry) {
      downloadDocumentFromServer(entry.fileSrc, entry.fileKey)
        .then((response: any) => {
          let filename = entry.filename;
          if (filename) {
            fileDownload(response, filename);
          }
        })
        .catch((error) => {
          if (toast && toast.current) {
            toast.current?.show({
              severity: 'error',
              summary: 'Der Download ist fehlgeschlagen',
              detail:
                'Der Download des Dokuments ist fehlgeschlagen, bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        });
    }
  }

  function downloadDocumentsByIds(entryIds: string[]) {
    setIsLoadingDownloadDocs(true);
    let downloadDocsPromises = [];
    for (let i = 0; i < entryIds.length; i++) {
      const entry = _.find(translatedDocs, function (doc: DocType) {
        return doc.id === entryIds[i];
      });
      if (entry) {
        downloadDocsPromises.push(
          downloadDocumentFromServer(entry.fileSrc, entry.fileKey).then(
            (result) => {
              let filename = entry.filename;
              if (filename) {
                return { name: filename, blob: result };
              }
              //no filename
            }
          )
        );
      }
    }
    handlePromisesAndIgnoreErrors(downloadDocsPromises)
      .then((result: any) => {
        if (result.fulfilled && result.fulfilled.length > 0) {
          let docFiles = [];
          for (let j = 0; j < result.fulfilled.length; j++) {
            docFiles.push(result.fulfilled[j].value);
          }
          const date = new Date().toLocaleDateString('de-DE');
          //zip data
          zipDocs(docFiles, 'BereitgestellteUnterlagen_' + date + '.zip')
            .then(() => {
              if (toast && toast.current) {
                if (!result.rejected || result.rejected.length <= 0) {
                  toast.current?.show({
                    severity: 'success',
                    summary: 'Download erfolgreich abgeschlossen',
                    sticky: false,
                    closable: true,
                    life: DURATION_NOTIFICATION_ERROR_LONG
                  });
                } else {
                  toast.current?.show({
                    severity: 'warn',
                    summary:
                      'Der Download ist für einige Dokumente fehlgeschlagen',
                    detail:
                      'Der Download der Dokumente ist vereinzelt fehlgeschlagen, bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
                    sticky: false,
                    closable: true,
                    life: DURATION_NOTIFICATION_ERROR_LONG
                  });
                }
              }
              setIsLoadingDownloadDocs(false);
            })
            .catch((error) => {
              if (toast && toast.current) {
                toast.current?.show({
                  severity: 'error',
                  summary: 'Der Download ist fehlgeschlagen',
                  detail:
                    'Die Dokumente konnten nicht gezipped und heruntergeladen werden. Bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
                  sticky: true,
                  closable: true,
                  life: DURATION_NOTIFICATION_ERROR_LONG
                });
              }
              setIsLoadingDownloadDocs(false);
            });
        } else if (result.rejected && result.rejected.length > 0) {
          //download failed for all documents
          if (toast && toast.current) {
            toast.current?.show({
              severity: 'error',
              summary: 'Der Download ist für alle Dokumente fehlgeschlagen',
              detail:
                'Der Server für den Download der Dokumente konnte nicht erreicht werden. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
              sticky: true,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
          setIsLoadingDownloadDocs(false);
        }
      })
      .catch((error) => {
        if (toast && toast.current) {
          toast.current?.show({
            severity: 'error',
            summary: 'Der Download ist fehlgeschlagen',
            detail:
              'Der Download der Dokumente ist leider fehlgeschlagen, bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
        setIsLoadingDownloadDocs(false);
      });
  }

  ////////////////
  // UI METHODS //
  ////////////////

  return (
    <div className="alpha-dl-verlauf-doc-table">
      {!isLoadingDocs ? (
        <BeeDocumentTable
          data={translatedDocs}
          type={'dark'}
          readOnly={true}
          disabled={false}
          showDownload={true}
          showDelete={false}
          enableBulkDownload={true}
          isBulkDownloadLoading={isLoadingDownloadDocs}
          onDownload={(docId) => {
            downloadDocumentById(docId);
          }}
          onDownloadAll={(docIds) => {
            downloadDocumentsByIds(docIds);
          }}
        />
      ) : (
        <BeeLoadingSpinner />
      )}
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
