import { authAxios } from './AuthServiceHelper';
import axios from 'axios';

import {
  DEFAULT_ERROR,
  ERROR_400,
  ERROR_429,
  ERROR_500
} from './ErrorMessages';
import { URL_TO_LIEGENSCHAFTEN_SERVER } from '../Statics/Constants';
import { extractUserId } from '../Util/JwtHelper';

export type ErrorResponseType = {
  message?: string;
  status?: number;
  timestamp?: string;
  path?: string;
  response: {
    status: number;
  };
};

export type Adresse = {
  strasse: string;
  hausnummer: string;
  postleitzahl: string;
  stadt: string;
  region: string;
  land: string;
  longitude?: number;
  latitude?: number;
};

export type GebaeudeEnergiesteckbrief = {
  id?: string;
  heizenergieVerbrauchWhM2a?: number | undefined;
  heizenergieZeitraumStart?: Date | undefined;
  heizenergieZeitraumEnd?: Date | undefined;
  heizenergieEmissionCo2Kg?: number | undefined;
  anteilFernwaerme?: number | undefined;
  anteilGas?: number | undefined;
  anteilOel?: number | undefined;
  anteilWaermepumpe?: number | undefined;
  anteilHolz?: number | undefined;
  anteilSonstiges?: number | undefined;
  stromVerbrauchWhM2a?: number | undefined;
  stromZeitraumStart?: Date | undefined;
  stromZeitraumEnd?: Date | undefined;
  stromEmissionCo2Kg?: number | undefined;
  typ?: string | undefined;
};

export type GebaeudeData = {
  tempId?: string;
  id?: string;
  createdAt?: Date;
  liegenschaftId?: string;
  nummer: string;
  name: string;
  eigentuemer: string;
  verantwortlicher: string;
  beschreibung: string;
  anmerkung: string;
  baujahr: number | undefined;
  anzahlEtagen: number | undefined;
  anzahlEtagenOberirdisch: number | undefined;
  anzahlEtagenUnterirdisch: number | undefined;
  anzahlTreppenhaeuser: number | undefined;
  anzahlAufzuege: number | undefined;
  anzahlStellplaetze: number | undefined;
  anzahlNutzerPersonen: number | undefined;
  anzahlBauteile: number | undefined;
  kostenNettoFmProJahr: number | undefined;
  jahresMieteinnahmen: number | undefined;
  nutzungsanteilOffice: number | undefined;
  nutzungsanteilRetail: number | undefined;
  nutzungsanteilWohnen: number | undefined;
  nutzungsanteilLogistik: number | undefined;
  nutzungsanteilSonstige: number | undefined;
  bruttoGrundflaeche: number | undefined;
  nettoRaumflaeche: number | undefined;
  nutzungsflaeche: number | undefined;
  technikflaeche: number | undefined;
  verkehrsflaeche: number | undefined;
  grundstuecksflaeche: number | undefined;
  aussenanlagenflaeche: number | undefined;
  flaecheLeerstand: number | undefined;
  zertifiziert: boolean | undefined | null;
  zertifiziertInfo: string | undefined;
  tiefgarage: boolean | undefined | null;
  fassadenbefahranlage: boolean | undefined | null;
  denkmalschutz: boolean | undefined | null;
  hauptbetriebszeitVon: string | undefined;
  hauptbetriebszeitBis: string | undefined;
  hauptbetriebsTage: string | undefined;
  baualtersstufe: string | undefined;
  technisierungsgrad: string | undefined;
  klimatisierung: string | undefined;
  gebaeudeTyp: string | undefined;
  hauptBildId?: string;
  hauptPlanId?: string;
  mediaIds?: string[];
  adresse: Adresse | undefined;
  energiesteckbrief: GebaeudeEnergiesteckbrief | undefined;
};

export type LiegenschaftsData = {
  tempId?: string;
  id?: string;
  organisationId?: string;
  createdAt?: Date;
  nummer?: string;
  name?: string;
  beschreibung?: string;
  anmerkung?: string;
  eigentuemer?: string;
  verantwortlicher?: string;
  hauptBildId?: string;
  hauptPlanId?: string;
  mediaIds?: string[];
  adresse: Adresse | undefined;
  gebaeudeIds?: string[];
};

export type AllLiegenschaftenResponseType = {
  data: { data: LiegenschaftsData[] };
};

export type BaualtersstufenResponseType = {
  data: { data: Baualtersstufen };
};
export type Baualtersstufen = string[];
export type Hauptbetriebstage = string[];
export type Technisierungsgrade = string[];
export type Klimatisierungen = string[];
export type Gebaeudetypen = string[];
export type Energiesteckbrieftypen = string[];

export const readAllLiegenschaften = () => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'get',
      url: URL_TO_LIEGENSCHAFTEN_SERVER + '/v1/liegenschaft'
    })
      .then((result: AllLiegenschaftenResponseType) => {
        console.log(result);
        if (result && result.data && result.data.data) {
          resolve(result.data.data);
        }
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

export const readLiegenschaftById = (id: string) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'get',
      url: URL_TO_LIEGENSCHAFTEN_SERVER + '/v1/liegenschaft/' + id
    })
      .then((result: { data: LiegenschaftsData }) => {
        resolve(result.data);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//fixme test
export const createLiegenschaft = (data: LiegenschaftsData) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'put',
      url: URL_TO_LIEGENSCHAFTEN_SERVER + '/v1/liegenschaft/',
      data: data
    })
      .then((result: any) => {
        console.log(result);
        resolve(result.data);
      })
      .catch((error: ErrorResponseType) => {
        console.log(error);
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

export const updateLiegenschaftById = (id: string, data: LiegenschaftsData) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'put',
      url: URL_TO_LIEGENSCHAFTEN_SERVER + '/v1/liegenschaft/' + id,
      data: data
    })
      .then((result: any) => {
        console.log(result);
        resolve(result.data);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//fixme test
export const deleteLiegenschaftById = (id: string) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'delete',
      url: URL_TO_LIEGENSCHAFTEN_SERVER + '/v1/liegenschaft/' + id
    })
      .then((result: any) => {
        console.log(result);
        resolve(result.data);
      })
      .catch((error: ErrorResponseType) => {
        console.log(error);
        let errorMsg = '';
        if (error && error.response) {
          if (error.response.status === 401) {
            errorMsg =
              'Leider konnte die Liegenschaft nicht gelöscht werden, da Sie nicht die notwendigen Berechtigungen haben. ';
          } else if (error.response.status === 404) {
            errorMsg =
              'Es konnte keine Liegenschaft mit der gegebenen Id gefunden werden. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.';
          } else if (error.response.status === 409) {
            errorMsg =
              'Die Liegenschaft ist derzeit mit einer Phase verknüpft. Um den Verlust von Daten zu vermeiden kann die Liegenschaft deshalb derzeit nicht gelöscht werden. Bitte entfernen Sie die Liegenschaft erst aus allen Angebotsphasen bevor Sie sie löschen.';
          } else if (error.response.status === 500) {
            errorMsg = ERROR_500;
          } else {
            errorMsg = DEFAULT_ERROR;
          }
          reject(errorMsg);
        } else if (error && error.status) {
          if (error.status === 401) {
            errorMsg =
              'Leider konnte die Liegenschaft nicht gelöscht werden, da Sie nicht die notwendigen Berechtigungen haben. ';
          } else if (error.status === 404) {
            errorMsg =
              'Es konnte keine Liegenschaft mit der gegebenen Id gefunden werden. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.';
          } else if (error.status === 409) {
            errorMsg =
              'Die Liegenschaft ist derzeit mit einer Phase verknüpft. Um den Verlust von Daten zu vermeiden kann die Liegenschaft deshalb derzeit nicht gelöscht werden. Bitte entfernen Sie die Liegenschaft erst aus allen Angebotsphasen bevor Sie sie löschen.';
          } else if (error.status === 500) {
            errorMsg = ERROR_500;
          } else {
            errorMsg = DEFAULT_ERROR;
          }
          reject(errorMsg);
        } else {
          errorMsg = DEFAULT_ERROR;
          reject(errorMsg);
        }
      });
  });
};

//fixme test
export const readAllGebaeude = (liegenschaftsId: string) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'get',
      url:
        URL_TO_LIEGENSCHAFTEN_SERVER +
        '/v1/liegenschaft/' +
        liegenschaftsId +
        '/gebaeude/'
    })
      .then((result: any) => {
        console.log(result);
        resolve(result.data);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

export const readGebaeudeById = (
  liegenschaftsId: string,
  gebaeudeId: string
) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'get',
      url:
        URL_TO_LIEGENSCHAFTEN_SERVER +
        '/v1/liegenschaft/' +
        liegenschaftsId +
        '/gebaeude/' +
        gebaeudeId
    })
      .then((result: { data: GebaeudeData }) => {
        console.log(result);
        resolve(result.data);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//fixme test
export const createGebaeude = (
  liegenschaftsId: string,
  data: GebaeudeData | {}
) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'put',
      url:
        URL_TO_LIEGENSCHAFTEN_SERVER +
        '/v1/liegenschaft/' +
        liegenschaftsId +
        '/gebaeude/',
      data: data
    })
      .then((result: any) => {
        console.log(result);
        resolve(result.data);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//fixme test
export const updateGebaeudeById = (
  liegenschaftsId: string,
  gebaeudeId: string,
  data: GebaeudeData
) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'put',
      url:
        URL_TO_LIEGENSCHAFTEN_SERVER +
        '/v1/liegenschaft/' +
        liegenschaftsId +
        '/gebaeude/' +
        gebaeudeId,
      data: data
    })
      .then((result: any) => {
        console.log(result);
        resolve(result.data);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//fixme test
export const deleteGebaeudeById = (
  liegenschaftsId: string,
  gebaeudeId: string
) => {
  return new Promise((resolve, reject) => {
    authAxios({
      method: 'delete',
      url:
        URL_TO_LIEGENSCHAFTEN_SERVER +
        '/v1/liegenschaft/' +
        liegenschaftsId +
        '/gebaeude/' +
        gebaeudeId
    })
      .then((result: any) => {
        console.log(result);
        resolve(result.data);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//DOWNLOAD AVAILABLE BAUALTERSSTUFE
export const downloadAvailableBaualtersstufen = () => {
  return new Promise<Baualtersstufen>((resolve, reject) => {
    authAxios({
      method: 'get',
      url: URL_TO_LIEGENSCHAFTEN_SERVER + '/v1/types/baualtersstufe',
      data: {}
    })
      .then((result: BaualtersstufenResponseType) => {
        console.log(result);
        resolve(result.data.data);
      })
      .catch((error) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//DOWNLOAD AVAILABLE HAUPTBETRIEBSTAGE
export const downloadAvailableHauptbetriebstage = () => {
  return new Promise<Hauptbetriebstage>((resolve, reject) => {
    authAxios({
      method: 'get',
      url: URL_TO_LIEGENSCHAFTEN_SERVER + '/v1/types/betriebstage',
      data: {}
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//DOWNLOAD AVAILABLE TECHNISIERUNGSGRADE
export const downloadAvailableTechnisierungsgrade = () => {
  return new Promise<Technisierungsgrade>((resolve, reject) => {
    authAxios({
      method: 'get',
      url: URL_TO_LIEGENSCHAFTEN_SERVER + '/v1/types/technisierungsgrad',
      data: {}
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//DOWNLOAD AVAILABLE KLIMATISIERUNGEN
export const downloadAvailableKlimatisierungen = () => {
  return new Promise<Klimatisierungen>((resolve, reject) => {
    authAxios({
      method: 'get',
      url: URL_TO_LIEGENSCHAFTEN_SERVER + '/v1/types/klimatisierung',
      data: {}
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//DOWNLOAD AVAILABLE GEBAEUDETYPEN
export const downloadAvailableGebaeudetypen = () => {
  return new Promise<Gebaeudetypen>((resolve, reject) => {
    authAxios({
      method: 'get',
      url: URL_TO_LIEGENSCHAFTEN_SERVER + '/v1/types/gebaeudetyp',
      data: {}
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//DOWNLOAD AVAILABLE ENERGIESTECKBRIEFTYPEN
export const downloadAvailableEnergietypen = () => {
  return new Promise<Energiesteckbrieftypen>((resolve, reject) => {
    authAxios({
      method: 'get',
      url: URL_TO_LIEGENSCHAFTEN_SERVER + '/v1/types/energiesteckbriefTyp',
      data: {}
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

//IMPORT LIEGENSCHAFT
export const importLiegenschaftExcel = (file: File) => {
  return new Promise((resolve, reject) => {
    const uploadUrl = URL_TO_LIEGENSCHAFTEN_SERVER + '/v1/liegenschaft/excel';

    let fd: any = new FormData();
    fd.append('file', file);
    fd.append('organisationId', extractUserId());

    authAxios
      .put(uploadUrl, fd)
      .then((result) => {
        console.log(result);
        resolve(result.data);
      })
      .catch((error) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

export const pingLiegenschaftenService = () => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: URL_TO_LIEGENSCHAFTEN_SERVER + '/v1/about_api'
    })
      .then((result: any) => {
        resolve(result.data.data);
      })
      .catch((error: ErrorResponseType) => {
        if (error && error.response) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};
