import _ from 'lodash';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import {
  alphaCreateServiceProvider,
  alphaReadAllServiceProvider,
  setDlPermissionsFullAccess,
  setDlPermissionsReadOnly,
  User,
  UserRight
} from '../../../Helper/ApiHelper/AuthServiceHelper';
import { errorMsgToString } from '../../../Helper/ApiHelper/ErrorMessages';
import {
  DURATION_NOTIFICATION_ERROR_LONG,
  USER_RIGHT_DLREAD,
  USER_RIGHT_DLWRITE_KEYVAL,
  USER_RIGHT_DLWRITE_MEDIA
} from '../../../Helper/Statics/Constants';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeIconButton from '../../Atoms/BeeIconButton';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import BeeOutlinedButton from '../../Atoms/BeeOutlinedButton';
import BeeValidatedCheckbox from '../../Atoms/BeeValidatedCheckbox';
import AlphaUserDialog from '../../Molecules/Dialogs/AlphaUserDialog';
import './AlphaOptionsPage.scss';
import './AlphaUserPage.scss';

export default function AlphaUserPage() {
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(false);
  const [vUserDialog, setVUserDialog] = useState<boolean>(false);
  const [currentEntry, setCurrentEntry] = useState<User>();
  const [allUser, setAllUser] = useState<User[]>([]);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    setDataIsLoading(true);
    alphaReadAllServiceProvider()
      .then((allUser: any) => {
        setAllUser(
          _.sortBy(allUser, ['registration', 'firstname', 'lastname'])
        );
        setDataIsLoading(false);
      })
      .catch((error) => {
        if (toast && toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Nutzer konnten nicht geladen werden',
            detail:
              'Leider konnten die Nutzer nicht geladen werden. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.',
            sticky: true,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
        setDataIsLoading(false);
      });
  }, []);

  function createUser(user: User) {
    alphaCreateServiceProvider(user)
      .then((savedUser: any) => {
        let currUserWithPermissions: User = savedUser;
        let adapted: User[] = _.cloneDeep(allUser);
        adapted = adapted ? adapted : [];
        adapted.push(currUserWithPermissions);
        console.log(adapted);
        setAllUser(adapted);
        setVUserDialog(false);
        setCurrentEntry(undefined);
      })
      .catch((error) => {
        if (toast && toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Nutzer konnten nicht gespeichert werden',
            detail: errorMsgToString(error)
              ? 'Leider konnte der Nutzer nicht gespeichert werden. ' +
                errorMsgToString(error)
              : 'Leider konnte der Nutzer nicht gespeichert werden. Bitte prüfen Sie Ihre Internetverbindung. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.',
            sticky: true,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
      });
  }

  function changeUserRightsWrite(userId: string, currValue: boolean) {
    if (currValue) {
      setDlPermissionsFullAccess(userId)
        .then((savedUser: any) => {
          let currUserWithPermissions: User = savedUser;
          let adapted: User[] = _.cloneDeep(allUser);
          adapted = adapted ? adapted : [];
          let currUserIndex = _.findIndex(adapted, function (user: User) {
            return user.id === currUserWithPermissions.id;
          });
          if (currUserIndex !== -1) {
            adapted[currUserIndex] = currUserWithPermissions;
          }
          setAllUser(adapted);
        })
        .catch(() => {
          if (toast && toast.current) {
            toast.current.show({
              severity: 'error',
              summary: 'Nutzerrechte konnten nicht gespeichert werden',
              detail:
                'Leider konnten die Nutzerrechte nicht geupdated werden. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.',
              sticky: true,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        });
    } else {
      setDlPermissionsReadOnly(userId)
        .then((savedUser: any) => {
          let currUserWithPermissions: User = savedUser;
          let adapted: User[] = _.cloneDeep(allUser);
          adapted = adapted ? adapted : [];
          let currUserIndex = _.findIndex(adapted, function (user: User) {
            return user.id === currUserWithPermissions.id;
          });
          if (currUserIndex !== -1) {
            adapted[currUserIndex] = currUserWithPermissions;
          }
          setAllUser(adapted);
        })
        .catch(() => {
          if (toast && toast.current) {
            toast.current.show({
              severity: 'error',
              summary: 'Nutzerrechte konnten nicht gespeichert werden',
              detail:
                'Leider konnte der Nutzerrechte nicht gespeichert werden. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.',
              sticky: true,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        });
    }
  }

  const actionTemplate = (rowData: User) => {
    return (
      <div>
        <BeeIconButton
          iconClass={'pi pi-eye'}
          rounded={false}
          disabled={false}
          type={'primary'}
          onClick={() => {
            setCurrentEntry(rowData);
            setVUserDialog(true);
          }}
        />
      </div>
    );
  };

  function injectUserDialog() {
    return (
      <AlphaUserDialog
        data={currentEntry}
        visible={vUserDialog}
        readOnly={currentEntry ? true : false}
        onHide={() => {
          setCurrentEntry(undefined);
          setVUserDialog(false);
        }}
        onCreate={(data: User) => {
          createUser(data);
        }}
      />
    );
  }

  function userRegistationBodyTemplate(rowData: User) {
    const date = rowData.registration ? new Date(rowData.registration) : null;
    return date ? date.toLocaleDateString('de-DE') : null;
  }

  function rightReadBodyTemplate(rowData: User) {
    const rights = rowData.grantedRights;
    const readRight = _.filter(rights, function (right: UserRight) {
      return right.title === USER_RIGHT_DLREAD;
    });

    const value = readRight && readRight.length > 0 ? true : false;

    return (
      <div>
        <BeeValidatedCheckbox
          label={''}
          value={value}
          stateFalse={''}
          stateTrue={''}
          disabled={false}
          formstate={'neutral'}
          readOnly={true}
        />
      </div>
    );
  }

  function rightWriteBodyTemplate(rowData: User) {
    const rights = rowData.grantedRights;
    const writeRight = _.filter(rights, function (right: UserRight) {
      return (
        right.title === USER_RIGHT_DLWRITE_KEYVAL ||
        right.title === USER_RIGHT_DLWRITE_MEDIA
      );
    });

    const userId = rowData && rowData.id ? rowData.id : '';
    const value = writeRight && writeRight.length > 1 ? true : false;

    return (
      <BeeValidatedCheckbox
        label={''}
        value={writeRight && writeRight.length > 1 ? true : false}
        stateFalse={''}
        stateTrue={''}
        disabled={false}
        formstate={'neutral'}
        readOnly={false}
        onChange={(e) => {
          //fixme process spinner/show process, disable onclick
          userId
            ? changeUserRightsWrite(userId, e.checked)
            : console.log('FIXME');
        }}
      />
    );
  }

  function injectUserTable() {
    return (
      <div className={'alpha-user-table'}>
        <DataTable
          value={allUser}
          emptyMessage={'Keine Nutzer vorhanden'}
          responsiveLayout="stack"
          breakpoint="730px"
        >
          <Column
            field="firstname"
            header="Vorname"
            headerClassName="sm-invisible"
            bodyClassName="sm-invisible"
            className={'firstname-col'}
          ></Column>
          <Column
            field="lastname"
            header="Nachname"
            headerClassName="sm-invisible"
            bodyClassName="sm-invisible"
            className={'lastname-col'}
          ></Column>
          <Column
            field="organisation"
            header="Organisation"
            headerClassName="sm-invisible"
            bodyClassName="sm-invisible"
            className={'organisation-col'}
          ></Column>
          <Column
            field="username"
            header="E-Mail"
            className={'email-col'}
          ></Column>

          <Column
            field="registration"
            header="Registriert am"
            body={userRegistationBodyTemplate}
            className={'registration-col'}
          ></Column>
          <Column
            header="Leserecht"
            body={rightReadBodyTemplate}
            className={'readRight-col'}
          ></Column>
          <Column
            header="Schreibrecht"
            body={rightWriteBodyTemplate}
            className={'writeRight-col'}
          ></Column>
          <Column
            header=""
            body={actionTemplate}
            className={'action-col'}
          ></Column>
        </DataTable>
      </div>
    );
  }

  function injectButton() {
    return (
      <div className="mt-2">
        <BeeOutlinedButton
          label={'Nutzer hinzufügen'}
          disabled={false}
          type={'secondary'}
          onClick={() => {
            setVUserDialog(true);
          }}
        />
      </div>
    );
  }

  function injectHeadline() {
    return (
      <div className={'user-header'}>
        <BeeContentHeadline
          label={'Nutzerverwaltung'}
          headline={'h1'}
          type={'primary'}
        />
        {dataIsLoading ? <>{injectLoadingSpinner()}</> : null}
      </div>
    );
  }
  function injectLoadingSpinner() {
    return <BeeLoadingSpinner strokeWidth={'3'} type={'secondary'} />;
  }

  function injectDescription() {
    return (
      <div className={'mb-3 text-color'}>
        {' '}
        Bitte legen Sie in folgender Übersicht die Nutzerkonten für die
        Dienstleister an, die an dem Verfahren teilnehmen sollen. Für jeden
        Dienstleister kann nur ein Konto angelegt werden. Das E-Mail-Konto
        sollte deshalb für alle Nutzer des Unternehmens zugänglich sein, da der
        Login über eine 2-Faktor-Authentifizierung durchgeführt wird. Werden
        einem Dienstleister im Laufe des Verfahrens die Schreibrechte entzogen,
        kann dieser keine Änderungen an seinen bisherigen Eingaben mehr
        vornehmen.
      </div>
    );
  }

  return (
    <div className={'alpha-user-page m-4 pt-2 justify-content-start'}>
      {injectHeadline()}
      {injectDescription()}

      {!dataIsLoading ? (
        <>
          {injectUserTable()}
          {injectButton()}
        </>
      ) : null}
      {vUserDialog ? injectUserDialog() : null}
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
