import { Card } from 'primereact/card';
import React from 'react';
import { useId } from 'react-id-generator';
import { LiegenschaftsData } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import BeeIconButton from '../../Atoms/BeeIconButton';
import BeeImage from '../../Atoms/BeeImage';
import { ImageType } from '../../Pages/AlphaPages/AlphaLiegenschaftVerwaltung';
import './AlphaAnalysisLiegenschaftCard.scss';
import { EnlargedLvPosition } from '../../../Helper/ApiHelper/LvEpNetworkHelper';
import { AuthUserType } from '../../../Helper/ApiHelper/LoginNetworkHelper';
import BeeValidatedCurrencyInput from '../../Atoms/BeeValidatedCurrencyInput';
import { calculateGP } from '../../../Helper/Util/LvCalculator';
import {
  DEFAULT_CALCULATION_MULTIPLIER,
  DEFAULT_FACTOR,
  MIN_NUMBER_OF_DECIMALS,
  VISIBLE_PROVIDER_IN_ANALYSIS
} from '../../../Helper/Statics/Constants';
import _ from 'lodash';
import { ReactComponent as MarktgebietIncompleteSVG } from '../../../Style/Icons/svgs/icons8-country-warning.svg';
import { ReactComponent as PriceInputIncompleteSVG } from '../../../Style/Icons/svgs/icons8-incompletePrice.svg';
import { Tooltip } from 'primereact/tooltip';

type AlphaAnalysisLiegenschaftCardProps = {
  property: LiegenschaftsData;
  serviceProvider: AuthUserType[];
  image: ImageType | null;
  lvData?: EnlargedLvPosition[];
  keyValues: any;
  activateHover: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClickInfo: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

type SpCalculation = {
  provider: AuthUserType;
  price: number;
  inMarketArea: boolean;
  pricingComplete: boolean;
};

export function AlphaAnalysisLiegenschaftCard({
  property,
  serviceProvider,
  image,
  lvData,
  keyValues,
  activateHover,
  onClick,
  onClickInfo
}: AlphaAnalysisLiegenschaftCardProps) {
  const currentId = useId(1, 'alpha-analysis-ls-card-')[0];

  //classNames
  const activateHoverClassNames = activateHover
    ? 'alpha-liegenschaft-ls-hover'
    : 'alpha-liegenschaft-ls-noHover';
  const orientationClassName = 'alpha-analysis-ls-card ';
  const classNames =
    'alpha-analysis-ls ' + orientationClassName + activateHoverClassNames;

  function clicked(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (onClick) {
      onClick(e);
    }
  }

  function clickInfo(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (onClickInfo) {
      onClickInfo(e);
    }
  }

  function injectTableRows() {
    let calculation: SpCalculation[] = [];
    let incomplete: SpCalculation[] = [];
    let excluding: SpCalculation[] = [];
    if (serviceProvider && lvData) {
      serviceProvider.forEach((dl: AuthUserType) => {
        let gpSum: number = 0;
        let notAllPrices: boolean = false;
        let notInArea: boolean = false;
        for (let i = 0; i < lvData.length; i++) {
          const pos = lvData[i];
          const amount = pos.amount ? pos.amount : 0;
          const priceRaw = keyValues.get(pos.epPriceId)
            ? keyValues.get(pos.epPriceId)[dl.id]
            : null;
          let priceVal = 0;
          if (!priceRaw) {
            notAllPrices = true;
          } else {
            priceVal = parseInt(priceRaw);
          }
          const blFac = parseInt(
            keyValues.get(pos.blFactorId) &&
              keyValues.get(pos.blFactorId)[dl.id]
              ? keyValues.get(pos.blFactorId)[dl.id]
              : DEFAULT_FACTOR
          );
          const lsFac = parseInt(
            keyValues.get(pos.lsFactorId) &&
              keyValues.get(pos.lsFactorId)[dl.id]
              ? keyValues.get(pos.lsFactorId)[dl.id]
              : DEFAULT_FACTOR
          );
          const nFac = parseInt(
            keyValues.get(pos.nFactorId) && keyValues.get(pos.nFactorId)[dl.id]
              ? keyValues.get(pos.nFactorId)[dl.id]
              : DEFAULT_FACTOR
          );
          const blVal = blFac ? blFac : DEFAULT_FACTOR;
          const lsVal = lsFac ? lsFac : DEFAULT_FACTOR;
          const nVal = nFac ? nFac : DEFAULT_FACTOR;
          const mVal: boolean =
            keyValues.get(pos.mFactorId) &&
            keyValues.get(pos.mFactorId)[dl.id] === 'false'
              ? false
              : true;
          if (!mVal) {
            notInArea = true;
          }
          //only add if this is an not an inactive optional position
          if (
            !pos.optionalPosition ||
            (pos.optionalPosition && pos.optionalPositionActive)
          ) {
            gpSum = gpSum + calculateGP(amount, priceVal, blVal, lsVal, nVal);
          }
        }
        if (notInArea) {
          excluding.push({
            provider: dl,
            price: gpSum,
            inMarketArea: !notInArea,
            pricingComplete: !notAllPrices
          });
        } else if (notAllPrices) {
          incomplete.push({
            provider: dl,
            price: gpSum,
            inMarketArea: !notInArea,
            pricingComplete: !notAllPrices
          });
        } else {
          calculation.push({
            provider: dl,
            price: gpSum,
            inMarketArea: !notInArea,
            pricingComplete: !notAllPrices
          });
        }
      });
    }
    //combine all sorted arrays and cut them
    calculation = _.sortBy(calculation, ['price']);
    incomplete = _.sortBy(incomplete, ['price']);
    calculation.push(...incomplete);
    calculation.push(...excluding);
    const cutted = _.take(calculation, VISIBLE_PROVIDER_IN_ANALYSIS);
    const rows = cutted.map((calc: SpCalculation, index: number) => {
      return (
        <tr
          key={'dlLiegenschaftCardRow_' + property.id! + '_' + calc.provider.id}
        >
          <td className="card-table-ranking-icon">
            <i
              className={
                index === 0
                  ? 'icon-prize gold'
                  : index === 1
                  ? 'icon-prize silver'
                  : index === 2
                  ? 'icon-prize bronze'
                  : 'icon-prize'
              }
            ></i>
          </td>
          <td className={'card-table-organisation'}>
            {calc.provider.organisation}
          </td>
          <td className={'card-table-price'}>
            <div>
              {calc.inMarketArea ? (
                <div>
                  <BeeValidatedCurrencyInput
                    label={''}
                    value={_.round(calc.price / DEFAULT_CALCULATION_MULTIPLIER)}
                    minFractionDigits={MIN_NUMBER_OF_DECIMALS}
                    maxFractionDigits={MIN_NUMBER_OF_DECIMALS}
                    disabled={true}
                    readOnly={true}
                    required={false}
                  />
                </div>
              ) : (
                <div>
                  <div>
                    <BeeValidatedCurrencyInput
                      label={''}
                      value={0}
                      minFractionDigits={MIN_NUMBER_OF_DECIMALS}
                      maxFractionDigits={MIN_NUMBER_OF_DECIMALS}
                      disabled={true}
                      readOnly={true}
                      required={false}
                    />
                  </div>
                </div>
              )}
            </div>
          </td>
          <td className={'card-table-incomplete'}>
            {!calc.inMarketArea ? (
              <>
                <MarktgebietIncompleteSVG className="marktgebietIncomplete" />
                <Tooltip target=".marktgebietIncomplete">
                  Nicht vollständig in Marktgebiet
                </Tooltip>
              </>
            ) : null}{' '}
            {!calc.pricingComplete && calc.inMarketArea ? (
              <>
                <PriceInputIncompleteSVG className="priceInputIncomplete" />
                <Tooltip target=".priceInputIncomplete">
                  unvollständige Preiseingabe
                </Tooltip>
              </>
            ) : null}
          </td>
        </tr>
      );
    });
    return (
      <table className={'a-analysis-liegenschaft-prev-card-table'}>
        <tbody>
          {lvData && lvData.length > 0 ? (
            rows
          ) : (
            <tr>
              <td>{'Kein LV hinterlegt.'}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  const injectHeadline = () => {
    return (
      <>
        {property && property.adresse && property.adresse.stadt ? (
          <div>{property.adresse.stadt}</div>
        ) : (
          <div></div>
        )}
        <h2>{property.name}</h2>
        {property.nummer ? <div>{property.nummer}</div> : <div></div>}
      </>
    );
  };

  const injectImage = () => {
    if (image && property.hauptBildId) {
      return (
        <BeeImage
          img_url={image.url}
          img_key={image.key}
          alt={image.alt}
          title={image.title}
          copyright={image.copyright}
          isSecured={image.secured ? image.secured : false}
          showDefault={false}
          showPlaceholder={
            image.showPlaceholder ? image.showPlaceholder : false
          }
        />
      );
    } else if (property.hauptBildId) {
      //show loader
      return (
        <BeeImage
          img_url={''}
          img_key={''}
          alt={''}
          title={''}
          copyright={''}
          isSecured={false}
          showDefault={false}
          showPlaceholder={true}
        />
      );
    } else {
      //show default property image
      return (
        <BeeImage
          img_url={''}
          img_key={''}
          alt={''}
          title={''}
          copyright={''}
          isSecured={false}
          showDefault={true}
          showPlaceholder={false}
        />
      );
    }
  };

  function injectInfoButton() {
    return (
      <BeeIconButton
        iconClass={'icon-search'}
        rounded={true}
        disabled={false}
        type={'secondary'}
        onClick={(e) => clickInfo(e)}
      />
    );
  }

  return (
    <Card id={currentId} className={classNames}>
      <div className={'alpha-analysis-ls-card-info'}>{injectInfoButton()}</div>
      <div onClick={(e) => clicked(e)}>
        <div className={'alpha-analysis-ls-card-headline'}>
          {injectHeadline()}
        </div>
        <div className={'alpha-analysis-ls-card-img'}>
          <div className={'lsCard-img'}>{injectImage()}</div>
        </div>
        {injectTableRows()}
      </div>
    </Card>
  );
}

export default AlphaAnalysisLiegenschaftCard;
