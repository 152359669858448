import { Card } from 'primereact/card';
import { DataView } from 'primereact/dataview';
import { useEffect, useRef, useState } from 'react';
import { useId } from 'react-id-generator';
import { useLocation, useNavigate } from 'react-router-dom';
import './AlphaLiegenschaftVerwaltung.scss';

import _ from 'lodash';
import { connect } from 'react-redux';

import {
  DROPDOWN_LIEGENSCHAFT_SORT,
  DURATION_NOTIFICATION_ERROR_LONG,
  DURATION_NOTIFICATION_SUCCESS_LONG,
  liegenschaftDeletedToast,
  LIEGENSCHAFT_SORT_ADDRESS,
  LIEGENSCHAFT_SORT_BUILDING_NO,
  LIEGENSCHAFT_SORT_CITY,
  LIEGENSCHAFT_SORT_NAME
} from '../../../Helper/Statics/Constants';
import { AppDispatch, RootState } from '../../../Redux/store';
import BeeAddEntryCard from '../../Atoms/BeeAddEntryCard';
import BeeDropDown from '../../Atoms/BeeDropDown';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import BeeSearchBar from '../../Atoms/BeeSearchBar';

import { Toast } from 'primereact/toast';

import {
  importLiegenschaftExcel,
  pingLiegenschaftenService,
  readAllLiegenschaften,
  updateGebaeudeById,
  updateLiegenschaftById
} from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import {
  ImageResponse,
  pingMediaService,
  translateAlphaMediaIds,
  translateImageIdsToData,
  uploadDocumentToServer,
  uploadImageToServer
} from '../../../Helper/ApiHelper/MediaNetworkHelper';
import { PATH_DETAIL_LIEGENSCHAFT } from '../../../Helper/Statics/Routes';
import {
  unpackZip,
  ZipData,
  zipMedia
} from '../../../Helper/StorageHelper/ZipHelper';
import { extractUserId } from '../../../Helper/Util/JwtHelper';
import BeeButton from '../../Atoms/BeeButton';
import AlphaLiegenschaftEingabeCard, {
  LiegenschaftType
} from '../../Molecules/Alpha/AlphaLiegenschaftEingabeCard';
import BeeUploadDialog from '../../Molecules/Dialogs/BeeUploadDialog';
import { FileUploadType } from '../DLPages/DLPriceInputPage';
import { errorMsgToString } from '../../../Helper/ApiHelper/ErrorMessages';

type AlphaLiegenschaftVerwaltungProps = {
  refreshToken: string;
};

export type ImageType = {
  id: string;
  url: string;
  key: string;
  title: string;
  alt: string;
  copyright: string;
  secured: boolean;
  showPlaceholder: boolean;
};

export function AlphaLiegenschaftVerwaltung(
  props: AlphaLiegenschaftVerwaltungProps
) {
  const currentId = useId(1, 'alpha-liegenschaft-select-')[0];
  const location = useLocation();
  let navigate = useNavigate();

  const [visibleData, setVisibleData] = useState<any>();
  const [initData, setInitData] = useState<any>();
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(false);
  const [images, setImages] = useState<ImageType[]>([]);
  const NO_FILTER = ' - keine Filterung - ';
  const [search, setSearch] = useState<any>('');
  const [sort, setSort] = useState<string>(LIEGENSCHAFT_SORT_CITY);
  const [filterOptions, setFilterOptions] = useState<any>();
  const [currentFilter, setCurrentFilter] = useState<any>(NO_FILTER);
  //upload
  const [v_lsUpload, setV_lsUpload] = useState<boolean>(false);
  const [uploadDialogProgress, setUploadDialogProgress] =
    useState<boolean>(false);
  const [errorUploadDialog, setErrorUploadDialog] = useState<string>();
  const [errorUploadDialogDescr, setErrorUploadDialogDescr] =
    useState<string>();
  const [newUploadSuccess, setNewUploadSuccess] = useState<boolean>(false);

  //refs
  const toast = useRef<Toast>(null);

  useEffect(() => {
    setDataIsLoading(true);
    const userId = extractUserId();

    //load all liegenschaften
    readAllLiegenschaften().then((result: any) => {
      let liegenschaften = result;
      //for empty entry (add card)
      liegenschaften = [{}, ...liegenschaften];
      setInitData(liegenschaften);
      //sort initial data
      setFilterOptions(null);
      filterUsingSearchTermSortAndFilter(
        liegenschaften,
        search,
        sort,
        currentFilter
      );
      setDataIsLoading(false);

      //get all ImageIds for main images
      let imageIds = [];
      for (let x = 0; x < liegenschaften.length; x++) {
        if (liegenschaften[x].hauptBildId) {
          imageIds.push(liegenschaften[x].hauptBildId);
        }
      }

      //unique testing of all ids
      imageIds = _.uniq(imageIds);

      translateAlphaMediaIds(imageIds)
        .then((data: any) => {
          const imgs: any = translateImageIdsToData(data);

          setImages(imgs);
        })
        .catch((error) => {
          // notify user that getting images failed
          toast.current?.show({
            severity: 'error',
            summary: 'Die Bilder konnten nicht geladen werden',
            detail:
              'Die Bilder für die Anzeige der Liegenschaften konnten nicht geladen werden. Bitte aktualisieren Sie die Seite oder wenden Sie sich an den Kundenservice',
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_SUCCESS_LONG
          });
        });
      setNewUploadSuccess(false);
    });
  }, [newUploadSuccess]);

  useEffect(() => {
    if (location && location.state && location.state?.showToast) {
      if (location.state?.showToast === liegenschaftDeletedToast) {
        toast.current?.show({
          severity: 'success',
          summary: 'Die Liegenschaft wurde erfolgreich gelöscht',
          sticky: false,
          closable: true,
          life: DURATION_NOTIFICATION_SUCCESS_LONG
        });

        window.history.replaceState({}, document.title);
      }
    }
  }, [location.state?.showToast]);

  //classNames
  const orientationClassNames = 'alpha-liegenschaft-select-card ';
  const hoverClassNames = 'alpha-liegenschaft-select-hover';

  const classNames = orientationClassNames + hoverClassNames;
  const addCardClassNames = ' alpha-liegenschaft-select-add-card';

  const dataViewClassNames = 'd-flex jc-end';
  //label
  const labelEmptyMessage = 'Keine Liegenschaften verfügbar!';
  const headline = 'Liegenschaften';

  function filterUsingSearchTermSortAndFilter(
    data: any,
    search: any,
    sort: any,
    currentFilter: any
  ) {
    setSearch(search);
    setSort(sort);
    setCurrentFilter(currentFilter);
    if (!search || search === '') {
      filterUsingFilter(currentFilter, sort, data);
    } else {
      if (data && data.length > 0) {
        let result = _.cloneDeep(data);
        result = _.filter(result, function (ls: any) {
          const attr = [];
          if (ls.adresse) {
            attr.push(_.toLower(ls.adresse.stadt ? ls.adresse.stadt : ''));
            attr.push(_.toLower(ls.adresse.strasse ? ls.adresse.strasse : ''));
            attr.push(
              _.toLower(ls.adresse.postleitzahl ? ls.adresse.postleitzahl : '')
            );
            attr.push(
              _.toLower(ls.adresse.hausnummer ? ls.adresse.hausnummer : '')
            );
          }

          attr.push(_.toLower(ls.nummer ? ls.nummer : ''));
          attr.push(_.toLower(ls.name ? ls.name : ''));
          return _.includes(attr.join(' '), _.toLower(search));
        });
        filterUsingFilter(currentFilter, sort, result);
      }
    }
  }

  function filterUsingFilter(currentFilter: any, sort: any, payload: any) {
    if (!currentFilter || currentFilter === NO_FILTER) {
      sortCurrentData(sort, payload);
    } else {
      let result = _.cloneDeep(payload);
      result = _.filter(result, function (ls: any) {
        return ls &&
          ls.adresse &&
          ls.adresse.stadt &&
          ls.adresse.stadt === currentFilter
          ? true
          : false;
      });
      sortCurrentData(sort, result);
    }
  }

  function sortCurrentData(sort: any, payload: any) {
    let ls = _.cloneDeep(payload);
    //test if add is contained
    let containsAdd = false;
    if (ls && ls.length > 0) {
      for (let i = 0; i < ls.length; i++) {
        if (Object.keys(ls[i]).length === 0) {
          containsAdd = true;
        }
      }
    }
    //remove empty add if contained
    _.remove(ls, function (n: any) {
      return Object.keys(n).length === 0;
    });
    //sort by choice
    switch (sort) {
      case LIEGENSCHAFT_SORT_NAME:
        ls = _.sortBy(ls, ['name', 'adresse.stadt']);
        break;
      case LIEGENSCHAFT_SORT_CITY:
        ls = _.sortBy(ls, ['adresse.stadt']);
        break;
      case LIEGENSCHAFT_SORT_ADDRESS:
        ls = _.sortBy(ls, ['adresse.strasse', 'adresse.hausnummer']);
        break;
      case LIEGENSCHAFT_SORT_BUILDING_NO:
        ls = _.sortBy(ls, ['nummer', 'adresse.stadt']);
        break;

      default:
    }
    if (containsAdd) {
      setVisibleData([{}, ...ls]);
    } else {
      setVisibleData(ls);
    }
  }

  function getCityFilter() {
    if (filterOptions) {
      return filterOptions;
    } else {
      //calculate first use
      if (visibleData && visibleData.length > 0) {
        let cities = [];
        for (let i = 0; i < visibleData.length; i++) {
          if (
            visibleData[i] &&
            visibleData[i].adresse &&
            visibleData[i].adresse.stadt
          ) {
            cities.push(visibleData[i].adresse.stadt);
          }
        }
        cities = _.uniq(cities);
        cities.sort();
        let tmp = [];
        tmp.push(NO_FILTER);
        const output = [...tmp, ...cities];
        setFilterOptions(output);
        return output;
      } else {
        return null;
      }
    }
  }

  function renderDataViewHeadline() {
    return (
      <div className={dataViewClassNames}>
        {/* {errorMsg ? (
          <BeeErrorLabel size={'large'} label={errorMsg} visible={true} />
        ) : null} */}
      </div>
    );
  }

  function clicked(lId: string) {
    if (lId) {
      navigate(PATH_DETAIL_LIEGENSCHAFT + '/?lid=' + lId);
    } else {
      navigate(PATH_DETAIL_LIEGENSCHAFT + '/?lid=');
    }
  }

  function createPreviewEntry(liegenschaft: LiegenschaftType) {
    const lId = liegenschaft.id;
    const image: any = _.find(images, function (d: ImageType) {
      return d.id === liegenschaft.hauptBildId;
    });

    return (
      <AlphaLiegenschaftEingabeCard
        liegenschaft={liegenschaft}
        image={image}
        activateHover={true}
        onClick={(e: any) => clicked(lId)}
      />
    );
  }

  function template(liegenschaft: any) {
    if (!liegenschaft || !liegenschaft.id) {
      return (
        <Card className={classNames + addCardClassNames}>
          <div>
            <BeeAddEntryCard
              onClick={(e) => {
                clicked('');
              }}
            />
          </div>
        </Card>
      );
    }
    return <div className={classNames}>{createPreviewEntry(liegenschaft)}</div>;
  }
  function injectHeadline() {
    return (
      <div
        className={
          'ls-verwaltung-headline flex justify-content-between align-items-center'
        }
      >
        <div className={'flex align-items-center'}>
          <h1>{headline} </h1>
          {dataIsLoading ? <BeeLoadingSpinner type={'secondary'} /> : null}
        </div>
        <BeeButton
          label={'Liegenschaft importieren'}
          disabled={false}
          type={'primary'}
          onClick={() => {
            setV_lsUpload(true);
          }}
        />
      </div>
    );
  }

  function injectSearchArea() {
    return (
      <div className="grid audit-overview-search-area">
        <div className="col">
          <BeeDropDown
            label={'Filterung (Stadt)'}
            value={currentFilter}
            options={getCityFilter()}
            disabled={false}
            formstate={'neutral'}
            readOnly={false}
            required={false}
            onChange={(e: any) =>
              filterUsingSearchTermSortAndFilter(initData, search, sort, e)
            }
          />
        </div>
        <div className="col">
          <BeeDropDown
            label={'Sortieren'}
            value={sort}
            options={DROPDOWN_LIEGENSCHAFT_SORT}
            disabled={false}
            formstate={'neutral'}
            readOnly={false}
            required={false}
            onChange={(e: any) =>
              filterUsingSearchTermSortAndFilter(
                initData,
                search,
                e,
                currentFilter
              )
            }
          />
        </div>
        <div className="col">
          <BeeSearchBar
            label={'Suche'}
            value={search}
            disabled={false}
            readOnly={false}
            required={false}
            formstate={'neutral'}
            type={'primary'}
            onSearch={(e: any) =>
              filterUsingSearchTermSortAndFilter(
                initData,
                e,
                sort,
                currentFilter
              )
            }
          />
        </div>
      </div>
    );
  }

  function loadProjectFromZip(file: FileUploadType) {
    setUploadDialogProgress(true);

    //extract zip

    //unpack & filestructure testing
    unpackZip(file.file)
      .then((data: any) => {
        const zipData: ZipData = data;
        //ping liegenschaftsservice + mediaservie
        pingLiegenschaftenService()
          .then((data) => {
            pingMediaService()
              .then((data) => {
                const lsImages = zipData.lsImages;
                const buildingImages = zipData.buildingImages;
                const lsDocs = zipData.lsDocs;
                const buildingDocs = zipData.buildingDocs;
                const lsMainImage = zipData.lsMainImg;
                const buildingMainImage = zipData.buildingMainImg;
                const excel = zipData.excel;
                //upload excel
                if (excel && excel.length > 0) {
                  const excelFile = new File([excel[0].blob], excel[0].name);
                  importLiegenschaftExcel(excelFile)
                    .then(async (data) => {
                      const lsGebData: any = _.cloneDeep(data);

                      //save ids of uploaded images and docs
                      let lsImgIds: string[] = [];
                      let lsMainImg = {} as ImageResponse;
                      let buildingImgIds: string[] = [];
                      let buildingMainImg = {} as ImageResponse;
                      let lsDocIds: string[] = [];
                      let buildingDocIds: string[] = [];

                      //initialize errors
                      let lsImageError = [];
                      let lsMainImageError;
                      let lsDocError = [];
                      let buildingImageError = [];
                      let buildingMainImageError;
                      let buildingDocError = [];

                      //promises
                      let lsUploadImgProms =
                        lsImages && lsImages.length > 0
                          ? lsImages.map(
                              (lsImage: { blob: Blob; name: string }) => {
                                const file = new File(
                                  [lsImage.blob],
                                  lsImage.name
                                );
                                return uploadImageToServer(
                                  file,
                                  true,
                                  lsImage.name
                                );
                              }
                            )
                          : [];
                      let buildingUploadImgProms =
                        buildingImages && buildingImages.length > 0
                          ? buildingImages.map((buildingImage: zipMedia) => {
                              const file = new File(
                                [buildingImage.blob],
                                buildingImage.name
                              );
                              return uploadImageToServer(
                                file,
                                true,
                                buildingImage.name
                              );
                            })
                          : [];

                      const fileMainImgLs = lsMainImage
                        ? new File([lsMainImage.blob], lsMainImage.name)
                        : null;
                      let lsUploadMainImgProms = lsMainImage
                        ? [
                            uploadImageToServer(
                              fileMainImgLs!,
                              true,
                              lsMainImage.name
                            )
                          ]
                        : [];

                      const fileMainImgGeb = buildingMainImage
                        ? new File(
                            [buildingMainImage.blob],
                            buildingMainImage.name
                          )
                        : null;
                      let buildingUploadMainImgProms = buildingMainImage
                        ? [
                            uploadImageToServer(
                              fileMainImgGeb!,
                              true,
                              buildingMainImage.name
                            )
                          ]
                        : [];

                      //docs
                      let lsUploadDocProms =
                        lsDocs && lsDocs.length > 0
                          ? lsDocs.map((lsDoc: zipMedia) => {
                              const file = new File([lsDoc.blob], lsDoc.name);
                              return uploadDocumentToServer(
                                file,
                                true,
                                lsDoc.name
                              );
                            })
                          : [];
                      let buildingUploadDocProms =
                        buildingDocs && buildingDocs.length > 0
                          ? buildingDocs.map((buildingDoc: zipMedia) => {
                              const file = new File(
                                [buildingDoc.blob],
                                buildingDoc.name
                              );
                              return uploadDocumentToServer(
                                file,
                                true,
                                buildingDoc.name
                              );
                            })
                          : [];

                      //alle images und docs hochladen
                      const lsResolvedImgs: any = await Promise.allSettled(
                        lsUploadImgProms
                      );
                      const lsResolvedMainImg: any = await Promise.allSettled(
                        lsUploadMainImgProms
                      );
                      const lsResolvedDocs: any = await Promise.allSettled(
                        lsUploadDocProms
                      );
                      const buildingResolvedImgs: any =
                        await Promise.allSettled(buildingUploadImgProms);
                      const buildingResolvedMainImg: any =
                        await Promise.allSettled(buildingUploadMainImgProms);
                      const buildingResolvedDocs: any =
                        await Promise.allSettled(buildingUploadDocProms);

                      //save image ids to update ls
                      if (lsResolvedImgs && lsResolvedImgs.length > 0) {
                        for (let i = 0; i < lsResolvedImgs.length; i++) {
                          if (lsResolvedImgs[i].status === 'fulfilled') {
                            lsImgIds.push(lsResolvedImgs[i].value.id);
                          } else {
                            lsImageError.push(lsResolvedImgs[i].reason);
                          }
                        }
                      }
                      //save doc ids
                      if (lsResolvedDocs && lsResolvedDocs.length > 0) {
                        for (let i = 0; i < lsResolvedDocs.length; i++) {
                          if (lsResolvedDocs[i].status === 'fulfilled') {
                            lsDocIds.push(lsResolvedDocs[i].value.id);
                          } else {
                            lsDocError.push(lsResolvedDocs[i].reason);
                          }
                        }
                      }
                      //save main img id
                      if (lsResolvedMainImg && lsResolvedMainImg.length > 0) {
                        if (lsResolvedMainImg[0].status === 'fulfilled') {
                          lsMainImg =
                            lsResolvedMainImg && lsResolvedMainImg.length > 0
                              ? lsResolvedMainImg[0].value
                              : null;
                        } else {
                          lsMainImageError = lsResolvedMainImg[0].reason;
                        }
                      }

                      if (
                        (lsImgIds && lsImgIds.length > 0) ||
                        (lsDocIds && lsDocIds.length > 0) ||
                        lsMainImg
                      ) {
                        const lsId = lsGebData.id;
                        let lsData = _.cloneDeep(lsGebData);

                        if (lsData.gebaeude) {
                          delete lsData.gebaeude;
                        }

                        lsData.mediaIds = [...lsImgIds, ...lsDocIds];

                        lsData.hauptBildId =
                          lsMainImg && lsMainImg.id ? lsMainImg.id : null;

                        await updateLiegenschaftById(lsId, lsData)
                          .then((data) => {
                            //ls data erfolgreich mit media verknüpft
                          })
                          .catch((error) => {
                            console.log(error);

                            lsImageError = lsImgIds;
                            lsMainImageError = lsMainImg;
                            lsDocError = lsDocIds;
                          });
                      }

                      //save img ids of building and update building
                      if (
                        buildingResolvedImgs &&
                        buildingResolvedImgs.length > 0
                      ) {
                        for (let i = 0; i < buildingResolvedImgs.length; i++) {
                          if (buildingResolvedImgs[i].status === 'fulfilled') {
                            buildingImgIds.push(
                              buildingResolvedImgs[i].value.id
                            );
                          } else {
                            buildingImageError.push(
                              buildingResolvedImgs[i].reason
                            );
                          }
                        }
                      }

                      //save Doc ids of building
                      if (
                        buildingResolvedDocs &&
                        buildingResolvedDocs.length > 0
                      ) {
                        for (let i = 0; i < buildingResolvedDocs.length; i++) {
                          if (buildingResolvedDocs[i].status === 'fulfilled') {
                            buildingDocIds.push(
                              buildingResolvedDocs[i].value.id
                            );
                          } else {
                            buildingDocError.push(
                              buildingResolvedDocs[i].reason
                            );
                          }
                        }
                      }

                      //save main img id of building
                      if (
                        buildingResolvedMainImg &&
                        buildingResolvedMainImg.length > 0
                      ) {
                        if (buildingResolvedMainImg[0].status === 'fulfilled') {
                          buildingMainImg =
                            buildingResolvedMainImg &&
                            buildingResolvedMainImg.length > 0
                              ? buildingResolvedMainImg[0].value
                              : null;
                        } else {
                          buildingMainImageError =
                            buildingResolvedMainImg[0].reason;
                          //FIXME
                        }
                      }

                      //update building
                      if (
                        (buildingImgIds && buildingImgIds.length > 0) ||
                        (buildingDocIds && buildingDocIds.length > 0) ||
                        buildingMainImg
                      ) {
                        const lsId = lsGebData.id;
                        const gebId = lsGebData.gebaeude[0].id;
                        let gebData = _.cloneDeep(lsGebData.gebaeude[0]);

                        gebData.mediaIds = [
                          ...buildingImgIds,
                          ...buildingDocIds
                        ];
                        gebData.hauptBildId =
                          buildingMainImg && buildingMainImg.id
                            ? buildingMainImg.id
                            : null;

                        updateGebaeudeById(lsId, gebId, gebData)
                          .then((data) => {
                            //geb erfolgreich mit media verknüpft
                          })
                          .catch((error) => {
                            //geb nicht updatebar
                            console.log(error);

                            buildingImageError = buildingImgIds;
                            buildingMainImageError = buildingMainImg;
                            buildingDocError = buildingDocIds;
                          });
                      }

                      //show Toast of unsuccessful upload of images/docs
                      if (
                        (lsImageError && lsImageError.length > 0) ||
                        (lsDocError && lsDocError.length > 0) ||
                        lsMainImageError ||
                        (buildingImageError && buildingImageError.length > 0) ||
                        (buildingDocError && buildingDocError.length > 0) ||
                        buildingMainImageError
                      ) {
                        const detailLsImage =
                          lsImageError && lsImageError.length > 0
                            ? lsImageError.toString() + ','
                            : '';
                        const detailLsDoc =
                          lsDocError && lsDocError.length > 0
                            ? lsDocError.toString() + ','
                            : '';
                        const detailLsMainImg = lsMainImageError
                          ? lsMainImageError.toString() + ','
                          : '';

                        const detailGebImage =
                          buildingImageError && buildingImageError.length > 0
                            ? buildingImageError.toString() + ','
                            : '';
                        const detailGebDoc =
                          buildingDocError && buildingDocError.length > 0
                            ? buildingDocError.toString() + ','
                            : '';
                        const detailGebMainImg = buildingMainImageError
                          ? buildingMainImageError.toString() + ','
                          : '';

                        setUploadDialogProgress(false);
                        setV_lsUpload(false);

                        //FIXME test
                        //fixme seite wird nicht neu geladen -> man sieht neu angelegtes nicht
                        toast.current?.show({
                          severity: 'warn',
                          summary:
                            'Einige Bilder oder Dokumente konnten nicht gespeichert werden',
                          detail:
                            'Die Liegenschaft wurde erfolgreich angelegt allerdings konnten nicht alle Bilder und Dokumente gespeichert werden. Bitte achten Sie darauf, dass Liegenschaftsname, -nummer, Gebäudename, -nummer und Adressen angegeben wurden und versuchen Sie es erneut oder importieren Sie die Dateien manuell: ' +
                            detailLsImage +
                            detailLsDoc +
                            detailLsMainImg +
                            detailGebImage +
                            detailGebDoc +
                            detailGebMainImg,
                          sticky: true,
                          closable: true,
                          life: DURATION_NOTIFICATION_ERROR_LONG
                        });
                      } else {
                        setUploadDialogProgress(false);
                        setV_lsUpload(false);
                        setNewUploadSuccess(true);
                        toast.current?.show({
                          severity: 'success',
                          summary:
                            'Die Liegenschaft wurde erfolgreich importiert',
                          detail:
                            'Die Liegenschaft, ihre Gebäude sowie Bilder und Dateien wurden erfolgreich hochgeladen',
                          sticky: false,
                          closable: true,
                          life: DURATION_NOTIFICATION_SUCCESS_LONG
                        });
                      }

                      //upload als nicht zip ordner?
                    })
                    .catch((error) => {
                      //excel error

                      setUploadDialogProgress(false);
                      setErrorUploadDialog(
                        'Upload fehlgeschlagen - Die Excel konnte nicht importiert werden'
                      );

                      setErrorUploadDialogDescr(
                        error
                          ? errorMsgToString(error)
                          : 'Die Excel mit den Liegenschafts- und Gebäudedaten konnte nicht hochgeladen werden. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.'
                      );
                    });
                } else {
                  setUploadDialogProgress(false);
                  setErrorUploadDialog('Upload fehlgeschlagen');

                  setErrorUploadDialogDescr(
                    'Die Excel mit den Liegenschafts- und Gebäudedaten konnte nicht gefunden werden. Bitte überprüfen Sie Ihre Ordnerstruktur. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.'
                  );
                }
              })
              .catch((error) => {
                setErrorUploadDialog('Upload fehlgeschlagen');
                setErrorUploadDialogDescr(
                  'Der Server konnte nicht erreicht werden. Dadurch kann der Upload der Liegenschaft und ihrer Bilder nicht durchgeführt werden. Bitte versuchen Sie es in ein paar Minuten erneut oder wenden Sie sich an den Kundenservice.'
                );
                setUploadDialogProgress(false);
              });
          })
          .catch((error) => {
            setErrorUploadDialog('Upload fehlgeschlagen');
            setErrorUploadDialogDescr(
              'Der Server konnte nicht erreicht werden. Dadurch kann der Import nicht durchgeführt werden. Bitte versuchen Sie es in ein paar Minuten erneut oder wenden Sie sich an den Kundenservice.'
            );
            setUploadDialogProgress(false);
          });
      })
      .catch((error) => {
        setErrorUploadDialog(
          'Die Datei konnten nicht entpackt werden. Bitte prüfen Sie auf eine korrekte Ordnerstruktur. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.'
        );
        setErrorUploadDialogDescr(
          'Die Dateien konnten nicht entpackt werden. Bitte prüfen Sie die Dateitypen des Uploadordners. Bilder werden nur als JPG, JPEG und PNG akzeptiert. Dokumente als PPT, PPTX, XLSX, XLS, PDF, DOC, DOCX, JPG, JPEG und PNG. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.'
        );
        setUploadDialogProgress(false);
      });
  }

  return (
    <div
      id={currentId}
      className={'alpha-liegenschaft-verwaltung m-4 pt-2 justify-content-start'}
    >
      {injectHeadline()}

      {injectSearchArea()}
      <DataView
        value={visibleData}
        itemTemplate={template}
        header={renderDataViewHeadline()}
        emptyMessage={labelEmptyMessage}
      />
      {v_lsUpload ? (
        <BeeUploadDialog
          visible={v_lsUpload}
          type={'primary'}
          disabled={false}
          locale={'de-DE'}
          header={'Zip-Ordner hochladen'}
          titleLabel={''}
          titleVisible={false}
          copyrightLabel={''}
          copyrightVisible={false}
          dropzoneTitle={'Drag and Drop Upload'}
          dropzoneDescription={
            'Bitte ziehen Sie den gewünschten Ordner in diesen Bereich oder Klicken Sie hinzufügen. Der Ordner sollte eine passende Excel-Datei sowie alle in der Excel referenzierten Bilder enthalten'
          }
          dropzoneAddLabel={'Klicken zum Hinzufügen'}
          dropzoneFormats={['.zip']}
          progressVisible={uploadDialogProgress}
          progressMessage={'Datei wird hochgeladen...'}
          errorVisible={errorUploadDialog ? true : false}
          errorHeadline={'Der Upload ist leider fehlgeschlagen'}
          errorDescription={errorUploadDialogDescr}
          onHide={() => {
            setV_lsUpload(false);
            setUploadDialogProgress(false);
            setErrorUploadDialog('');
            setErrorUploadDialogDescr('');
          }}
          onUpload={(file: FileUploadType) => {
            loadProjectFromZip(file);
          }}
        />
      ) : null}
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}

const mapState = (state: RootState) => {
  return {
    refreshToken: state.app.refreshToken
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {};
};

export default connect(
  mapState,
  mapDispatchToProps
)(AlphaLiegenschaftVerwaltung);
