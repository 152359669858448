import _ from 'lodash';

export function generateZipName(name: string) {
  const ending = '.zip';
  const filename = name;
  return generateDate() + '_' + filename + ending;
}

function cleanString(input: string) {
  if (input) {
    const name = input.replace(/[^a-z0-9]/gi, '');
    if (name !== '') {
      return name;
    } else {
      return '_';
    }
  } else {
    return '_';
  }
}

function generateDate() {
  const today = new Date();
  return (
    today.getFullYear() + '.' + (today.getMonth() + 1) + '.' + today.getDate()
  );
}

function generateTime() {
  const today = new Date();
  return today.getHours() + '-' + today.getMinutes() + '-' + today.getSeconds();
}
