import { Checkbox, CheckboxChangeParams } from 'primereact/checkbox';
import { useId } from 'react-id-generator';

//theme
import './BeeCheckbox.scss';
type BeeCheckboxProps = {
  id?: string;
  label?: string;
  stateFalse?: string;
  stateTrue?: string;
  value?: boolean | null | undefined;
  disabled?: boolean;
  formstate?: string;
  readOnly?: boolean;
  required?: boolean;
  onChange: (e: CheckboxChangeParams) => void;
};
export function BeeCheckbox({
  id,
  label,
  value,
  disabled,
  formstate,
  stateTrue,
  stateFalse,
  required,
  readOnly,
  onChange
}: BeeCheckboxProps) {
  const currentId = useId(1, 'bee-checkbox-')[0];

  const inputId = currentId + 'input';

  //classNames
  const formStateClassnames =
    formstate === 'valid'
      ? ' bee-checkbox-valid'
      : formstate === 'error'
      ? ' bee-checkbox-error'
      : formstate === 'neutral'
      ? ' bee-checkbox-neutral'
      : ' bee-checkbox-neutral';
  const labelClassNames = 'bee-checkbox-label';
  let readOnlyClassnames = '';
  if (readOnly) {
    readOnlyClassnames = ' bee-checkbox-readOnly';
    disabled = true;
  }
  const disabledClassNames = disabled ? ' bee-checkbox-disabled' : '';
  const classNames =
    'bee-checkbox ' +
    formStateClassnames +
    readOnlyClassnames +
    disabledClassNames;
  const inputClassNames = 'bee-checkbox-input';
  const stateLabelClassName = 'bee-checkbox-value';
  const requiredClassName = 'bee-checkbox-label-required';
  const requiredStarClassName = 'bee-checkbox-label-required-star';

  //placeholder
  const checkPlaceholder = 'pi pi-check';
  const timesPlaceholder = 'pi pi-times';
  const placeholderType = 'primary';
  const placeholderSize = 'large';

  function change(e: CheckboxChangeParams) {
    if (!disabled && onChange) {
      onChange(e);
    }
  }

  const renderCheckPlaceholder = () => {
    return (
      <i className={checkPlaceholder}></i>
      /*   <BeeIcon
        iconClass={checkPlaceholder}
        type={placeholderType}
        size={placeholderSize}
      /> */
    );
  };

  const renderTimesPlaceholder = () => {
    return (
      <i className={timesPlaceholder}></i>
      /*  <BeeIcon
        iconClass={timesPlaceholder}
        type={placeholderType}
        size={placeholderSize}
      /> */
    );
  };

  return (
    <span id={id ? id : currentId} className={classNames}>
      <div className={inputClassNames}>
        {readOnly ? (
          <>{value ? renderCheckPlaceholder() : renderTimesPlaceholder()}</>
        ) : (
          <Checkbox
            inputId={inputId}
            checked={value}
            onChange={(e) => change(e)}
          />
        )}
        <label className={stateLabelClassName} htmlFor={inputId}>
          {value === true
            ? stateTrue
            : value === false
            ? stateFalse
            : stateFalse}
        </label>
      </div>
      {label ? (
        <label htmlFor={inputId} className={labelClassNames}>
          {label}
          {required ? (
            <span className={requiredClassName}>
              <span className={requiredStarClassName}> *</span>
            </span>
          ) : null}
        </label>
      ) : null}
    </span>
  );
}

export default BeeCheckbox;
