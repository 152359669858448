import { Button } from 'primereact/button';
import React from 'react';
import { useId } from 'react-id-generator';
import './BeeLinkButton.scss';

type BeeLinkButtonProps = {
  id?: string;
  label?: any;
  raised?: boolean;
  disabled?: boolean;
  type?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export function BeeLinkButton({
  id,
  label,
  raised,
  disabled,
  type,
  onClick
}: BeeLinkButtonProps) {
  const genId = useId(1, 'bee-link-button-')[0];
  const currentId = id ? id : genId;

  //classNames
  const isRaisedClassNames = raised
    ? 'p-button-raised p-button-text'
    : 'p-button-text';

  const typeClassNames =
    type === 'primary'
      ? ' p-button-primary bee-link-btn-primary'
      : type === 'secondary'
      ? ' p-button-secondary bee-link-btn-secondary'
      : type === 'danger'
      ? ' p-button-danger bee-link-btn-danger'
      : type === 'light'
      ? ' bee-link-btn-light'
      : ' bee-link-btn-primary';

  const classNames = isRaisedClassNames + typeClassNames;

  function clicked(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (!disabled && onClick) {
      onClick(e);
    }
  }

  return (
    <Button
      className={classNames}
      disabled={disabled}
      id={currentId}
      label={label}
      onClick={(e) => clicked(e)}
    />
  );
}

export default BeeLinkButton;
