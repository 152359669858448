import Lottie from 'lottie-react';
import AnimationEmptyBox from '../../../Style/Lottie-Animations/629-empty-box.json';
import './NoPhases.scss';

export default function NoPhases() {
  return (
    <div className={'al-noPhases'}>
      <div className={'noPhases-content'}>
        <h2>Keine Phasen enthalten</h2>
        <div>
          Es wurde noch keine Phasen angelegt. Bitte legen Sie zunächst Phasen
          an.
        </div>
      </div>
      <div className={'animation animation-box'}>
        <Lottie animationData={AnimationEmptyBox} loop={true} autoplay={true} />
      </div>
    </div>
  );
}
